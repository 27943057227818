<div class="container signature-container">
    <div class="row">
        <div class="col-lg-12 col-12">
            <ul ngbNav #navJustified="ngbNav" class="nav-tabs" (navChange)="changeSignatureType($event)">
                <li ngbNavItem="draw">
                    <a ngbNavLink>Draw signature</a>
                    <ng-template ngbNavContent>
                        <div class="signature-header-text">
                            <span>John</span>
                            <span>{{currectDate|date:'MMM d y'}}</span>
                        </div>
                        <div class="signature-canvas-box">
                            <div class="signature-color-picker">
                                <div *ngFor="let color of penColors; let i = index" class="signature-color-{{color.colorname}}" [ngClass]="{'active-signature-color': i === penColorIndex}" (click)="changeColor(color,i)"></div>
                            </div>
                            <canvas id="regular_signaturepad" width="100%" height=270></canvas>
                            <span class="clear-signature" (click)="clearSignature()">Clear</span>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem ngbNavItem="type">
                    <a ngbNavLink>Type signature</a>
                    <ng-template ngbNavContent>
                        <div class="signature-header-text">
                            <span>John</span>
                            <span>{{currectDate|date:'MMM d y'}}</span>
                        </div>

                        <div class="signature-canvas-box">
                            <div class="signingTypeFontSize-dropdown">
                                <ng-select [items]="signatureFontSize" bindLabel="name" bindValue="name" [(ngModel)]="selectedSignatureFontSize" appendTo="body" [clearable]="false" placeholder="Choose Font Size" (change)="changeFontSize($event)"></ng-select>
                            </div>
                            <div class="signature-color-picker">
                                <div *ngFor="let color of penColors; let i = index" class="signature-color-{{color.colorname}}" [ngClass]="{'active-signature-color': i === penColorIndex}" (click)="changeColor(color,i)"></div>
                            </div>
                            <input [style.color]="penColor" [style.fontFamily]="fontFamily" [style.fontSize.px]="selectedSignatureFontSize" type="text" class="signature-type-box" placeholder="Type your signature" autofocus>
                            <button type="button" class="btn btn-success signatureTypeBtn" (click)="changeNextFont()">Next Font</button>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem ngbNavItem="upload">
                    <a ngbNavLink>Upload signature</a>
                    <ng-template ngbNavContent>
                        <div class="signature-header-text">
                            <span>John</span>
                            <span>{{currectDate|date:'MMM d y'}}</span>
                        </div>

                        <div class="signature-canvas-box uplaodSignature-canvas-box">
                            <button type="button" class="btn btn-success uploadSignatureBtn">Upload Your Signature</button>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="navJustified" class="mt-75"></div>
        </div>
    </div>
</div>