import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';



@Component({
  selector: 'app-pdfbreak-addon',
  templateUrl: './pdfbreak-addon.component.html',
  styleUrls: ['./pdfbreak-addon.component.scss']
})
export class PDFBreakAddonComponent implements OnInit {


  // modal Basic
  modalOpen(pdfbreakSettingModal) {
    this.modalService.open(pdfbreakSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }
  // swiper
  public swiperPaginations: SwiperConfigInterface = {
    // pagination: true
  };

  

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
