import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cc-custom-box',
  templateUrl: './cc-custom-box.component.html',
  styleUrls: ['./cc-custom-box.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CcCustomBoxComponent implements OnInit {

  hiddenValueField: boolean = false;
  ShowValueFieldClick() {
    this.hiddenValueField  = !this.hiddenValueField;
  }
  // modal Open Form
  customBoxAddNewFeildModalClick(customBoxAddNewFeildModal) {
    this.modalService.open(customBoxAddNewFeildModal);
  }
  public readonly = false;
  public searchable = false;
  chooseType = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
