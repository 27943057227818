import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-attachment-addon',
  templateUrl: './video-attachment-addon.component.html',
  styleUrls: ['./video-attachment-addon.component.scss']
})
export class VideoAttachmentAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
