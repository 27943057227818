import { Component, OnInit } from '@angular/core';
import { colors } from 'app/colors.const';

@Component({
  selector: 'app-contract-control-chart-view',
  templateUrl: './contract-control-chart-view.component.html',
  styleUrls: ['./contract-control-chart-view.component.scss']
})
export class ContractControlChartViewComponent implements OnInit {

  constructor() { }


// Color Variables
private primaryColorShade = '#836AF9';
private yellowColor = '#ffe800';
private successColorShade = '#28dac6';
private warningColorShade = '#ffe802';
private warningLightColor = '#FDAC34';
private infoColorShade = '#299AFF';
private greyColor = '#4F5D70';
private blueColor = '#2c9aff';
private blueLightColor = '#84D0FF';
private greyLightColor = '#EDF1F4';
private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
private lineChartPrimary = '#666ee8';
private lineChartDanger = '#ff4961';
private labelColor = '#6e6b7b';
private grid_line_color = 'rgba(200, 200, 200, 0.2)'; // RGBA color helps in dark layout

  // doughnut Chart
  public doughnutChart = {
    chartType: 'doughnut',
    options: {
      responsive: false,
      responsiveAnimationDuration: 500,
      cutoutPercentage: 60,
      aspectRatio: 1.4,
      legend: { display: false },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[0].labels[tooltipItem.index] || '',
              value = data.datasets[0].data[tooltipItem.index];
            var output = ' ' + label + ' : ' + value + ' %';
            return output;
          }
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      }
    },

    datasets: [
      {
        labels: ['Active', 'Inactive'],
        data: [80, 20],
        backgroundColor: [this.successColorShade, this.warningLightColor],
        borderWidth: 0,
        pointStyle: 'rectRounded'
      }
    ]
  };

  ngOnInit(): void {
  }

}
