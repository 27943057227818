<div class="card mb-1 p-50 CC_periodandrenewalContainer draggable">
    <div class="collapse-icon">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="lead collapse-title "> Period & renewal
                    </span>
                </ng-template>
                <ng-template ngbPanelContent class="p-0">
                    <div>
                        <form>
                            <div class="col-xl-12 col-md-12 col-12 mb-1 p-0">
                                <div class="form-group">
                                    <label for="first-name-icon">Start date</label>
                                    <div class="col-12 p-0">
                                        <ng2-flatpickr [config]="basicDateOptions" name="basicDate" class="CC_datePicker"></ng2-flatpickr>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-md-12 col-12 mb-1 p-0">
                                <div class="form-group mb-0">
                                    <label for="customSelect">Contract length</label>
                                    <ng-select [items]="chooseContractlength" bindLabel="name" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Contract length"> </ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-8 col-md-8 col-8 mb-1">
                                    <div class="form-group mb-0">
                                        <label for="WebhookURL">Notice period</label>
                                        <input type="number" class="form-control" id="" placeholder="Notice period" />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-4 mb-1 pl-0">
                                    <div class="form-group mb-0">
                                        <label for="">Days/Month</label>
                                        <ng-select [items]="chooseDaysMounth" bindLabel="name" [readonly]="readonly"
                                            [searchable]="searchable" placeholder=""> </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="custom-control custom-checkbox mb-1">
                                <input type="checkbox" class="custom-control-input" id="autorenewal" checked />
                                <label class="custom-control-label" for="autorenewal">Auto renewal</label>
                            </div>
                            <div class="row border-top pt-75">
                                <div class="col-xl-6 col-md-6 col-6 mb-1">
                                    <div class="form-group mb-0">
                                        <label for=""> Contract length after renewal</label>
                                        <ng-select [items]="chooseContractlengthAfterRenewal" bindLabel="name"
                                            [readonly]="readonly" [searchable]="searchable" placeholder=""> </ng-select>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-6 mb-1 pl-0">
                                    <div class="form-group mb-0">
                                        <label for="WebhookURL">
                                            Notice period after renewal
                                        </label>
                                        <input type="number" class="form-control" id="" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-xl-12 col-md-12 col-12 mb-1">
                                    <div class="form-group mb-0">
                                        <label for="">Days/Month</label>
                                        <ng-select [items]="chooseDaysMounthLast" bindLabel="name" [readonly]="readonly"
                                            [searchable]="searchable" placeholder=""> </ng-select>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>