<!-- Main Sidebar -->
<div class="main-sidebar sidebar templateMainSideBar">
  <div class="sidebar-content todo-sidebar">
    <div class="todo-app-menu">
      <div class="add-task">
        <button type="button" class="btn btn-success btn-block" rippleEffect (click)="edittemplateView()">
          Add new template
        </button>
      </div>
      <div class="sidebar-menu-list">
        <div class="list-group list-group-filters">
          <a class="list-group-item list-group-item-action active">
            <i size="18" class="mr-50 feather icon-file-text align-middle"></i>
            <span class="align-middle">My templates</span>
          </a>
          <a class="list-group-item list-group-item-action">
            <i size="18" class="mr-50 feather icon-search align-middle"></i>
            <span class="align-middle">Explore templates</span>
          </a>
        </div>
        <div class="mt-2 px-2 d-flex justify-content-between">
          <h6 class="section-label mb-1">FILTERS</h6>
          <i class="feather icon-plus cursor-pointer" placement="auto" container="body" ngbTooltip="Show filters"
            (click)="templateFiltersShowHideClick()"></i>
        </div>
        <div class="templateFiltersContainer" *ngIf="tempfiltersShowHide">
          <div class="list-group-item mb-0 pb-0 pt-0">
            <label>Show document types</label>

            <div class="form-group mb-0">
              <!-- Basic Multi select -->
              <ng-select #SelectDocTypeSR [items]="showDocumentTypeFilter" bindLabel="name" [readonly]="readonly"
                [searchable]="false" placeholder="Please select">
                <ng-template ng-header-tmp>
                  <input class="form-control w-100" type="text" (input)="SelectDocTypeSR.filter($event.target.value)" />
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="dropdown-divider mb-1 mt-2 "></div>
          <div class="list-group-item pb-0 pt-0 mb-0">
            <label>Show all categories</label>

            <div class="form-group mb-0">
              <ng-select #SelectDocTypeSR [items]="showAllCategory" bindLabel="name" [readonly]="readonly" [searchable]="false"
                placeholder="Please select">
                <ng-template ng-header-tmp>
                  <input class="form-control w-100" type="text" (input)="SelectDocTypeSR.filter($event.target.value)" />
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="list-group-item mb-0">
            <label>Show all Languages</label>

            <div class="form-group mb-0">
              <ng-select #SelectDocTypeSR [items]="showAllLanguages" bindLabel="name" [readonly]="readonly"
                [searchable]="false" placeholder="Please select">
                <ng-template ng-header-tmp>
                  <input class="form-control w-100" type="text" (input)="SelectDocTypeSR.filter($event.target.value)" />
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<!--/ Main Sidebar -->