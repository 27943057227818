import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-users-rightmenu',
  templateUrl: './users-rightmenu.component.html',
  styleUrls: ['./users-rightmenu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersRightmenuComponent implements OnInit {

   // modal Open Form
   userAddFieldModalOpen(userAddFieldModal) {
    this.modalService.open(userAddFieldModal);
  }
  userfieldtype = [
    { id: 1, name: 'Text' },
    { id: 2, name: 'Date picker' },
    { id: 3, name: 'Multiple choice' }
  ];
  constructor(private modalService: NgbModal,private _coreSidebarService: CoreSidebarService) { }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }


  ngOnInit(): void {
  }

}
