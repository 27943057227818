<div class="card addonBoxContainer">
    <div class="card-body">
        <h5>ESP</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Select which ESP provider you want to use for your SimpleSign account
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <img src="assets/images/addons-images/esp.png" class="addonThumbnailImg" alt="addonThumbnail" />
    </div>
</div>