import { Injectable } from '@angular/core';
import axios from 'axios';
import { AuthenticationService } from 'app/auth/service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _authenticationService: AuthenticationService) {
    this.initAxios();
  }

  /**
   * Init axios library
   */
  async initAxios(){
    axios.interceptors.request.use(
    async (config) => {
        const token = await this._authenticationService.getData('_token');
        console.log(token,'token');
        config.baseURL = 'https://api.simplesign.se/api/';
        // config.timeout = 8000;
        config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["Accept"] = 'application/json';
        config.headers["Content-Type"] = 'application/x-www-form-urlencoded'
        return config;
    });
    axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        return error.response;
        // Do something with response error
        // return Promise.reject(error.response);
    });
  }
  
  /**
   * 
   * @param credential email and password
   */
  doLogin(credential:any){
    return axios.post('login',credential);
  }

  doRegister(registerDetails:any){
    return axios.post('register',registerDetails);
  }

  /**
   * logout the user
   */
  logout(){
    return axios.post('logout');
  }

}
