import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FlatpickrOptions } from 'ng2-flatpickr';



@Component({
  selector: 'app-document-right-sidebar',
  templateUrl: './document-right-sidebar.component.html',
  styleUrls: ['./document-right-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})



export class DocumentRightSidebarComponent implements OnInit {
  public basicDateOptions: FlatpickrOptions = {
    altInput: true
  }

  // Chat Widget
  public userProfile;
  public chatUser;
  public chats;
  public newChat;
  public chatMessage = '';


  size = 30;
  constructor(private _coreSidebarService: CoreSidebarService) { }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
  }
}
