<div class="col-md-12 p-0">
    <div class="card text-center mb-1">
        <div class="card-body">
            <ul ngbNav #navTab="ngbNav" class="nav-tabs border-bottom">
                <li ngbNavItem>
                    <a ngbNavLink>Upcoming events</a>
                    <ng-template ngbNavContent>
                        <div id="upcomingEvent-chart">
                            <canvas baseChart #barChartRef [datasets]="barChart.datasets" [labels]="barChart.labels"
                                [options]="barChart.options" [legend]="barChart.legend" [chartType]="barChart.chartType">
                            </canvas>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>Notes</a>
                    <ng-template ngbNavContent>
                        <fieldset class="form-label-group">
                            <textarea class="form-control" id="label-textarea" rows="4" placeholder="Enter your comment here"></textarea>
                        </fieldset>
                        <button type="button" class="btn btn-sm float-right btn-primary" rippleEffect>Submit</button>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a href="javascript:void(0)" ngbNavLink>Activity</a>
                    <ng-template ngbNavContent>
                        <div class="addActivityBtn p-3">
                            <button type="button" class="btn btn-success" (click)="CC_addActivityModalOpen(CC_addActivityModal)"
                                rippleEffect>Add activity</button>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a href="javascript:void(0)" ngbNavLink>Documents</a>
                    <ng-template ngbNavContent>
                        <div class="col-8 m-auto p-2">
                            <div class="d-flex align-content-center justify-content-between w-100">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i data-feather="search"></i></span>
                                    </div>
                                    <input type="text" class="form-control" id="search" placeholder="Search document"
                                        aria-label="Search..." aria-describedby="search" />
                                </div>
                            </div>
                            <p class="my-1">Or</p>
                            <button type="button" class="btn btn-success" (click)="CC_uploadDocumentModalOpen(CC_uploadDocumentModal)"
                                rippleEffect>Upload document from computer</button>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="navTab" class="mt-0 pt-75"></div>
        </div>
    </div>
</div>


<!-- addActivity Modal -->
<ng-template #CC_addActivityModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New activity</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <label for="Description">Description</label>
        <fieldset class="form-label-group">
            <textarea class="form-control" id="label-textarea" rows="3" placeholder="Description"></textarea>
        </fieldset>
        <div class="form-group">
            <label for="first-name-icon">Due Date</label>
            <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="Basic Date Picker" (click)="basicDP.toggle()"
                    [(ngModel)]="basicDPdata" ngbDatepicker #basicDP="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()" type="button"
                        rippleEffect></button>
                </div>
            </div>
        </div>
        <div class="addAsignIconCont">
            <label for="first-name-icon">Assign to:</label>
            <i data-feather="plus-circle" class="font-large-1 ml-50 addAsignIcon cursor-pointer"></i>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Create task
        </button>
    </div>
</ng-template>
<!-- / addActivity Modal -->

<!-- Upload document Modal -->
<ng-template #CC_uploadDocumentModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Upload document</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0" tabindex="0" ngbAutofocus>
        <div class="row py-2 px-2">
            <div class="col-6">
                <p>You can add all files to the same folder by selecting target folder here</p>
            </div>
            <div class="col-3">
                <div class="form-group mb-0">
                    <ng-select [items]="chooseSaveLocation" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                        placeholder="Please setect"> </ng-select>
                </div>
            </div>
            <div class="col-3 pl-0">
                <div class="form-group mb-0">
                    <ng-select [items]="choosePosition" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                        placeholder="Please select"> </ng-select>
                </div>
            </div>
        </div>
        <div class="d-flex align-content-center pb-50 px-2 border-bottom flex-row justify-content-between w-100">
            <div class="ULfilename">File name</div>
            <div class="ULDocumetType">Document type</div>
            <div class="ULfolder">Folder</div>
            <div class="ULsize">size</div>
            <div class="ULstatus">Status</div>
        </div>
        <div class="uploadedDocCont" [perfectScrollbar]>
            <h2 class="dragTextCC">Drag and drop</h2>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Start upload
        </button>
    </div>
</ng-template>
<!-- / Upload document Modal -->