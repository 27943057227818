import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BrandingService } from './branding.service';
import { GlobalService } from 'app/global/global.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrandingComponent implements OnInit {


  public title = 'colorPicker';
  public color: string = '#2889e9';
  public brandingForm: FormGroup;
  public loading: boolean = false;
  public arrayColors: any = {
    color1: '#2883e9',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  public selectedColor: string = 'color1';
  public backgroundLogo:any = '';
  public favicon:any = '';
  public readonly = false;
  public searchable = false;
  public submitted: boolean = false;
  public chooseheading = [
    { id: 1, heading: 'Normal' },
    { id: 2, heading: 'H1' },
    { id: 3, heading: 'H2' },
    { id: 4, heading: 'H3' },
    { id: 5, heading: 'H4' },
    { id: 5, heading: 'H5' },
    { id: 5, heading: 'H6' }
  ];

  public choosefontsize = [
    { id: 1, font_size: 8 },
    { id: 2, font_size: 9 },
    { id: 3, font_size: 11 },
    { id: 4, font_size: 12 },
    { id: 5, font_size: 13 },
    { id: 6, font_size: 14 },
    { id: 7, font_size: 16 },
    { id: 8, font_size: 18 },
    { id: 9, font_size: 20 },
    { id: 10, font_size: 22 },
    { id: 11, font_size: 24 },
    { id: 12, font_size: 26 },
    { id: 13, font_size: 28 },
    { id: 14, font_size: 32 },
    { id: 15, font_size: 36 },
    { id: 16, font_size: 48 },
    { id: 17, font_size: 72 }
  ];

  public choosefontstyle = [
    { id: 1, font_style: 'Arial' },
    { id: 2, font_style: 'Glegoo' },
    { id: 3, font_style: 'Comic Sans MS' },
    { id: 4, font_style: 'Tahoma' },
    { id: 5, font_style: 'Verdana' },
    { id: 6, font_style: 'Calibri' },
    { id: 7, font_style: 'Open Sans, sans-serif' },
    { id: 8, font_style: 'Lato' },
    { id: 9, font_style: 'Oswald' },
    { id: 10, font_style: 'Anuphan' },
    { id: 11, font_style: 'Roboto' },
    { id: 12, font_style: 'Roboto Condensed' },
    { id: 13, font_style: 'Roboto Mono' },
    { id: 14, font_style: 'Roboto Slab' },
    { id: 15, font_style: 'Roboto Flex' },
    { id: 16, font_style: 'Roboto Serif' },
    { id: 17, font_style: 'Abril Fatface' },
    { id: 18, font_style: 'Comfortaa' },
    { id: 19, font_style: 'Lobster' },
    { id: 20, font_style: 'Kanit' },
    { id: 21, font_style: 'Source Sans Pro' },
    { id: 22, font_style: 'Chivo Mono' },
    { id: 23, font_style: 'Dongle' },
    { id: 24, font_style: 'Raleway' },
    { id: 25, font_style: 'Nunito' },
    { id: 26, font_style: 'Sedgwick Ave Display' },
    { id: 27, font_style: 'Titillium Web' },
    { id: 28, font_style: 'DynaPuff' },
    { id: 29, font_style: 'Dancing Script' },
    { id: 30, font_style: 'Maven Pro' }
  ];
  constructor(private brandingService: BrandingService, private globalService: GlobalService, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.initForm({});
    this.getBrands();
    console.log(this.authenticationService.getData('user'), 'check')
  }



  /**
   * Initializing Form with Object
   * @param data 
   */
  initForm(data:any){
    this.brandingForm = new FormGroup({
      id: new FormControl(data.id ?? ''),
      logo: new FormControl(null),
      title: new FormControl(data.title ?? '', [Validators.required]),
      favicon: new FormControl(null),
      sms_originator: new FormControl(data.sms_originator ?? ''),
      heading: new FormControl(data.heading ?? 'Normal'),
      font_size: new FormControl(data.font_size ?? '8'),
      font_style: new FormControl(data.font_style ?? 'Tahoma'),
      font_color: new FormControl(data.font_color ?? 'rgb(0, 0, 0)')
    });
  }


  /** 
  * Convenience getter for easy access to form fields 
  */
  get form() {
    return this.brandingForm.controls;
  }


  /**
   * Remove media behalf on type
   * @param type 
   */
  removeMedia(type:any) {
    if(type == 'logo'){
      this.backgroundLogo = '';
      this.brandingForm.value.logo = null;
    }
    if(type == 'favicon'){
      this.favicon = '';
      this.brandingForm.value.favicon = null;
    }
  }


  /**
   * Showing image with FileReader
   * @param $event 
   */
  getLogo($event:any, type:any){
    var file = $event.target.files[0];
    if(type == 'logo') 
      this.brandingForm.patchValue({logo: file});
    else 
      this.brandingForm.patchValue({favicon: file});

    var that = this;
    if(file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = function(event) {
        const dataURL = event.target.result;
        if(type == 'logo'){
          that.backgroundLogo = dataURL;
        }else {
          that.favicon = dataURL;
        }
      };
      reader.readAsDataURL(file);
    }
  }


  /**
   * Fetching brandings data
   */
  async getBrands(){
    const brandingData = await this.brandingService.getBrandingData();
    this.initForm(brandingData.data.data);
    this.showMediaFiles(brandingData.data.data);
  }


  /**
   * Submiting Branding form
   */ 
  async submitBrandingData(){
    this.submitted = true;
    this.loading = true;
    var formData = this.globalService.toFormData(this.brandingForm.value);
    const brandingdata = await this.brandingService.createBrandingData(formData);
    if(brandingdata.status != 200){
      this.submitted = false;
      this.loading = false;
      this.globalService.toastrError(brandingdata.data.message);
      return;
    }
    this.globalService.toastrSuccess(brandingdata.data.message);
    this.showMediaFiles(brandingdata.data.data);
    this.initForm(brandingdata.data.data);
    this.loading = false;
    this.submitted = false;
  }


  /**
   * Showing media if its uploaded
   * @param brandingdata 
   */
  showMediaFiles(brandingdata:any){
    var logoImg = brandingdata.logo ? this.globalService.basePath + 'uploads/brand/logos/' + brandingdata.user_id + '/' + brandingdata.logo : '';
    var favicon = brandingdata.favicon ? this.globalService.basePath + 'uploads/brand/favicon/' + brandingdata.user_id + '/' + brandingdata.favicon : '';
    this.backgroundLogo = logoImg;
    this.favicon = favicon;
  }

}
