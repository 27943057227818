import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { CoreSidebarModule } from '@core/components'
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { TabsModule } from 'app/main/components/tabs/tabs.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgSelectModule } from '@ng-select/ng-select';






import { AddonsComponent } from './addons/addons.component';
import { SimpleStorageAddonComponent } from './simple-storage-addon/simple-storage-addon.component';
import { GetContactsfromAPIAddonComponent } from './get-contactsfrom-apiaddon/get-contactsfrom-apiaddon.component';
import { ConnectYourDropboxAddonComponent } from './connect-your-dropbox-addon/connect-your-dropbox-addon.component';
import { ContentLibraryAddonComponent } from './content-library-addon/content-library-addon.component';
import { DroplistSettingsAddonComponent } from './droplist-settings-addon/droplist-settings-addon.component';
import { ProductTableAddonComponent } from './product-table-addon/product-table-addon.component';
import { DocumentFieldsAddonComponent } from './document-fields-addon/document-fields-addon.component';
import { ContractControlAddonComponent } from './contract-control-addon/contract-control-addon.component';
import { ConnectYourGoogleDriveAddonComponent } from './connect-your-google-drive-addon/connect-your-google-drive-addon.component';
import { DSRPlugandPlayAddonComponent } from './dsrplugand-play-addon/dsrplugand-play-addon.component';
import { DocumentKeyAddonComponent } from './document-key-addon/document-key-addon.component';
import { BulkSendAddonComponent } from './bulk-send-addon/bulk-send-addon.component';
import { SchoolsoftIntegrationAddonComponent } from './schoolsoft-integration-addon/schoolsoft-integration-addon.component';
import { PDFBreakAddonComponent } from './pdfbreak-addon/pdfbreak-addon.component';
import { AutoSaveChangesAddonComponent } from './auto-save-changes-addon/auto-save-changes-addon.component';
import { DocumentWorkflowAddonComponent } from './document-workflow-addon/document-workflow-addon.component';
import { BrandingAddonComponent } from './branding-addon/branding-addon.component';
import { FTPUploadAddonComponent } from './ftpupload-addon/ftpupload-addon.component';
import { InMeetingSigningAddonComponent } from './in-meeting-signing-addon/in-meeting-signing-addon.component';
import { GetLinkAddonComponent } from './get-link-addon/get-link-addon.component';
import { CreditSafeAddonComponent } from './credit-safe-addon/credit-safe-addon.component';
import { RevokeAddonComponent } from './revoke-addon/revoke-addon.component';
import { PointofSaleAddonComponent } from './pointof-sale-addon/pointof-sale-addon.component';
import { CsvexportinDocumentAddonComponent } from './csvexportin-document-addon/csvexportin-document-addon.component';
import { APIAddonComponent } from './apiaddon/apiaddon.component';
import { TaskManagementAddonComponent } from './task-management-addon/task-management-addon.component';
import { AutomaticalDeletionofDocumentsAddonComponent } from './automatical-deletionof-documents-addon/automatical-deletionof-documents-addon.component';
import { TimeWaveAddonComponent } from './time-wave-addon/time-wave-addon.component';
import { EpsAddonComponent } from './eps-addon/eps-addon.component';
import { SwedishBankIDAddonComponent } from './swedish-bank-idaddon/swedish-bank-idaddon.component';
import { FinnishBankIDAddonComponent } from './finnish-bank-idaddon/finnish-bank-idaddon.component';
import { DanishBankIDAddonComponent } from './danish-bank-idaddon/danish-bank-idaddon.component';
import { NorwegianBankIDAddonComponent } from './norwegian-bank-idaddon/norwegian-bank-idaddon.component';
import { SendPDFWordExcelAddonComponent } from './send-pdfword-excel-addon/send-pdfword-excel-addon.component';
import { InvitationOrderAddonComponent } from './invitation-order-addon/invitation-order-addon.component';
import { ContactsAddonComponent } from './contacts-addon/contacts-addon.component';
import { VideoAttachmentAddonComponent } from './video-attachment-addon/video-attachment-addon.component';
import { AttachmentAddonComponent } from './attachment-addon/attachment-addon.component';
import { TransferSignatureRoleAddonComponent } from './transfer-signature-role-addon/transfer-signature-role-addon.component';
import { AttestParticipantAddonComponent } from './attest-participant-addon/attest-participant-addon.component';
import { ExpirationDateAddonComponent } from './expiration-date-addon/expiration-date-addon.component';
import { EditorAddonComponent } from './editor-addon/editor-addon.component';
import { SharePointAddonComponent } from './share-point-addon/share-point-addon.component';
import { GroupsAndPermissionsAddonComponent } from './groups-and-permissions-addon/groups-and-permissions-addon.component';
import { AzureActiveDirectoryAddonComponent } from './azure-active-directory-addon/azure-active-directory-addon.component';
import { DocumentChatAddonComponent } from './document-chat-addon/document-chat-addon.component';
import { DocumentPlusAddonComponent } from './document-plus-addon/document-plus-addon.component';
import { VerificationUsingBSIAddonComponent } from './verification-using-bsiaddon/verification-using-bsiaddon.component';
import { SAMLIDPAuthenticationAddonComponent } from './samlidpauthentication-addon/samlidpauthentication-addon.component';
import { TwoFactorAuthenticationAddonComponent } from './two-factor-authentication-addon/two-factor-authentication-addon.component';
import { LockOptionfortemplateDetailsAddonComponent } from './lock-optionfortemplate-details-addon/lock-optionfortemplate-details-addon.component';
import { PipedriveAddonComponent } from './pipedrive-addon/pipedrive-addon.component';
import { HubSpotAddonComponent } from './hub-spot-addon/hub-spot-addon.component';
import { MicrosoftDynamics365AddonComponent } from './microsoft-dynamics365-addon/microsoft-dynamics365-addon.component';
import { HideOptionforDocumentfieldsAddonComponent } from './hide-optionfor-documentfields-addon/hide-optionfor-documentfields-addon.component';
import { ViewerPartyAddonComponent } from './viewer-party-addon/viewer-party-addon.component';
import { DocumentTypeAddonComponent } from './document-type-addon/document-type-addon.component';
import { DocumentPriceAddonComponent } from './document-price-addon/document-price-addon.component';
import { CustomSMSInvitationAddonComponent } from './custom-smsinvitation-addon/custom-smsinvitation-addon.component';
import { DataGroupsAddonComponent } from './data-groups-addon/data-groups-addon.component';
import { SMTPAddonComponent } from './smtpaddon/smtpaddon.component';
import { UploadFilesAfterSigningAddonComponent } from './upload-files-after-signing-addon/upload-files-after-signing-addon.component';
import { BillectaDataAddonComponent } from './billecta-data-addon/billecta-data-addon.component';
import { TemplateRedirectAddonComponent } from './template-redirect-addon/template-redirect-addon.component';
import { SignaturePopupMessageAddonComponent } from './signature-popup-message-addon/signature-popup-message-addon.component';
import { SelectTypeofSignatureAddonComponent } from './select-typeof-signature-addon/select-typeof-signature-addon.component';
import { WidgetwithBillectaAddonComponent } from './widgetwith-billecta-addon/widgetwith-billecta-addon.component';
import { UpsalesAddonComponent } from './upsales-addon/upsales-addon.component';





// routing
const routes: Routes = [
  {
    path: 'addons/addons',
    component: AddonsComponent,
    data: { animation: 'misc' }
  },
]

@NgModule({
  declarations: [
    AddonsComponent,
    SimpleStorageAddonComponent,
    GetContactsfromAPIAddonComponent,
    ConnectYourDropboxAddonComponent,
    ContentLibraryAddonComponent,
    DroplistSettingsAddonComponent,
    ProductTableAddonComponent,
    DocumentFieldsAddonComponent,
    ContractControlAddonComponent,
    ConnectYourGoogleDriveAddonComponent,
    DSRPlugandPlayAddonComponent,
    DocumentKeyAddonComponent,
    BulkSendAddonComponent,
    SchoolsoftIntegrationAddonComponent,
    PDFBreakAddonComponent,
    AutoSaveChangesAddonComponent,
    DocumentWorkflowAddonComponent,
    BrandingAddonComponent,
    FTPUploadAddonComponent,
    InMeetingSigningAddonComponent,
    GetLinkAddonComponent,
    CreditSafeAddonComponent,
    RevokeAddonComponent,
    PointofSaleAddonComponent,
    CsvexportinDocumentAddonComponent,
    APIAddonComponent,
    TaskManagementAddonComponent,
    AutomaticalDeletionofDocumentsAddonComponent,
    TimeWaveAddonComponent,
    EpsAddonComponent,
    SwedishBankIDAddonComponent,
    FinnishBankIDAddonComponent,
    DanishBankIDAddonComponent,
    NorwegianBankIDAddonComponent,
    SendPDFWordExcelAddonComponent,
    InvitationOrderAddonComponent,
    ContactsAddonComponent,
    VideoAttachmentAddonComponent,
    AttachmentAddonComponent,
    TransferSignatureRoleAddonComponent,
    AttestParticipantAddonComponent,
    ExpirationDateAddonComponent,
    EditorAddonComponent,
    SharePointAddonComponent,
    GroupsAndPermissionsAddonComponent,
    AzureActiveDirectoryAddonComponent,
    DocumentChatAddonComponent,
    DocumentPlusAddonComponent,
    VerificationUsingBSIAddonComponent,
    SAMLIDPAuthenticationAddonComponent,
    TwoFactorAuthenticationAddonComponent,
    LockOptionfortemplateDetailsAddonComponent,
    PipedriveAddonComponent,
    HubSpotAddonComponent,
    MicrosoftDynamics365AddonComponent,
    HideOptionforDocumentfieldsAddonComponent,
    ViewerPartyAddonComponent,
    DocumentTypeAddonComponent,
    DocumentPriceAddonComponent,
    CustomSMSInvitationAddonComponent,
    DataGroupsAddonComponent,
    SMTPAddonComponent,
    UploadFilesAfterSigningAddonComponent,
    BillectaDataAddonComponent,
    TemplateRedirectAddonComponent,
    SignaturePopupMessageAddonComponent,
    SelectTypeofSignatureAddonComponent,
    WidgetwithBillectaAddonComponent,
    UpsalesAddonComponent,
  ],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    RouterModule,
    CoreSidebarModule,
    CoreCommonModule,
    PerfectScrollbarModule,
    TabsModule,
    NgbModule,
    SwiperModule,
    NgSelectModule
  ]
})
export class AddonsModule { }
