<div class="card addonBoxContainer">
    <div class="card-body">
        <h5>Verification Using BSI</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Seal your signed documents using BSI (Blockchain Signature Infrastructure)
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <img src="assets/images/addons-images/fingerprint.png" class="addonThumbnailImg" alt="addonThumbnail" />
        <!-- <span [data-feather]="'save'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span> -->
    </div>
</div>