<div class="slideout-content">
    <!-- Modal to add attachments starts-->
    <div class="modalsd modal-slide-in sdfade recipient-right-sidebar" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <div class="modal-header mb-1 d-flex justify-content-between align-items-center px-2 py-1">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="avatar mr-1 bg-light-primary">
                            <div class="avatar-content">{{recipientForm.value.firstname + ' '+ recipientForm.value.lastname | shortName}}</div>
                        </div>
                        <h5 class="modal-title" id="exampleModalLabel">{{recipientForm.value.firstname + ' '+ recipientForm.value?.lastname}}</h5>
                    </div>
                    <div class="d-flex justify-content-end align-items-center text-dark cursor-pointer">
                        <span *ngIf="!isDeleted" placement="auto" container="body" ngbTooltip="Delete" (click)="removeRecipient()">
                            <i data-feather="trash" [size]="16" class="mr-75"></i>
                        </span>
                        <span *ngIf="isDeleted" placement="auto" container="body" ngbTooltip="Delete">
                            <span class="spinner-border spinner-border-sm mr-1"></span>
                        </span>
                        <span placement="auto" container="body" ngbTooltip="Send new">
                            <i data-feather="send" [size]="16" class="mr-75"></i>
                        </span>
                        <span placement="auto" container="body" ngbTooltip="Close" (click)="toggleSidebar('recipient-right-sidebar')">
                            <i data-feather="x" [size]="16" data-dismiss="modal" aria-label="Close"></i>
                        </span>
                    </div>
                </div>
                <div class="modal-body flex-grow-1 p-0">
                    <ul ngbNav #navJustified="ngbNav" class="nav-tabs nav-justified recipient-right-sidebarTabs" (navChange)="navChange($event)" [activeId]="'private'">
                        <li ngbNavItem="private">
                            <a ngbNavLink>Private</a>
                            <ng-template ngbNavContent>
                                <div class="px-2 py-2 recipient-scroll-content-container" [perfectScrollbar]>
                                    <div>
                                        <form [formGroup]="recipientForm" (ngSubmit)="updateRecipient()">
                                            <div class="form-group">
                                                <label class="form-label" for="first-Name">First Name</label>
                                                <input type="text" formControlName="firstname" class="form-control" aria-describedby="first-Name" [ngClass]="{ 'is-invalid error': submitted && form.firstname.errors }" />
                                                <div *ngIf="submitted && form.firstname.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.firstname.errors }">
                                                    <div *ngIf="form.firstname.errors.required">First name is required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="last-Name">Last Name</label>
                                                <input type="text" formControlName="lastname" class="form-control" aria-describedby="last-Name" />
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="email">Email</label>
                                                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.email.errors }" aria-describedby="email" />
                                                <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
                                                    <div *ngIf="form.email.errors.required">Email is required</div>
                                                    <div *ngIf="form.email.errors.email">Email must be a valid email address</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="editphone">Phone</label>
                                                <input id="editphone" type="tel" formControlName="phone_no" class="form-control" name="phone" aria-describedby="phone" [ngClass]="{ 'is-invalid': submitted && !phone_no_valid }" [value]="recipientData?.phone_no" />
                                                <div [ngClass]="{ 'is-invalid': submitted && !phone_no_valid }"></div>
                                                <div *ngIf="submitted && !phone_no_valid" class="invalid-feedback">
                                                    <div *ngIf="!phone_no_valid">Phone number is not valid</div>
                                                </div>
                                            </div>
                                            <div *ngIf="hiddenFields">
                                                <div class="form-group">
                                                    <label class="form-label" for="persnol-id-number">Persnol ID Number</label>
                                                    <input type="text" formControlName="persnol_no" class="form-control" aria-describedby="persnol-id-number" />
                                                </div>
                                                <div class="form-group">
                                                    <label class="form-label" for="address">Address</label>
                                                    <input type="text" formControlName="address" class="form-control" aria-describedby="address" />
                                                </div>
                                                <div class="form-group">
                                                    <label class="form-label" for="zip-code">Zip Code</label>
                                                    <input type="text" formControlName="zipcode" class="form-control" aria-describedby="zip-code" />
                                                </div>
                                                <div class="form-group">
                                                    <label class="form-label" for="city">City</label>
                                                    <input type="text" formControlName="city" class="form-control" aria-describedby="city" />
                                                </div>
                                            </div>
                                            <button type="button" class="btn p-0 ml-auto btn-sm d-flex text-primary btn-transparent" (click)="showHiddenFields()">{{changeText}}</button>
                                            <div class="d-flex mt-2">
                                                <button [disabled]="loading || !changesDetect" type="submit" class="btn btn-primary mr-1" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Update</button>
                                                <button [disabled]="loading" type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('recipient-right-sidebar')" rippleEffect>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                    <hr class="mt-2 mb-2">
                                    <div class="people-link-organization mb-0">
                                        <div class="user-page-info">
                                            <div class="d-flex justify-content-between align-items-center" *ngIf="connectedOrganization.length == 0"    >
                                                <div>
                                                    <h6 class="mb-0"> Organization </h6>
                                                    <small class="mb-0"> No organization linked</small>
                                                </div>
                                                <button placement="auto" container="body" ngbTooltip="Link an organization" type="button" class="btn btn-icon btn-primary btn-sm" (click)="linkOrganizationModalOpen(linkedOrganizationeModal)" rippleEffect>
                                                    <span [data-feather]="'link'"></span></button>
                                            </div>
                                        </div>

                                        <div class="added-people-listing-wrapper" *ngIf="objectLength(connectedOrganization).length > 0">
                                            <div class="mb-0 d-flex justify-content-between align-items-center px-0 pb-50 pt-1">
                                                <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                    <div class="avatar bg-light-info mr-75">
                                                        <div class="avatar-content">{{ connectedOrganization.name ?? '' | shortName }}</div>
                                                    </div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">{{ connectedOrganization.name }}
                                                    </p>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center text-dark">
                                                    <span placement="auto" container="body" ngbTooltip="Remove organization" (click)="unlinkOrganization($event)">
                                                        <i data-feather="trash" [size]="16" class="cursor-pointer"
                                                            data-dismiss="modal" aria-label="Close"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem="comments">
                            <a ngbNavLink>Comments</a>
                            <ng-template ngbNavContent>
                                <div class="recipientChatContainer chat-widget">
                                    <!-- User Chat messages -->
                                    <section class="chat-app-window">
                                        <div class="user-chats" [perfectScrollbar]>
                                            <div class="chats">
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best admin template. Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide you such information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best admin template. Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide you such information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best admin template. Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide you such information.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat ClientChatContainer">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best admin template. Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content InternalComment">
                                                            <p class="font-small-3">Hey John, I am looking for the best admin template. Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Submit Chat form -->
                                        <form class="chat-app-form justify-content-between">
                                            <div class="input-group input-group-merge mr-50 w-75 form-send-message">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <label for="attach-doc" class="attachment-icon mb-0">
                                                            <i data-feather="image" class="cursor-pointer lighten-2 text-secondary"></i>
                                                            <input type="file" id="attach-doc" hidden /> </label></span>
                                                </div>
                                                <input type="text" class="form-control message" placeholder="Type your message" name="chat-message" />
                                            </div>
                                            <!-- <div class=" w-100 mr-50">
                                                <fieldset>
                                                    <div class="input-group">
                                                        <div ngbDropdown class="input-group-prepend">
                                                            <button type="button" class="btn btn-outline-primary"
                                                                ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false" rippleEffect>
                                                                <i data-feather="edit-2"></i>
                                                            </button>
                                                            <div ngbDropdownMenu>
                                                                <a ngbDropdownItem>Internal comment</a>
                                                                <a ngbDropdownItem>Public comment</a>
                                                            </div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="Type your message" />
                                                    </div>
                                                </fieldset>
                                            </div> -->
                                            <button type="button" class="btn btn-primary send" rippleEffect>
                                                <i data-feather="send" class="d-lg-none"></i>
                                                <span class="d-none text-nowrap d-lg-block">Send</span>
                                            </button>
                                        </form>
                                        <!--/ Submit Chat form -->
                                    </section>
                                    <!-- User Chat messages -->
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem="attachments">
                            <a ngbNavLink>Attachments</a>
                            <ng-template ngbNavContent>
                                <div class="attachments-wrapper px-2 pt-1">
                                    <div class="user-page-info d-flex justify-content-between align-items-center">
                                        <h6 class="mb-0"> Attachments </h6>
                                        <button placement="auto" container="body" (click)="uploadAttachmentsModalOpen(uploadAttachmentsModal)" ngbTooltip="Upload attachments" type="button" class="btn btn-icon btn-outline-primary btn-sm" rippleEffect>
                                            <span [data-feather]="'link'"></span></button>
                                    </div>

                                    <hr class="mt-1 mb-50">

                                    <div class="uploaded-attachments-wrapper">
                                        <div class="mb-0 d-flex justify-content-between uploaded-attachments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Deletion : No date</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeAttachmentIcon" ngbTooltip="Remove attachment">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-attachments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Deletion : No date</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeAttachmentIcon" ngbTooltip="Remove attachment">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-attachments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Deletion : No date</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeAttachmentIcon" ngbTooltip="Remove attachment">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </li>
                        <li ngbNavItem="documents">
                            <a ngbNavLink>Documents</a>
                            <ng-template ngbNavContent>
                                <div class="document-wrapper px-2 pt-1">

                                    <div class="uploaded-document-wrapper">
                                        <div class="mb-0 d-flex justify-content-between uploaded-doocuments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeDocumentIcon" ngbTooltip="Remove documents">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-doocuments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeDocumentIcon" ngbTooltip="Remove documents">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-doocuments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeDocumentIcon" ngbTooltip="Remove documents">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </li>
                        <li ngbNavItem="contracts">
                            <a ngbNavLink>Contracts</a>
                            <ng-template ngbNavContent>
                                <div class="Contracts-wrapper px-2 pt-1">
                                    <div class="uploaded-Contracts-wrapper">
                                        <div class="mb-0 d-flex justify-content-between uploaded-Contracts-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeContractIcon" ngbTooltip="Remove contract">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-Contracts-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeContractIcon" ngbTooltip="Remove contract">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-Contracts-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle" id="exampleModalLabel">Dorolice Crossman
                                                    </p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeContractIcon" ngbTooltip="Remove contract">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </li>

                    </ul>
                    <div [ngbNavOutlet]="navJustified" class="mt-2"></div>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add attachments Ends-->
</div>


<!-- add contacts Modal -->
<ng-template #linkedOrganizationeModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Link an organization</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-2" tabindex="0" ngbAutofocus>
        <div class="ng-autocomplete">
            <div class="input-group-prepend">
                <span class="input-group-text"><i data-feather="briefcase"></i></span>
            </div>
            <ng-autocomplete class="organdRecAutoComp" #auto [data]="data" [searchKeyword]="keyword" placeholder="Name" (selected)='selectEvent($event);auto.clear()' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [isLoading]="isLoading">
                
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Link an organization
        </button>
    </div>
</ng-template>
<!-- / add contacts Modal -->

<!-- add attachment Modal -->
<ng-template #uploadAttachmentsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Upload Attachments</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body px-0" tabindex="0" ngbAutofocus>
        <div class="uploadAttachment-wrapper">
            <div class="table-listing">
                <div class="table-responsive">
                    <!-- Hoverable rows -->
                    <table class="table">
                        <thead>
                            <tr>
                                <th>File name</th>
                                <th>Attachments</th>
                                <th>size</th>
                                <th colspan="2">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tableDataRow">
                                <td>
                                    <div class="d-flex align-items-center ng-star-inserted">
                                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                                            Dorolice Crossman
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group mb-0">
                                        <!-- Basic Multi select -->
                                        <ng-select class="ng-select-size-sm" [items]="showDocumentFilter" bindLabel="name" [readonly]="readonly" [searchable]="false" container="body" placeholder="Please select">
                                        </ng-select>
                                    </div>
                                </td>
                                <td>
                                    <span class="">468 kb</span>
                                </td>
                                <td class="">
                                    <span>2%</span>
                                </td>
                                <td class="">
                                    <span placement="auto" container="body" ngbTooltip="delete attachment">
                                        <i data-feather="trash" [size]="16" class="ml-1 cursor-pointer" data-dismiss="modal"
                                            aria-label="Close"></i>
                                    </span>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Add attachments
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Start upload
        </button>
    </div>
</ng-template>
<!-- / add attachment Modal -->