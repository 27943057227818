import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-danish-bank-idaddon',
  templateUrl: './danish-bank-idaddon.component.html',
  styleUrls: ['./danish-bank-idaddon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class DanishBankIDAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
