<div class="slideout-content">
    <!-- Modal to add attachments starts-->
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('document-field-sidebar')">
                    ×
                </button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title" id="exampleModalLabel">Add document field</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <div class="form-group">
                        <label class="form-label" for="Name">Name</label>
                        <input type="text" class="form-control dt-full-name" id="Name" placeholder="Name" name=""
                            aria-describedby="Name" />
                    </div>
                    <div class="form-group">
                        <label for="customSelect">Type of field</label>
                        <ng-select [items]="includeOrFieldtype" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                            placeholder="Type of field"> </ng-select>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="APIAttribute">API Attribute</label>
                        <input type="text" class="form-control dt-full-name" id="Name" placeholder="API Attribute" name=""
                            aria-describedby="APIAttribute" />
                    </div>
                    <div class="custom-control custom-switch form-group">
                        <p class="mb-50">Required?</p>
                        <div class="d-flex justify-content-between">
                            <small>settings</small>
                            <input type="checkbox" class="custom-control-input" id="Required" />
                            <label class="custom-control-label" for="Required"></label>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label for="Description">Description</label>
                        <fieldset class="form-group">
                            <textarea class="form-control" id="Description" rows="2" placeholder="Enter your description here"></textarea>
                        </fieldset>
                    </div>
                    <button type="submit" class="btn btn-primary mr-1" rippleEffect>Add field</button>
                    <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('document-field-sidebar')"
                        rippleEffect>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add attachments Ends-->
</div>