import { Component, OnInit } from '@angular/core';
import uuid from 'short-unique-id';
import { GlobalService } from 'app/global/global.service';
import SignaturePad from 'signature_pad';
@Component({
  selector: 'app-signature-box',
  templateUrl: './signature-box.component.html',
  styleUrls: ['./signature-box.component.scss']
})
export class SignatureBoxComponent implements OnInit {

  public currectDate = new Date();
  public uniqueid = new uuid({ length: 6, dictionary: 'number' });
  public penColors = [{color:'#0061ef',colorname:'blue'},{color:'#000',colorname:'black'},{color:'red',colorname:'red'}];
  public penColor = '#0061ef';
  public penColorIndex = 0;
  public uuid = this.uniqueid();
  public signatureType = 'draw';
  public nextFont = 0;
  public fontFamily = 'Montserrat';
  public selectedSignatureFontSize = '34';
  constructor(private GlobalService:GlobalService) { }

  public signatureFontSize = [
    { id: 1, name: '34' },
    { id: 2, name: '36' },
    { id: 3, name: '38' },
    { id: 4, name: '40' },
    { id: 5, name: '42' },
    { id: 6, name: '44' },
    { id: 7, name: '46' },
    { id: 8, name: '48' },
    { id: 9, name: '50' },
    { id: 10, name: '52' },
    { id: 11, name: '54' },
    { id: 12, name: '56' },
    { id: 13, name: '58' },
    { id: 14, name: '60' },
    { id: 15, name: '62' },
    { id: 16, name: '64' },
    { id: 17, name: '66' },
    { id: 18, name: '68' },
    { id: 19, name: '70' },
    { id: 20, name: '72' },
  ];

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.GlobalService.initializeSignature(document.getElementById('regular_signaturepad'),this.uuid);
  }

  changeColor(color,index){
    this.GlobalService.signatureObjects[this.uuid].penColor = color.color;
    this.penColor = color.color;
    this.penColorIndex = index;
    if(this.signatureType == 'draw'){
      var penData = this.GlobalService.signatureObjects[this.uuid].toData();
      penData = penData.map(d => {
        d.penColor = color.color;
        return d;
      });
      this.GlobalService.signatureObjects[this.uuid].fromData(penData, { clear: false });
    }
  }

  clearSignature(){
    this.GlobalService.signatureObjects[this.uuid].clear();
    $('.signature-type-box').val('');
  }

  changeSignatureType(type:any){
    this.signatureType = type.nextId;
    var that = this;
    setTimeout(() => {
        that.GlobalService.initializeSignature(document.getElementById('regular_signaturepad'),that.uuid,[],that.penColor);
    }, 600);
  }

  changeNextFont(){
    this.nextFont++;
    if(this.nextFont >= this.GlobalService.fonts.length){
      this.nextFont = 0;
    }
    this.fontFamily = this.GlobalService.fonts[this.nextFont];
  }

  changeFontSize(size:any){
    
  }

}
