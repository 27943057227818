import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgSelectModule } from '@ng-select/ng-select';


import { ProfileSettingsComponent } from '../profile-settings/profile-settings.component';



// routing
const routes: Routes = [
  {
    path: 'profileSetting',
    component: ProfileSettingsComponent,
    data: { animation: 'zoomIn' }
  },
]
@NgModule({
  declarations: [
    ProfileSettingsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    Ng2FlatpickrModule,
    NgSelectModule,
    CoreCommonModule
  ]
})
export class ProfileSettingsModule { }
