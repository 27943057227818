import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-apiaddon',
  templateUrl: './apiaddon.component.html',
  styleUrls: ['./apiaddon.component.scss']
})
export class APIAddonComponent implements OnInit {

      // modal Basic
      modalOpen(APISettingModal) {
        this.modalService.open(APISettingModal, {
          // centered: true,
          size: 'lg' 
        });
      }
      // swiper
      public swiperPaginations: SwiperConfigInterface = {
        // pagination: true
      };
      
      
      
      constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
