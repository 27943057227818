import { CommonModule } from '@angular/common';
 import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CoreSidebarModule } from '@core/components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { SigningPageComponent } from './signing-page/signing-page.component';
import { SigningSidebarComponent } from './signing-sidebar/signing-sidebar.component';
import { SignatureBoxComponent } from './signature-box/signature-box.component';
import { EformRecipientFieldsComponent } from './eform-recipient-fields/eform-recipient-fields.component';
import { RecipientIdAttachFieldComponent } from './recipient-id-attach-field/recipient-id-attach-field.component';
import { SignBankIdBoxComponent } from './sign-bank-id-box/sign-bank-id-box.component';

const appRoutes: Routes = [
  {
    path: 's/:id',
    component: SigningPageComponent,
    data: {signingArea:true}
  }
]
@NgModule({
  declarations: [
    SigningPageComponent,
    SigningSidebarComponent,
    SignatureBoxComponent,
    EformRecipientFieldsComponent,
    RecipientIdAttachFieldComponent,
    SignBankIdBoxComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgbModule,
    NgSelectModule,
    PerfectScrollbarModule,
    FormsModule,
    ColorPickerModule,
    CoreTouchspinModule,
    CoreSidebarModule,
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    SigningSidebarComponent,
    SignatureBoxComponent
  ],
  providers: []
})
export class SigningPagesModule {}
