import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-norwegian-bank-idaddon',
  templateUrl: './norwegian-bank-idaddon.component.html',
  styleUrls: ['./norwegian-bank-idaddon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class NorwegianBankIDAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
