import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { EditTemplateComponent } from '../../edit-template/edit-template.component';
import { Fields } from '../fields/fields';
import { Blocks } from '../blocks/blocks';
import { PopOver } from '../popover/popover';
import { ContentTabComponent } from '../content-tab/content-tab.component';
import uuid from 'short-unique-id';
import feather from 'feather-icons';

declare var $: any;
@Component({
  selector: 'app-general-popover-settings',
  templateUrl: './general-popover-settings.component.html',
  styleUrls: ['./general-popover-settings.component.scss'],
  providers: [ContentTabComponent, Fields, Blocks]
})
export class GeneralPopoverSettingsComponent implements OnChanges {

  constructor(
    private template: EditTemplateComponent,
    private contenttab: ContentTabComponent,
    private Fields: Fields,
    private PopOver: PopOver,
  ) { }
  @Input() data: any[];
  @ViewChild('roleSelecter') roleSelecter
  public unique_id = new uuid({ length: 6, dictionary: 'number' });
  public readonly = false;
  public searchable = false;
  // public previousClass = 'field-size-14';
  public fieldPreviousClass = 'fill-aligned-label';
  public isRequired = true;
  public isPreChecked = false;
  public isMultiChoiceChecked = false;
  public isMultiLinesChecked = false;
  public isCalculationChecked = false;
  public isConditionalChecked = false;
  public slashCommandTable = false;
  public isLabelHide = false;
  public isLocked = true;
  public currentElement = null;
  public placeholderName = "";
  public labelName = "";
  public showLabel = 0;
  public multiChoiceOption = [];
  public preAddMultiChoiceOption = [];
  public TextandDatepickerFields = [];
  public fieldSelectedType = 1;
  public isTagView = 0;
  fieldtype = [
    { id: 0, name: 'New field', field_type: 'New' },
    { id: 1, name: 'Full name', field_type: 'Sender'  },
    { id: 2, name: 'Last name', field_type: 'Sender' },
    { id: 3, name: 'Email', field_type: 'Sender' },
    { id: 4, name: 'Address', field_type: 'Sender' },
    { id: 5, name: 'Mobile number', field_type: 'Sender' },
    { id: 6, name: 'Full name', field_type: 'Recipient' },
    { id: 7, name: 'Last name', field_type: 'Recipient' },
    { id: 8, name: 'Full name', field_type: 'Recipient 2' },
    { id: 9, name: 'Last name', field_type: 'Recipient 2' },
    { id: 10, name: 'Amount', field_type: 'Doc field' },
    { id: 11, name: 'Type', field_type: 'Doc field' },
  ];

  public selectedCheckBoxType = 1;
  CheckBoxfieldtype = [
    { id: 0, name: 'New field' },
    { id: 1, name: 'Checkbox' },
  ]

  public selectedDroplistType = 1;
  droplistfieldtype = [
    { id: 0, name: 'New field' },
    { id: 1, name: 'Country', options: ['Pakistan', 'India', 'Sweden'] },
  ]

  datepickerFieldType = [
    { id: 0, name: 'New field' },
    { id: 1, name: 'Start date' },
  ]

  public selectedRole = 0;
  selectRole = [
    { name: 'Recipient', id: 1, color: '#a782be' },
    { name: 'Recipient 2', id: 2, color: '#ff8510' },
    { name: 'Sender', id: 0, color: '#0061ef' },
  ];

  public selectedCurrency = 1;
  currency = [
    { id: 1, name: 'USD, US dollars' },
    { id: 2, name: 'EUR, Euros' },
  ];

  public selectIcon = [
    {
      id: 1,
      name: 'Full name',
      docType: 'Sender'
    },
    {
      id: 2,
      name: 'Last name',
      docType: 'Sender'
    },
    {
      id: 3,
      name: 'Email',
      docType: 'Recipient 1'
    },
    {
      id: 4,
      name: 'Address',
      docType: 'Recipient 2'
    },
    {
      id: 5,
      name: 'Mobile number',
      docType: 'Recipient 3'
    }
  ];
  public selectIconSelected = this.selectIcon[0].name;
  public selectCustomSelected = this.selectIcon[0].name;

  public selectedFontSize = '14';
  selectFontSize = [
    { id: 1, name: '8', fieldClass: 'field-size-8' },
    { id: 2, name: '10', fieldClass: 'field-size-10' },
    { id: 3, name: '12', fieldClass: 'field-size-12' },
    { id: 4, name: '14', fieldClass: 'field-size-14' },
    { id: 5, name: '16', fieldClass: 'field-size-16' },
    { id: 6, name: '18', fieldClass: 'field-size-18' },
    { id: 7, name: '20', fieldClass: 'field-size-20' },
    { id: 8, name: '22', fieldClass: 'field-size-22' },
    { id: 9, name: '24', fieldClass: 'field-size-24' },
    { id: 10, name: '26', fieldClass: 'field-size-26' },
    { id: 11, name: '28', fieldClass: 'field-size-28' },
    { id: 12, name: '30', fieldClass: 'field-size-30' },
  ];

  public fields_style = [
    {
      id: 1,
      name: 'Filled',
      class: 'fill-aligned-label',
    },
    {
      id: 2,
      name: 'Top',
      class: 'top-aligned-label',
    },
    {
      id: 3,
      name: 'Bottom',
      class: 'bottom-aligned-label',
    },
    {
      id: 4,
      name: 'Left',
      class: 'left-aligned-label',
    }
  ];
  public selectedFieldStyle = this.fields_style[0].name;
  public selectedColumnSize = 50;
  selectColumnSize = [
    { id: 100, name: '100%' },
    { id: 50, name: '50%' },
  ];
  // selectedFeildStyle = this.fields_style[0].name;
  ngOnInit(): void {
    // this.updateFields();
  }

  updateFields() {
    this.currentElement = $('.drag-fields[uuid="' + this.data['uuid'] + '"]');
    this.isRequired = ((typeof this.currentElement.attr('field_required') == 'undefined' ? true : this.currentElement.attr('field_required') == 1 ? true : false));
    this.isMultiChoiceChecked = ((typeof this.currentElement.attr('multi_choice') == 'undefined' ? false : this.currentElement.attr('multi_choice') == 1 ? true : false));
    this.isMultiLinesChecked = ((typeof this.currentElement.attr('allow_multi_lines') == 'undefined' ? false : this.currentElement.attr('allow_multi_lines') == 1 ? true : false));
    this.slashCommandTable = ((typeof this.currentElement.attr('slashcommandtable') == 'undefined' ? false : this.currentElement.attr('slashcommandtable') == 1 ? true : false));
    this.isLabelHide = ((typeof this.currentElement.attr('hide_label') == 'undefined' ? false : this.currentElement.attr('hide_label') == 1 ? true : false));
    this.isPreChecked = ((typeof this.currentElement.attr('pre_checked') == 'undefined' ? false : this.currentElement.attr('pre_checked') == 1 ? true : false));
    this.isLocked = ((typeof this.currentElement.attr('field_locked') == 'undefined' ? true : this.currentElement.attr('field_locked') == 1 ? true : false));
    this.selectedFontSize = ((typeof this.currentElement.attr('field_fontsize') == 'undefined' ? '14' : this.currentElement.attr('field_fontsize')));
    this.selectedColumnSize = Number((typeof this.currentElement.attr('column') == 'undefined' ? 50 : this.currentElement.attr('column')));
    this.fieldSelectedType = Number((typeof this.currentElement.attr('selectedtypeid') == 'undefined' ? 0 : this.currentElement.attr('selectedtypeid')));
    this.selectedRole = Number((typeof this.currentElement.attr('selectedforrole') == 'undefined' ? 0 : this.currentElement.attr('selectedforrole')));
    this.selectedFieldStyle = ((typeof this.currentElement.attr('field_style') == 'undefined' ? 'Filled' : this.fields_style.find((result) => result.id == this.currentElement.attr('field_style')).name));
    this.labelName = ((typeof this.currentElement.find('.selected-label') == 'undefined' ? this.labelName : this.currentElement.find('.selected-label').text())).replaceAll('[','').replaceAll(']','');
    this.placeholderName = ((typeof this.currentElement.find('input,textarea') == 'undefined' ? '' : this.currentElement.find('input,textarea').attr('placeholder')));
    this.isTagView = Number((typeof this.currentElement.attr('tag_view') == 'undefined' ? 0 : this.currentElement.attr('tag_view')));
    this.multiChoiceOption = this.getData(this.data['uuid']);
    this.preAddMultiChoiceOption = this.getData(this.data['uuid']);
    switch (this.data['selected_field']) {
      case '1':
        this.TextandDatepickerFields = this.fieldtype;
        break;
      case '4':
        this.TextandDatepickerFields = this.datepickerFieldType;
        break;
    }
    this.showLabel = this.fieldSelectedType;
    if(this.isTagView == 1){
      this.labelName = this.currentElement.attr('selecttypetext');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.updateFields();
    }
  }

  deleteSettings() {
    if(this.data['isFieldBlock'] == 1){
      this.PopOver.deleteFieldBlock(this.data['uuid']);
    }else{
      this.contenttab.removeField(this.data['uuid']);    
    }
  }


  clsoe() {
    this.template.closePopOverSettingView(false);
  }

  changeFontSize(data) {
    // this.currentElement.removeClass(this.previousClass);
    // this.currentElement.addClass(data.fieldClass);
    // this.previousClass = data.fieldClass;
    this.selectedFontSize = data.name;
    this.currentElement.attr('field_fontsize', data.name);
  }

  selectFieldStyle(style_type, selected_fields) {
    this.selectedFieldStyle = selected_fields.name;
    this.currentElement.removeClass(this.fieldPreviousClass);
    this.currentElement.addClass(selected_fields.class);
    this.fieldPreviousClass = selected_fields.class;
    this.currentElement.attr('field_style', selected_fields.id);
  }


  onRequiredChange(event) {
    this.isRequired = event.target.checked;
    this.currentElement.attr('field_required', (event.target.checked ? 1 : 0));
  }

  onLockedChange(event) {
    this.isLocked = event.target.checked;
    this.currentElement.attr('field_locked', (event.target.checked ? 1 : 0));
  }

  onPreCheckedChange(event) {
    this.isPreChecked = event.target.checked;
    this.currentElement.attr('pre_checked', (event.target.checked ? 1 : 0));
    this.currentElement.toggleClass("checkbox-prechecked", this.isPreChecked);
  }

  onMultiChoiceChange(event) {
    this.isMultiChoiceChecked = event.target.checked;
    this.currentElement.attr('multi_choice', (event.target.checked ? 1 : 0));
    this.currentElement.find('.multiChoice-item').removeClass('multiChoice-active');
    if (this.isMultiChoiceChecked) {
      this.currentElement.removeClass("multiChoice-radio").addClass("multiChoice-checkbox");
    } else {
      this.currentElement.removeClass("multiChoice-checkbox").addClass("multiChoice-radio");
    }
  }

  hideLabel(event) {
    this.isLabelHide = event.target.checked;
    this.currentElement.attr('hide_label', (event.target.checked ? 1 : 0));
    this.currentElement.find('.selected-label').toggleClass("hidden", this.isLabelHide);
  }

  renameLabel(event) {
    this.labelName = event.target.value;
    if(this.isTagView == 1){
      this.currentElement.find('.selected-label').text(`[${event.target.value}]`);
      this.currentElement.attr({'selectedtype': event.target.value.toLowerCase().replace(/ /g, "_"), tagname: event.target.value.toLowerCase().replace(/ /g, "_"), 'selecttypetext': event.target.value});
    }else{
      this.currentElement.find('.selected-label').text(event.target.value);
    }
  }

  renamePlaceholder(event) {
    this.placeholderName = event.target.value;
    this.currentElement.find('input,textarea').attr('placeholder', event.target.value);
  }

  getData(uuid) {
    try {
      // return JSON.parse($('[uuid="' + uuid + '"] script[type="text/json"]').text());
      return JSON.parse($('[uuid="' + uuid + '"] #'+uuid+' ').data('options'));
    } catch (err) { // if we have not valid json or dont have it
      return [];
    }
  }

  setData(index, value, action) {
    var that = this;
    var options = that.getData(that.data['uuid']);
    switch (action) {
      case 'add':
        options.push(value);
        break;
      case 'update':
        options[index].key = value;
        break;
      case 'delete':
        options = options.filter(option => option.value != value);
        break;
    }
    // $('[uuid="' + that.data['uuid'] + '"] script[type="text/json"]').text(JSON.stringify(options));
      $('[uuid="' + that.data['uuid'] + '"] #'+that.data['uuid']+' ').data('options',JSON.stringify(options));
  }

  addOption(field_type) {
    var uuid = this.unique_id();
    var optionElement = $();
    var optionList = '';
    var option = '';
    if (field_type == 'multichoice') {
      optionElement = $(this.currentElement).find('.multiChoice-item-container');
      optionList = '.multiChoice-item';
      option = this.Fields.appendMultiChoiceField({ uuid: uuid, key: 'Option ' + (Number(optionElement.find(optionList).length) + 1) });
    } else if (field_type == 'droplist') {
      optionElement = $(this.currentElement).find('ng-dropdown-panel .scroll-host');
      optionList = '.ng-option';
      option = this.Fields.appendDroplistField({ uuid: uuid, key: 'Option ' + (Number(optionElement.find(optionList).not('.not-found').length) + 1) });
    }
    optionElement.append(option);
    this.setData(null, { value: uuid, key: 'Option ' + (Number(optionElement.find(optionList).not('.not-found').length)) }, 'add');
    this.multiChoiceOption = this.getData(this.data['uuid']);
  }

  preAddedOptions(field_type, key) {
    var uuid = this.unique_id();
    var optionElement = $();
    var optionList = '';
    var option = '';
    if (field_type == 'multichoice') {
      optionElement = $(this.currentElement).find('.multiChoice-item-container');
      optionList = '.multiChoice-item';
      option = this.Fields.appendMultiChoiceField({ uuid: uuid, key: key });
    } else if (field_type == 'droplist') {
      optionElement = $(this.currentElement).find('ng-dropdown-panel .scroll-host');
      optionList = '.ng-option';
      option = this.Fields.appendDroplistField({ uuid: uuid, key: key });
    }
    optionElement.append(option);
    this.setData(null, { value: uuid, key: key }, 'add');
    this.preAddMultiChoiceOption = this.getData(this.data['uuid']);
  }

  renameOption(event, option, index, field_type) {
    if (field_type == 'multichoice') {
      $(this.currentElement).find('.multiChoice-item[option_uuid="' + option.value + '"] label').text(event.target.value);
    } else if (field_type == 'droplist') {
      $(this.currentElement).find('.ng-option[option_uuid="' + option.value + '"] span').text(event.target.value);
      $(this.currentElement).find('.selected-option-label[option_uuid="' + option.value + '"]').text(event.target.value);
    }
    this.setData(index, event.target.value, 'update');
  }

  deleteOption(event, option, index, field_type) {
    if (field_type == 'multichoice') {
      $(this.currentElement).find('.multiChoice-item[option_uuid="' + option.value + '"]').remove();
    } else if (field_type == 'droplist') {
      $(this.currentElement).find('.ng-option[option_uuid="' + option.value + '"]').remove();
      $(this.currentElement).find('.selected-option-label[option_uuid="' + option.value + '"]').text('');
    }
    this.setData(index, option.value, 'delete');
    this.multiChoiceOption = this.getData(this.data['uuid']);
  }

  allowMultiLines(event) {
    this.isMultiLinesChecked = event.target.checked;
    this.currentElement.attr('allow_multi_lines', (event.target.checked ? 1 : 0));
    this.currentElement.toggleClass('allow-multirows', this.isMultiLinesChecked);
    this.currentElement.find('.text-field-textarea').toggleClass('hidden', !this.isMultiLinesChecked);
    this.currentElement.find('.text-field-input').toggleClass('hidden', this.isMultiLinesChecked);
    // this.currentElement.find('span.field-label').toggleClass('hidden', !this.isMultiLinesChecked);
    if (!this.data['isFormBuilder'] && !this.data['isFieldBlock']) {
      $(this.currentElement).css('height', '');
      this.currentElement.resizable("destroy");
      this.currentElement.resizable({
        containment: '.content-box',
        handles: this.isMultiLinesChecked ? 'se' : 'e',
        resize() {
          if (this.isMultiLinesChecked) {
            $(this).css('height', $(this).height())
          } else {
            //$(this).css('height', '')
          }
        },
      })
    } else {
      this.currentElement.find('input').toggleClass('hidden', this.isMultiLinesChecked);
    }
  }

  backToFormBuilder() {
    this.template.showFormBuilderSettings(true, { uuid: this.data['formUuid'] });
  }

  changeColumnSize(data) {
    this.selectedColumnSize = data.id;
    this.currentElement.attr('column', data.id);
  }

  onSelectTypeChange(type: any) {
    this.showLabel = type.id;
    this.fieldSelectedType = type.id;
    this.currentElement.attr({ 'selectedtypeid': type.id, 'selectedtype': type.name.toLowerCase().replace(/ /g, "_"), tagname: type.name.toLowerCase().replace(/ /g, "_"),'selecttypetext': type.name});
    this.currentElement.find('input,textarea').attr('placeholder',type.name);
    this.placeholderName = type.name;
    if(this.isTagView == 1){
      this.currentElement.find('.selected-label').text(`[${type.name}]`);
    }else{
      this.currentElement.find('.selected-label').text(`${type.name}`);
    }
    this.labelName = type.name;
  }

  onDroplistTypeChange(droplist: any, field_type: any) {
    var that = this;
    that.multiChoiceOption = [];
    $(that.currentElement).find('.multiChoice-item, .ng-option').not('.not-found').remove();
    $(that.currentElement).find('.selected-option-label').attr('option_uuid',0).text('');
    // $('[uuid="' + that.data['uuid'] + '"] script[type="text/json"]').text(JSON.stringify([]));
    $('[uuid="' + that.data['uuid'] + '"] #'+that.data['uuid']+' ').data('options',JSON.stringify([]));
    that.showLabel = droplist.id;
    that.fieldSelectedType = droplist.id;
    this.currentElement.attr({ 'selectedtypeid': droplist.id, 'selectedtype': droplist.name.toLowerCase().replace(/ /g, "_"), 'selecttypetext': droplist.name });
    if (droplist.hasOwnProperty('options') && typeof droplist.options == 'object') {
      for (const option of droplist.options) {
        that.preAddedOptions(field_type, option);
      }
    }

    if(droplist.id == 0){
      this.labelName = 'Select option';
      this.currentElement.find('.selected-label').text('Select option');
      if(field_type == 'multichoice'){
        for (let index = 0; index < 2; index++) {
            that.addOption(field_type);
        }
      }
    }else {
      this.labelName = droplist.name;
      this.currentElement.find('.selected-label').text(droplist.name);
    }
  }

  hideOption(toggle: any,index:any,option:any,field_type:any,option_type='preadded') {
    var that = this;
    var options = that.getData(that.data['uuid']);
    options[index].hide = toggle;
    // $('[uuid="' + that.data['uuid'] + '"] script[type="text/json"]').text(JSON.stringify(options));
    $('[uuid="' + that.data['uuid'] + '"] #'+that.data['uuid']+' ').data('options',JSON.stringify(options));
    if(option_type == 'preadded'){
      this.preAddMultiChoiceOption = this.getData(this.data['uuid']);
    }else{
      this.multiChoiceOption = this.getData(this.data['uuid']);
    }
    if (field_type == 'multichoice') {
      $(this.currentElement).find('.multiChoice-item[option_uuid="' + option.value + '"]').toggleClass('hidden',(toggle == 1 ? true : false));
    } else if (field_type == 'droplist') {
      $(this.currentElement).find('.ng-option[option_uuid="' + option.value + '"]').toggleClass('hidden',(toggle == 1 ? true : false));
    }
  }

  onChangeRoleType(role:any){
    this.currentElement.attr('selectedforrole', role.id);
    this.currentElement.find('.recipient-role').text(String(role.name).substring(0,3).toLocaleUpperCase()).css('background',role.color);
    // this.currentElement.css('border-color',`${role.color}`);
    this.currentElement.find('.fields-ele-container, .tag-container').toggleClass('pointer-none',(role.id ? true : false ));
    if(this.isTagView == 1){
      this.currentElement.find('.tag .selected-label').css('color',`${role.color}`);
      this.currentElement.find('.tag .selected-label').text(`[${this.currentElement.attr('selecttypetext')}]`);
      this.currentElement.find('.text-tag-editor').val('');
    }
  }

  activeCalculation(event) {
    this.isCalculationChecked = event.target.checked;
  }

  activeConditional(event) {
    this.isConditionalChecked = event.target.checked;
  }
}
