<div class="content-wrapper dataGroups-wrapper container-xxl p-0">
        <div class="content-body">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="card mb-2">
                        <div class="p-2">
                            <div class="row">
                                <div class="col-md-5 col-lg-5">
                                    <div class="d-flex align-content-center justify-content-between w-100">
                                        <div class="input-group input-group-merge">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i data-feather="search"></i></span>
                                            </div>
                                            <input type="text" class="form-control" id="" placeholder="Search" aria-label="Search..."
                                                aria-describedby="search" />
                                        </div>
                                    </div>
                                </div>
                                <div class="m-0 d-flex align-items-center justify-content-end header-section-btn col-sm-12 col-md-6 col-lg-7">
                                    <!-- Add groups modal -->
                                    <app-datagroupsmodal class="adddatagroupBtn"></app-datagroupsmodal>
                                    <!-- Add groups modal -->
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <!-- Hoverable rows -->
                            <div class="row" id="table-hover-row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="table-responsive">
                                            <!-- Hoverable rows -->
    
                                            <table class="table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>CREATOR</th>
                                                        <th>DATA GROUP NAME</th>
                                                        <th class="align-items-center justify-content-end text-right">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="tableDataRow">
                                                        <td>
                                                            <span class="">Demo name</span>
                                                        </td>
                                                        <td>
                                                            <span class="">Other</span>
                                                        </td>
                                                        <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow"
                                                                    ngbDropdownToggle data-toggle="dropdown">
                                                                    <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-2" class="mr-50"></i>
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="copy" class="mr-50"></i>
                                                                        <span>Duplicate</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-3" class="mr-50"></i>
                                                                        <span>rename</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);"><i
                                                                            data-feather="trash" class="mr-50"></i>
                                                                        <span>Delete</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="tableDataRow">
                                                        <td>
                                                            <span class="">Demo name</span>
                                                        </td>
                                                        <td>
                                                            <span class="">Other</span>
                                                        </td>
                                                        <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow"
                                                                    ngbDropdownToggle data-toggle="dropdown">
                                                                    <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-2" class="mr-50"></i>
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="copy" class="mr-50"></i>
                                                                        <span>Duplicate</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-3" class="mr-50"></i>
                                                                        <span>rename</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);"><i
                                                                            data-feather="trash" class="mr-50"></i>
                                                                        <span>Delete</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="tableDataRow">
                                                        <td>
                                                            <span class="">Demo name</span>
                                                        </td>
                                                        <td>
                                                            <span class="">Other</span>
                                                        </td>
                                                        <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow"
                                                                    ngbDropdownToggle data-toggle="dropdown">
                                                                    <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-2" class="mr-50"></i>
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="copy" class="mr-50"></i>
                                                                        <span>Duplicate</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-3" class="mr-50"></i>
                                                                        <span>rename</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);"><i
                                                                            data-feather="trash" class="mr-50"></i>
                                                                        <span>Delete</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="tableDataRow">
                                                        <td>
                                                            <span class="">Demo name</span>
                                                        </td>
                                                        <td>
                                                            <span class="">Other</span>
                                                        </td>
                                                        <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow"
                                                                    ngbDropdownToggle data-toggle="dropdown">
                                                                    <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-2" class="mr-50"></i>
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="copy" class="mr-50"></i>
                                                                        <span>Duplicate</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-3" class="mr-50"></i>
                                                                        <span>rename</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);"><i
                                                                            data-feather="trash" class="mr-50"></i>
                                                                        <span>Delete</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="tableDataRow">
                                                        <td>
                                                            <span class="">Demo name</span>
                                                        </td>
                                                        <td>
                                                            <span class="">Other</span>
                                                        </td>
                                                        <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow"
                                                                    ngbDropdownToggle data-toggle="dropdown">
                                                                    <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-2" class="mr-50"></i>
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="copy" class="mr-50"></i>
                                                                        <span>Duplicate</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-3" class="mr-50"></i>
                                                                        <span>rename</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);"><i
                                                                            data-feather="trash" class="mr-50"></i>
                                                                        <span>Delete</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="tableDataRow">
                                                        <td>
                                                            <span class="">Demo name</span>
                                                        </td>
                                                        <td>
                                                            <span class="">Other</span>
                                                        </td>
                                                        <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow"
                                                                    ngbDropdownToggle data-toggle="dropdown">
                                                                    <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-2" class="mr-50"></i>
                                                                        <span>Edit</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="copy" class="mr-50"></i>
                                                                        <span>Duplicate</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="edit-3" class="mr-50"></i>
                                                                        <span>rename</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);"><i
                                                                            data-feather="trash" class="mr-50"></i>
                                                                        <span>Delete</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
    
    
                                                </tbody>
                                            </table>
                                            <ngb-pagination class="d-flex justify-content-end mt-1 mr-1" [collectionSize]="30"
                                                [(page)]="pageBasic" aria-label="Default pagination">
                                                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                                        [size]="18" [class]="'font-weight-bolder'"></span>
                                                </ng-template>
                                                <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'"
                                                        [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
                                            </ngb-pagination>
    
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--/ Hoverable rows -->
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </div>