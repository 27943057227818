import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-auto-save-changes-addon',
  templateUrl: './auto-save-changes-addon.component.html',
  styleUrls: ['./auto-save-changes-addon.component.scss']
})
export class AutoSaveChangesAddonComponent implements OnInit {


   // modal Basic
   modalOpen(autoSaveChangesSettingModal) {
    this.modalService.open(autoSaveChangesSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }
  // swiper
  public swiperPaginations: SwiperConfigInterface = {
    // pagination: true
  };

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
