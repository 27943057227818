<div class="card mb-1 p-50 card-transaction CC_managersContainer draggable">
    <div class="collapse-icon">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">Managers <small>Internal users</small>
                    </span>
                </ng-template>
                <ng-template ngbPanelContent class="p-0">
                    <div>
                        <div class="d-flex align-content-center justify-content-between mb-2 w-100">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i data-feather="search"></i></span>
                                </div>
                                <input type="text" class="form-control" id="search" placeholder="Search contacts.."
                                    aria-label="Search..." aria-describedby="search" />
                            </div>
                        </div>
                        <div class="transaction-item border-bottom managerListHover">
                            <div class="media">
                                <div class="avatar mr-1">
                                    <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                        height="42" />
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Wallet</h6>
                                    <small>Anique4@yopmail.com</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder listOptionHide">
                                <div class="text-danger font-small-2 cursor-pointer" placement="auto" ngbTooltip="Delete">Delete</div>
                            </div>
                        </div>
                        <div class="transaction-item border-bottom managerListHover">
                            <div class="media">
                                <div class="avatar mr-1">
                                    <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                        height="42" />
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Wallet</h6>
                                    <small>Anique4@yopmail.com</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder listOptionHide">
                                <div class="text-danger font-small-2 cursor-pointer" placement="auto" ngbTooltip="Delete">Delete</div>
                            </div>
                        </div>
                        <div class="transaction-item border-bottom managerListHover">
                            <div class="media">
                                <div class="avatar mr-1">
                                    <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                        height="42" />
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Wallet</h6>
                                    <small>Anique4@yopmail.com</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder listOptionHide">
                                <div class="text-danger font-small-2 cursor-pointer" placement="auto" ngbTooltip="Delete">Delete</div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>

    </div>
</div>