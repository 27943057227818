import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-automatical-deletionof-documents-addon',
  templateUrl: './automatical-deletionof-documents-addon.component.html',
  styleUrls: ['./automatical-deletionof-documents-addon.component.scss']
})
export class AutomaticalDeletionofDocumentsAddonComponent implements OnInit {

   // modal Basic
   modalOpen(autoDelDocSettingModal) {
    this.modalService.open(autoDelDocSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }
  // swiper
  public swiperPaginations: SwiperConfigInterface = {
    
  };

  constructor(private modalService: NgbModal) { }

  chooseDocumenttype = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  chooseStatus = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  ngOnInit(): void {
  }

}
