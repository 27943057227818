<div class="card addonBoxContainer" (click)="modalOpen(FTPuploadSettingModal)">
    <div class="card-body">
        <h5>FTP Upload</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Brand your account with your company profile.
        </p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'upload-cloud'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>


<!-- Modal -->
<ng-template #FTPuploadSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">FTP Upload </h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 border-right">
                            <div class="form-group">
                                <label for="basicInput">FTP Address</label>
                                <input type="text" class="form-control" id="FTPAddress" placeholder="FTP Address" />
                            </div>
                            <div class="form-group">
                                <label for="basicInput">Username</label>
                                <input type="text" class="form-control" id="Username" placeholder="Username" />
                            </div>
                            <div class="form-group mb-1">
                                <label for="customSelect">Security</label>
                                <ng-select [items]="chooseSecurity" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                                    placeholder="Nothing selected"> </ng-select>
                            </div>
                            <div class="form-group">
                                <label for="basicInput">Destination folder for PDF</label>
                                <input type="text" class="form-control" id="" placeholder="" />
                            </div>
                            <div class="custom-control custom-switch border-top border-bottom pt-2 pb-2 form-group mb-1 mt-2">
                                <div class="d-flex justify-content-between">
                                    <small>Save all signed documents to the FTP</small>
                                    <input type="checkbox" class="custom-control-input" id="signedDocumentsFTP" />
                                    <label class="custom-control-label" for="signedDocumentsFTP"></label>
                                </div>
                            </div>
                            <div class="form-group mb-1">
                                <label for="customSelect">Signed documents from specific templates</label>
                                <ng-select [items]="choosespecifictemplates" bindLabel="name" [readonly]="readonly"
                                    [searchable]="searchable" placeholder="Nothing selected"> </ng-select>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="basicInput">Port</label>
                                <input type="text" class="form-control" id="Port" placeholder="Port" />
                            </div>
                            <div class="form-group">
                                <label for="basicInput">Password</label>
                                <input type="password" class="form-control" id="" placeholder="password" />
                            </div>
                            <div class="form-group mb-1">
                                <label for="customSelect">Format</label>
                                <ng-select [items]="chooseFormat" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                                    placeholder="Nothing selected"> </ng-select>
                            </div>
                            <div class="form-group">
                                <label for="basicInput">Destination folder for XML</label>
                                <input type="text" class="form-control" id="" placeholder="" />
                            </div>
                            <div class="form-group mb-1">
                                <label for="customSelect">Signed documents from specific document types</label>
                                <ng-select [items]="choosespecifictype" bindLabel="name" [readonly]="readonly"
                                    [searchable]="searchable" placeholder="Nothing selected"> </ng-select>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary waves-effect" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Test connection
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / Modal -->