import { Injectable, Inject, Injector } from '@angular/core';
import feather from 'feather-icons';
import uuid from 'short-unique-id';
import { ToastrService } from 'ngx-toastr';
// import { Blocks } from '../blocks/blocks';
import { GlobalService } from 'app/global/global.service';
import { Blocks } from '../blocks/blocks';
import { tagsBuilder } from '../tags-builder/tagsBuilder';
import { PopOver } from '../popover/popover';
declare var $: any;
declare var CKEDITOR: any;
@Injectable({
    providedIn: 'root',
})
export class contentFields {
    public instance_name = '';
    public uuid = new uuid({ length: 6, dictionary: 'number' });
    public keydownCount = 0;
    public selectMenuIsOpen = false;
    constructor(@Inject(Injector) private readonly injector: Injector) {
        var that = this;
    }

    textFieldBlockHtml(params: any = {}) {
        return `
        <div contenteditable="false" class="form-builder-table drag-fields" selected-field="1" field-name="Text Field" field="text-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" allow_multi_lines="0" column="100" selectedtype="full_name" selecttypetext="Full name" selectedtypeid="1" selectedtypeid="0" field_block="1">
            <div class="custum-form-builder-box fields-ele-container">
                <label class="custum-form-builder-label selected-label">Full name</label>
                <input class="custum-form-builder-field form-control form-builder-field-label" type="text" placeholder="Full name">
                <textarea class="hidden text-field-textarea form-control" placeholder="Full name"></textarea>
            </div>
        </div>
     `;
    }

    datepickerFieldBlockHtml(params: any = {}) {
        return `
        <div contenteditable="false" class="form-builder-table drag-fields" selected-field="4" field-name="Datepicker" field="datepicker-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" column="100" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedtypeid="0" field_block="1">
            <div class="custum-form-builder-box fields-ele-container">
                <label class="custum-form-builder-label selected-label">New field</label>
                <input class="custum-form-builder-field form-control form-builder-field-label datepicker-field" type="text" placeholder="New field">
            </div>
        </div>
        `;
    }

    checkboxFeildBlockHtml(params: any = {}) {
        return `<div selected-field="2" field-name="Checkbox" field="checkbox-field" class="form-builder-table checkbox-field drag-fields" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" pre_checked="0" column="100" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedtypeid="0" field_block="1">
                <div class="drag-fields-inner-container checkbox-field-inner-container fields-ele-container">
                    <div class="checkbox-field-box">
                        ${feather.icons.check.toSvg({ class: 'cursor-pointer checkbox-checkIcon', width: 14, height: 14 })}
                    </div>
                    <span class="field-label checkbox-field-label selected-label">New field</span>
                </div>
            </div>
        `;
    }

    droplistFeildBlockHtml(params: any = {}) {
        var uuid = params.uuid;
        return `<div selected-field="3" field-name="Droplist" field="droplist-field" class="form-builder-table droplist-field drag-fields" uuid="${uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" column="50" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" optionlabel="" optionvalue="0" selectedtypeid="0" field_block="1">
                <div class="drag-fields-inner-container droplist-field-inner-container fields-ele-container">
                    <span class="custum-form-builder-label field-label selected-label">Select Option</span>
                    <div class="droplist-inner-box droplist-menu">
                        <span class="selected-option-label droplist-option-label form-builder-field-label" droplistplaceholder="Select Option"></span>
                        ${feather.icons['chevron-down'].toSvg({ class: 'cursor-pointer droplist-arrowDown', width: 14, height: 14 })}
                        <ng-dropdown-panel role="listbox" aria-label="Options list" class="ng-dropdown-panel ng-select-bottom droplist-dropdown hidden">
                            <div class="ng-dropdown-panel-items scroll-host">
                                <div class="ng-option not-found pointer-none" option_uuid="0">
                                    <span class="ng-option-label">No option found</span>
                                </div>
                            </div>
                        </ng-dropdown-panel>
                    </div>
                    <div id="${uuid}" data-options='${JSON.stringify([])}'></div>
                </div>
                </div>
            </div>
        `;
    }

    multiChoiceFeildBlockHtml(params: any = {}) {
        var uuid = params.uuid;
        var defaultOptions = [{ key: 'Option 1', value: this.uuid() }, { key: 'Option 2', value: this.uuid() }];
        var options = '';
        for (const option of defaultOptions) {
            options += `
                        <div class="multiChoice-item" option_uuid="${option.value}">
                            <div class="multiChoice-box">
                                <div class="multiChoice-inner-circle"></div>
                                ${feather.icons.check.toSvg({ class: 'cursor-pointer multiChoice-inner-check', width: 14, height: 14 })}
                            </div>
                            <label class="multiChoice-label-text">${option.key}</label>
                        </div>`;
        }
        return `<div selected-field="6" field-name="List" field="multichoice-field" class="form-builder-table multichoice-field multiChoice-radio drag-fields" uuid="${uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" multi_choice="0" hide_label="0" column="50" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedtypeid="0" field_block="1">
                    <div class="custum-form-builder-box">
                        <span class="custum-form-builder-label field-label selected-label">Select Option</span>
                        <!--<script id="${uuid}" type="text/json">${JSON.stringify(defaultOptions)}</script>-->
                        <div id="${uuid}" data-options='${JSON.stringify(defaultOptions)}'></div>
                        <div class="multiChoice-item-container fields-ele-container">
                            ${options}
                        </div>
                    </div>
                </div>
            `;
    }

    signatureFeildBlockHtml(params: any = {}) {
            return `<div selected-field="5" field-name="Signature" field="signature-field" class="form-builder-table signature-field drag-fields" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" column="100" selectedtypeid="0" field_block="1">
                    <div class="drag-fields-inner-container signature-field-inner-container fields-ele-container">
                        <span class="field-label selected-label">Signature</span>
                        <div class="clear-signature-btn" uuid="${params.uuid}">${feather.icons['rotate-ccw'].toSvg({ class: 'cursor-pointer', width: 20, height: 20 })}</div>
                        <canvas id="signaturepad${params.uuid}" width="100%" height=320></canvas>
                    </div>
                </div>
            `;
        }

    fieldBlock(params:any = {}){
        var that = this;
        var uuid = params.uuid;
        var fieldView = that.viewRoute(params.type,uuid);
        const textBlockElement = document.createElement('div')
        textBlockElement.className = 'content-box content-box-block content-field-block marginminus70';
        textBlockElement.innerHTML = `<div class="sort-helper">
        <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
        <div class="highlightSortContentBox">
                        <p>field Block</p><svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" class="Icon"><path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z" clip-rule="evenodd"></path></svg></div></div><div class="content-box-row">
            <div class="rowItem">
                ${that.popOverFunc.listingPopover({classType:'field-block',uuid:uuid,contentType:'settings'})}
                <div class="sort-helper-ss"><div class="sort-helper sort-helper-inner"><svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
                    <div class="highlightSortContentBox">
                        <p>field Block</p><svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" class="Icon"><path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z" clip-rule="evenodd"></path></svg></div>
                </div>
                </div>
                <div class="content-box-inner content-field-inner" editor-uuid="${uuid}" type="${params.type} block">
                    <div class="editor${uuid} cke-editor-placeholder cke-editor-box ${params.type}-block" editor-uuid="${uuid}">
                        <div class="${params.type}-block-parent-container" contenteditable="false">${fieldView}</div>
                    </div>
                </div>
            </div>
        </div>`;
        return textBlockElement;
    }

    fieldBlockZone(params:any = {}){
        var that = this;
        var uuid = params.uuid;
        var fieldView = that.viewRoute(params.type,uuid);
       return  `<div class="rowItem">
                ${that.popOverFunc.listingPopover({classType:'field-block',uuid:uuid,contentType:'settings'})}
                <div class="sort-helper-ss">
                    <div class="sort-helper sort-helper-inner">
                    <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
                        <div class="highlightSortContentBox">
                            <p>Block</p><svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" class="Icon"><path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z" clip-rule="evenodd"></path></svg>
                        </div>
                    </div>
                </div>
                <div class="content-box-inner content-field-inner" editor-uuid="${uuid}" type="${params.type} block">
                    <div class="editor${uuid} cke-editor-placeholder cke-editor-box ${params.type}-block" editor-uuid="${uuid}">
                        <div class="${params.type}-block-parent-container" contenteditable="false">${fieldView}</div>
                    </div>
                </div>
            </div>`
    }

    addFieldBlock(params:any = {},callback){
        var uuid = this.uuid();
         if (params.position == 'left') {
            $(this.fieldBlockZone({type:params.type,uuid:uuid})).insertBefore($('.content-box-enter').find('.rowItem:first'));
        } else if (params.position == 'right') {
            $(this.fieldBlockZone({type:params.type,uuid:uuid})).insertAfter($('.content-box-enter').find('.rowItem:last'));
        } else {
            $(this.fieldBlock({type:params.type,uuid:uuid})).insertAfter($('.dropIndicator-bottom').parent('.content-box'));
        }
        if(callback){
            callback(uuid,$('.content-box-enter'));
        }
    }

    viewRoute(viewname:any,uuid:any){
        var that = this;
        var view = '';
        switch (viewname) {
            case 'text':
                view = that.textFieldBlockHtml({uuid:uuid});
                break;
            case 'datepicker':
                view = that.datepickerFieldBlockHtml({uuid:uuid});
                break;
            case 'checkbox':
                view = that.checkboxFeildBlockHtml({uuid:uuid});
                break;
            case 'droplist':
                view = that.droplistFeildBlockHtml({uuid:uuid});
                break;
            case 'multichoice':
                view = that.multiChoiceFeildBlockHtml({uuid:uuid});
                break;
            case 'signature':
                view = that.signatureFeildBlockHtml({uuid:uuid});
                break;
        }
        return view;
    }

    private get popOverFunc(){
        return this.injector.get(PopOver);
    }
}