import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apisettings',
  templateUrl: './apisettings.component.html',
  styleUrls: ['./apisettings.component.scss']
})
export class ApisettingsComponent implements OnInit {

  public readonly = false;
  public searchable = false;
  choosetemplate = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  chooseTimeZone = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
