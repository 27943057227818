<!-- Modal -->
<button type="button" class="btn btn-success float-right adddatagroupBtn" (click)="dataGroupsModalOpen(dataGroupsModal)"
    rippleEffect>Add data group
</button>

<ng-template #dataGroupsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Data Group</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <div class="form-group mt-2">
                <input type="text" placeholder="Data group" class="form-control m-auto w-50" />
            </div>
            <div class="text-center">
                <p class="card-text d-block mb-1">Select a file to upload (CSV Only)</p>
                <button type="button" class="btn mb-2 btn-success">Select (CSV)</button>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / Modal -->