import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-get-link-addon',
  templateUrl: './get-link-addon.component.html',
  styleUrls: ['./get-link-addon.component.scss']
})
export class GetLinkAddonComponent implements OnInit {

  // modal Basic
  modalOpen(getLinkSettingModal) {
    this.modalService.open(getLinkSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }
  // swiper
  public swiperPaginations: SwiperConfigInterface = {
    // pagination: true
  };

  

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
