import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-type-addon',
  templateUrl: './document-type-addon.component.html',
  styleUrls: ['./document-type-addon.component.scss']
})
export class DocumentTypeAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
