import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-select-typeof-signature-addon',
  templateUrl: './select-typeof-signature-addon.component.html',
  styleUrls: ['./select-typeof-signature-addon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class SelectTypeofSignatureAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
