import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-revoke-addon',
  templateUrl: './revoke-addon.component.html',
  styleUrls: ['./revoke-addon.component.scss']
})
export class RevokeAddonComponent implements OnInit {

     // modal Basic
 modalOpen(revokeSettingModal) {
  this.modalService.open(revokeSettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
  // pagination: true
};



constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
