<div>
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header py-75 px-1 mt-50">
        <div class="linked-organization-inner">
            <div class="d-flex justify-content-start align-items-center">
                <div class="user-page-info">
                    <h5 class="mb-0">
                        Content table settings
                    </h5>
                </div>
            </div>
        </div>
        <div class="cursor-pointer closeMenu" (click)="clsoe()">
            <i data-feather="x" [size]="16" class="ml-0"></i>
        </div>
    </div>
</div>

<div class="content-tableSetting-scroll" [perfectScrollbar]>
    <div class="form-group px-1">
        <label class="col-form-label pt-0">Styles</label>

        <div class="d-flex py-25">
            <div class="custom-control custom-checkbox w-50">
                <input type="checkbox" [checked]="header" class="custom-control-input" id="header-option" (change)="toggleHeaderColor($event)" />
                <label class="custom-control-label" for="header-option">Header</label>
            </div>
            <div class="custom-control custom-checkbox w-50">
                <input type="checkbox" [checked]="footer" class="custom-control-input" id="footer-option" (change)="toggleFooterColor($event)"/>
                <label class="custom-control-label" for="footer-option">Footer</label>
            </div>
        </div>
        <div class="d-flex py-75">
            <div class="custom-control custom-checkbox w-50">
                <input type="checkbox" [checked]="borderRadius" class="custom-control-input" id="border-radius" (change)="toggleBorderRadius($event)" />
                <label class="custom-control-label" for="border-radius">Border radius</label>
            </div>
        </div>
    </div>

    <div class="form-group px-1">
        <label class="col-form-label pt-25">Default Styles</label>

        <div class="default-style-container">
            <div class="default-style-column" *ngFor="let color of colors; let i = index" [ngClass]="{'active': i == activatedTheme}" (click)="selectTheme(color,i)">
                <div class="default-style-column-layers" [ngStyle]="{'background-color':(color.headerColor)}"></div>
                <div class="default-style-column-layers" [ngStyle]="{'background-color':color.evenColor}"></div>
                <div class="default-style-column-layers" [ngStyle]="{'background-color':color.oddColor}"></div>
                <div class="default-style-column-layers" [ngStyle]="{'background-color':(footer ? color.secondaryFooterColor : color.footerColor)}"></div>
            </div>
        </div>
    </div>

    <div class="form-group px-1">
        <label class="col-form-label pt-25">Custom Styles</label>

        <div class="table-setting-color-box mb-75" *ngIf="header">
            <input class="form-control" [style.background]="headerColor" value="Header" readonly />
            <div class="color-picker-ts-box">
                <div [style.background]="headerColor" [cpOutputFormat]="'rgba'" [(colorPicker)]="headerColor" class="color-picker-ts" (colorPickerChange)="changeHeaderColor($event)"></div>
            </div>
        </div>

        <div class="table-setting-color-box mb-75">
            <input class="form-control" [style.background]="evenColor" value="Color 1" readonly />
            <div class="color-picker-ts-box">
                <div [style.background]="evenColor" [cpOutputFormat]="'rgba'" [(colorPicker)]="evenColor" class="color-picker-ts" (colorPickerChange)="changeEvenColor($event)"></div>
            </div>
        </div>

        <div class="table-setting-color-box mb-75">
            <input class="form-control" [style.background]="oddColor" value="Color 2" readonly />
            <div class="color-picker-ts-box">
                <div [style.background]="oddColor" [cpOutputFormat]="'rgba'" [(colorPicker)]="oddColor" class="color-picker-ts" (colorPickerChange)="changeOddColor($event)"></div>
            </div>
        </div>

        <div class="table-setting-color-box mb-75" *ngIf="footer">
            <input class="form-control" [style.background]="footerColor" value="Footer Color" readonly />
            <div class="color-picker-ts-box">
                <div [style.background]="footerColor" [cpOutputFormat]="'rgba'" [(colorPicker)]="footerColor" class="color-picker-ts" (colorPickerChange)="changeFooterColor($event)"></div>
            </div>
        </div>

        <div class="table-setting-color-box mb-75">
            <input class="form-control" [style.background]="borderColor" value="Border Color" readonly />
            <div class="color-picker-ts-box">
                <div [style.background]="borderColor" [cpOutputFormat]="'rgba'" [(colorPicker)]="borderColor" class="color-picker-ts" (colorPickerChange)="changeBorderColor($event)"></div>
            </div>
        </div>

        <div class="table-setting-color-box mb-75">
            <input style="color: white;" class="form-control" [style.background]="textColor" value="Text Color" readonly />
            <div class="color-picker-ts-box">
                <div [style.background]="textColor" [cpOutputFormat]="'rgba'" [(colorPicker)]="textColor" class="color-picker-ts" (colorPickerChange)="changeTextColor($event)"></div>
            </div>
        </div>
    </div>

    <div class="form-group px-1">
        <label class="col-form-label pt-0" for="name">Border Style</label>
        <ng-select [items]="borderStyle" bindLabel="name" bindValue="id" [(ngModel)]="selectedBorderStyle" [clearable]="false" (change)="changeBorderStyle($event)">
        </ng-select>
    </div>
</div>