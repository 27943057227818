import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';


@Component({
  selector: 'app-cc-documents',
  templateUrl: './cc-documents.component.html',
  styleUrls: ['./cc-documents.component.scss']
})
export class CCDocumentsComponent implements OnInit {

  constructor(private _coreSidebarService: CoreSidebarService) { }

/**
   * Toggle Sidebar
   *
   * @param nameRef
   */
  createNewTodo(nameRef, closeNameRef): void {
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
    this._coreSidebarService.getSidebarRegistry(closeNameRef).toggleOpen();
  }
  /**
   * Toggle Sidebar
   *
   * @param nameRef
   */
  toggleSidebar(nameRef): void {
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
  }
  ngOnInit(): void {
  }

}
