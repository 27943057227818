<div class="content-area-wrapper template-view container-xxl p-0">
    <!-- Todo Main Sidebar (Filter) -->
    <core-sidebar
      class="sidebar-left"
      name="todo-sidebar-main"
      overlayClass="body-content-overlay"
      collapsibleSidebar="bs-gt-lg"
    >
      <app-template-main-sidebar></app-template-main-sidebar>
    </core-sidebar>
    <!--/ Todo Main Sidebar -->
  
    <!-- content-right -->
    <div class="content-right">
      <app-template-listing></app-template-listing>
    </div>
    <!--/ content-right -->
  </div>
  