<div class="content-area-wrapper template-view container-xxl p-0">
    <!-- Todo Main Sidebar (Filter) -->
    <core-sidebar class="sidebar-left" name="todo-sidebar-main-one" overlayClass="body-content-overlay"
      collapsibleSidebar="bs-gt-lg">
      <app-document-main-sidebar></app-document-main-sidebar>
    </core-sidebar>
    <!--/ Todo Main Sidebar -->
  
  
    <!-- content-right -->
    <div class="content-right">
      <div class="todo-app-list">
        <div class="app-fixed-search d-flex align-items-center">
          <div class="sidebar-toggle d-block d-lg-none ml-1">
            <button class="btn p-0" (click)="toggleSidebar('todo-sidebar-main-one')">
              <i class="feather icon-menu"></i>
            </button>
          </div>
  
          <div class="d-flex align-content-center justify-content-between w-100">
            <div class="input-group input-group-merge">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="feather icon-search text-muted"></i></span>
              </div>
              <input type="text" class="form-control" id="document-search" placeholder="Search documents" aria-label="Search..."
                aria-describedby="document-search" />
            </div>
          </div>
          <a class="p-0 d-flex align-content-center mr-1 justify-content-center">
            <i class="feather font-medium-1 icon-pie-chart" (click)="showDocumentChartViewCode()"></i>
          </a>
          <a class="p-0 d-flex align-content-center mr-1 justify-content-center">
            <i class="feather font-medium-1 icon-folder" (click)="showFolderViewCode()"></i>
          </a>
          <a class="p-0 d-flex align-content-center mr-75 justify-content-center">
            <i class="feather font-medium-2 icon-menu" (click)="showDocumentTableListViewCode()"></i>
          </a>
          <div ngbDropdown>
            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow mr-75 d-flex" id="todoActions" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i data-feather="more-vertical" [size]="18" class="text-body"></i>
            </a>
            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
              <a ngbDropdownItem class="sort-asc">
                <i class="feather font-medium-3 mr-50 icon-upload-cloud"></i>
                Upload file</a>
              <a ngbDropdownItem class="sort-asc">
                <i class="feather font-medium-3 mr-50 icon-edit"></i>
                Edit folder</a>
              <a ngbDropdownItem class="sort-asc">
                <i class="feather font-medium-3 mr-50 icon-download"></i>
                Export CSV</a>
              <a ngbDropdownItem class="sort-asc">
                <i class="feather font-medium-3 mr-50 icon-trash-2"></i>
                Trash</a>
            </div>
          </div>
        </div>
      </div>
      <div class="documentListingViewMainContainer" *ngIf="hiddenDocumentTableListView">
        <!-- listing view table -->
        <app-document-listing></app-document-listing>
        <!-- listing view table -->
      </div>
  
      <div class="documentFolderviewMainContainer" *ngIf="hiddenDocumentFolderView">
        <!-- document folder view -->
        <app-document-folder-view></app-document-folder-view>
        <!-- document folder view -->
      </div>
  
      <div class="documentChartviewMainContainer" *ngIf="hiddenDocumentChartView">
          <!-- document graph view -->
          <app-document-graph-view></app-document-graph-view>
          <!-- document graph view -->
        </div>
    </div>
    <!--/ content-right -->
  
  </div>
  
  <!-- add-attachments-sidebar -->
  <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="document-right-sidebar" overlayClass="modal-backdrop">
    <app-document-right-sidebar></app-document-right-sidebar>
  </core-sidebar>
  <!--/ add-attachments-sidebar -->