import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-get-contactsfrom-apiaddon',
  templateUrl: './get-contactsfrom-apiaddon.component.html',
  styleUrls: ['./get-contactsfrom-apiaddon.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class GetContactsfromAPIAddonComponent implements OnInit {

  // modal Basic
modalOpen(getContactFromAPISettingModal) {
  this.modalService.open(getContactFromAPISettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
 
};

constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
