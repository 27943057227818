import { Component, OnInit } from '@angular/core';
import { colors } from 'app/colors.const';

@Component({
  selector: 'app-document-graph-view',
  templateUrl: './document-graph-view.component.html',
  styleUrls: ['./document-graph-view.component.scss']
})
export class DocumentGraphViewComponent implements OnInit {

  constructor() { }

  // Color Variables
private primaryColorShade = '#836AF9';
private yellowColor = '#ffe800';
private successColorShade = '#28dac6';
private warningColorShade = '#ffe802';
private warningLightColor = '#FDAC34';
private infoColorShade = '#299AFF';
private greyColor = '#4F5D70';
private blueColor = '#2c9aff';
private blueLightColor = '#84D0FF';
private greyLightColor = '#EDF1F4';
private tooltipShadow = 'rgba(0, 0, 0, 0.25)';
private lineChartPrimary = '#666ee8';
private lineChartDanger = '#ff4961';
private labelColor = '#6e6b7b';
private grid_line_color = 'rgba(200, 200, 200, 0.2)'; // RGBA color helps in dark layout

  // doughnut Chart
  public doughnutChart = {
    chartType: 'doughnut',
    options: {
      responsive: false,
      responsiveAnimationDuration: 500,
      cutoutPercentage: 60,
      aspectRatio: 1.4,
      legend: { display: false },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[0].labels[tooltipItem.index] || '',
              value = data.datasets[0].data[tooltipItem.index];
            var output = ' ' + label + ' : ' + value + ' %';
            return output;
          }
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      }
    },

    datasets: [
      {
        labels: ['Accepted', 'Pending','drafts','lost'],
        data: [50, 20, 15, 15],
        backgroundColor: [this.successColorShade, this.warningLightColor, this.lineChartPrimary, this.greyColor],
        borderWidth: 0,
        pointStyle: 'rectRounded'
      }
    ]
  };

   // scatter Chart
   public scatterChart = {
    chartType: 'scatter',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 800,
      title: {
        display: false,
        text: 'Chart.js Scatter Chart'
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: this.grid_line_color,
              zeroLineColor: this.grid_line_color
            },
            ticks: {
              stepSize: 10,
              min: 0,
              max: 140
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              color: this.grid_line_color,
              zeroLineColor: this.grid_line_color
            },
            ticks: {
              stepSize: 100,
              min: 0,
              max: 400
            }
          }
        ]
      },
      legend: {
        position: 'top',
        align: 'start',
        labels: {
          usePointStyle: true,
          padding: 25,
          boxWidth: 9
        }
      }
    },

    datasets: [
      {
        label: 'Sent',
        data: [
          {
            x: 72,
            y: 225
          },
          {
            x: 81,
            y: 270
          },
          {
            x: 90,
            y: 230
          },
          {
            x: 103,
            y: 305
          },
          {
            x: 103,
            y: 245
          },
          {
            x: 108,
            y: 275
          },
          {
            x: 110,
            y: 290
          },
          {
            x: 111,
            y: 315
          },
          {
            x: 109,
            y: 350
          },
          {
            x: 116,
            y: 340
          },
          {
            x: 113,
            y: 260
          },
          {
            x: 117,
            y: 275
          },
          {
            x: 117,
            y: 295
          },
          {
            x: 126,
            y: 280
          },
          {
            x: 127,
            y: 340
          },
          {
            x: 133,
            y: 330
          }
        ],
        borderColor: 'transparent',
        pointBorderWidth: 2,
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: colors.solid.primary,
        pointHoverBackgroundColor: colors.solid.primary,
        pointHoverBorderColor: colors.solid.primary
      },
      {
        label: 'Accepted',
        data: [
          {
            x: 13,
            y: 95
          },
          {
            x: 22,
            y: 105
          },
          {
            x: 17,
            y: 115
          },
          {
            x: 19,
            y: 130
          },
          {
            x: 21,
            y: 125
          },
          {
            x: 35,
            y: 125
          },
          {
            x: 13,
            y: 155
          },
          {
            x: 21,
            y: 165
          },
          {
            x: 25,
            y: 155
          },
          {
            x: 18,
            y: 190
          },
          {
            x: 26,
            y: 180
          },
          {
            x: 43,
            y: 180
          },
          {
            x: 53,
            y: 202
          },
          {
            x: 61,
            y: 165
          },
          {
            x: 67,
            y: 225
          }
        ],
        pointBackgroundColor: this.yellowColor,
        borderColor: 'transparent',
        pointRadius: 5,
        pointHoverBackgroundColor: this.yellowColor,
        pointHoverBorderColor: this.yellowColor
      }
    ]
  };
   // Horizontal Bar Chart
   public horiBarChart = {
    chartType: 'horizontalBar',

    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'right'
        }
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: this.tooltipShadow,
        backgroundColor: colors.solid.white,
        titleFontColor: colors.solid.black,
        bodyFontColor: colors.solid.black
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              zeroLineColor: this.grid_line_color,
              borderColor: 'transparent',
              color: this.grid_line_color,
              drawTicks: false
            },
            scaleLabel: {
              display: true
            },
            ticks: {
              min: 0
            }
          }
        ],
        yAxes: [
          {
            display: true,
            barThickness: 15,
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true
            }
          }
        ]
      }
    },

    labels: ['MON', 'TUE', 'WED ', 'THU', 'FRI', 'SAT', 'SUN'],
    datasets: [
      {
        data: [710, 350, 470, 580, 230, 460, 120],
        backgroundColor: colors.solid.info,
        borderColor: 'transparent',
        hoverBackgroundColor: colors.solid.info,
        hoverBorderColor: colors.solid.info,
        borderWidth: 42,
        radius: 12,
        pointRadius: 12,
        hoverBorderWidth: 12
      }
    ],

    legend: false
  };
  //** To add spacing between legends and chart
  public plugins = [
    {
      beforeInit(chart) {
        chart.legend.afterFit = function () {
          this.height += 20;
        };
      }
    }
  ];

  ngOnInit(): void {
  }

}
