<section class="p-2 documentChartViewinnerContainer" [perfectScrollbar]>
    <div class="row">
        <!-- Donut Chart Starts -->
        <div class="col-lg-6 col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Status Overview</h4>
                    <p class="font-small-3">Show realtime status of all contracts</p>
                </div>
                <div class="card-body">
                    <canvas class="doughnut-chart-ex chartjs mx-auto" height="214" width="300" baseChart [datasets]="doughnutChart.datasets"
                        [options]="doughnutChart.options" [chartType]="doughnutChart.chartType">
                    </canvas>
                    <div class="d-flex justify-content-between mt-3 mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="check-square" class="font-medium-2 text-primary"></i>
                            <span class="font-weight-bold ml-75 mr-25">Accepted</span>
                            <span>- 50%</span>
                        </div>
                        <div>
                            <span>20%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="alert-triangle" class="font-medium-2 text-warning"></i>
                            <span class="font-weight-bold ml-75 mr-25">Pending</span>
                            <span>- 20%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="x-square" class="font-medium-2 text-warning"></i>
                            <span class="font-weight-bold ml-75 mr-25">Draft</span>
                            <span>- 15%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="alert-octagon" class="font-medium-2 text-danger"></i>
                            <span class="font-weight-bold ml-75 mr-25">Lost</span>
                            <span>- 15%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Donut Chart Starts -->
        <!-- Donut Chart Starts -->
        <div class="col-lg-6 col-12">
            <div class="card">
                <div class="card-header px-0">
                    <h4 class="card-title">Contracts Needing Attention</h4>
                    <p class="font-small-3">Gives you a glimt of documents that could need some attention</p>
                </div>
                <div class="card-body">
                    <canvas class="doughnut-chart-ex chartjs mx-auto" baseChart [datasets]="doughnutChart.datasets"
                        [options]="doughnutChart.options" [chartType]="doughnutChart.chartType">
                    </canvas>
                    <div class="d-flex justify-content-between mt-3 mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="check-square" class="font-medium-2 text-primary"></i>
                            <span class="font-weight-bold ml-75 mr-25">Accepted</span>
                            <span>- 50%</span>
                        </div>
                        <div>
                            <span>20%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="alert-triangle" class="font-medium-2 text-warning"></i>
                            <span class="font-weight-bold ml-75 mr-25">Pending</span>
                            <span>- 20%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="x-square" class="font-medium-2 text-warning"></i>
                            <span class="font-weight-bold ml-75 mr-25">Draft</span>
                            <span>- 15%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="alert-octagon" class="font-medium-2 text-danger"></i>
                            <span class="font-weight-bold ml-75 mr-25">Lost</span>
                            <span>- 15%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Donut Chart Starts -->
    </div>

    <!-- Scatter Chart Starts  -->
    <div class="col-lg-12 col-12 p-0">
        <div class="card">
            <div class="card-header">
                <div>
                    <h4 class="card-title mb-25">Sent & Accepted Over Time</h4>
                    <p>Shows you all sent and signed documents per day</p>
                </div>
            </div>
            <div class="card-body">
                <canvas class="scatter-chart-ex chartjs" baseChart height="415" [datasets]="scatterChart.datasets"
                    [options]="scatterChart.options" [chartType]="scatterChart.chartType" [plugins]="plugins"></canvas>
            </div>
        </div>
    </div>
    <!--/ Scatter Chart Ends  -->

    <!-- Horizontal Bar Chart Start -->
    <div class="col-xl-12 col-12 p-0">
        <div class="card">
            <div class="card-header">
                <div class="header-left">
                    <h4 class="card-title">Status Overview Per User</h4>
                    <p>Shows you a status overview of all documents devided per user, in alphabetical order</p>
                </div>   
            </div>
            <div class="card-body">
                <canvas baseChart class="horizontal-bar-chart-ex chartjs" height="400" [datasets]="horiBarChart.datasets"
                    [labels]="horiBarChart.labels" [options]="horiBarChart.options" [legend]="horiBarChart.legend"
                    [chartType]="horiBarChart.chartType"></canvas>
            </div>
        </div>
    </div>
    <!--/ Horizontal Bar Chart End -->

</section>