<div class="content-wrapper people-view container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="card mb-2">
                    <div class="p-2">
                        <div class="row">
                            <div class="col-md-5 col-lg-5">
                                <div class="d-flex align-content-center justify-content-between w-100">
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i data-feather="search"></i></span>
                                        </div>
                                        <input (keydown.enter)="fetchRecipient(1,$event.target.value)" type="text" class="form-control" id="email-search" placeholder="Search" aria-label="Search..." aria-describedby="search" />
                                    </div>
                                </div>
                            </div>
                            <div class="m-0 d-flex align-items-center justify-content-end header-section-btn col-sm-12 col-md-6 col-lg-7">
                                <button type="button" class="btn btn-success float-right" (click)="bulkUploadModalOpen(bulkUploadModal)" rippleEffect> Bulk Uplaod
                                </button>
                                <button type="button" class="btn btn-success float-right ml-1" (click)="toggleSidebar('add-recipient-right-sidebar')"
                                    rippleEffect> Add Contact </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-listing">
                        <div class="table-responsive">
                            <app-skeleton *ngIf="loading" [loop]="5" [section]="'default'"></app-skeleton>
                            <!-- Hoverable rows -->
                            <table class="table table-hover table-striped" *ngIf="!loading">
                                <thead>
                                    <tr>
                                        <th>Users</th>
                                        <th>Organization</th>
                                        <th>Linked Document</th>
                                        <th>Added</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="tableDataRow no-record-found" *ngIf="recipients.length == 0 && !loading">
                                        <td colspan="4" class="text-center">No records found</td>
                                    </tr>
                                    <tr *ngFor="let recipient of recipients; let i = index" class="tableDataRow" (click)="toggleSidebar('recipient-right-sidebar',recipient,i)">
                                        <td>
                                            <div class="d-flex align-items-center ng-star-inserted">
                                                <div class="avatar mr-1">
                                                    <span class="avatar-content">{{recipient.firstname +' '+recipient.lastname | shortName}}</span>
                                                </div>
                                                <div>
                                                    <a href="javascript:void(0)" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                                                        {{recipient.firstname +' '+(recipient.lastname ?? '')  }}
                                                    </a>
                                                    <small> {{recipient.email}} </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{{ recipient.organization ? recipient.organization.name : ''   }}</span>
                                        </td>
                                        <td>
                                            <span class=""></span>
                                        </td>
                                        <td class="">
                                            <span>{{recipient.created_at | dateFormat:'DD-MMM-YYYY hh:mm:ss'}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ngb-pagination class="d-flex justify-content-end mt-1 mr-1" (pageChange)="fetchRecipient($event)" [collectionSize]="pagination.total" [(page)]="page" [maxSize]="3" aria-label="Default pagination">
                                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                        [size]="18" [class]="'font-weight-bolder'"></span>
                                </ng-template>
                                <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'"
                                        [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="add-recipient-right-sidebar" overlayClass="modal-backdrop">
    <app-add-recipient-sidebar (fetchRecipient)="fetchRecipient()"></app-add-recipient-sidebar>
</core-sidebar>


<!-- recipients-right-sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="recipient-right-sidebar" overlayClass="modal-backdrop">
    <app-recipients-right-sidebar (fetchRecipient)="fetchRecipient()" (deleteRecipient)="deleteRecipient($event)" (updateListing)="updateListing($event)"></app-recipients-right-sidebar>
</core-sidebar>
<!--/ recipients-right-sidebar -->

<!-- Modal -->
<ng-template #bulkUploadModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Bulk upload</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <div class="text-center mt-2">
                <p class="card-text d-block mb-1">Select a file to upload (CSV Only)</p>
                <button type="button" class="btn mb-2 btn-success">Select (CSV)</button>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / Modal -->