import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-uploaddocumentwidget',
  templateUrl: './uploaddocumentwidget.component.html',
  styleUrls: ['./uploaddocumentwidget.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class UploaddocumentwidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
