import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cc-revenue',
  templateUrl: './cc-revenue.component.html',
  styleUrls: ['./cc-revenue.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CCRevenueComponent implements OnInit {

  constructor() { }

  hiddenStartupField: boolean = false;
  hiddenStartupBtn: boolean = true;
  // hiddenCustomColumnFields: boolean = false;


  addStartupPrice() {
    this.hiddenStartupField = true;
    this.hiddenStartupBtn = false
  }
  hideStartupPrice() {
    this.hiddenStartupField = false;
    this.hiddenStartupBtn = true;
  }
  public readonly = false;
  public searchable = false;
  chooseCurrency = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  chooseDaysMounth = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  ngOnInit(): void {
  }

}
