import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-main-sidebar',
  templateUrl: './template-main-sidebar.component.html',
  styleUrls: ['./template-main-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TemplateMainSidebarComponent implements OnInit {

  public readonly = false;
  public searchable = false;
  tempfiltersShowHide: boolean = false;

  showDocumentTypeFilter = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  showAllLanguages = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  showAllCategory = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  templateFiltersShowHideClick() {
    this.tempfiltersShowHide = !this.tempfiltersShowHide;
  }

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  edittemplateView = function () {
      this.router.navigateByUrl('/edit-template');
  };

}
