import { Component, OnInit } from '@angular/core';
import { RecipientsTabComponent } from '../recipients-tab/recipients-tab.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-recipient-settings',
    templateUrl: './recipient-settings.component.html',
    styleUrls: ['./recipient-settings.component.scss']
})
export class RecipientSettingsComponent implements OnInit {

    constructor(
        private recipientsTab: RecipientsTabComponent,
        private modalService: NgbModal
    ) { }
    invitaionBoxHide:boolean = false;
    showBtn:boolean = true;

    authenticationBoxHide:boolean = false;
    authenticationShowBtn:boolean = true;

    public selectedInvitationType = 1;
    type = [
        { id: 1, name: 'Email' },
        { id: 2, name: 'Email & SMS' },
        { id: 3, name: 'SMS' },
        { id: 4, name: 'POS' },
        { id: 5, name: 'In-meeting' },
        { id: 6, name: 'Get link' },
    ];

    public selectedInvitationOrder = 1;
    order = [
        { id: 1, name: '1st' },
        { id: 2, name: '2nd' },
        { id: 3, name: '3rd' },
    ];

    public selectedConfirmation = 1;
    confirmation = [
        { id: 1, name: 'Email' },
        { id: 2, name: 'Email & SMS' },
        { id: 3, name: 'No confirmation' },
    ];

    public selectedAuthentication = 1;
    authentication = [
        { id: 1, name: 'E-signature' },
        { id: 2, name: 'Attest' },
        { id: 3, name: 'Esign & SMS PIN' },
    ];

    public selectedRequestaAttachments = 1;
    requestattachments = [
        { id: 1, name: 'No request' },
        { id: 2, name: 'ID attachment' },
    ];

    ngOnInit(): void {
    }

    modalOpen(modalRolesfieldEdit) {
        this.modalService.open(modalRolesfieldEdit, {
            size: 'lg',
            windowClass: 'select-template-modal',
            backdrop: 'static'
        });
    }

    backToRecipientView() {
        this.recipientsTab.recipientViewType = 0;
    }

    invitationCollapseShowFunc(){
      this.showBtn = false;
      this.invitaionBoxHide = true;
    }

    invitationCollapseHideFunc(){
      this.invitaionBoxHide = false;
      this.showBtn = true;
    }
  
    authenticationCollapseShowFunc(){
      this.authenticationShowBtn = false;
      this.authenticationBoxHide = true;
    }

    authenticationCollapseHideFunc(){
      this.authenticationBoxHide = false;
      this.authenticationShowBtn = true;
    }
}
