import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EditTemplateComponent } from '../../edit-template/edit-template.component';
import { currencies } from '../../general-modals/product-table-modals/currencies';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import uuid from 'short-unique-id';
import { productTable } from '../../general-editor-tabs/product-table/productTable';
import { GlobalService } from 'app/global/global.service';
declare var $: any;
@Component({
  selector: 'app-product-table-settings',
  templateUrl: './product-table-settings.component.html',
  styleUrls: ['./product-table-settings.component.scss']
})
export class ProductTableSettingsComponent implements OnChanges {

  constructor(
    private modalService: NgbModal,
    private productTable: productTable,
    private globalService: GlobalService,
    private template: EditTemplateComponent
  ) { }

  public uuid = new uuid({ length: 6, dictionary: 'number' });
  @Input() data: any[];
  public tableUuid = 0;
  public tableInstance = '';
  public productlibraryitemdetails = false;
  public productLibraryItemListingAppend = false;
  public showDesccripitonCheck = true;
  public selectedRolesAllowedPermission = 0;
  public selectedProductCurrency = 'USD';
  public selectedTaxInCurrency = '%';
  public selectedDiscountInCurrency = 1;
  public selectedProductTableFontSize = 6;
  public selectedProductTableFontStyle = 1;
  public selectedProductTableDescriptionFontSize = 5;
  public selectedProductTableAddColumn = 1;
  public numberOfDecimals = 2;
  public rolesAllowedPermission = [
    { name: 'Recipient', id: 1, color: '#a782be' },
    { name: 'Recipient 2', id: 2, color: '#ff8510' },
    { name: 'Sender', id: 0, color: '#0061ef' },
    { name: 'Anyone', id: -1, color: '#0061ef' },
  ];
  public taxSettings = {
    totalTax: 1,
    addTax: 0,
    taxType: '%',
    taxValue: '0',
    editTaxTitle: 0,
    applyTaxType: 'percentage'
  }
  public discountSettings = {
    totalDiscount: 1,
    addDiscount: 0,
    discountType: '%',
    discountValue: '0',
    editDiscountTitle: 0,
    applyDiscountType: 'percentage'
  }
  public productCurrency = currencies;

  public taxInCurrency = [
    { id: 1, name: '%' },
    { id: 2, name: 'USD' },
  ];

  public discountInCurrency = [
    { id: 1, name: '%' },
    { id: 2, name: 'USD' },
  ];

  public productTableFontStyle = [
    { id: 1, name: 'Normal', style:'normal' },
    { id: 2, name: 'Italic', style:'italic' },
  ];

  public productTableFontStyleWithBold = [
    { id: 1, name: 'Normal', style:'normal' },
    { id: 2, name: 'Italic', style:'italic' },
    { id: 2, name: 'Bold', style:'bold' },
  ];

  public productTableFontSize = [
    { id: 1, size: '8' },
    { id: 2, size: '9' },
    { id: 3, size: '10' },
    { id: 4, size: '11' },
    { id: 5, size: '12' },
    { id: 6, size: '14' },
    { id: 7, size: '16' },
    { id: 8, size: '18' },
    { id: 9, size: '20' },
    { id: 10, size: '22' },
    { id: 11, size: '24' },
    { id: 12, size: '26' },
    { id: 13, size: '28' },
    { id: 14, size: '30' },
  ];

  public productTableAddColumn = [
    { id: 1, name: 'Select Column' },
  ];
  public title = 'colorPicker';
  public color: string = '#3b3b3b'
  public arrayColors: any = {
    color1: '#3b3b3b',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  public selectedColor: string = 'color1';
  public productTableName = '';
  public productSettingsMenu = 'Default';
  public tableDesignSettings = {
    showHeader: "1",
    showVerticalborder: "1",
    showHorizontalborder: "1",
    header_fontsize: "14",
    header_fontstyle: "Normal",
    header_fontcolor: "#000000",
    header_bgcolor: "#f4f4f4",
    title_fontsize: "14",
    title_fontstyle: "Normal",
    title_fontcolor: "#000000",
    desc_fontsize: "11",
    desc_fontstyle: "Normal",
    desc_fontcolor: "#000000",
    is_desc: "1"
  };
  public totalSettingsOfTable = {
    show_subtotal: 1,
    show_sectionsubtotal: 1,
    show_total: 1,
    show_totalquantity: 0,
    show_inlinetaxttotal: 0,
    show_inlinediscounttotal: 0,
    allow_addnewrows: 1,
    allow_addnewcolumns: 1,
    allow_addremoveproducts: 1,
    show_inlineitemtotal: 1,
    show_priceperunit: 1,
    show_itemquantities: 1,
    addtablein_grandtotal: 1
  };
  public tablePermissionForRole = {
    whocanedit: 0,
    permissionaddproduct: 0,
    permissionremoveproduct: 0,
    permissionaddrow: 0,
    permissioneditqty: 0
  }
  ngOnInit(): void {
    var that = this;
    $(document).ready(function () {

    });
  }

  updateSettings() {
    $('.block-action-menu').addClass('hidden');
    this.tableUuid = this.data['uuid'];
    this.productTableName = $('table[table-uuid="' + this.tableUuid + '"]').attr('tablename');
    this.applySettings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.updateSettings();
    }
  }

  goToDetailFields() {
    this.productLibraryItemListingAppend = !this.productLibraryItemListingAppend;
  }


  applySettings() {
    const { decimalsallowed, currencysymbol, taxSettings, discountSettings, tableDesignSettings, totalSettingsOfTable, tablePermissionForRole } = this.getTableSettings(this.tableUuid);
    this.numberOfDecimals = decimalsallowed;
    this.selectedProductCurrency = currencysymbol;
    this.taxInCurrency = [
      { id: 1, name: '%' },
      { id: 2, name: currencysymbol },
    ];
    this.discountInCurrency = [
      { id: 1, name: '%' },
      { id: 2, name: currencysymbol },
    ];
    this.taxSettings = taxSettings;
    this.discountSettings = discountSettings;
    this.tableDesignSettings = tableDesignSettings;
    this.totalSettingsOfTable = totalSettingsOfTable;
    this.tablePermissionForRole = tablePermissionForRole;
  }

  createColumn(modal: any) {
    modal.dismiss('Cross click');
    var uuid = this.uuid();
    var table = $('table[table-uuid="' + this.tableUuid + '"]');
    $(this.productTable.addColumn({ uuid: uuid, tableUuid: this.tableUuid })).insertBefore(table.find('.itemTotalheading'));
    var columnRowHtml = '<td heading-uuid="' + uuid + '" contenteditable="true" class="extra-cell eachtd"></td>';
    $(columnRowHtml).insertBefore(table.find('.item_total'));
    this.globalService.initializColumnResizer(table);
  }


  changeCurrency(currency: any) {
    this.selectedProductCurrency = currency.code;
    $('table[table-uuid="' + this.tableUuid + '"]').attr({ currencysymbol: currency.code })
    $('table[table-uuid="' + this.tableUuid + '"]').css('--currencysymbol', "'" + currency.code + "'");
    $('table[total-table-uuid="' + this.tableUuid + '"]').css('--currencysymbol', "'" + currency.code + "'");
    const { taxSettings } = this.getTableSettings(this.tableUuid);
    if (taxSettings.taxType != '%') {
      this.updateTaxSettings('taxType', this.selectedProductCurrency);
    }
  }

  changeCostDecimal(decimal: any) {
    var that = this;
    $('table[table-uuid="' + this.tableUuid + '"]').attr('decimalsallowed', decimal.target.value);
    for (let index = 0; index < document.querySelectorAll('table[table-uuid="' + this.tableUuid + '"] tbody tr.item_row').length; index++) {
      var tr = document.querySelectorAll('table[table-uuid="' + this.tableUuid + '"] tbody tr.item_row')[index];
      var currentitemprice = $(tr).find('td.item_price:first').text();
      var returnDecimal = that.convertocaleStringFormat($('table[table-uuid="' + this.tableUuid + '"]'), currentitemprice, 2);
      $(tr).find('td.item_price:first').text(returnDecimal);
    }
  }

  addTax(value) {
    this.updateTaxSettings('addTax', value);
    if (value == 1) {
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').removeClass('hidden');
    } else {
      $('table[table-uuid="' + this.tableUuid + '"]').attr('taxsettings', JSON.stringify({ totalTax: 1, addTax: 0, taxType: '%', taxValue: '', editTaxTitle: 0, applyTaxType: 'percentage' }));
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').addClass('hidden');
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.inlinetotal_tax span:first').attr('contenteditable',false);
      this.taxSettings = { totalTax: 1, addTax: 0, taxType: '%', taxValue: '', editTaxTitle: 0, applyTaxType: 'percentage' };
      this.removeLineTax();
    }
  }

  taxType(value) {
    this.updateTaxSettings('totalTax', value);
    if(value == 0){
      this.inlineTax(value);
    }else{
      this.removeLineTax();
    }
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').toggleClass('hidden', (value == 0 ? true : false));
    if(value == 0 && this.totalSettingsOfTable.show_inlinetaxttotal == 1){
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').removeClass('hidden');
    }
  }

  changeTaxValue(taxvalue) {
    this.updateTaxSettings('taxValue', taxvalue.target.value);
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_tax_percentage').text(taxvalue.target.value);
  }

  changeTaxType(type) {
    this.updateTaxSettings('taxType', type.name);
    this.updateTaxSettings('applyTaxType', type.name == '%' ? 'percentage' : 'flat');
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_tax_percentage_main').toggleClass('hidden', (type.name == '%' ? false : true));
  }

  allowEditTaxTitle(event: any) {
    this.updateTaxSettings('editTaxTitle', (event.target.checked ? 1 : 0));
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.inlinetotal_tax span:first').attr('contenteditable',event.target.checked);
  }

  updateTaxSettings(key, value) {
    this.taxSettings[key] = value;
    $('table[table-uuid="' + this.tableUuid + '"]').attr('taxsettings', JSON.stringify(this.taxSettings));
  }

  inlineTax(value){
    var uuid = this.uuid();
    var table = $('table[table-uuid="' + this.tableUuid + '"]');
    $(this.productTable.addTaxColumn({ uuid: uuid, tableUuid: this.tableUuid, taxValue: this.taxSettings.taxValue})).insertBefore(table.find('.itemTotalheading'));
    var columnRowHtml = '<td heading-uuid="' + uuid + '" style="border: none;" contenteditable="true" class="extra-tax-cell item_tax eachtd">' + this.taxSettings.taxValue + '</td>';
    $(columnRowHtml).insertBefore(table.find('.item_total'));
    this.globalService.initializColumnResizer(table);
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_tax_percentage_main').addClass('hidden');
  }

  removeLineTax(){
    var inlineTax = $('table[table-uuid="' + this.tableUuid + '"]').find('.lineTax');
    var index = inlineTax.index();
    inlineTax.closest('table').find('tr.item_row').each(function() {
        this.removeChild(this.cells[ index ]);
    });
    inlineTax.remove();
    this.globalService.initializColumnResizer($('table[table-uuid="' + this.tableUuid + '"]'));
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_tax_percentage_main').removeClass('hidden');
  }

  /**
   * discount 
   */
  addDiscount(value) {
    this.updateDiscountSettings('addDiscount', value);
    if (value == 1) {
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineDiscount').removeClass('hidden');
    } else {
      $('table[table-uuid="' + this.tableUuid + '"]').attr('discountsettings', JSON.stringify({ totalDiscount: 1, addDiscount: 0, discountType: '%', discountValue: '', editDiscountTitle: 0, applyDiscountType: 'percentage' }));
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineDiscount').addClass('hidden');
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.inlinetotal_discount span:first').attr('contenteditable',false);
      this.discountSettings = { totalDiscount: 1, addDiscount: 0, discountType: '%', discountValue: '', editDiscountTitle: 0, applyDiscountType: 'percentage' };
      this.removeLineDiscount();
    }
  }

  discountType(value) {
    this.updateDiscountSettings('totalDiscount', value);
    if(value == 0){
      this.inlineDiscount();
    }else{
      this.removeLineDiscount();
    }
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineDiscount').toggleClass('hidden', (value == 0 ? true : false));
    if(value == 0 && this.totalSettingsOfTable.show_inlinediscounttotal == 1){
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineDiscount').removeClass('hidden');
    }
  }

  changeDiscountValue(discountvalue) {
    this.updateDiscountSettings('discountValue', discountvalue.target.value);
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_discount_percentage').text(discountvalue.target.value);
  }

  changeDiscountType(type) {
    this.updateDiscountSettings('discountType', type.name);
    this.updateDiscountSettings('applyDiscountType', type.name == '%' ? 'percentage' : 'flat');
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_discount_percentage_main').toggleClass('hidden', (type.name == '%' ? false : true));
  }

  allowEditDiscountTitle(event: any) {
    this.updateDiscountSettings('editDiscountTitle', (event.target.checked ? 1 : 0));
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.inlinetotal_discount span:first').attr('contenteditable',event.target.checked);
  }

  updateDiscountSettings(key, value) {
    this.discountSettings[key] = value;
    $('table[table-uuid="' + this.tableUuid + '"]').attr('discountsettings', JSON.stringify(this.discountSettings));
  }

  inlineDiscount(){
    var uuid = this.uuid();
    var table = $('table[table-uuid="' + this.tableUuid + '"]');
    $(this.productTable.addDiscountColumn({ uuid: uuid, tableUuid: this.tableUuid, discountValue: this.discountSettings.discountValue})).insertBefore(table.find('.itemTotalheading'));
    var columnRowHtml = '<td heading-uuid="' + uuid + '" style="border: none;" contenteditable="true" class="extra-discount-cell item_discount eachtd">' + this.discountSettings.discountValue + '</td>';
    $(columnRowHtml).insertBefore(table.find('.item_total'));
    this.globalService.initializColumnResizer(table);
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_discount_percentage_main').addClass('hidden');
  }

  removeLineDiscount(){
    var inlineDiscount = $('table[table-uuid="' + this.tableUuid + '"]').find('.lineDiscount');
    var index = inlineDiscount.index();
    inlineDiscount.closest('table').find('tr.item_row').each(function() {
        this.removeChild(this.cells[ index ]);
    });
    inlineDiscount.remove();
    this.globalService.initializColumnResizer($('table[table-uuid="' + this.tableUuid + '"]'));
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_discount_percentage_main').removeClass('hidden');
  }
  //end discount code

  /**
   * design settings
   */

  showHideHeader(event:any){
    this.updateDesignSettings('showHeader',(event.target.checked ? 1 : 0));
    $('table[table-uuid="' + this.tableUuid + '"] thead').toggleClass('hidden', event.target.checke);
    this.globalService.initializColumnResizer($('table[table-uuid="' + this.tableUuid + '"]'));
  }

  showHideVerticalBorder(event:any){
    this.updateDesignSettings('showVerticalborder',(event.target.checked ? 1 : 0));
    $('table[table-uuid="' + this.tableUuid + '"]').toggleClass('product-table-borders-v', event.target.checke);
  }

  showHideHorizontalBorder(event:any){
    this.updateDesignSettings('showHorizontalborder',(event.target.checked ? 1 : 0));
    $('table[table-uuid="' + this.tableUuid + '"]').toggleClass('product-table-borders-h', event.target.checke);
  }

  changeFontSize(fontSize:any,key:any,variable:any){
    this.updateDesignSettings(key,fontSize.size);
    $('table[table-uuid="' + this.tableUuid + '"]').css(variable, `${fontSize.size}px`);
  }

  changeFontStyle(fontStyle:any,key:any,variable:any){
    this.updateDesignSettings(key,fontStyle.name);
    $('table[table-uuid="' + this.tableUuid + '"]').css(variable, `${fontStyle.style}`);
  }
  
  changeColor(color:any,key:any,variable:any){
    this.updateDesignSettings(key,color);
    $('table[table-uuid="' + this.tableUuid + '"]').css(variable, `${color}`);
  }
  
  toggleDescriptoin(event:any){
    $('table[table-uuid="' + this.tableUuid + '"]').find('.tableDescriptionTr').toggleClass('hidden', event.target.checke);
    this.updateDesignSettings('is_desc',(event.target.checked ? 1 : 0));
  }
  
  updateDesignSettings(key, value) {
    this.tableDesignSettings[key] = value;
    $('table[table-uuid="' + this.tableUuid + '"]').attr('tabledesignsettings', JSON.stringify(this.tableDesignSettings));
  } 

  /**
   * end design settings
   */

  /**
   * total settings
   */

    toggleSubTotal(event){
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.producttable_subsection_total').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('show_subtotal',(event.target.checked ? 1 : 0));
    }

    toggleTotal(event){
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.producttable_total').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('show_total',(event.target.checked ? 1 : 0));
    }

    toggleMainQuantity(event){
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.producttable_quantity').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('show_totalquantity',(event.target.checked ? 1 : 0));
    }

    toggleMainTax(event){
      // $('table[total-table-uuid="' + this.tableUuid + '"]').find('.producttable_subsection_total').toggleClass('hidden',event.target.checked);
      if(event.target.checked && this.taxSettings.totalTax == 0){
        $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').toggleClass('hidden', false);
      }else if(event.target.checked == false && this.taxSettings.totalTax == 0){
        $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').toggleClass('hidden', true);
      }
      this.updateTotalSettings('show_inlinetaxttotal',(event.target.checked ? 1 : 0));
    }

    toggleMainDiscount(event){
      // $('table[total-table-uuid="' + this.tableUuid + '"]').find('.producttable_subsection_total').toggleClass('hidden',event.target.checked);
      if(event.target.checked && this.discountSettings.totalDiscount == 0){
        $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineDiscount').toggleClass('hidden', false);
      }else if(event.target.checked == false && this.discountSettings.totalDiscount == 0){
        $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineDiscount').toggleClass('hidden', true);
      }
      this.updateTotalSettings('show_inlinediscounttotal',(event.target.checked ? 1 : 0));
    }

    toggleGrandTotal(event){
      // $('table[total-table-uuid="' + this.tableUuid + '"]').find('.producttable_subsection_total').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('addtablein_grandtotal',(event.target.checked ? 1 : 0));
    }

    togglePriceOnRow(event){
      $('table[table-uuid="' + this.tableUuid + '"]').find('.priceperunitheading, .item_price').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('show_priceperunit',(event.target.checked ? 1 : 0));
      this.globalService.initializColumnResizer($('table[table-uuid="' + this.tableUuid + '"]'));
    }

    toggleQuantityOnRow(event){
      $('table[table-uuid="' + this.tableUuid + '"]').find('.quantityheading, .item_quantity').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('show_itemquantities',(event.target.checked ? 1 : 0));
      this.globalService.initializColumnResizer($('table[table-uuid="' + this.tableUuid + '"]'));
    }

    toggleTotalOnRow(event){
      $('table[table-uuid="' + this.tableUuid + '"]').find('.inlinetotalheading, .item_total').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('show_inlineitemtotal',(event.target.checked ? 1 : 0));
      this.globalService.initializColumnResizer($('table[table-uuid="' + this.tableUuid + '"]'));
    }

    toggleAddRemoveProduct(event){
      $('table[table-uuid="' + this.tableUuid + '"]').find('.deleteRowTable').toggleClass('hidden',!event.target.checked);
      $('table[button-table-uuid="' + this.tableUuid + '"]').find('#addFromCatalogPopupBtn').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('allow_addremoveproducts',(event.target.checked ? 1 : 0));
    }

    toggleAddRows(event){
      $('table[button-table-uuid="' + this.tableUuid + '"]').find('#addNewTableRows').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('allow_addnewrows',(event.target.checked ? 1 : 0));
    }

    toggleAddColumns(event){
      $('table[button-table-uuid="' + this.tableUuid + '"]').find('#productTableAddColumnBtn').toggleClass('hidden',!event.target.checked);
      this.updateTotalSettings('allow_addnewcolumns',(event.target.checked ? 1 : 0));
    }

    updateTotalSettings(key, value) {
      this.totalSettingsOfTable[key] = value;
      $('table[table-uuid="' + this.tableUuid + '"]').attr('totaltabsettingsoftable', JSON.stringify(this.totalSettingsOfTable));
    }

  /**
   * end total settings
   */

  /**
  * permission settings
  */

    changePermissionSettings(event, key){
      var value = 0
      if(key == 'whocanedit'){
        value = event.id
      }else{
        value = (event.target.checked ? 1 : 0)
      }
      this.updatePermissionSettings(key, value);
    }
    
    updatePermissionSettings(key, value) {
      this.tablePermissionForRole[key] = value;
      $('table[table-uuid="' + this.tableUuid + '"]').attr('tablepermissionforrole', JSON.stringify(this.tablePermissionForRole));
    }
  /**
  * permission settings
  */

  changeProductTableName(event:any){
    $('table[table-uuid="' + this.tableUuid + '"]').attr('tablename',event.target.value);
  }

  changeProductSettingsMenu(menu:any){
    this.applySettings();
    this.productSettingsMenu = menu;
  }

  convertocaleStringFormat(tableInstance: any, cost: any, maxDigits: any = '') {
    var decimalsallowed = $(tableInstance).attr('decimalsallowed');
    var options = {
      minimumFractionDigits: decimalsallowed,
      maximumFractionDigits: (maxDigits == '' ? decimalsallowed : maxDigits)
    };
    return Number(cost).toLocaleString('es-US', options);
  }

  getTableSettings(tableUuid: any) {
    var settings = $('table[table-uuid="' + tableUuid + '"]');
    var decimalsallowed = settings.attr('decimalsallowed');
    var currencysymbol = settings.attr('currencysymbol');
    var taxSettings = JSON.parse(settings.attr('taxsettings') || {});
    var discountSettings = JSON.parse(settings.attr('discountsettings') || {});
    var tableDesignSettings = JSON.parse(settings.attr('tabledesignsettings') || {});
    var totalSettingsOfTable = JSON.parse(settings.attr('totaltabsettingsoftable') || {});
    var tablePermissionForRole = JSON.parse(settings.attr('tablepermissionforrole') || {});
    return {
      decimalsallowed: decimalsallowed,
      currencysymbol: currencysymbol,
      taxSettings: taxSettings,
      discountSettings: discountSettings,
      tableDesignSettings: tableDesignSettings,
      totalSettingsOfTable: totalSettingsOfTable,
      tablePermissionForRole: tablePermissionForRole,
    }
  }

  close() {
    this.template.closeExternalRightMenu()
  }

}
