import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-document-fields-addon',
  templateUrl: './document-fields-addon.component.html',
  styleUrls: ['./document-fields-addon.component.scss']
})
export class DocumentFieldsAddonComponent implements OnInit {

   // modal Basic
   modalOpen(documentFieldsSettingModal) {
    this.modalService.open(documentFieldsSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }
  // swiper
  public swiperPaginations: SwiperConfigInterface = {
    pagination: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };
  
  
  
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
