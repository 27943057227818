import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor() { }


  getCompanySettings(){
    return axios.get('companies');
  }

  saveCompanySettings(formData:any){
    return axios.post('companyadd', formData);
  }
}
