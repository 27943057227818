<div>
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header py-75 px-1 mt-50">
        <div class="cursor-pointer" *ngIf="data['isFormBuilder'] == 1" (click)="backToFormBuilder()">
            <i data-feather="arrow-left" [size]="16" class="ml-0"></i>
        </div>
        <div class="linked-organization-inner">
            <div class="d-flex justify-content-start align-items-center">
                <div class="user-page-info">
                    <h5 class="mb-0">
                        {{data['field_name']}}
                    </h5>
                </div>
            </div>
        </div>
        <div class="cursor-pointer closeMenu" (click)="clsoe()">
            <i data-feather="x" [size]="16" class="ml-0"></i>
        </div>
    </div>

    <!-- Text || Datepikcer Field Settings View -->
    <div id="textFieldBox" *ngIf="data['selected_field'] == 1 || data['selected_field'] == 4">
        <div class="fieldsSettings-scroll-content-container" [perfectScrollbar]>
            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Field type</label>

                <div class="custum-dropdown-field-type" >
                    <!-- Select with custom Option -->
                    <ng-select
                    bindLabel="name" bindValue="id" [(ngModel)]="fieldSelectedType" [items]="TextandDatepickerFields" bindLabel="name" [readonly]="readonly" [searchable]="searchable" placeholder="Select field type" [clearable]="false" (change)="onSelectTypeChange($event)"
                    >
                        <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div>
                                <div class="">
                                    <span class="">{{ item.name }}</span>
                                    <span class="field-type-label">{{ item.field_type }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </ng-select>
                    <!--/ Select with custom Option -->
                </div>
            </div>
            <div class="form-group px-1" *ngIf="false">
                <label class="col-form-label pt-0" for="name">Field type</label>
                <ng-select bindLabel="name" bindValue="id" [(ngModel)]="fieldSelectedType" [items]="TextandDatepickerFields" bindLabel="name" [readonly]="readonly" [searchable]="searchable" placeholder="Select field type" [clearable]="false" (change)="onSelectTypeChange($event)"> </ng-select>
            </div>
            <div class="form-group px-1" *ngIf="showLabel == 0">
                <label class="col-form-label pt-0" for="basicInput">Label</label>
                <input type="text" class="form-control" id="basicInput" [placeholder]="labelName" [value]="labelName"
                    (keyup)="renameLabel($event)" />
            </div>
            <div class="form-group px-1" *ngIf="isTagView == 0">
                <label class="col-form-label pt-0" for="basicInput">Placeholder</label>
                <input type="text" class="form-control" id="basicInput" [placeholder]="placeholderName" [value]="[placeholderName]"
                    (keyup)="renamePlaceholder($event)" />
            </div>
            <hr class="mt-2 mb-2">
            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Required</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" [checked]="isRequired" class="custom-control-input" id="customSwitch3"
                        (change)="onRequiredChange($event)" />
                    <label class="custom-control-label" for="customSwitch3"></label>
                </div>
            </div>

            <div class="form-group px-1 mt-1" *ngIf="isTagView == 0">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" [checked]="isLocked" class="custom-control-input" id="customCheck1" (change)="onLockedChange($event)" />
                    <label class="custom-control-label" for="customCheck1">Lock</label>
                </div>
            </div>

            <hr class="mt-2 mb-1" *ngIf="isTagView == 0">

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Which role will fill this field?</label>
                <ng-select #roleSelecter [items]="selectRole" bindLabel="name" bindValue="id" [(ngModel)]="selectedRole" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select role" [clearable]="false" (change)="onChangeRoleType($event)"> </ng-select>
            </div>

            <div class="form-group px-1" *ngIf="isTagView == 0">
                <label class="col-form-label pt-0" for="name">Font size</label>
                <ng-select [items]="selectFontSize" bindLabel="name" [(ngModel)]="selectedFontSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select font size" (change)="changeFontSize($event)" [clearable]="false">
                </ng-select>
            </div>

            <div class="form-group px-1" *ngIf="data['isFormBuilder'] == 1 && isTagView == 0">
                <label class="col-form-label pt-0" for="name">Column size</label>
                <ng-select [items]="selectColumnSize" bindLabel="name" bindValue="id" [(ngModel)]="selectedColumnSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select column size" (change)="changeColumnSize($event)" [clearable]="false">
                </ng-select>
            </div>

            <!-- <hr class="mt-2 mb-1"> -->

            <div class="form-group px-1" *ngIf="!slashCommandTable && isTagView == 0 && data['isFieldBlock'] == 0">
                <label class="col-form-label pt-0" for="name">Field Style</label>
                <ng-select [items]="fields_style" [(ngModel)]="selectedFieldStyle" bindLabel="name" bindValue="name" (change)="selectFieldStyle(i,$event)" [clearable]="false" [clearable]="false">
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div class="field-style-container">
                            <div class="field-style-box cursor-pointer">
                                <div class="field-style {{item['class']}}">
                                    <span class="placeholder-field-label">{{item['name']}}</span>
                                </div>
                            </div>
                            <!-- <div class="field-style-box cursor-pointer" *ngIf="item.name == 'Top'">{{item.name}}</div>
                            <div class="field-style-box cursor-pointer" *ngIf="item.name == 'Left'">{{item.name}}</div>
                            <div class="field-style-box cursor-pointer" *ngIf="item.name == 'Bottom'">{{item.name}}</div> -->
                        </div>
                    </ng-template>
                </ng-select>
                <!-- <div class="field-style-container">
                    <div class="field-style-box cursor-pointer" *ngFor="let fields of fields_style; let i = index" [ngClass]="{'active': fields.id == selectedFieldStyle}" (click)="selectFieldStyle(i,fields)">
                        <div class="field-style {{fields['class']}}"><span class="placeholder-field-label">{{fields['name']}}</span></div>
                    </div> -->
            </div>

            <hr class="mt-2 mb-2" *ngIf="isTagView == 0">

            <div class="form-group d-flex justify-content-between align-items-center px-1" *ngIf="data['selected_field'] == 1 && isTagView == 0">
                <label class="col-form-label pt-0 pb-0" for="name">Allow multiple rows</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" class="custom-control-input" id="customSwitch4" [checked]="isMultiLinesChecked"
                        (change)="allowMultiLines($event)" />
                    <label class="custom-control-label" for="customSwitch4"></label>
                </div>
            </div>

            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Conditional</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" class="custom-control-input" id="Conditional" (change)="activeConditional($event)" />
                    <label class="custom-control-label" for="Conditional"></label>
                </div>
            </div>

            <div class="form-group px-1 mt-1" *ngIf="isConditionalChecked">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck6" />
                    <label class="custom-control-label" for="customCheck6">Reverse</label>
                </div>
            </div>

            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1 mb-2">
                <label class="col-form-label pt-0 pb-0" for="name">Calculation</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" class="custom-control-input" id="Calculation" (change)="activeCalculation($event)" />
                    <label class="custom-control-label" for="Calculation"></label>
                </div>
            </div>

            <div class="form-group px-1" *ngIf="isCalculationChecked">
                <input type="text" class="form-control" placeholder="Example: [f1] + [f2]" />
            </div>

            <div class="form-group px-1" *ngIf="isCalculationChecked">
                <input type="number" class="form-control" placeholder="Add decimals" />
            </div>        

            <hr class="mt-2 mb-1">

            <div class="px-1 mt-2 mb-2">
                <div class="cursor-pointer mt-50 text-danger d-flex align-items-center">
                    <i data-feather="trash" [size]="16" class="mr-50"></i>
                    <span class="align-text-bottom line-height-1" (click)="deleteSettings()">Delete</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / Text || Datepikcer Field Settings View -->

    <!-- Checkbox Settings View -->
    <div id="checkboxFieldBox" *ngIf="data['selected_field'] == 2">
        <div class="fieldsSettings-scroll-content-container" [perfectScrollbar]>
            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Field type</label>
                <ng-select bindLabel="name" bindValue="id" [(ngModel)]="fieldSelectedType" [items]="CheckBoxfieldtype"
                    bindLabel="name" [readonly]="readonly" [searchable]="searchable" placeholder="Select field type" [clearable]="false" (change)="onSelectTypeChange($event)">
                </ng-select>
            </div>
            <div class="form-group px-1" *ngIf="showLabel == 0">
                <label class="col-form-label pt-0" for="basicInput">Label</label>
                <input type="text" class="form-control" id="basicInput" [placeholder]="labelName" [value]="[labelName]"
                    (keyup)="renameLabel($event)" />
            </div>
            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Pre-checked?</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" [checked]="isPreChecked" class="custom-control-input" id="checkBoxPreChecked"
                        (change)="onPreCheckedChange($event)" />
                    <label class="custom-control-label" for="checkBoxPreChecked"></label>
                </div>
            </div>

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Required</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" [checked]="isRequired" class="custom-control-input" id="checkFieldRequired"
                        (change)="onRequiredChange($event)" />
                    <label class="custom-control-label" for="checkFieldRequired"></label>
                </div>
            </div>

            <hr class="mt-2 mb-1">

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Who should check this box?</label>
                <ng-select [items]="selectRole" bindLabel="name" bindValue="id" [(ngModel)]="selectedRole" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select role" [clearable]="false" (change)="onChangeRoleType($event)"> </ng-select>
            </div>

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Font size</label>
                <ng-select [items]="selectFontSize" bindLabel="name" [(ngModel)]="selectedFontSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select font size" (change)="changeFontSize($event)" [clearable]="false">
                </ng-select>
            </div>
    
            <div class="form-group px-1" *ngIf="data['isFormBuilder'] == 1">
                <label class="col-form-label pt-0" for="name">Column size</label>
                <ng-select [items]="selectColumnSize" bindLabel="name" bindValue="id" [(ngModel)]="selectedColumnSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select column size" (change)="changeColumnSize($event)" [clearable]="false">
                </ng-select>
            </div>
            <!-- <hr class="mt-2 mb-1">

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Field Style</label>
                <div class="field-style-container">
                    <div class="field-style-box cursor-pointer" *ngFor="let fields of fields_style; let i = index" [ngClass]="{'active': fields.id == selectedFieldStyle}" (click)="selectFieldStyle(i,fields)">
                        <div class="field-style {{fields['class']}}"><span class="placeholder-field-label">{{fields['name']}}</span></div>
                    </div>
                </div>
            </div> -->

            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Conditional</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" class="custom-control-input" id="Conditional" (change)="activeConditional($event)" />
                    <label class="custom-control-label" for="Conditional"></label>
                </div>
            </div>

            <div class="form-group px-1 mt-1" *ngIf="isConditionalChecked">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck6" />
                    <label class="custom-control-label" for="customCheck6">Reverse</label>
                </div>
            </div>

            <hr class="mt-2 mb-1">

            <div class="px-1 mt-2 mb-2">
                <div class="cursor-pointer mt-50 text-danger d-flex align-items-center">
                    <i data-feather="trash" [size]="16" class="mr-50"></i>
                    <span class="align-text-bottom line-height-1" (click)="deleteSettings()">Delete</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / Checkbox Settings View -->

    <!-- droplist Settings View -->
    <div id="droplistFieldBox" *ngIf="data['selected_field'] == 3">
        <div class="fieldsSettings-scroll-content-container" [perfectScrollbar]>
            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Field type</label>
                <ng-select bindLabel="name" bindValue="id" [(ngModel)]="fieldSelectedType" [items]="droplistfieldtype"
                    bindLabel="name" [readonly]="readonly" [searchable]="searchable" placeholder="Select field type" [clearable]="false" (change)="onDroplistTypeChange($event,'droplist')">
                </ng-select>
            </div>
            <div class="px-1 mb-2" *ngIf="showLabel != 0">
                <div class="create-option-container">
                    <div class="input-group input-group-merge mb-1" *ngFor="let option of preAddMultiChoiceOption; let i = index">
                        <input type="text" class="form-control" value="{{option.key}}" disabled />
                        <div class="input-group-append">
                            <span *ngIf="!option.hide || option.hide == 0" class="input-group-text pr-50 pl-50" (click)="hideOption(1,i,option,'droplist')">
                                <i class="cursor-pointer" data-feather="eye"></i>
                            </span>
                            <span *ngIf="option.hide && option.hide == 1" class="input-group-text pr-50 pl-50" (click)="hideOption(0,i,option,'droplist')">
                                <i class="cursor-pointer" data-feather="eye-off"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-1 mb-2" *ngIf="showLabel == 0">
                <div class="form-group">
                    <label class="col-form-label pt-0" for="basicInput">Label</label>
                    <input type="text" class="form-control" id="basicInput" [placeholder]="labelName" [value]="[labelName]"
                        (keyup)="renameLabel($event)" />
                </div>

                <div class="create-option-container">
                    <div class="input-group input-group-merge mb-1" *ngFor="let option of multiChoiceOption; let i = index">
                        <input type="text" class="form-control" value="{{option.key}}" (keyup)="renameOption($event,option,i,'droplist')" />
                        <div class="input-group-append">
                            <span *ngIf="!option.hide || option.hide == 0" class="input-group-text pr-25 pl-25" (click)="hideOption(1,i,option,'droplist','default')">
                                <i class="cursor-pointer" data-feather="eye"></i>
                            </span>
                            <span *ngIf="option.hide && option.hide == 1" class="input-group-text pr-25 pl-25" (click)="hideOption(0,i,option,'droplist','default')">
                                <i class="cursor-pointer" data-feather="eye-off"></i>
                            </span>
                            <span class="input-group-text pr-50 pl-50" (click)="deleteOption($event,option,i,'droplist')">
                                <i class="cursor-pointer" data-feather="trash"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <button (click)="addOption('droplist')" type="button" class="btn p-0 btn-sm d-flex text-success btn-transparent">Add
                    Option</button>
            </div>

            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Required</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" [checked]="isRequired" class="custom-control-input" id="customSwitch3"
                        (change)="onRequiredChange($event)" />
                    <label class="custom-control-label" for="customSwitch3"></label>
                </div>
            </div>

            <!-- <hr class="mt-2 mb-1"> -->

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Who select this option?</label>
                <ng-select [items]="selectRole" bindLabel="name" bindValue="id" [(ngModel)]="selectedRole" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select role" [clearable]="false" (change)="onChangeRoleType($event)"> </ng-select>
            </div>

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Font size</label>
                <ng-select [items]="selectFontSize" bindLabel="name" [(ngModel)]="selectedFontSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select font size" [clearable]="false" (change)="changeFontSize($event)"> </ng-select>
            </div>

            <div class="form-group px-1" *ngIf="data['isFormBuilder'] == 1">
                <label class="col-form-label pt-0" for="name">Column size</label>
                <ng-select [items]="selectColumnSize" bindLabel="name" bindValue="id" [(ngModel)]="selectedColumnSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select column size" (change)="changeColumnSize($event)" [clearable]="false">
                </ng-select>
            </div>
            <!-- <hr class="mt-2 mb-1">

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Field Style</label>
                <div class="field-style-container">
                    <div class="field-style-box cursor-pointer" *ngFor="let fields of fields_style; let i = index" [ngClass]="{'active': fields.id == selectedFieldStyle}" (click)="selectFieldStyle(i,fields)">
                        <div class="field-style {{fields['class']}}"><span class="placeholder-field-label">{{fields['name']}}</span></div>
                    </div>
                </div>
            </div> -->

            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Conditional</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" class="custom-control-input" id="Conditional" (change)="activeConditional($event)" />
                    <label class="custom-control-label" for="Conditional"></label>
                </div>
            </div>

            <div class="form-group px-1 mt-1" *ngIf="isConditionalChecked">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck6" />
                    <label class="custom-control-label" for="customCheck6">Reverse</label>
                </div>
            </div>

            <hr class="mt-2 mb-1">

            <div class="px-1 mt-2 mb-2">
                <div class="cursor-pointer mt-50 text-danger d-flex align-items-center">
                    <i data-feather="trash" [size]="16" class="mr-50"></i>
                    <span class="align-text-bottom line-height-1" (click)="deleteSettings()">Delete</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / droplist Settings View -->


    <!-- signature Settings View -->
    <div id="signatureboxFieldBox" *ngIf="data['selected_field'] == 5">
        <div class="fieldsSettings-scroll-content-container" [perfectScrollbar]>
            <hr class="mt-2 mb-1">

            <div class="px-1 mt-2 mb-2">
                <div class="cursor-pointer mt-50 text-danger d-flex align-items-center">
                    <i data-feather="trash" [size]="16" class="mr-50"></i>
                    <span class="align-text-bottom line-height-1" (click)="deleteSettings()">Delete</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / signature Settings View -->

    <!-- Multi Choice Settings View -->
    <div id="multiChoiceFieldBox" *ngIf="data['selected_field'] == 6">
        <div class="fieldsSettings-scroll-content-container" [perfectScrollbar]>
            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Field type</label>
                <ng-select bindLabel="name" bindValue="id" [(ngModel)]="fieldSelectedType" [items]="droplistfieldtype"
                    bindLabel="name" [readonly]="readonly" [searchable]="searchable" placeholder="Select field type" [clearable]="false" (change)="onDroplistTypeChange($event,'multichoice')">
                </ng-select>
            </div>
            <div class="px-1 mb-2" *ngIf="showLabel != 0">
                <div class="create-option-container">
                    <div class="input-group input-group-merge mb-1" *ngFor="let option of preAddMultiChoiceOption; let i = index">
                        <input type="text" class="form-control" value="{{option.key}}" disabled />
                        <div class="input-group-append">
                            <span *ngIf="!option.hide || option.hide == 0" class="input-group-text pr-50 pl-50" (click)="hideOption(1,i,option,'multichoice')">
                                <i class="cursor-pointer" data-feather="eye"></i>
                            </span>
                            <span *ngIf="option.hide && option.hide == 1" class="input-group-text pr-50 pl-50" (click)="hideOption(0,i,option,'multichoice')">
                                <i class="cursor-pointer" data-feather="eye-off"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-1 mb-2" *ngIf="showLabel == 0">
                <div class="form-group">
                    <label class="col-form-label pt-0" for="basicInput">Label</label>
                    <input type="text" class="form-control" id="basicInput" [placeholder]="labelName" [value]="[labelName]"
                        (keyup)="renameLabel($event)" />
                </div>

                <div class="create-option-container">
                    <div class="input-group input-group-merge mb-1" *ngFor="let option of multiChoiceOption; let i = index">
                        <input type="text" class="form-control" value="{{option.key}}" (keyup)="renameOption($event,option,i,'multichoice')" />
                        <div class="input-group-append">
                            <span *ngIf="!option.hide || option.hide == 0" class="input-group-text pr-50 pl-25" (click)="hideOption(1,i,option,'multichoice','default')">
                                <i class="cursor-pointer" data-feather="eye"></i>
                            </span>
                            <span *ngIf="option.hide && option.hide == 1" class="input-group-text pr-50 pl-25" (click)="hideOption(0,i,option,'multichoice','default')">
                                <i class="cursor-pointer" data-feather="eye-off"></i>
                            </span>
                            <span class="input-group-text pr-50 pl-50" (click)="deleteOption($event,option,i,'multichoice')">
                                <i class="cursor-pointer" data-feather="trash"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <button (click)="addOption('multichoice')" type="button" class="btn p-0 btn-sm d-flex text-success btn-transparent">Add
                    Option</button>
            </div>

            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Required</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" [checked]="isRequired" class="custom-control-input" id="customSwitch3"
                        (change)="onRequiredChange($event)" />
                    <label class="custom-control-label" for="customSwitch3"></label>
                </div>
            </div>

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Allow multi response</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" class="custom-control-input" id="allowMultiResponse" (change)="onMultiChoiceChange($event)"
                        [checked]="isMultiChoiceChecked" />
                    <label class="custom-control-label" for="allowMultiResponse"></label>
                </div>
            </div>

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Hide label</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input [checked]="isLabelHide" type="checkbox" class="custom-control-input" id="hideLabel" (change)="hideLabel($event)" />
                    <label class="custom-control-label" for="hideLabel"></label>
                </div>
            </div>

            <hr class="mt-2 mb-1">

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Who select this option?</label>
                <ng-select [items]="selectRole" bindLabel="name" bindValue="id" [(ngModel)]="selectedRole" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select role" [clearable]="false" (change)="onChangeRoleType($event)"> </ng-select>
            </div>

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Font size</label>
                <ng-select [items]="selectFontSize" bindLabel="name" [(ngModel)]="selectedFontSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select font size" (change)="changeFontSize($event)" [clearable]="false"></ng-select>
            </div>
            <div class="form-group px-1" *ngIf="data['isFormBuilder'] == 1">
                <label class="col-form-label pt-0" for="name">Column size</label>
                <ng-select [items]="selectColumnSize" bindLabel="name" bindValue="id" [(ngModel)]="selectedColumnSize" [readonly]="readonly"
                    [searchable]="searchable" placeholder="Select column size" (change)="changeColumnSize($event)" [clearable]="false">
                </ng-select>
            </div>
            <!-- <hr class="mt-2 mb-1">

            <div class="form-group px-1">
                <label class="col-form-label pt-0" for="name">Field Style</label>
                <div class="field-style-container">
                    <div class="field-style-box cursor-pointer" *ngFor="let fields of fields_style; let i = index" [ngClass]="{'active': fields.id == selectedFieldStyle}" (click)="selectFieldStyle(i,fields)">
                        <div class="field-style {{fields['class']}}"><span class="placeholder-field-label">{{fields['name']}}</span></div>
                    </div>
                </div>
            </div> -->

            <hr class="mt-2 mb-2">

            <div class="form-group d-flex justify-content-between align-items-center px-1">
                <label class="col-form-label pt-0 pb-0" for="name">Conditional</label>
                <div class="custom-control custom-control-primary custom-switch ">
                    <input type="checkbox" class="custom-control-input" id="Conditional" (change)="activeConditional($event)" />
                    <label class="custom-control-label" for="Conditional"></label>
                </div>
            </div>

            <div class="form-group px-1 mt-1" *ngIf="isConditionalChecked">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck6" />
                    <label class="custom-control-label" for="customCheck6">Reverse</label>
                </div>
            </div>

            <hr class="mt-2 mb-1">

            <div class="px-1 mt-2 mb-2">
                <div class="cursor-pointer mt-50 text-danger d-flex align-items-center">
                    <i data-feather="trash" [size]="16" class="mr-50"></i>
                    <span class="align-text-bottom line-height-1" (click)="deleteSettings()">Delete</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / Multi Choice Settings View -->
        
    <!-- Document price settings -->
        <div id="multiChoiceFieldBox" *ngIf="data['selected_field'] == 'document_price'">
            <div class="price-setting-scroll" [perfectScrollbar]>
                <label class="px-1 mb-1">Here you can auto-calculate and track the sum of any/all product tables within a document or set a custom "total" amount. This sum will be displayed in: Documents, API and on Statistics</label>

                <div class="form-group px-1">
                    <label class="col-form-label pt-0">Currency</label>
                    <ng-select [items]="currency" bindLabel="name" bindValue="id" [(ngModel)]="selectedCurrency" [clearable]="false">
                    </ng-select>
                </div>
                
                <div class="px-1 mt-2">
                    <label class="col-form-label pt-0 pb-0"> Product Table </label>
                    <label class="mb-0 mt-50">Choose the tables to include in document price</label>
                </div>

                <hr class="mt-2 mb-2">

                <div class="form-group px-1">
                    <label class="col-form-label pt-0" for="basicInput">Custom</label>
                    <div class="input-group input-group-merge"> 
                        <input
                            type="number"
                            class="form-control"
                            placeholder="-"
                            aria-label="-"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text">USD</span>
                        </div>
                    </div>
                </div>

                <div class="px-1 mt-2">
                    <label class="col-form-label pt-0 pb-0"> Grand Total </label>
                    <h4 class="font-weight-bolder mb-0 mt-25 text-success">0,00 <span>USD</span></h4>
                </div>
            </div>
        </div>
    <!-- / Document price settings -->
</div>