import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-in-meeting-signing-addon',
  templateUrl: './in-meeting-signing-addon.component.html',
  styleUrls: ['./in-meeting-signing-addon.component.scss']
})
export class InMeetingSigningAddonComponent implements OnInit {

    // modal Basic
 modalOpen(inMeetingSignSettingModal) {
  this.modalService.open(inMeetingSignSettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
  pagination: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};



constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
