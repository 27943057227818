import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cc-cost',
  templateUrl: './cc-cost.component.html',
  styleUrls: ['./cc-cost.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CCCostComponent implements OnInit {

  constructor() { }

  hiddenStartupCost: boolean = false;
  hiddenStartupCostBtn: boolean = true;
  // hiddenCustomColumnFields: boolean = false;


  addStartupCost() {
    this.hiddenStartupCost = true;
    this.hiddenStartupCostBtn = false
  }
  hideStartupCost() {
    this.hiddenStartupCost = false;
    this.hiddenStartupCostBtn = true;
  }
  public readonly = false;
  public searchable = false;
  chooseCurrency = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  chooseDaysMounth = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  ngOnInit(): void {
  }

}
