<div class="slideout-content">
    <!-- Modal to add attachments starts-->
    <div class="modalsd modal-slide-in sdfade add-organization-sidebar" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <div class="modal-header mb-1 d-flex justify-content-between align-items-center px-2 py-1">
                    <h5 class="modal-title" id="exampleModalLabel">Add New Organization</h5>
                    <div class="d-flex justify-content-between align-items-center text-dark cursor-pointer" (click)="toggleSidebar('add-organization-sidebar')">
                        <i data-feather="x" [size]="16" class="ml-1" data-dismiss="modal" aria-label="Close"></i>
                    </div>
                </div>
                <div class="modal-body flex-grow-1 p-0 ">
                    <div class="add-new-Org-Scroll px-2" [perfectScrollbar]>
                        <form [formGroup]="organizationForm" (ngSubmit)="saveOrganization()">
                            <div class="form-group">
                                <label class="form-label" for="name">Name</label>
                                <input type="text" class="form-control" name="" formControlName="name" aria-describedby="name" [ngClass]="{ 'is-invalid error': submitted && form.name.errors }" />
                                <div *ngIf="submitted && form.name.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.name.errors }">
                                    <div *ngIf="form.name.errors.required">Name is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="reg-no">Reg No</label>
                                <input type="text" class="form-control" formControlName="register_no" aria-describedby="reg-no" [ngClass]="{ 'is-invalid error': submitted && form.register_no.errors }" />
                                <div *ngIf="submitted && form.register_no.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.register_no.errors }">
                                    <div *ngIf="form.register_no.errors.required">Reg No is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="mobile-number">Mobile No</label>
                                <input type="text" class="form-control" formControlName="phone" aria-describedby="mobile-number" />
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="address">Address</label>
                                <input type="text" class="form-control" formControlName="address" aria-describedby="address" />
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="zip-code">Zip Code</label>
                                <input type="text" class="form-control" formControlName="zipcode" aria-describedby="zip-code" />
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="city">City</label>
                                <input type="text" class="form-control" formControlName="city" aria-describedby="city" />
                            </div>
                            <div class="d-flex mt-2">
                                <button type="submit" [disabled]="loading" class="btn btn-primary mr-1" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Save</button>
                                <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('add-organization-sidebar')" rippleEffect>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add attachments Ends-->
</div>