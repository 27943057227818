<div class="card addonBoxContainer" (click)="modalOpen(inMeetingSignSettingModal)">
    <div class="card-body">
        <h5>In-Meeting Signing</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            In a meeting? No worries, simply use this add-on to quickly sign in-person.
        </p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'users'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>


<!-- Modal -->
<ng-template #inMeetingSignSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">In-Meeting Signing</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                Sign a document just as if it was on paper. Hand over your phone, tablet, or computer
                                and let your client sign on the spot. Simple Sign then automatically stores your GPS
                                coordinates. These coordinates are then added to the full audit trail in the document's
                                verification certificate, which provides full proof of how and when you and your
                                clients interacted with the document.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets/images/addons-images/addon-thumbnails/In-meetingSigning/InMeetingSigning1.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets/images/addons-images/addon-thumbnails/In-meetingSigning/InMeetingSigning2.png"
                                    alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->