<!-- account setting page -->
<section id="page-account-settings">
    <div class="row d-flex">
        <div class="col-md-3 mb-2 mb-md-0">
            <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                <li class="nav-item" ngbNavItem>
                    <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-Profile" data-toggle="pill"
                        aria-expanded="true">
                        <i data-feather="user" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">Profile</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <div class="card-body">
                                <!-- header media -->
                                <div class="media">
                                    <a href="javascript:void(0);" class="mr-25">
                                        <img src="../../../../assets/images/portrait/small/avatar-s-11.jpg" class="rounded mr-50"
                                            alt="profile image" height="76" width="80" />
                                    </a>
                                    <!-- upload and reset button -->
                                    <div class="media-body mt-75 ml-1">
                                        <button for="account-upload" class="btn btn-sm btn-primary mb-75 mr-75"
                                            rippleEffect>
                                            Upload
                                        </button>
                                        <input type="file" #file id="account-upload" hidden accept="image/*" />
                                        <button class="btn btn-sm btn-outline-secondary mb-75" rippleEffect>Reset</button>
                                        <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
                                    </div>
                                    <!--/ upload and reset button -->
                                </div>
                                <!--/ header media -->

                                <!-- form -->
                                <form class="validate-form mt-2">
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="account-Name">Name</label>
                                                <input type="text" class="form-control" id="account-Name" name="Name"
                                                    placeholder="Name" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="account-e-mail">E-mail</label>
                                                <input type="email" class="form-control" id="account-e-mail" name="email"
                                                    placeholder="Email" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="account-company">Organization</label>
                                                <input type="text" class="form-control" id="account-company"
                                                    placeholder="Organization" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="regno">Reg.no</label>
                                                <input type="text" class="form-control" id="regno" placeholder="Reg.no" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="phone">Mobile.no</label>
                                                <input type="text" class="form-control" id="phone" placeholder="Mobile.no" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="address">Address</label>
                                                <input type="text" class="form-control" id="address" placeholder="Address" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="zipcode">Zip code</label>
                                                <input type="text" class="form-control" id="zipcode" placeholder="Zip code" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="city">City</label>
                                                <input type="text" class="form-control" id="city" placeholder="City" />
                                            </div>
                                        </div>

                                        <div class="dropdown-divider my-1 col-12"></div>

                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="Billing">Billing country</label>
                                                <!-- Basic Multi select -->
                                                <ng-select #BillingCountry [items]="chooseBillingCountry" bindLabel="name"
                                                    [readonly]="readonly" [searchable]="false" placeholder="Please select">
                                                    <ng-template ng-header-tmp>
                                                        <input class="form-control w-100" type="text" (input)="BillingCountry.filter($event.target.value)" />
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="VATnumber">VAT number</label>
                                                <input type="text" class="form-control" id="VATnumber" placeholder="VAT number" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="Billing">Select your industry</label>
                                                <!-- Basic Multi select -->
                                                <ng-select #Industry [items]="chooseIndustry" bindLabel="name"
                                                    [readonly]="readonly" [searchable]="false" placeholder="Please select">
                                                    <ng-template ng-header-tmp>
                                                        <input class="form-control w-100" type="text" (input)="Industry.filter($event.target.value)" />
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button type="submit" class="btn btn-primary mt-2 mr-1" rippleEffect>Save
                                                changes</button>
                                            <button type="reset" class="btn btn-outline-secondary mt-2" rippleEffect>Cancel</button>
                                        </div>
                                    </div>

                                </form>

                                <!--/ form -->

                            </div>
                        </div>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem>
                    <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-password" data-toggle="pill" href="#account-vertical-password"
                        aria-expanded="false">
                        <i data-feather="lock" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">Change Password</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <div class="card-body">
                                <!-- form -->
                                <form class="validate-form">
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="account-old-password">Old Password</label>
                                                <div class="input-group form-password-toggle input-group-merge">
                                                    <input [type]="passwordTextTypeOld ? 'text' : 'password'" class="form-control"
                                                        id="account-old-password" name="password" placeholder="Old Password" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text cursor-pointer"><i class="feather font-small-4"
                                                                [ngClass]="{'icon-eye-off': passwordTextTypeOld,'icon-eye': !passwordTextTypeOld}"
                                                                (click)="togglePasswordTextTypeOld()"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="account-new-password">New Password</label>
                                                <div class="input-group form-password-toggle input-group-merge">
                                                    <input [type]="passwordTextTypeNew ? 'text' : 'password'" id="account-new-password"
                                                        name="new-password" class="form-control" placeholder="New Password" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text cursor-pointer"><i class="feather font-small-4"
                                                                [ngClass]="{
                                        'icon-eye-off': passwordTextTypeNew,
                                        'icon-eye': !passwordTextTypeNew
                                      }"
                                                                (click)="togglePasswordTextTypeNew()"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="account-retype-new-password">Retype New Password</label>
                                                <div class="input-group form-password-toggle input-group-merge">
                                                    <input [type]="passwordTextTypeRetype ? 'text' : 'password'" class="form-control"
                                                        id="account-retype-new-password" name="confirm-new-password"
                                                        placeholder="New Password" />
                                                    <div class="input-group-append">
                                                        <span class="input-group-text cursor-pointer"><i class="feather font-small-4"
                                                                [ngClass]="{
                                        'icon-eye-off': passwordTextTypeRetype,
                                        'icon-eye': !passwordTextTypeRetype
                                      }"
                                                                (click)="togglePasswordTextTypeRetype()"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button type="submit" class="btn btn-primary mr-1 mt-1" rippleEffect>Save
                                                changes</button>
                                            <button type="reset" class="btn btn-outline-secondary mt-1" rippleEffect>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                                <!--/ form -->
                                <i class="feather icon-info profileInfoIcon" placement="top" container="body" placement="auto"
                                    ngbTooltip="Update your password and do it frequently to maintain a high level of security for your account."></i>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem>
                    <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-info" data-toggle="pill" href="#account-vertical-info"
                        aria-expanded="false">
                        <i data-feather="edit-3" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">My signature</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <div class="card-body">
                                <ul ngbNav #navWithIcons="ngbNav" class="nav-tabs border-bottom">
                                    <li ngbNavItem>
                                        <a ngbNavLink><span [data-feather]="'edit-3'"></span> My signature</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="text-center font-medium-1 mb-2">Here is your signature</h2>
                                            <div class="signatureMainContainer p-0">
                                                <div class="signatureInnerBox m-2 border-bottom">

                                                </div>
                                                <button type="submit" class="btn float-right btn-danger mt-2"
                                                    rippleEffect>Remove
                                                </button>
                                            </div>

                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink><span [data-feather]="'edit-3'"></span> Draw it</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="text-center font-medium-1 mb-2">Draw your signature</h2>
                                            <div class="signatureMainContainer p-0">
                                                <div class="signatureInnerBox m-2 border-bottom">

                                                </div>
                                                <button type="submit" class="btn float-right btn-primary mt-2"
                                                    rippleEffect>Apply
                                                </button>
                                                <button type="submit" class="btn float-right btn-danger mt-2 mr-50"
                                                    rippleEffect>Clear
                                                </button>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink><span [data-feather]="'align-left'"></span> Type it</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="text-center font-medium-1 mb-2">Type your signature</h2>
                                            <div class="signatureMainContainer p-0">
                                                <div class="signatureInnerBox m-2">
                                                    <div class="d-flex content-justify-center align-items-center">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control w-100 font-large-2 border-bottom typeSignatureInput"
                                                                placeholder="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" class="btn float-right btn-primary mt-2"
                                                    rippleEffect>Apply
                                                </button>
                                                <button type="submit" class="btn float-right btn-success mt-2 mr-50"
                                                    rippleEffect>Next font
                                                </button>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink><span [data-feather]="'upload'"></span> Upload</a>
                                        <ng-template ngbNavContent>
                                            <h2 class="text-center font-medium-1 mb-2">Upload your signature</h2>
                                            <div class="signatureMainContainer p-0">
                                                <div class="signatureInnerBox m-2">
                                                        <div
                                                        ng2FileDrop
                                                        [ngClass]="{ 'file-drop-over': hasBaseDropZoneOver }"
                                                        (fileOver)="fileOverBase($event)"
                                                        [uploader]="uploader"
                                                        class="py-2 mb-3 text-center font-medium-3 text-uppercase file-drop-zone"
                                                      >
                                                        Drop Files Here To Upload
                                                      </div>
                                                </div>
                                                <button type="submit" class="btn float-right btn-primary mt-2"
                                                    rippleEffect>Apply
                                                </button>
                                            </div>
                                        </ng-template>
                                    </li>
                                    <li ngbNavItem>
                                        <a ngbNavLink><span [data-feather]="'smartphone'"></span> Use smartphone</a>
                                        <ng-template ngbNavContent>
                                            <div class="useSmartphoneContentBody">
                                                <h2 class="text-center font-medium-1 mb-2">TAKE A PHOTO OF YOUR
                                                    SIGNATURE</h2>
                                                <p class="font-small-3 mb-1">Please follow the instruction below:</p>
                                                <p class="font-small-3 mb-50">1. TAKE A PHOTO OF YOUR SIGNATURE</p>
                                                <p class="font-small-3 mb-50">2. Email the signature to:
                                                    import@simplesign.io with the subject of: 27245731</p>
                                                <p class="font-small-3 mb-50">2.1 For initials type subject: 27245438</p>
                                                <p class="font-small-3 mb-50">3. Click continue</p>
                                                <button type="submit" class="btn btn-primary mt-1" rippleEffect>Continue</button>
                                            </div>

                                        </ng-template>
                                    </li>

                                </ul>
                                <div [ngbNavOutlet]="navWithIcons" class="mt-2"></div>

                            </div>
                        </div>
                    </ng-template>
                </li>


                <li class="nav-item" ngbNavItem>
                    <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-notifications" data-toggle="pill" href="#account-vertical-notifications"
                        aria-expanded="false">
                        <i data-feather="bell" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">Notifications</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <h6 class="section-label mx-1 mb-2">Activity</h6>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" checked class="custom-control-input" id="accountSwitch1" />
                                                    <label class="custom-control-label" for="accountSwitch1">
                                                        When a document is signed
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="accountSwitch2" />
                                                    <label class="custom-control-label" for="accountSwitch2">
                                                        When a recipient reviews a document
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" checked class="custom-control-input" id="accountSwitch3" />
                                                    <label class="custom-control-label" for="accountSwitch3">When a
                                                        document is
                                                        rejected</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="accountSwitch4" />
                                                    <label class="custom-control-label" for="accountSwitch4">When a
                                                        document
                                                        fails to deliver to a recipient</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" checked class="custom-control-input" id="accountSwitch5" />
                                                    <label class="custom-control-label" for="accountSwitch5">When a
                                                        document is
                                                        about to be due (24 hours before)</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="accountSwitch6" />
                                                    <label class="custom-control-label" for="accountSwitch6">When a
                                                        document
                                                        has passed its due date</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" checked class="custom-control-input" id="accountSwitch7" />
                                                    <label class="custom-control-label" for="accountSwitch7">When a
                                                        signature
                                                        credits are low</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6 mb-2">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" id="accountSwitch8" />
                                                    <label class="custom-control-label" for="accountSwitch8">Get all
                                                        notifications from all your users</label>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" checked class="custom-control-input" id="accountSwitch9" />
                                                    <label class="custom-control-label" for="accountSwitch9">Get
                                                        web-notifications for document activities</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- <div class="col-12">
                                        <button type="submit" class="btn btn-primary mt-2 mr-1" rippleEffect>Save
                                            changes</button>
                                        <button type="reset" class="btn btn-outline-secondary mt-2" rippleEffect>Cancel</button>
                                    </div> -->
                                </div>
                            </div>
                            <i class="feather icon-info profileInfoIcon" placement="top" container="body" placement="auto"
                                ngbTooltip="Set which notifications you want to be sent to your email. Activate the notifications you may find important. Note that when a contract is signed, we will always send you a signed copy of that contract."></i>
                        </div>
                    </ng-template>
                </li>
                <li class="nav-item" ngbNavItem>
                    <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-social" data-toggle="pill" href="#account-vertical-social"
                        aria-expanded="false">
                        <i data-feather="clock" class="font-medium-3 mr-1"></i>
                        <span class="font-weight-bold">Time Zone</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card">
                            <div class="card-body">
                                <!-- form -->
                                <form class="validate-form">
                                    <div class="col-12 p-0">
                                        <div class="row">
                                            <div class="col-12 p-0 col-sm-6 leftBoxborderClass">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="Billing">Select timezone</label>
                                                        <!-- Basic Multi select -->
                                                        <ng-select #Selecttimezone [items]="chooseSelecttimezone"
                                                            bindLabel="name" [readonly]="readonly" [searchable]="false"
                                                            container="body" placeholder="Please select">
                                                            <ng-template ng-header-tmp>
                                                                <input class="form-control w-100" type="text" (input)="Selecttimezone.filter($event.target.value)" />
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 mb-1">
                                                    <label class="mb-0" for="Billing">Select month format</label>
                                                    <div class="mt-25">
                                                        <div class="custom-control custom-radio d-inline mr-1">
                                                            <input type="radio" id="customRadio1" name="customRadiofm"
                                                                class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="customRadio1">2023
                                                                02 09</label>
                                                        </div>
                                                        <div class="custom-control custom-radio d-inline">
                                                            <input type="radio" id="customRadio2" name="customRadiofm"
                                                                class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="customRadio2">2023
                                                                Feb 09</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 mb-1">
                                                    <label class="mb-0" for="Billing">Select date format</label>
                                                    <div class="mt-25">
                                                        <div class="custom-control custom-radio d-inline mr-1">
                                                            <input type="radio" id="customRadio3" name="customRadiodf"
                                                                class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="customRadio3">MM-DD-YYYY</label>
                                                        </div>
                                                        <div class="custom-control custom-radio d-inline mr-1">
                                                            <input type="radio" id="customRadio4" name="customRadiodf"
                                                                class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="customRadio4">DD-MM-YYYY</label>
                                                        </div>
                                                        <div class="custom-control custom-radio d-inline">
                                                            <input type="radio" id="customRadio5" name="customRadiodf"
                                                                class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="customRadio5">YYYY-MM-DD</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 mb-1">
                                                    <label class="mb-0" for="Billing">Select hours format</label>
                                                    <div class="mt-25">
                                                        <div class="custom-control custom-radio d-inline mr-1">
                                                            <input type="radio" id="customRadio6" name="customRadiohr"
                                                                class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="customRadio6">24
                                                                hours</label>
                                                        </div>
                                                        <div class="custom-control custom-radio d-inline">
                                                            <input type="radio" id="customRadio7" name="customRadiohr"
                                                                class="custom-control-input" checked />
                                                            <label class="custom-control-label" for="customRadio7">12
                                                                hours</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <p class="font-small-3">Here you can modify and edit the settings
                                                    related to your time zone.
                                                    It will reflect on all your contracts and settings for your entire
                                                    organization.</p>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                                <!--/ form -->
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
    </div>
</section>
<!--/ account setting page -->