<div class="card addonBoxContainer" (click)="modalOpen(documentkeySettingModal)">
    <div class="card-body">
        <h5>Document Key</h5>
        <p class="card-text font-small-3 addonboxDiscription">Make your sensitive documents secure by adding a password
            to the document.</p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'lock'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #documentkeySettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Document Key</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                Add an extra level of security to your sensitive documents by making them password
                                protected. This add-on allows you to require a password (or another form of
                                verification) before allowing the recipient to open the document. The verification
                                options include:
                                <br><br>
                                - Password
                                <br>
                                - PIN via SMS
                                <br>
                                - BankID
                                <br><br>
                                If using a password as verification you can set the password to be automatically sent
                                to the recipient via SMS when the document is sent. Thus, the recipient is
                                automatically informed of the password without you having to contact them separately.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\DocumentKey\DocumentKey1.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\DocumentKey\DocumentKey2.png"
                                    alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->