<div class="content-wrapper userandRolesWrapper container-xxl p-0">
    <div class="content-body">
        <!-- users start -->
        <section class="app-users">
            <div class="card">
                <div class="card-body p-0">
                    <ul ngbNav #nav="ngbNav" class="nav nav-pills p-2 mb-0 border-bottom">
                        <li ngbNavItem>
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="user" class="mr-sm-50"></i><span
                                    class="d-none d-sm-block">Users</span></a>
                            <ng-template ngbNavContent>
                                <!-- Users Tab starts -->
                                <div class="tab-pane active" id="Users" aria-labelledby="Users-tab" role="tabpanel">
                                    <!-- users listing component -->
                                    <app-userslisting></app-userslisting>
                                    <!-- users listing component -->
                                </div>
                                <!-- users Tab ends -->
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink class="d-flex align-items-center">
                                <i data-feather="layers" class="mr-sm-50"></i><span class="d-none d-sm-block">Roles</span></a>
                            <ng-template ngbNavContent>
                                <!-- Roles Tab starts -->
                                <div class="tab-pane" id="Roles" aria-labelledby="Roles-tab" role="tabpanel">
                                    <!-- roles component -->
                                    <app-roles></app-roles>
                                    <!-- roles component -->
                                </div>
                                <!-- Roles Tab ends -->
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink class="d-flex align-items-center"><i data-feather="users" class="mr-sm-50"></i><span
                                    class="d-none d-sm-block">Groups</span></a>
                            <ng-template ngbNavContent>
                                <!-- Groups Tab starts -->
                                <div class="tab-pane" id="Groups" aria-labelledby="Groups-tab" role="tabpanel">
                                    <!-- Groups compunent -->
                                    <app-groups></app-groups>
                                    <!-- Groups compunent -->
                                </div>
                                <!-- Groups Tab ends -->
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-0"></div>
                </div>
            </div>
        </section>
        <!-- users edit ends -->
    </div>
</div>