import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components'

import { ChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BreadcrumbModule } from 'app/layout/components/content-header/breadcrumb/breadcrumb.module';
import { NgxMaskModule } from 'ngx-mask';
import { DragulaModule } from 'ng2-dragula';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
// import { TableModule } from 'app/main/tables/table/table.module';





import { ContractControlComponent } from './contract-control.component';
import { ContractControlRightSidebarComponent } from './contract-control-right-sidebar/contract-control-right-sidebar.component';
// import { ContractControlMainSidebarComponent } from './contract-control-main-sidebar/contract-control-main-sidebar.component';
import { ContractControlListingComponent } from './contract-control-listing/contract-control-listing.component';
import { ContractControlChartViewComponent } from './contract-control-chart-view/contract-control-chart-view.component';
import { ContractControlFiltersComponent } from './contract-control-filters/contract-control-filters.component';
import { ContractControlDetailViewComponent } from './contract-control-detail-view/contract-control-detail-view.component';
import { CCPeriodandrenewalComponent } from './contract-control-detail-view/cc-periodandrenewal/cc-periodandrenewal.component';
import { CCContactsComponent } from './contract-control-detail-view/cc-contacts/cc-contacts.component';
import { CCRevenueComponent } from './contract-control-detail-view/cc-revenue/cc-revenue.component';
import { CCUpcomingeventComponent } from './contract-control-detail-view/cc-upcomingevent/cc-upcomingevent.component';
import { CCDocumentsComponent } from './contract-control-detail-view/cc-documents/cc-documents.component';
import { CCManagersComponent } from './contract-control-detail-view/cc-managers/cc-managers.component';
import { CCCostComponent } from './contract-control-detail-view/cc-cost/cc-cost.component';
import { CcRelatedContractComponent } from './contract-control-detail-view/cc-related-contract/cc-related-contract.component';
import { CcCustomBoxComponent } from './contract-control-detail-view/cc-custom-box/cc-custom-box.component';
import { LinkDocumentSidebarComponent } from './link-document-sidebar/link-document-sidebar.component';

const routes: Routes = [
  {
    path: 'contract-control',
    component: ContractControlComponent,
    data: { animation: 'contract-control' }
  },
  {
    path: 'contract-control/contract-control-detail-view',
    component: ContractControlDetailViewComponent,
    data: { animation: 'contract-control' }
  },
];

@NgModule({
  declarations: [
    ContractControlComponent,
    ContractControlRightSidebarComponent,
    // ContractControlMainSidebarComponent,
    ContractControlListingComponent,
    ContractControlChartViewComponent,
    ContractControlFiltersComponent,
    ContractControlDetailViewComponent,
    CCPeriodandrenewalComponent,
    CCContactsComponent,
    CCRevenueComponent,
    CCUpcomingeventComponent,
    CCDocumentsComponent,
    CCManagersComponent,
    CCCostComponent,
    CcRelatedContractComponent,
    CcCustomBoxComponent,
    LinkDocumentSidebarComponent,
    
  ],
  imports: [
    CommonModule,
    ChartsModule,
    RouterModule.forChild(routes),
    RouterModule,
    CoreCommonModule,
    CoreSidebarModule,
    NgbModule,
    NgSelectModule,
    PerfectScrollbarModule,
    BreadcrumbModule,
    NgxMaskModule.forRoot(),
    DragulaModule.forRoot(),
    Ng2FlatpickrModule,
    // TableModule
    
  ]
})
export class ContractControlModule { }
