<div class="d-flex justify-content-between align-items-center content-sidebar-header py-75 px-1">
    <div class="d-flex justify-content-start align-items-center">
        <div class="user-page-info">
            <h5 class="mb-0"> Content library </h5>
        </div>
    </div>
    <div>

    </div>
</div>

<div class="content-library-tab-content">
    <div class="px-1">
        <div class="input-group input-group-merge mb-50 mt-25">
            <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon-search2"><i data-feather="search"></i></span>
            </div>
            <input
                type="text"
                class="form-control"
                placeholder="Search..."
                aria-label="Search..."
                aria-describedby="basic-addon-search2"
            />
        </div>
    </div>
    <div class="content-library-tab-scroll editorTabScroll" [perfectScrollbar]>
        <div class="content-library-area px-1 mt-1">
            <div class="content-library-items">
                <div class="content-library-img-box">
                    <img src="../../../../../assets/images/pages/documentThumb.jpg" alt="" height="150" width="150" />
                </div>
                <div class="content-library-title-box">
                    <h3>Rental agreement studio</h3>
                </div>
            </div>
            <div class="content-library-items">
                <div class="content-library-img-box">
                    <img src="../../../../../assets/images/pages/documentThumbOne.jpg" alt="" height="150" width="150" />
                </div>
                <div class="content-library-title-box">
                    <h3>Business proposal</h3>
                </div>
            </div>
            <div class="content-library-items">
                    <div class="content-library-img-box">
                        <img src="../../../../../assets/images/pages/documentThumbTwo.jpg" alt="" height="150" width="150" />
                    </div>
                    <div class="content-library-title-box">
                        <h3>Business</h3>
                    </div>
                </div>
        </div>
    </div>
</div>