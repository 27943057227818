import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-taskandreminderswidget',
  templateUrl: './taskandreminderswidget.component.html',
  styleUrls: ['./taskandreminderswidget.component.scss']
})
export class TaskandreminderswidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
