import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widgetwith-billecta-addon',
  templateUrl: './widgetwith-billecta-addon.component.html',
  styleUrls: ['./widgetwith-billecta-addon.component.scss']
})
export class WidgetwithBillectaAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
