import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-pointof-sale-addon',
  templateUrl: './pointof-sale-addon.component.html',
  styleUrls: ['./pointof-sale-addon.component.scss']
})
export class PointofSaleAddonComponent implements OnInit {

  // modal Basic
 modalOpen(pointOfSellSettingModal) {
  this.modalService.open(pointOfSellSettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
  pagination: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};



constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
