import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';

// import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-cc-periodandrenewal',
  templateUrl: './cc-periodandrenewal.component.html',
  styleUrls: ['./cc-periodandrenewal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CCPeriodandrenewalComponent implements OnInit {

  // Basic Date Picker
  public readonly = false;
  public searchable = false;
  public basicDateOptions: FlatpickrOptions = {
    altInput: true
  };
  chooseContractlength = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  chooseDaysMounth = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  chooseContractlengthAfterRenewal = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  chooseDaysMounthLast = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
