import { Injectable, Inject, Injector } from '@angular/core';
import feather from 'feather-icons';
import uuid from 'short-unique-id';
import { GlobalService } from 'app/global/global.service';
import { PopOver } from '../popover/popover';
declare var $: any;
@Injectable({
   providedIn: 'root',
})
export class productTable {
   public instance_name = '';
   public uuid = new uuid({ length: 6, dictionary: 'number' });
   public keydownCount = 0;
   public selectMenuIsOpen = false;
   constructor(@Inject(Injector) private readonly injector: Injector) {
      var that = this;
      // that.toastr.toastrConfig.preventDuplicates = true;
      $(document).ready(function () { 
            
      });
   }

   productTableBox(params: any = {}) {
      var that = this;
      var count_id = this.uuid();
      var defaultProductTable = that.defaultTable({ uuid: count_id });
      const productTableElement = document.createElement('div')
      productTableElement.className = 'content-box content-box-block product-table-block marginminus70';
      productTableElement.innerHTML = `<div class="sort-helper">
        <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
        <div class="highlightSortContentBox"><p>Product table</p><svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" class="Icon"><path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z" clip-rule="evenodd"></path></svg></div></div><div class="content-box-row"><div class="rowItem">${that.popOverFunc.listingPopover({ classType: 'product-table', uuid: count_id, contentType: 'settings' })}<div class="sort-helper-ss"><div class="sort-helper sort-helper-inner"><svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg><div class="highlightSortContentBox"><p>Product table</p><svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" class="Icon"><path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z" clip-rule="evenodd"></path></svg></div></div></div><div class="content-box-inner" editor-uuid="${count_id}" type="product-table"><div class="editor${count_id} cke-editor-placeholder cke-editor-box product-table" editor-uuid="${count_id}" ><div class="product-table-parent-container" contenteditable="false">${defaultProductTable}</div></div></div></div></div>`;
      if ($('.dropIndicator-bottom').length == 0 && $('.template-page').hasClass('addContentBox')) {
         $('.template-page').append(productTableElement);
      } else if ($('.content-box-inner.active-border') && params.type == 'slashcommand') {
         $(productTableElement).insertAfter($('.content-box-inner.active-border').parents('.content-box'));
      } else {
         $(productTableElement).insertAfter(
            $('.dropIndicator-bottom').parent('.content-box'),
         )
      }
      that.GlobalServiceFunc.contentBoxSort();
      setTimeout(() => {
         $('.editor' + count_id).parents('.content-box-inner').addClass('active-border');
         this.GlobalServiceFunc.initializColumnResizer($('table[table-uuid="'+count_id+'"]'));
      }, 200);
   }


   addRow(params: any = {}){
      let row = `<tr class="item_row" style="height: 40px;" itemprice="0" itemtotal="0" row_uuid="${params.uuid}" table-uuid="${params.tableUuid}">
         <td class="item_name" style="border: none;">
            <table class="deleteRowTable cke_show_border ${(params.totalSettingsOfTable.allow_addremoveproducts == 0 ? 'hidden' : '')}">
                  <tbody>
                     <tr>
                        <td>
                              ${feather.icons['trash'].toSvg({ class: `cursor-pointer delete-product-row`, width: 14, height: 14 })}
                        </td>
                     </tr>
                  </tbody>
            </table>
            <table class="itemCostRowTable cke_show_border lockContenteditable hidden" contenteditable="false">
                  <tbody>
                     <tr>
                        <td class="lockContenteditable" contenteditable="false">
                              <i aria-hidden="true" class="fa fa-cog"></i>
                        </td>
                     </tr>
                  </tbody>
            </table>
            <table class="titleDescriptionTable cke_show_border">
                  <tbody>
                     <tr class="tableTitleTr">
                        <td contenteditable="true" class="tableTitleTd eachtd" placeholder="Product Name..."></td>
                     </tr>
                     <tr class="tableDescriptionTr ${(params.is_desc == 0 ? 'hidden' : '')}" style="display: table-row;">
                        <td contenteditable="true" placeholder="Type description..." class="tableDescriptionTd"></td>
                     </tr>
                  </tbody>
            </table>
         </td>
         <td contenteditable="true" class="item_price after_value itempricefocused eachtd ${(params.totalSettingsOfTable.show_priceperunit == 0 ? 'hidden' : '')}" style="border: none;" itemcost="0" itemmargin="0">0.00</td>
         <td contenteditable="true" class="item_quantity eachtd ${(params.totalSettingsOfTable.show_itemquantities == 0 ? 'hidden' : '')}" style="">1</td>`;
      var extraHeading = $('table[table-uuid="'+params.tableUuid+'"]').find('.extra-heading').length;
      for (let index = 0; index < extraHeading; index++) {
         const extraHead = document.querySelectorAll('table[table-uuid="'+params.tableUuid+'"] .extra-heading');
         if(extraHead[index].classList.contains('lineTax')){
            var taxAmount = extraHead[index].getAttribute('tax-amount');
            row += `<td heading-uuid="${params.uuid}" style="border: none;" contenteditable="true" class="extra-tax-cell item_tax eachtd">${taxAmount}</td>`;
         }else if(extraHead[index].classList.contains('lineDiscount')){
            var discountAmount = extraHead[index].getAttribute('discount-amount');
            row += `<td heading-uuid="${params.uuid}" style="border: none;" contenteditable="true" class="extra-discount-cell item_discount eachtd">${discountAmount}</td>`;
         }else{
            row += `<td style="border: none;" contenteditable="true" class="eachtd"></td>`;  
         }
      }
      row += `<td contenteditable="false" class="item_total after_value eachtd ${(params.totalSettingsOfTable.show_inlineitemtotal == 0 ? 'hidden' : '')}">0.00</td>
      </tr>`;
      return row;
   }

   addColumn(params:any = {}){
      return `<th class="productTableDragArea quantityheading extra-heading custom-column" style="width: 96px;" heading-uuid="${params.uuid}" table-uuid="${params.tableUuid}">
                 <span>
                    <strong contenteditable="true">&nbsp</strong>
                 </span>
                 <span class="deleteProductTable-column" contenteditable="false">
                    ${feather.icons['trash'].toSvg({ class: `cursor-pointer delete-product-column`, width: 14, height: 14 })}
                 </span>
              </th>`;
   }

   addTaxColumn(params:any = {}){
      return `<th class="productTableDragArea lineTax custom-column extra-heading" tax-amount="${params.taxValue}" contenteditable="true" style="width: 15.3%;"><strong>Tax</strong></th>`;
   }

   addDiscountColumn(params:any = {}){
      return `<th class="productTableDragArea lineDiscount custom-column extra-heading" discount-amount="${params.discountValue}" contenteditable="true" style="width: 15.3%;"><strong>Discount</strong></th>`;
   }

   defaultTable(params: any = {}) {
      var totalTables = this.total_product();
      return `<table contenteditable="false" class="calculationTemplate calculationTemplateTable calculation_table general_calculation_table sar-table initResize0 product-table-borders-v product-table-borders-h " style="width: 100%;border-collapse: collapse;overflow: hidden;margin-bottom: 5px;--currencysymbol:'USD'" table-uuid="${params.uuid}" count="${totalTables}" currency_format="after_value" tablename="Product table ${totalTables}" currencysymbol="USD" decimalsallowed="2" id="JColResizer0" previouslydiscountadded="" previouslytaxadded="" productdescription="1" tabledesignsettings='{"showHeader": "1","showVerticalborder": "1","showHorizontalborder": "1","header_fontsize": "14","header_fontstyle": "Normal","header_fontcolor": "#000000","header_bgcolor": "#f4f4f4","title_fontsize": "14","title_fontstyle": "Normal","title_fontcolor": "#000000","desc_fontsize": "11","desc_fontstyle": "Normal","desc_fontcolor": "#000000","is_desc": "1"}' selected_categories="{Others:1}" desclength="200" profitmargin="0 USD" totalcost="0.00 USD" totaltableprice="0.00 USD" taxsettings='{"totalTax":1,"addTax":0,"taxType":"%","taxValue":0,"editTaxTitle":0,"applyTaxType":"percentage"}' discountsettings='{"totalDiscount":1,"addDiscount":0,"discountType":"%","discountValue":0,"editDiscountTitle":0,"applyDiscountType":"percentage"}' totaltabsettingsoftable='{"show_subtotal":1,"show_sectionsubtotal":1,"show_total":1,"show_totalquantity":0,"show_inlinetaxttotal":0,"show_inlinediscounttotal":0,"allow_addnewrows":1,"allow_addnewcolumns":1,"allow_addremoveproducts":1,"show_inlineitemtotal":1,"show_priceperunit":1,"show_itemquantities":1,"addtablein_grandtotal":1}' tablepermissionforrole='{"whocanedit": 0,"permissionaddproduct": 0,"permissionremoveproduct": 0,"permissionaddrow": 0,"permissioneditqty": 0}'>
        <thead>
           <tr class="table_headingsRow" style="height: 40px;display: table-row;">
              <th contenteditable="true" class="productTableDragArea product_heading" style="width: 50.4%;">
                 <strong>
                    Product
                 </strong>
              </th>
              <th contenteditable="true" class="productTableDragArea priceperunitheading" style="width: 18.8%;">
                 <strong>
                    Price per unit
                 </strong>
              </th>
              <th contenteditable="true" class="productTableDragArea quantityheading" style="width: 15.3%;">
                 <strong>
                    Quantity 
                 </strong>
              </th>
              <th contenteditable="true" class="itemTotalheading inlinetotalheading" style="width: 15.5%;">
                 <strong>
                    Total 
                 </strong>
              </th>
           </tr>
         </thead>
         <tbody>
           <tr class="item_row" style="height: 40px;" itemprice="0" itemtotal="0" row_uuid="${this.uuid()}">
              <td class="item_name" style="border: none;">
                 <table class="deleteRowTable cke_show_border">
                    <tbody>
                       <tr>
                          <td>
                            ${feather.icons['trash'].toSvg({ class: `cursor-pointer delete-product-row`, width: 14, height: 14 })}
                          </td>
                       </tr>
                    </tbody>
                 </table>
                 <table class="itemCostRowTable cke_show_border lockContenteditable hidden" contenteditable="false">
                    <tbody>
                       <tr>
                          <td class="lockContenteditable" contenteditable="false">
                             <i aria-hidden="true" class="fa fa-cog"></i>
                          </td>
                       </tr>
                    </tbody>
                 </table>
                 <table class="titleDescriptionTable cke_show_border">
                    <tbody>
                       <tr class="tableTitleTr">
                          <td contenteditable="true" class="tableTitleTd eachtd" placeholder="Product Name..." ></td>
                       </tr>
                       <tr class="tableDescriptionTr" style="display: table-row;">
                          <td contenteditable="true" placeholder="Type description..." class="tableDescriptionTd" ></td>
                       </tr>
                       <tr class="productTableImagesTr" style="display: table-row;">
                            <td class="productTableImagesTd" contenteditable="false">
                                <ul class="product-image-ul" style="margin-top: 10px;">
                                    <li class="deletelibitemimage" style="background-image: url('../assets/images/logo/sidebarlogoIcon.png') !important;background-size: contain !important;border: 0;background-repeat: no-repeat !important;"><button class="deleteProductImage btn-icon btn-danger btn-outline btn-round pull-right " type="button"><i aria-hidden="true" class="fa fa-trash-o pull-left margin-0"></i></button></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                 </table>
              </td>
              <td contenteditable="true" class="item_price after_value itempricefocused eachtd" style="border: none;" itemcost="0" itemmargin="0">0.00</td>
              <td contenteditable="true" class="item_quantity eachtd" style="">1</td>
              <td contenteditable="false" class="item_total after_value eachtd">0.00</td>
           </tr>
        </tbody>
     </table>
     <table class="lockContenteditable totaltaxdiscounttable" contenteditable="false" style="width: 100%;border-collapse: separate;border: none;padding-right: 10px;position: relative;clear: right;--currencysymbol:'USD'" align="=" currency_format="after_value" total-table-uuid="${params.uuid}">
        <tbody>
            <tr>
                <td style="text-align: right;width: 100%;">
                    <table style="float: right;">
                    <tbody>
                        <tr class="general_tableitemquantity producttable_quantity hidden" style="height: 30px;">
                            <td class="subsection_total lockContenteditable" contenteditable="false" style="border: none;color: rgb(0, 0, 0);border-radius: 0;font-weight: 600;text-align: right;outline: none;border-top: 0 !important;padding-right: 20px;">
                                Quantity:
                            </td>
                            <td class="total_itemquantity lockContenteditable" contenteditable="false" style="text-align: right;border: none;color: rgb(0, 0, 0);border-radius: 0px 0px 0px 0px;font-weight: 800;border-top: 0 !important;">1</td>
                        </tr>
                        <tr class="producttable_subsection_total general_tablesubtotal" style="height: 30px;">
                            <td class="subsection_total lockContenteditable" contenteditable="false" style="border: none;color:rgb(0, 0, 0);border-radius: 0;font-weight: 600;text-align: right;outline: none;border-top: 0 !important;padding-right: 20px;">Subtotal:</td>
                            <td class="subsection_total_amount lockContenteditable currency_text" contenteditable="false" style="text-align: right;border: none;color: rgb(0, 0, 0);border-radius: 0px 0px 0px 0px;font-weight: 800;border-top: 0 !important;">0.00</td>
                        </tr>
                        <tr class="producttable_inlinediscount_total total_inlineDiscount hidden" style="height: 30px;">
                            <td class="inlinetotal_discount lockContenteditable" contenteditable="false" style="border: none;color: rgb(0, 0, 0);border-radius: 0;font-weight: 600;text-align: right;outline: none;border-top: 0 !important;padding-right: 20px;">
                              <span contenteditable="false">Discount</span><span class="calculate_discount_percentage_main">(<span class="calculate_discount_percentage">0</span>%)</span>:
                            </td>
                            <td class="total_item_discountamount lockContenteditable currency_text" contenteditable="false" style="text-align: right;border: none;color: rgb(0, 0, 0);border-radius: 0px 0px 0px 0px;font-weight: 800;border-top: 0 !important;">-0.00</td>
                        </tr>
                        <tr class="producttable_inlinetax_total total_inlineTax hidden" style="height: 30px;">
                            <td class="inlinetotal_tax lockContenteditable" contenteditable="false" style="border: none;color: rgb(0, 0, 0);border-radius: 0;font-weight: 600;text-align: right;outline: none;border-top: 0 !important;padding-right: 20px;">
                                <span contenteditable="false">Tax</span><span class="calculate_tax_percentage_main">(<span class="calculate_tax_percentage">0</span>%)</span>:
                            </td>
                            <td class="total_item_taxamount lockContenteditable currency_text" contenteditable="false" style="text-align: right;border: none;color: rgb(0, 0, 0);border-radius: 0px 0px 0px 0px;font-weight: 800;border-top: 0 !important;">0.00</td>
                        </tr>
                        <tr class="producttable_total general_tableaddrow" style="height: 30px;">
                            <td class="total_amounttext lockContenteditable" contenteditable="false" style="border: none;color: rgb(0, 0, 0);border-radius: 0px 0px 0px 6px;font-weight: 600;text-align: right;outline: none;padding-right: 20px;">        Total:</td>
                            <td class="calculation_total_amount lockContenteditable currency_text" contenteditable="false" style="text-align: right;border: none;color: rgb(0, 0, 0);border-radius: 0px 0px 0px 0px;font-weight: 800;">0.00</td>
                        </tr>
                    </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="lockContenteditable" contenteditable="false" style="width: auto;border-collapse: separate;border: none;padding-right: 10px;position: relative;clear: right;position: absolute;bottom: 5px;" align="=" button-table-uuid="${params.uuid}">
   <tbody>
      <tr>
         <td style="">
            <table style="">
               <tbody>
                  <tr style="height: 30px;">
                     <td class="lockContenteditable" contenteditable="false" style="border: none;color: rgb(0, 0, 0);border-radius: 0px 0px 0px 6px;font-weight: 600;outline: none;">
                        <div class="btn btn-success addnewrow productTableFooterBtn" id="addNewTableRows" style="padding-top: 3px;padding-bottom: 3px;margin-top: 8px;z-index: 9999;font-size: 13px;margin-right: 4px;" table-uuid="${params.uuid}">
                           Add new row
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </td>
         <td>
            <table style="">
               <tbody>
                  <tr style="height: 30px;">
                     <td class="lockContenteditable" contenteditable="false" style="border: none;color: #4c4c4c;border-radius: 0px 0px 0px 6px;font-weight: 600;outline: none;">
                        <div class="btn btn-success addfromlib productTableFooterBtn" id="addFromCatalogPopupBtn" style="padding-top: 3px;padding-bottom: 3px;margin-top: 8px;z-index: 9999;font-size: 13px;margin-right: 4px;" table-uuid="${params.uuid}">Add from products</div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </td>
         <td>
            <table style="">
               <tbody>
                  <tr style="height: 30px;">
                     <td class="lockContenteditable" contenteditable="false" style="border: none;color: #4c4c4c;border-radius: 0px 0px 0px 6px;font-weight: 600;outline: none;">
                        <div class="btn btn-success addtablecolumn productTableFooterBtn add-column-product-table" id="productTableAddColumnBtn" style="padding-top: 3px;padding-bottom: 3px;margin-top: 8px;z-index: 9999;font-size: 13px;margin-right: 4px;" table-uuid="${params.uuid}">Add column</div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </td>
      </tr>
   </tbody>
</table>
     `;
   }

   total_product() {
      var maximum = 0;
      for (let index = 0; index < document.querySelectorAll('.general_calculation_table').length; index++) {
         const element = document.querySelectorAll('.general_calculation_table')[index];
         var value = parseFloat(element.getAttribute('count'));
         maximum = (value > maximum) ? value : maximum;
      }
      return (maximum == 0 ? 1 : maximum+1);   
   }

   private get GlobalServiceFunc() {
      return this.injector.get(GlobalService);
   }

   private get popOverFunc() {
      return this.injector.get(PopOver);
   }
}