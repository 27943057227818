import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-tab',
  templateUrl: './settings-tab.component.html',
  styleUrls: ['./settings-tab.component.scss']
})
export class SettingsTabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
