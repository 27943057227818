<div class="content-wrapper container-xxl p-0">
    <div class="content-body position-relative">
        <!-- profile header -->
        <div class="row">
            <!-- app-breadcrumb component -->
            <app-breadcrumb [breadcrumb]="breadcrumbContractControl" class="mb-50 ml-75"></app-breadcrumb>
            <!--/ app-breadcrumb component -->
            <div class="col-12">
                <div class="card profile-header mb-0 CC_detailViewHeader">
                    <div class="position-relative">
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-sm-12">
                                <div class="profile-img-container d-flex align-items-center">
                                    <div class="profile-img">
                                        <img src="assets/images/portrait/small/avatar-s-10.jpg" class="rounded-circle mr-1 CC_MainAvatar"
                                            alt="Card image" />
                                    </div>
                                    <!-- profile title -->
                                    <div class="profile-title ml-2">
                                        <h2 class="font-medium-2">Contract name</h2>
                                        <div class="d-flex align-items-center align-content-between">
                                            <div ngbDropdown class="">
                                                <a class="font-small-3 mb-0 text-primary pr-2 border-right"
                                                    ngbDropdownToggle>ZOMATA
                                                    .COM</a>
                                                <div ngbDropdownMenu class="CC_headerDropdown">
                                                    <div class="d-flex align-content-center justify-content-between w-100">
                                                        <div class="input-group input-group-merge">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i data-feather="search"></i></span>
                                                            </div>
                                                            <input type="text" class="form-control" id="search"
                                                                placeholder="Search" aria-label="Search..."
                                                                aria-describedby="search" />
                                                        </div>
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <a ngbDropdownItem href="#">Option 1</a>
                                                    <a ngbDropdownItem href="#">Option 2</a>
                                                    <a ngbDropdownItem href="#">Option 3</a>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="d-flex align-items-center justify-content-between w-100">
                                                        <a class="font-small-2 text-primary" (click)="addCompanyModalOpenClick(addCompanyModal)">Add
                                                            organization</a>
                                                        <a class="font-small-2 text-primary">Clear</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div ngbDropdown class="">
                                                <a class="font-small-3 mb-0 text-primary px-2 border-right"
                                                    ngbDropdownToggle>HR</a>
                                                <div ngbDropdownMenu class="CC_headerDropdown">
                                                    <div class="d-flex align-content-center justify-content-between w-100">
                                                        <div class="input-group input-group-merge">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i data-feather="search"></i></span>
                                                            </div>
                                                            <input type="text" class="form-control" id="search"
                                                                placeholder="Search" aria-label="Search..."
                                                                aria-describedby="search" />
                                                        </div>
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <a ngbDropdownItem class="active" href="#">Option 1</a>
                                                    <a ngbDropdownItem href="#">Option 2</a>
                                                    <a ngbDropdownItem href="#">Option 3</a>
                                                    <a ngbDropdownItem href="#">Option 3</a>
                                                    <a ngbDropdownItem href="#">Option 3</a>
                                                </div>
                                            </div>
                                            <div ngbDropdown class="">
                                                <a class="font-small-3 text-primary px-2 mb-0" ngbDropdownToggle>Software</a>
                                                <div ngbDropdownMenu class="CC_headerDropdown">
                                                    <div class="d-flex align-content-center justify-content-between w-100">
                                                        <div class="input-group input-group-merge">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i data-feather="search"></i></span>
                                                            </div>
                                                            <input type="text" class="form-control" id="search"
                                                                placeholder="Search" aria-label="Search..."
                                                                aria-describedby="search" />
                                                        </div>
                                                    </div>
                                                    <div class="dropdown-divider"></div>
                                                    <a ngbDropdownItem href="#">Option 1</a>
                                                    <a ngbDropdownItem href="#">Option 2</a>
                                                    <a ngbDropdownItem href="#">Option 3</a>
                                                    <div class="dropdown-divider"></div>
                                                    <div class="d-flex align-items-center justify-content-between w-100">
                                                        <a class="font-small-2 text-primary" (click)="addCetagoryModalOpenClick(addCetagoryModal)">Add
                                                            new category</a>
                                                        <a class="font-small-2 text-primary">Clear</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-sm-12 d-flex border-left align-items-center justify-content-between">
                                <div>
                                    <p class="mb-50 font-small-3">Contract no <strong>63998132a94a7</strong></p>
                                    <div ngbDropdown class="d-flex align-items-center mb-50 justify-content-between w-100">
                                        <a class="font-small-3">This is a <span ngbDropdownToggle class="text-primary cursor-pointer">master
                                                contract</span>
                                        </a>
                                        <div ngbDropdownMenu class="CC_headerDropdown">
                                            <div class="d-flex align-content-center justify-content-between w-100">
                                                <div class="input-group input-group-merge">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i data-feather="search"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="search" placeholder="Search"
                                                        aria-label="Search..." aria-describedby="search" />
                                                </div>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                            <a ngbDropdownItem href="#">Option 1</a>
                                            <a ngbDropdownItem href="#">Option 2</a>
                                            <a ngbDropdownItem href="#">Option 3</a>
                                            <div class="dropdown-divider"></div>
                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                <a class="font-small-2" href="#">Add new category</a>
                                                <a class="font-small-2" href="#">Clear</a>
                                            </div>
                                        </div>
                                        <!-- <div ngbDropdown class="mb-50">
                                            <a class="font-small-3">
                                                <span ngbDropdownToggle><i class="ml-4 cursor-pointer" data-feather="edit"></i></span>
                                            </a>
                                            
                                        </div> -->
                                    </div>


                                    <div ngbDropdown class="d-flex align-items-center mb-50 justify-content-between w-100">
                                        <a class="font-small-3 mb-0">Connected to <span ngbDropdownToggle class="text-primary cursor-pointer">Test
                                                template</span>
                                        </a>
                                        <div ngbDropdownMenu class="CC_headerDropdown">
                                            <div class="d-flex align-content-center  justify-content-between w-100">
                                                <div class="input-group input-group-merge">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i data-feather="search"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="search" placeholder="Search"
                                                        aria-label="Search..." aria-describedby="search" />
                                                </div>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                            <a ngbDropdownItem href="#">Option 1</a>
                                            <a ngbDropdownItem href="#">Option 2</a>
                                            <a ngbDropdownItem href="#">Option 3</a>
                                            <div class="dropdown-divider"></div>
                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                <a class="font-small-2" href="#">Add new category</a>
                                                <a class="font-small-2" href="#">Clear</a>
                                            </div>
                                        </div>
                                        <!-- <div class="mb-50">
                                            <a class="font-small-3 mb-0">
                                                <span >
                                                    <i class="ml-4 cursor-pointer" data-feather="edit"></i>
                                                </span>
                                            </a>
                                            
                                        </div> -->
                                    </div>


                                </div>
                                <div>
                                    <div class="btn-group">
                                        <div ngbDropdown>
                                            <button ngbDropdownToggle class="btn btn-outline-primary round" type="button"
                                                id="dropdownMenuButton" rippleEffect>
                                                Active
                                            </button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                                                <a ngbDropdownItem href="javascript:void(0)">Active</a>
                                                <a ngbDropdownItem href="javascript:void(0)">Non Active</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="progress-wrapper mb-2">
                    <ngb-progressbar type="success" class="progress-bar-success" [value]="45"></ngb-progressbar>
                </div>
            </div>
            <div ngbDropdown class="CC_settingDropdown position-absolute" container="body">
                <span ngbDropdownToggle>
                    <i class="ml-4 cursor-pointer" data-feather="settings"></i>
                </span>
                <div ngbDropdownMenu class="CC_headerDropdown">
                    <a ngbDropdownItem (click)="CC_IndexDataModalOpen(CC_indexDataModal)">Show index data</a>
                    <a ngbDropdownItem (click)="CC_AudittrailModalOpen(CC_AudittrailModal)">Audit trail</a>
                    <a ngbDropdownItem href="#">Edit layout</a>
                    <a ngbDropdownItem href="#">Edit reminders</a>
                    <a ngbDropdownItem href="#">Duplicate contract</a>
                    <a ngbDropdownItem class="text-danger" href="#">Delete</a>
                </div>
            </div>
        </div>
        <!--/ profile header -->
        <div class="contractControlMiddleSection">
            <div class="row">
                <div class="col-md-5 col-sm-12 col-lg-5">
                    <!-- period and renewal -->

                    <app-cc-periodandrenewal dragula="card-drag-area"></app-cc-periodandrenewal>

                    <!-- period and renewal -->

                    <!-- CC contacts -->
                    <app-cc-contacts dragula="card-drag-area"></app-cc-contacts>
                    <!-- CC contacts -->

                    <!-- CC revenew -->
                    <app-cc-revenue dragula="card-drag-area"></app-cc-revenue>
                    <!-- CC revenew -->

                    <!-- CC managers -->
                    <app-cc-managers dragula="card-drag-area"></app-cc-managers>
                    <!-- CC managers -->

                    <!-- CC cc-cost -->
                    <app-cc-cost dragula="card-drag-area"></app-cc-cost>
                    <!-- CC cc-cost -->

                    <!-- cc-related-contract -->
                    <app-cc-related-contract dragula="card-drag-area"></app-cc-related-contract>
                    <!-- cc-related-contract -->

                    <!-- cc-custom-box -->
                    <app-cc-custom-box dragula="card-drag-area"></app-cc-custom-box>
                    <!-- cc-custom-box -->


                </div>
                <div class="col-md-7 col-sm-12 col-lg-7 pl-0">
                    <!-- CC contacts -->
                    <app-cc-upcomingevent></app-cc-upcomingevent>
                    <!-- CC contacts -->

                    <!-- CC document -->
                    <app-cc-documents></app-cc-documents>
                    <!-- CC document -->
                </div>
            </div>
        </div>


    </div>
</div>

<!-- add-attachments-sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="Link-document-right-sidebar" overlayClass="modal-backdrop">
    <app-link-document-sidebar></app-link-document-sidebar>
</core-sidebar>
<!--/ add-attachments-sidebar -->

<!-- Add Cetagory Modal -->
<ng-template #addCetagoryModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add Cetagory</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="form-group mb-1">
            <label for="cetagory">Cetagory</label>
            <input type="text" class="form-control" id="cetagory" placeholder="Cetagory" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Add
        </button>
    </div>
</ng-template>
<!-- / Add Cetagory Modal -->

<!-- Add Company Modal -->
<ng-template #addCompanyModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add Company</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="form-group mb-1">
            <label for="Firstname">Name</label>
            <input type="text" class="form-control" id="Firstname" placeholder="Name" />
        </div>
        <div class="form-group mb-1">
            <label for="Lastname">Reg.no</label>
            <input type="text" class="form-control" id="Lastname" placeholder="Reg.no" />
        </div>
        <div class="form-group mb-1">
            <label for="phone">Phone</label>
            <input class="form-control" placeholder="+46" type="text" prefix="+46 " mask="00 000 00 00" />
        </div>
        <div class="form-group mb-1">
            <label for="email">Address</label>
            <input type="text" class="form-control" id="email" placeholder="Address" />
        </div>
        <div class="form-group mb-1">
            <label for="email">Zip code</label>
            <input type="text" class="form-control" id="email" placeholder="Zip code" />
        </div>
        <div class="form-group mb-1">
            <label for="email">City</label>
            <input type="text" class="form-control" id="email" placeholder="City" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / Add Company Modal -->


<!-- Audittrail Modal -->
<ng-template #CC_AudittrailModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Audit trail</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body py-2 px-0" tabindex="0" ngbAutofocus>
        <div class="CC_auditTrailScrollCont" [perfectScrollbar]>
            <ul class="timeline px-2">
                <li class="timeline-item">
                    <span class="timeline-point timeline-point-indicator"></span>
                    <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                            <h5>Dec 29 2022</h5>
                            <span class="timeline-event-time">Changed</span>
                        </div>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                    </div>
                </li>
                <li class="timeline-item">
                    <span class="timeline-point timeline-point-indicator"></span>
                    <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                            <h5>Dec 29 2022</h5>
                            <span class="timeline-event-time">Changed</span>
                        </div>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                    </div>
                </li>
                <li class="timeline-item">
                    <span class="timeline-point timeline-point-indicator"></span>
                    <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                            <h5>Dec 29 2022</h5>
                            <span class="timeline-event-time">Changed</span>
                        </div>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                    </div>
                </li>
                <li class="timeline-item">
                    <span class="timeline-point timeline-point-indicator"></span>
                    <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                            <h5>Dec 29 2022</h5>
                            <span class="timeline-event-time">Changed</span>
                        </div>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                        <p class="mb-50 font-small-2" placement="top" container="body" ngbTooltip="Dec 29 2022"><b>Anique
                                Jaffri</b> Marked <b>Completed</b></p>
                    </div>
                </li>
            </ul>

        </div>
    </div>
</ng-template>
<!-- / Audittrail Modal -->

<!-- indexData Modal -->
<ng-template #CC_indexDataModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Show index data</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body py-0 px-0" tabindex="0" ngbAutofocus>
        <div class="CC_indexDataScrollCont CC_indexDataContainer" [perfectScrollbar]>
            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Contract Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>Bugs in template</td>
                        </tr>
                        <tr>
                            <td>Document type</td>
                            <td>Hr</td>
                        </tr>
                        <tr>
                            <td>Category</td>
                            <td>Software</td>
                        </tr>
                        <tr>
                            <td>Contract number </td>
                            <td>63998132a94a7</td>
                        </tr>
                        <tr>
                            <td>Level</td>
                            <td>Master contract</td>
                        </tr>
                        <tr>
                            <td>Sub contracts</td>
                            <td>Testing template</td>
                        </tr>
                        <tr>
                            <td>Created</td>
                            <td>2022-12-14 10:32:08</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>Inactive</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">About Employment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>My dropdown</td>
                            <td>8</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Contacts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Full name</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Email</td>
                            <td>Easykaam@kaamchalao.com</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Phone</td>
                            <td>+923323832485</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Full name</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Email</td>
                            <td>Easykaam@kaamchalao.com</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Phone</td>
                            <td>+923323832485</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Managers</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Full name</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Email</td>
                            <td>Easykaam@kaamchalao.com</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Role</td>
                            <td>Member</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Full name</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Email</td>
                            <td>Easykaam@kaamchalao.com</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Role</td>
                            <td>Member</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Linked Contracts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>Demo - supplier agreement 23605</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>Demo - supplier agreement 23605</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Organization</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>Zomatatat</td>
                        </tr>
                        <tr>
                            <td>Reg.no</td>
                            <td>A123</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Phone number </td>
                            <td>63998132a94a7</td>
                        </tr>
                        <tr>
                            <td>Zip code</td>
                            <td>12234</td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>Testing</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Period & Renewal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Start date</td>
                            <td>0000-00-00</td>
                        </tr>
                        <tr>
                            <td>End date</td>
                            <td>0000-00-00</td>
                        </tr>
                        <tr>
                            <td>Notice period</td>
                            <td>0 day(s)</td>
                        </tr>
                        <tr>
                            <td>Auto renewal</td>
                            <td>No</td>
                        </tr>
                        <tr>
                            <td>Contract length after renewal</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Notice period after renewal</td>
                            <td>0 month(s)</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cost</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td>Sek</td>
                        </tr>
                        <tr>
                            <td>Payment period</td>
                            <td>0 day(s)</td>
                        </tr>
                        <tr>
                            <td>Startup cost</td>
                            <td>0</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Revenue</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Amount</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td>Sek</td>
                        </tr>
                        <tr>
                            <td>Payment period</td>
                            <td>0 day(s)</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Task</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Message</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Date</td>
                            <td>2022-12-07</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Assigned to</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Full name</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Email</td>
                            <td>Easykaam@kaamchalao.com</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Status</td>
                            <td>Completed</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- <div class="table-responsive ">
                <table class="table">
                    <thead>
                        <tr>
                            <th colspan="2">Attach Documents</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Name</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Folder</td>
                            <td>Documents/sent/sample</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Pdf</td>
                            <td><a href="#">Https://esign.simplesign.io/avtal/downloadpdf/956332/6399adda57acd</a></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Name</td>
                            <td>Easy</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Folder</td>
                            <td>Documents/sent/sample</td>
                        </tr>
                        <tr>
                            <td class="paddingForPadding">Pdf</td>
                            <td><a href="#">Https://esign.simplesign.io/avtal/downloadpdf/956332/6399adda57acd</a></td>
                        </tr>
                    </tbody>
                </table>
            </div> -->

        </div>
    </div>
</ng-template>
<!-- / indexData Modal -->