<div class="card addonBoxContainer" (click)="modalOpen(dsrPlugandPlaySettingModal)">
    <div class="card-body">
        <h5>DSR Plug and Play</h5>
        <p class="card-text font-small-3 addonboxDiscription">Simplify your GDPR with this smart DSR feature.</p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'database'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #dsrPlugandPlaySettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">DSR Plug and Play </h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                According to GDPR, every Data Subject has the right to request information about
                                themselves, in other words, a Data Subject Request (DSR).
                                <br><br>
                                The company the request is submitted to is then required to comply with and address
                                this request within a certain time frame. One issue plaguing companies after the new
                                GDPR regulations went into effect is trying to ensure that every DSR comes from the
                                authentic Data Subject and not a malicious actor.
                                <br><br>
                                Simple Sign’s smart Plug and Play feature solves this issue and allows you to comply in
                                seconds.
                                <br><br>
                                This DSR feature gives your company a unique URL that you can add on your webpage (e.g.
                                your terms and conditions). The URL links the Data Subject to a pre-built web form that
                                is in compliance with DPR and allows them to submit a data request efficiently and
                                securely. We allow you to choose the method of validation for prospective requests in
                                order to ensure that person submitting the request is whom they say they are.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets/images/addons-images/addon-thumbnails/DSRPlugandPlay/DSRPlugandPlay1.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets/images/addons-images/addon-thumbnails/DSRPlugandPlay/DSRPlugandPlay2.png"
                                    alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 border-right">
                            <div class="form-group mb-1">
                                <label for="customSelect">Authentication</label>
                                <ng-select [items]="chooseAuthentication" bindLabel="name" [readonly]="readonly"
                                    [searchable]="searchable" placeholder="Authentication"> </ng-select>
                            </div>
                            <div class="form-group mb-0">
                                <label for="Signatureconfirmation">Confirmation message (popup):</label>
                                <fieldset class="form-group">
                                    <textarea class="form-control" id="" rows="4" placeholder="Confirmation message"></textarea>
                                </fieldset>
                            </div>
                            <div class="form-group mb-0">
                                <label for="Signatureconfirmation">Confirmation message (email):</label>
                                <fieldset class="form-group">
                                    <textarea class="form-control" id="" rows="4" placeholder="Confirmation message"></textarea>
                                </fieldset>
                            </div>
                            <div class="form-group mb-1">
                                <label for="customSelect">Language</label>
                                <ng-select [items]="chooseLanguage" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                                    placeholder="Language"> </ng-select>
                            </div>
                            <div class="custom-control custom-switch form-group mb-1">
                                <div class="d-flex justify-content-between">
                                    <small>Hide country</small>
                                    <input type="checkbox" class="custom-control-input" id="hideCountry" />
                                    <label class="custom-control-label" for="hideCountry"></label>
                                </div>
                            </div>
                            <div class="custom-control custom-switch form-group mb-1">
                                <div class="d-flex justify-content-between">
                                    <small>Hide comments</small>
                                    <input type="checkbox" class="custom-control-input" id="hideComments" />
                                    <label class="custom-control-label" for="hideComments"></label>
                                </div>
                            </div>
                            <div class="form-group mb-0 mb-0">
                                <label for="customSelect">Select recipient of DSR request</label>
                                <ng-select [items]="chooseRecipient" bindLabel="name" [readonly]="readonly"
                                    [searchable]="false" placeholder="Select recipient">
                                </ng-select>
                            </div>

                            <button type="button" class="btn btn-success mt-1 float-right" rippleEffect>Save</button>
                        </div>

                        <div class="col-md-6 col-lg-6">
                            <h5 class="mb-1">DSR Plug and Play</h5>
                            <label>DOCUMENT LINK</label>
                            <ul class="list-group">
                                <li class="list-group-item font-small-3 list-group-item-primary">https://esign.simplesign.io/gdpr/89/637f670c20805</li>
                            </ul>
                            <div class="row">
                                <div class="d-flex col-12 align-content-center justify-content-center w-100">
                                    <button type="button" class="btn btn-danger mt-2 mr-75" rippleEffect><span [data-feather]="'link'"
                                            [class]="'mr-25'"></span>Get Link</button>
                                    <button type="button" class="btn  btn-danger mt-2 " (click)="showQRCode()" rippleEffect><span
                                            [data-feather]="'maximize'" [class]="'mr-25'"></span>Get
                                        QR Code</button>
                                </div>
                            </div>
                            <div class="DRSAddonQRCodeContainer p-2 text-center border-top mt-3" *ngIf="hiddenQRcodeCont">
                                <img src="assets\images\addons-images\QRimg.jpg" alt="QR img">
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->