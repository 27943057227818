<div class="card addonBoxContainer" (click)="modalOpen(droplistSettingModal)">
    <div class="card-body">
        <h5>Droplist settings +</h5>
        <p class="card-text font-small-3 addonboxDiscription">Hide drop-down lists in your templates to create different scenarios within the same template</p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'list'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #droplistSettingModal let-modal>
        <div class="modal-header">
            <div class="addonPopupHeader">
                <h4 class="modal-title" id="myModalLabel1">Droplist settings +</h4>
                <small>Powered by Simple Sign</small>
            </div>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
            <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
                Activate Add-on</button>
            <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
                <li ngbNavItem>
                    <a ngbNavLink>Overview</a>
                    <ng-template ngbNavContent>
                        <div class="row">
                            <div class="col-md-7 col-lg-7">
                                <p class="font-small-3">
                                        Select to hide some of the droplist values instead of created multiple versions of the same droplist type. This will simplify your work when you need to use the same droplist type but not the same droplist values on multiple templates.
                                </p>
                            </div>
                            <div class="col-md-5 col-lg-5">
                                <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                    <img class="img-fluid" src="assets/images/addons-images/no-thumbnail.jpg"
                                        alt="banner" />
                                </swiper>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-1"></div>
        </div>
    </ng-template>
    <!-- / Modal -->