import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-datagroupsmodal',
  templateUrl: './datagroupsmodal.component.html',
  styleUrls: ['./datagroupsmodal.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DatagroupsmodalComponent implements OnInit {

  constructor(private modalService: NgbModal,) {


   }

  ngOnInit(): void {
  }

    // modal Open Form
    dataGroupsModalOpen(dataGroupsModalmmm) {
      this.modalService.open(dataGroupsModalmmm, {

        size: 'xl'
    
      });
    }

}
