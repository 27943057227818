import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hide-optionfor-documentfields-addon',
  templateUrl: './hide-optionfor-documentfields-addon.component.html',
  styleUrls: ['./hide-optionfor-documentfields-addon.component.scss']
})
export class HideOptionforDocumentfieldsAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
