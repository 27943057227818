import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AttachmentsComponent implements OnInit {

  public pageBasic = 1;
  // Private
  responsive: true;

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  constructor(
    // private _userListService: UserListService,
    private _coreSidebarService: CoreSidebarService,
    // private _coreConfigService: CoreConfigService
  ) {  }

  ngOnInit(): void {
  }

}
