import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from './company.service';
import { GlobalService } from 'app/global/global.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {

  public companyForm: FormGroup;
  public readonly = false;
  public searchable = false;
  public submitted: boolean = false;
  public loading: boolean = false;
  public changesDetect: boolean = false;
  public outgoingEmail = [
    { id: 0, name: 'Name' },
    { id: 1, name: 'Organization' },
  ];
  public chooseprize = [
    { label: "1 000,50", value: "sv-SE" },
    { label: "1.000,50", value: "da-DK" },
    { label: "1,000.50", value: "en-US" },
    { label: "1000.50",  value: "es-US" },
    { label: "1000,50",  value: "es-ES" }
  ];
  public chooseCurrency = [
    { currency_format: "after_value", currency: 'After price' },
    { currency_format: "before_value", currency: 'Before price' },
  ];


  constructor(private companyService: CompanyService, private globalService: GlobalService) { }

  ngOnInit(): void {
    this.initForm({});
    this.companies();
  }


  async companies(){
    const companies = await this.companyService.getCompanySettings();
    this.initForm(companies.data.data);
  }


  initForm(data:any){ 
    this.companyForm = new FormGroup({
      id: new FormControl(data.id ?? ''),
      bcc_active: new FormControl(data.bcc_active ?? +false),
      bcc_pdf_varification: new FormControl(data.bcc_pdf_varification ?? +false),
      bcc_email: new FormControl(data.bcc_email ?? '', [Validators.email]),
      subject: new FormControl(data.subject ?? ''),
      finger_sign: new FormControl(data.finger_sign ?? +false),
      name_format: new FormControl(data.name_format ?? +false),
      signature_type: new FormControl(data.signature_type ?? +false),
      remove_pdf: new FormControl(data.remove_pdf ?? +false),
      print_doc: new FormControl(data.print_doc ?? +false),
      outgoing_email: new FormControl(data.outgoing_email ?? 1),
      active_footer_email: new FormControl(data.active_footer_email ?? +false),
      footer_email: new FormControl(data.footer_email ?? '', [Validators.email]),
      active_invitation: new FormControl(data.active_invitation ?? +false),
      invitation_message: new FormControl(data.invitation_message ?? ''),
      pricing_format_amount: new FormControl(data.pricing_format_amount ?? 'sv-SE'),
      pricing_format_currency: new FormControl(data.pricing_format_currency ?? 'after_value'),
      pricing_format_example: new FormControl(data.pricing_format_example ?? '1 000,50')
    });
    const initialValue = this.companyForm.value;
    this.companyForm.valueChanges.subscribe((value: any) => {
      this.changesDetect = Object.keys(initialValue).some(
        (key) => this.companyForm.controls[key].value !=
          initialValue[key]
      );
    });
  }


 /** 
  * Convenience getter for easy access to form fields 
  */
  get form() {
    return this.companyForm.controls;
  }


  setPricingFormat($event:any){
    this.companyForm.patchValue({pricing_format_example: $event?.label})
  }


  async saveCompanySettings(){
    this.submitted = true;
    if(this.companyForm.invalid){
      return;
    }
    this.loading = true;
    const settings = await this.companyService.saveCompanySettings(this.companyForm.value);
    if(settings.status != 200){
      this.loading = false;
      this.submitted = false;
      this.globalService.toastrError(settings.data.message);
      return;
    }
    this.loading = false;
    this.submitted = false;
    this.globalService.toastrSuccess(settings.data.message);
    this.initForm(settings.data.data);
    this.changesDetect = false;
  }

}
