import { Component, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-datagroups',
  templateUrl: './datagroups.component.html',
  styleUrls: ['./datagroups.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatagroupsComponent implements OnInit {

  public pageBasic = 1;

  constructor() { }

  ngOnInit(): void {
  }



}
