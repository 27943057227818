<div class="slideout-content ">
    <div class="modalsd modal-slide-in sdfade productLibrary-right-sidebar new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <div class="add-new-user modal-content pt-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('productLibrarysidebar-sidebar')">
                    ×
                </button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title" id="exampleModalLabel">Add product</h5>
                </div>
                <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-justified productLibrary-right-sidebarTabs">
                    <li ngbNavItem>
                        <a ngbNavLink>Item settings</a>
                        <ng-template ngbNavContent>
                            <div class="px-2 py-2 productLibrary-scroll-content-container" [perfectScrollbar]>
                                <form class="">
                                    <div class="form-group">
                                        <label class="form-label" for="SKU">SKU</label>
                                        <input type="text" class="form-control" id="SKU" placeholder="SKU" name=""
                                            aria-describedby="SKU" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="Product Name">Product Name</label>
                                        <input type="text" class="form-control" id="" placeholder="Product Name" name="" />
                                    </div>
                                    <div class="form-group" *ngIf="hiddenFields">
                                        <label class="form-label" for="Amount">Amount</label>
                                        <input type="text" class="form-control" id="Amount" placeholder="Amount" name="" />
                                    </div>

                                    <div class="form-group" *ngIf="hiddenFields">
                                        <label for="customSelect">Select currency</label>
                                        <ng-select [items]="selectCurrency" bindLabel="name" [readonly]="readonly"
                                            [searchable]="searchable" placeholder="Select currency"> </ng-select>
                                    </div>
                                    <label class="form-label" *ngIf="hiddenFields" for="Cost">Cost</label>
                                    <div class=" input-group mb-1" *ngIf="hiddenFields">

                                        <input type="text" class="form-control" id="Cost" placeholder="Cost" aria-label="Cost" />
                                        <div class="input-group-append">
                                            <span class="input-group-text" id="basic-addon2">USD</span>
                                        </div>
                                    </div>

                                    <div class="form-group mb-0" *ngIf="hiddenFields">
                                        <label for="Description">Description</label>
                                        <fieldset class="form-group">
                                            <textarea class="form-control" id="Description" rows="4" placeholder="Enter your description here"></textarea>
                                        </fieldset>
                                    </div>
                                    <div class="custom-control custom-checkbox form-group" *ngIf="hiddenFields">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1" checked />
                                        <label class="custom-control-label" for="customCheck1">Product name editable</label>
                                    </div>
                                    <div class="custom-control custom-checkbox form-group" *ngIf="hiddenFields">
                                        <input type="checkbox" class="custom-control-input" id="customCheck2" checked />
                                        <label class="custom-control-label" for="customCheck2">Product price editable</label>
                                    </div>
                                    <div class="custom-control custom-checkbox form-group" *ngIf="hiddenFields">
                                        <input type="checkbox" class="custom-control-input" id="customCheck3" checked />
                                        <label class="custom-control-label" for="customCheck3">Product description
                                            editable</label>
                                    </div>

                                    <div class="form-group" *ngIf="hiddenCatagoryFields">
                                        <label class="form-label" for="Product Category">Product Category</label>
                                        <input type="text" class="form-control" placeholder="Product Category" name=""
                                            aria-describedby="Product Category" />
                                    </div>
                                    <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addCategoryBtn btn-transparent text-primary"
                                        *ngIf="hiddenFields" (click)="addCategoryField()"> Add Category</button>
                                    <hr class="mt-1 mb-2" *ngIf="hiddenFields">
                                    <div class="people-link-organization mb-2" *ngIf="hiddenFields">
                                        <div class="user-page-info">
                                            <h5 class="mb-2">Product Images</h5>
                                        </div>
                                        <div class="P-0">
                                            <div class="py-4 text-center font-medium-5 text-uppercase file-drop-zone col-md-6 col-lg-6 col-sm-12">
                                                click to upload
                                            </div>
                                        </div>
                                    </div>





                                </form>
                                <button type="button" class="btn p-0 ml-auto mt-50 btn-sm d-flex text-primary btn-transparent"
                                    (click)="showHiddenFields()">{{changeText}}</button>
                            </div>

                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Custom columns</a>
                        <ng-template ngbNavContent>
                            <div class="px-2 py-2 productLibraryCustomColumnTab-scroll-content-container" [perfectScrollbar]>
                                <h5 class="mb-0">Select from existing columns</h5>
                                <hr class="mt-75 mb-50">
                                <div class="form-group" *ngIf="hiddenCustomColumnFields">
                                    <label class="form-label" for="Field name">Field name</label>
                                    <input type="text" class="form-control" placeholder="Field name" name=""
                                        aria-describedby="Field name" />
                                </div>
                                <div class="form-group" *ngIf="hiddenCustomColumnFields">
                                    <label class="form-label" for="Field value">Field value</label>
                                    <input type="text" class="form-control" placeholder="Field value" name=""
                                        aria-describedby="Field value" />
                                </div>
                                <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addCategoryBtn btn-transparent text-primary"
                                    (click)="addCustomColumnField()"> Add new column</button>
                            </div>
                            <div class="px-2">
                                <button type="submit" class="btn btn-primary mr-1" rippleEffect>Add</button>
                                <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('productLibrarysidebar-sidebar')"
                                    rippleEffect>
                                    Cancel
                                </button>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-0"></div>
            </div>
        </div>
    </div>
</div>