<!-- Product Table Settings Modal Start -->
<ng-template #modalProductTableSetting let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Product table</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div>          
            <div class="nav-vertical">
                <ul ngbNav #navVertical="ngbNav" class="nav nav-tabs nav-left flex-column" (navChange)="applySettings()">
                    <li ngbNavItem>
                        <a ngbNavLink>Currency</a>
                        <ng-template ngbNavContent>
                        <div class="product-table-currency">
                            <p class="mb-50 content-title font-weight-500">Currency</p>
                            <div class="form-group col-lg-6 p-0">
                                <ng-select [items]="productCurrency" bindLabel="name" bindValue="code" [(ngModel)]="selectedProductCurrency" [clearable]="false" (change)="changeCurrency($event)">
                                </ng-select>
                            </div>
                        </div>
                        <div class="product-table-currency mt-2">
                            <p class="mb-50 content-title font-weight-500">Decimals</p>
                            <div class="d-flex align-items-center">
                                <p class="mb-0 mr-75 content-title">Number of decimals</p>
                                <div class="form-group col-lg-2 p-0 mb-0">
                                    <input type="number" class="form-control" id="basicInput" [placeholder]="numberOfDecimals" [value]="numberOfDecimals" max="2" min="0" (change)="changeCostDecimal($event)" />
                                </div>
                            </div>
                            <small class="mt-2 d-block">
                                Number of decimals for this product table, maximum 2 
                            </small>
                        </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Tax</a>
                        <ng-template ngbNavContent>
                        <div class="tax-radio" *ngIf="taxSettings.addTax == 1">
                            <p class="mb-1 content-title font-weight-500">Tax</p>
                            <div class="radio-box-container">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="0" [checked]="(taxSettings.totalTax == 0 ? true : false)" (change)="taxType(0)" />
                                    <label class="form-check-label" for="inlineRadio1">Line item tax</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="1" [checked]="(taxSettings.totalTax == 1 ? true : false)" (change)="taxType(1)" />
                                    <label class="form-check-label" for="inlineRadio2">Total tax</label>
                                </div>
                            </div>
                            <div class="row m-0 mt-1" *ngIf="taxSettings.totalTax == 1">
                                <div class="form-group col-lg-5 p-0 mb-0">
                                    <input type="number" class="form-control" id="taxValue" placeholder="0" [value]="taxSettings.taxValue" (keyup)="changeTaxValue($event)" (change)="changeTaxValue($event)" min="0" />
                                </div>
                                <div class="form-group col-lg-2 mb-0">
                                    <ng-select [items]="taxInCurrency" bindLabel="name" bindValue="name" [(ngModel)]="taxSettings.taxType" [clearable]="false" (change)="changeTaxType($event)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="product-tax-title mt-2" *ngIf="taxSettings.addTax == 1">
                            <p class="mb-50 content-title font-weight-500">Edit your tax title</p>
                            <p class="mb-1 d-block">You can change you tax title as (VAT, GST, HST, etc.)</p>
                            <div class="totals-switchries-container">
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="editTaxTitle" [checked]="taxSettings.editTaxTitle" (change)="allowEditTaxTitle($event)"/>
                                    <label class="custom-control-label" for="editTaxTitle"></label>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center" *ngIf="taxSettings.addTax == 0" (click)="addTax(1)">
                            <i data-feather="plus-square" [size]="16" class="ml-0 text-success"></i>
                            <p class="cursor-pointer text-success font-weight-bold d-inline m-0 pl-50">Add Tax</p>
                         </div>
                         <div class="d-flex align-items-center" *ngIf="taxSettings.addTax == 1" (click)="addTax(0)">
                            <i data-feather="minus-square" [size]="16" class="ml-0 text-danger"></i>
                            <p class="cursor-pointer text-danger font-weight-bold d-inline m-0 pl-50">Remove Tax</p>
                         </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Discount</a>
                        <ng-template ngbNavContent>
                        <div class="tax-radio">
                            <p class="mb-1 content-title font-weight-500">Discount</p>
                            <div class="radio-box-container">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option1" checked
                                    />
                                    <label class="form-check-label" for="inlineRadio3">Line item discount</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option2"
                                    />
                                    <label class="form-check-label" for="inlineRadio4">Total discount</label>
                                </div>
                            </div>
                            <div class="row m-0 mt-1">
                                <div class="form-group col-lg-5 p-0 mb-0">
                                    <input type="number" class="form-control" id="basicInput" placeholder="0" />
                                </div>
                                <div class="form-group col-lg-2 mb-0">
                                    <ng-select [items]="discountInCurrency" bindLabel="name" bindValue="id" [(ngModel)]="selectedDiscountInCurrency" [clearable]="false">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-2 align-items-center hidden">
                            <i data-feather="plus-square" [size]="16" class="ml-0 text-success"></i>
                            <p class="cursor-pointer text-success font-weight-bold d-inline m-0 pl-50">Add Discount</p>
                         </div>
                         <div class="d-flex mt-2 align-items-center">
                            <i data-feather="minus-square" [size]="16" class="ml-0 text-danger"></i>
                            <p class="cursor-pointer text-danger font-weight-bold d-inline m-0 pl-50">Remove Discount</p>
                         </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Design</a>
                        <ng-template ngbNavContent>
                        <div class="table-design-sec d-flex align-items-center">
                            <div class="product-table-design w-50">
                                <p class="mb-50 content-title font-weight-500">Header</p>
                                <div class="totals-switchries-container mb-0">
                                    <div class="custom-control custom-switch custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch15" />
                                        <label class="custom-control-label" for="customSwitch15">Show header</label>
                                    </div>
                                </div>
                            </div>
                            <div class="product-table-design w-50">
                                <p class="mb-50 content-title font-weight-500">Borders</p>
                                <div class="totals-switchries-container mb-0">
                                    <div class="custom-control custom-switch custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch16" />
                                        <label class="custom-control-label" for="customSwitch16">Vertical</label>
                                    </div>
                                    <div class="custom-control custom-switch custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch17" />
                                        <label class="custom-control-label" for="customSwitch17">Horizontal</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="mb-2 mt-2" />

                        <div class="table-design-sec">
                            <div class="product-table-design">
                                <p class="mb-50 content-title font-weight-500">Headlines</p>
                                <div class="row m-0">
                                    <div class="form-group col-lg-3 mb-0 pl-0">
                                        <label>Font size</label>
                                        <ng-select [items]="productTableFontSize" bindLabel="name" bindValue="id" [(ngModel)]="selectedProductTableFontSize" appendTo="body" [clearable]="false">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-lg-3 mb-0 pl-0">
                                        <label>Font size</label>
                                        <ng-select [items]="productTableFontStyle" bindLabel="name" bindValue="id" [(ngModel)]="selectedProductTableFontStyle" appendTo="body" [clearable]="false">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-lg-3 mb-0 pl-0">
                                        <label>background color</label>
                                        <div class="product-table-design-ele position-relative">
                                            <input class="form-control" [(colorPicker)]="color" [style.background]="" [cpOutputFormat]="'hsla'" [value]="color" />
                                            <div [style.background]="color" class="color-picker-ss"></div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3 mb-0 pl-0">
                                        <label>Font color</label>
                                        <div class="product-table-design-ele position-relative">
                                            <input class="form-control" [(colorPicker)]="color" [style.background]="" [cpOutputFormat]="'hsla'" [value]="color" />
                                            <div [style.background]="color" class="color-picker-ss"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="mb-2 mt-2" />

                        <div class="table-design-sec">
                            <div class="product-table-design">
                                <p class="mb-50 content-title font-weight-500">Title</p>
                                <div class="row m-0">
                                    <div class="form-group col-lg-4 mb-0 pl-0">
                                        <label>Font size</label>
                                        <ng-select [items]="productTableFontSize" bindLabel="name" bindValue="id" [(ngModel)]="selectedProductTableFontSize" appendTo="body" [clearable]="false">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-lg-4 mb-0 pl-0">
                                        <label>Font size</label>
                                        <ng-select [items]="productTableFontStyle" bindLabel="name" bindValue="id" [(ngModel)]="selectedProductTableFontStyle" appendTo="body" [clearable]="false">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-lg-4 mb-0 pl-0">
                                        <label>Font color</label>
                                        <div class="product-table-design-ele position-relative">
                                            <input class="form-control" [(colorPicker)]="color" [style.background]="" [cpOutputFormat]="'hsla'" [value]="color" />
                                            <div [style.background]="color" class="color-picker-ss"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="mb-2 mt-2" />

                        <div class="table-design-sec mb-4">
                            <div class="product-table-design">
                                <p class="mb-50 content-title font-weight-500">Description</p>
                                <div class="form-group mb-1 mt-1">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" [checked]="showDesccripitonCheck" class="custom-control-input" id="showDesccripiton" />
                                        <label class="custom-control-label product-table-dec-label" for="showDesccripiton">Show Description</label>
                                    </div>
                                </div>
                                <div class="row m-0">
                                    <div class="form-group col-lg-4 mb-0 pl-0">
                                        <label>Font size</label>
                                        <ng-select [items]="productTableDescriptionFontSize" bindLabel="name" bindValue="id" [(ngModel)]="selectedProductTableDescriptionFontSize" appendTo="body" [clearable]="false">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-lg-4 mb-0 pl-0">
                                        <label>Font size</label>
                                        <ng-select [items]="productTableFontStyle" bindLabel="name" bindValue="id" [(ngModel)]="selectedProductTableFontStyle" appendTo="body" [clearable]="false">
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-lg-4 mb-0 pl-0">
                                        <label>Font color</label>
                                        <div class="product-table-design-ele position-relative">
                                            <input class="form-control" [(colorPicker)]="color" [style.background]="" [cpOutputFormat]="'hsla'" [value]="color" />
                                            <div [style.background]="color" class="color-picker-ss"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Totals</a>
                        <ng-template ngbNavContent>
                        <div class="product-table-totals">
                            <p class="mb-1 content-title font-weight-500">Totals</p>

                            <div class="totals-switchries-container">
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" />
                                    <label class="custom-control-label" for="customSwitch1">Show Subtotal (below table)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch2" />
                                    <label class="custom-control-label" for="customSwitch2">Show Total (below table)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch3" />
                                    <label class="custom-control-label" for="customSwitch3">Show Quantity (below table)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch4" />
                                    <label class="custom-control-label" for="customSwitch4">Show Item Tax (below table)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch5" />
                                    <label class="custom-control-label" for="customSwitch5">Show Item Discount (below table)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch6" />
                                    <label class="custom-control-label" for="customSwitch6">Include in grand total</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch7" />
                                    <label class="custom-control-label" for="customSwitch7">Show Price (on row)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch8" />
                                    <label class="custom-control-label" for="customSwitch8">Show Quantity (on row)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch9" />
                                    <label class="custom-control-label" for="customSwitch9">Show Totals (on row)</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch10" />
                                    <label class="custom-control-label" for="customSwitch10">Add/Remove Products</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch11" />
                                    <label class="custom-control-label" for="customSwitch11">Add Rows</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch12" />
                                    <label class="custom-control-label" for="customSwitch12">Add Columns</label>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Product categories</a>
                        <ng-template ngbNavContent>
                            <div class="product-table-categories">
                                <p class="mb-50 content-title font-weight-500">Categories</p>
                                <p class="mb-1 d-block">Show products as per category seleceted</p>
                                <div class="totals-switchries-container">
                                    <div class="custom-control custom-switch custom-control-inline">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch13" />
                                        <label class="custom-control-label" for="customSwitch13">Others</label>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Permissions</a>
                        <ng-template ngbNavContent>
                        <div class="mb-2">
                            <p class="mb-75 content-title font-weight-500">Which roles are allowed to edit the product table?</p>
                            <div class="form-group col-lg-6 p-0">
                                <ng-select [items]="rolesAllowedPermission" bindLabel="name" bindValue="id" [(ngModel)]="selectedRolesAllowedPermission" [clearable]="false">
                                </ng-select>
                            </div>
                        </div>
                        <div class="product-table-totals">
                            <p class="mb-75 content-title font-weight-500">Allow product table to work on signing page</p>

                            <div class="permissions-switchries-container">
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch13" />
                                    <label class="custom-control-label" for="customSwitch13">Allow to add products</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch14" />
                                    <label class="custom-control-label" for="customSwitch14">Allow to remove products</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch15" />
                                    <label class="custom-control-label" for="customSwitch15">Allow to add new row</label>
                                </div>
                                <div class="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch16" />
                                    <label class="custom-control-label" for="customSwitch16">Allow to edit quantity</label>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="navVertical"></div>
            </div>      
        </div>
    </div>
</ng-template>
<!-- / Product Table Settings Modal End -->

<!-- Product Table Add Column Modal Start -->
<ng-template #addColumnProductTableSettings let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add new column</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="productTable-add-column-modal-body">
            <div class="form-group p-0 mb-0 box-column-first">
                <ng-select [items]="productTableAddColumn" bindLabel="name" bindValue="id" [(ngModel)]="selectedProductTableAddColumn" [clearable]="false">
                </ng-select>
            </div>
            <div class="box-column-second">
                <p class="m-0">Or</p>
            </div>
            <div class="box-column-third">
                <button type="button" class="btn btn-success" rippleEffect (click)="createColumn(modal)">Create empty</button>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Product Table Add Column Modal End -->

<!-- Product Table Add From Products Modal Start -->
<ng-template #addFromProducts let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add from products</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close-">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="productTable-add-library-modal-body">
            <div class="catalog-left-box">
                <div *ngIf="!productLibraryItemListingAppend">
                    <div class="mb-75 mt-75 px-1 addToListHeaderArea hidden">
                        <div class="addToListCheckBox">
                            <div class="custom-control custom-control-success custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck20" />
                                <label class="custom-control-label" for="customCheck20"><span>1</span> item selected</label>
                            </div>
                        </div>
                        <div class="addToListBtn">
                            <button type="button" class="btn btn-success" rippleEffect>Add to list</button>
                        </div>
                    </div>

                    <div class="input-group input-group-merge mb-75 mt-75 px-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon-search2"><i data-feather="search"></i></span>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Search products"
                            aria-label="Search products"
                            aria-describedby="basic-addon-search2"
                        />
                    </div>
    
                    <ul class="addProductItemListing_Ul">
                        <li class="addProductItemListing_Li">
                            <div class="innerBox innerBoxFirst">
                                <i class="fa fa-tag icon" aria-hidden="true"></i>
                                <div class="productItemlistCheckBox">
                                    <div class="custom-control custom-control-success custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck21" />
                                        <label class="custom-control-label" for="customCheck21"></label>
                                    </div>
                                </div>
                            </div>
                            <div class="innerBox">
                                <p class="addProductItemListing_Li_paraOne">Apple</p>
                                <p class="addProductItemListing_Li_paraTwo">55056</p>
                            </div>

                            <span (click)="goToDetailFields()">
                                <i data-feather="info" [size]="22" class="text-body icon pe-info"></i>
                            </span>
                        </li>
                    </ul>
                </div>

                <div id="productlibraryitemdetails" class="product-list-fields mt-75" *ngIf="productLibraryItemListingAppend"> 
                    <div class="mb-1 d-flex align-items-center justify-content-between">
                        <div class="breadcrumb-wrapper d-flex">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item" (click)="goToDetailFields()">
                                    <a href="javascript:void(0)" class="">Product item </a>
                                </li>
                                <li class="breadcrumb-item">
                                    <span class="">Apple</span>
                                </li>
                            </ol>
                        </div>
                        <div class="d-flex align-items-center pr-1">
                            <i data-feather="plus-square" [size]="16" class="ml-0 text-success"></i>
                            <p class="cursor-pointer text-success font-weight-bold d-inline m-0 pl-50">Add to list</p>
                        </div>
                    </div>  
                    
                    <div class="product-list-fields-container">
                        <div class="form-group px-1">
                            <label class="col-form-label pt-0 pb-0" for="basicInput">SKU</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                
                        <div class="form-group px-1">
                            <label class="col-form-label pt-0 pb-0" for="basicInput">Name</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                
                        <div class="form-group px-1">
                            <label class="col-form-label pt-0 pb-0" for="basicInput">Price</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                
                        <div class="form-group px-1">
                            <label class="col-form-label pt-0 pb-0" for="basicInput">Cost</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>

                        <div class="form-group px-1">
                            <label class="col-form-label pt-0 pb-0" for="basicInput">Description</label>
                            <textarea class="form-control" id="basicTextarea" rows="4"></textarea>
                        </div>
                    </div>

                    <div class="custom-fiels-container mb-1">
                        <label class="control-label px-1 mb-1 mt-25"> Custom fields </label>
                        <div class="row m-0">
                            <div class="col-xl-6 col-md-6 col-12 pr-75">
                                <div class="form-group">
                                    <label for="basicInput">Field name</label>
                                    <input type="text" class="form-control" id="basicInput" />
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6 col-12 pl-75">
                                <div class="form-group">
                                    <label for="basicInput">Field value</label>
                                    <input type="text" class="form-control" id="basicInput" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="catalog-right-box">
                <div id="producLibrarySelectedItemList">
                    <div class="productSelectedItemHeader listingselecteditemsdiv">
                        <span class="SelectedItemsheadertext">2 items added</span>
                        <button type="button" class="btn btn-danger productSelectedItemDeleteBtn">
                            <i data-feather="trash" [size]="14" class="mr-25"></i>
                            Delete Items
                        </button>
                    </div>

                    <ul class="addProductItemSelectedListing_Ul">
                        <li class="addProductItemSelectedListing_Li">
                            <div class="innerBox innerBoxFirst">
                                <i class="fa fa-tag icon" aria-hidden="true"></i>
                                <i data-feather="trash" [size]="18" class="ml-0 deletecurrentitefromlog"></i>
                            </div>
                            <div class="innerBox">
                                <p class="addProductItemSelectedListing_Li_paraOne">Apple</p>
                                <p class="addProductItemSelectedListing_Li_paraTwo">55056</p>
                                <p class="addFromProductLibrarySelectedItemsPrice">
                                    <span></span>15.00<span class="margin-left-5"> USD/</span>
                                </p>
                                <div class="input-group bootstrap-touchspin">
                                    <core-touchspin [color]="'success'"></core-touchspin>
                                </div>
                            </div>
                        </li>

                        <li class="addProductItemSelectedListing_Li">
                            <div class="innerBox innerBoxFirst">
                                <i class="fa fa-tag icon" aria-hidden="true"></i>
                                <i data-feather="trash" [size]="18" class="ml-0 deletecurrentitefromlog"></i>
                            </div>
                            <div class="innerBox">
                                <p class="addProductItemSelectedListing_Li_paraOne">Apple</p>
                                <p class="addProductItemSelectedListing_Li_paraTwo">55056</p>
                                <p class="addFromProductLibrarySelectedItemsPrice">
                                    <span></span>15.00<span class="margin-left-5"> USD/</span>
                                </p>
                                <div class="input-group bootstrap-touchspin">
                                    <core-touchspin [color]="'success'"></core-touchspin>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="addFromProductLibraryFooter">
                        <div class="row m-0">
                            <div class="col-md-6 pl-0">
                                <p class="addFromProductLibraryFooterItems">1 item total</p>
                                <p class="addFromProductLibraryFooterItemsPrice">30.00</p>
                            </div>
                            <div class="col-md-6">
                                <button type="button" class="btn btn-success btn-block addToProductTablePopupBtn" rippleEffect> Add to product table </button>
                            </div>
                            <div class="col-md-12 alert alert-warning alert-dismissible currencywarningdiv px-1 py-75">
                                Pricing follows the default currency&nbsp; <strong>USD</strong>. You can change currency in product table&nbsp;
                            </div>
                        </div>
                    </div>
                </div>

                <div class="noItemAddedBox hidden">
                    <i class="fa fa-tag icon" aria-hidden="true"></i>
                    <p class="paraOne">
                       No items added
                    </p>
                    <p class="paraTwo">
                       Select item(s) from the product list to add them to the product table 
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Product Table Add From Products Modal End -->