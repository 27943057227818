import { Component, OnInit, Inject, Injector, Injectable, ViewChild } from '@angular/core';
import feather from 'feather-icons';
// import * as InlineEditor from './../ckCustumBuild/build/ckeditor.js';
// import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import uuid from 'short-unique-id';
import 'jquery-ui-dist/jquery-ui';
// import 'jquery-ui/ui/widgets/sortable'
import { GlobalService } from 'app/global/global.service';
import { PopOver } from '../general-editor-tabs/popover/popover';
import { ToastrService } from 'ngx-toastr';
import { ContentTabComponent } from '../general-editor-tabs/content-tab/content-tab.component';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import ConvertApi from 'convertapi-js'
import { Blocks } from '../general-editor-tabs/blocks/blocks';
import { tagsBuilder } from '../general-editor-tabs/tags-builder/tagsBuilder';

declare var $: any;
declare var CKEDITOR: any;

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss'],
  providers: [PopOver, Blocks, tagsBuilder]
})

export class EditTemplateComponent implements OnInit {
  @ViewChild('attachmentsModal') public attachmentsModal;
  @ViewChild('uploadImageModal') public uploadImageModal;
  @ViewChild('modalProductTableSetting') public modalProductTableSetting;
  @ViewChild('addColumnProductTableSettings') public addColumnProductTableSettings;
  @ViewChild('addFromProducts') public addFromProducts;
  // public Editor = customBuild;
  public savingTemaplte = false;
  public defaultMenu = true;
  public isFeildSettingPopOver = false;
  public isFromBuilderSettings = false;
  public isPdfBlockSettings = false;
  public isContentTableSettings = false;
  public rightMenuType = 'DEFAULT';
  public fieldSettings = {};
  activatedNav: any = 1;
  public uuid = new uuid({ length: 6, dictionary: 'number' });
  public contentBuilders = [
    {
      "id": "1",
      "name": "Form Builder",
      "secondary": "Form builder",
      "type": "formbuilder"
    },
    {
      "id": "2",
      "name": "Content box",
      "secondary": "Content",
      "type": "content"
    },
    {
      "id": "3",
      "name": "Content library",
      "secondary": "Content library",
      "type": "contentlibrary"
    },
    {
      "id": "4",
      "name": "Attachment",
      "secondary": "Attachment",
      "type": "attachment"
    },
    {
      "id": "5",
      "name": "Page Break",
      "secondary": "Page break",
      "type": "pagebreak"
    },
    {
      "id": "6",
      "name": "Product Table",
      "secondary": "Product table",
      "type": "producttable"
    },
  ];
  public imageModalRef: NgbModalRef;
  public myImages = [
    { uri: './assets/images/pages/content-img-4.jpg', name: 'content-img-4' }, 
    { uri: './assets/images/pages/search-result.jpg', name: 'search-result' },
    { uri: './assets/images/pages/1-apex.png', name: '1-apex' },
    { uri: './assets/images/pages/2-stack.png', name: '2-stack' },
    { uri: './assets/images/pages/3-convex.png', name: '3-convex' },
    { uri: './assets/images/pages/4-materialize.png', name: '4-materialize' },
    { uri: './assets/images/icons/upload-image.png', name: 'upload image' },
    { uri: './assets/images/pages/graphic-1.png', name: 'graphic' },
    { uri: './assets/images/pages/graphic-5.png', name: 'graphic 2' },
    { uri: './assets/images/pages/register.jpg', name: 'register' },
    { uri: './assets/images/pages/reset-password.png', name: 'reset-password' },
  ];
  public activeImage = -1;
  public selectedImage = '';
  public loadView = false;
  public selectedattachmentFontSize = 1;
  public selectedattachmentFontStyle = 1;
  public includeExcludeDropdown = 1;
  public RequestFromDropdown = 1;
  public UploadingIsDropdown = 1;
  public selectAttachmentDropdown = 1;
  public allowMultipleCheck = true;

  includeExcludeDropdownItem = [
    { id: 1, name: 'Include in document' },
    { id: 2, name: 'Exclude from document' },
  ];

  selectAttachmentDropdownItem = [
    { id: 1, name: 'Select attachment' },
  ];

  RequestFromDropdownItem = [
    { id: 1, name: 'Anyone can upload' },
    { id: 2, name: 'Sender' },
    { id: 2, name: 'Recipient' },
  ];

  UploadingIsDropdownItem = [
    { id: 1, name: 'Optional' },
    { id: 2, name: 'Required' },
  ];

  attachmentFontSize = [
    { id: 1, name: '8' },
    { id: 2, name: '9' },
    { id: 3, name: '10' },
    { id: 4, name: '11' },
    { id: 5, name: '12' },
    { id: 6, name: '14' },
    { id: 7, name: '16' },
    { id: 8, name: '18' },
    { id: 9, name: '20' },
    { id: 10, name: '22' },
    { id: 11, name: '24' },
  ];

  attachmentFontStyle = [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'Italic' },
  ];

  rolesAllowedPermission = [
    { id: 1, name: 'Owner' },
    { id: 2, name: 'Anyone' },
    { id: 3, name: 'Recipient' },
  ];


  title = 'colorPicker';
  color: string = '#3b3b3b'
  arrayColors: any = {
    color1: '#3b3b3b',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  selectedColor: string = 'color1';

  title1 = 'colorPicker';
  color1: string = '#3b3b3b'
  arrayColors1: any = {
    color1: '#3b3b3b',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  selectedColor1: string = 'color1';

  showBackbutton = false;
  hideBackButton = '';
  constructor(
    public globalService: GlobalService,
    private PopOver: PopOver,
    private Blocks: Blocks,
    private tagsBuilder: tagsBuilder,
    private toastr: ToastrService,
    private modalService: NgbModal,
    @Inject(Injector) private readonly injector: Injector
  ) { }

  ngOnInit() {
    var that = this;
    $(document).ready(function () {
      that.intiDefaultEditor();
      $(document).off('click', 'body').on('click', 'body', event => {
        if (!$(event.target).closest('.fieldsPopover').length) {
          $('.fieldsPopover').remove()
        }

        if (!$(event.target).closest('.content-box-block').length && !$(event.target).closest('.contentBox-popover-container').length) {
          $('.contentBox-popover-container').remove();
          $('.form-builder').parents('.content-box-inner').removeClass('active-border');
          $('.content-box-inner').removeClass('active-border');
          $('.content-selected-image').removeClass('active');
        }

        // if (!$(event.target).closest('.form-built-block').length && !$(event.target).closest('.formBlock-popover-container').length) {
        //   $('.formBlock-popover-container').remove()
        // }

        if (!$(event.target).closest('.page-break-block').length && !$(event.target).closest('.pageBreak-popover-container').length) {
          $('.pageBreak-popover-container').remove()
        }

        if (!$(event.target).parents('.content-builder-menu').length) {
          $('.content-builder-menu').addClass('hidden').css({ top: 0, left: 0, position: 'fixed' });
        }
        if (!$(event.target).closest('.droplist-menu').length) {
          $('.droplist-dropdown').addClass('hidden');
        }
        if (!$(event.target).parents('.open-editor-menu').length) {
          $('.block-action-menu').addClass('hidden');
          $('.open-editor-menu').find('.block-action-menu').remove();
        }

        if (!$(event.target).closest('.tablePopover').length) {
          $('.selected_column').removeClass('selected_column');
          $('.tablePopover').remove();
        }

        if (!$(event.target).closest('.tags-list-menu').length) {
          $('.tags-list-menu').addClass('hidden');
        }
      })

      $(document).off('click', '.content-box-block').on('click', '.content-box-block', function (e) {
        e.stopPropagation();
        $('.content-box-block').removeClass('active-border');
        $('.content-selected-image').removeClass('active');
        $('.content-box-block').find('.content-box-inner').removeClass('active-border');
        $(e.target).parents('.content-box-inner').addClass('active-border');
        // $(this).addClass('active-border');
        const element = this;
        that.showContentBoxPopover(element, e.target);
      })

      $(document).off('click', '.field_settings, .field-block-settings').on('click', '.field_settings, .field-block-settings', function (e) {
        e.stopPropagation();
        var isFormBuilder = ($(this).parents('.form-builder-field-setting').length ? 1 : 0);
        var formUuid = $(this).parents('.form-builder-field-setting').attr('form-uuid');
        var uuid = $(this).parents('.fieldsPopover, .form-builder-field-setting, .contentBox-popover-container, .block-action-menu').attr('uuid');
        var isFieldBlock = ($(".drag-fields[uuid='" + uuid + "']").is('[field_block]') ? 1 : 0);
        var field_name = $(".drag-fields[uuid='" + uuid + "']").attr('field-name');
        var selected_field = $(".drag-fields[uuid='" + uuid + "']").attr('selected-field');
        that.showPopOverSettingView(true, { uuid: uuid, field_name: field_name, selected_field: selected_field, isFormBuilder: isFormBuilder, formUuid: formUuid, isFieldBlock: isFieldBlock });
        $('.block-action-menu').addClass('hidden');
      });

      $(document).off('click', '.form-builder-settings').on('click', '.form-builder-settings', function (e) {
        e.stopPropagation();
        var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
        that.showFormBuilderSettings(true, { uuid: uuid });
        $('.block-action-menu').addClass('hidden');
      });

      $(document).off('click', '.droplist-menu').on('click', '.droplist-menu', function (e) {
        $('.droplist-dropdown').addClass('hidden');
        if ($(e.target).hasClass('droplist-menu') || $(e.target).hasClass('selected-option-label')) {
          $(this).find('.droplist-dropdown').toggleClass('hidden');
        }
      });

      $(document).off('click', '.ng-option').on('click', '.ng-option', function () {
        var optionLabel = $(this).children('span').text();
        var optionValue = $(this).attr('option_uuid');
        $('.droplist-dropdown').addClass('hidden');
        $(this).parents('.droplist-menu').children('.selected-option-label').attr('option_uuid', optionValue).text(optionLabel);
        $(this).parents('.droplist-field').attr({ optionlabel: optionLabel, optionvalue: optionValue });
      });

      $(document).off('click', '.checkbox-field-box').on('click', '.checkbox-field-box', function () {
        $(this).parents('.checkbox-field').toggleClass('checkbox-prechecked', function () {
          var checked = $(this).hasClass('checkbox-prechecked') ? 1 : 0;
          $(this).attr('pre_checked', checked);
        })
      });

      $(document).off('click', '.multiChoice-box').on('click', '.multiChoice-box', function () {
        if ($(this).parents('.multichoice-field').hasClass('multiChoice-radio')) {
          $(this).parents('.multichoice-field').find('.multiChoice-item').removeClass('multiChoice-active');
          $(this).parent().addClass('multiChoice-active');
        } else if ($(this).parents('.multichoice-field').hasClass('multiChoice-checkbox')) {
          $(this).parent().toggleClass('multiChoice-active');
        }
      });

      $(document).off('click', '.clear-signature-btn').on('click', '.clear-signature-btn', function () {
        var uuid = $(this).attr('uuid');
        that.globalService.clearSignature(uuid);
      });

      $(document).on('click', '.upload-attachment-modal-setting', function (e) {
        e.preventDefault();
        that.attachmentPOpUp();
      });

      $(document).off('click', '.upload-image-modal-setting').on('click', '.upload-image-modal-setting', function () {
        var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
        setTimeout(() => {
          that.imageModalRef = that.modalService.open(that.uploadImageModal, {
            // centered: true,
            size: 'lg',
            windowClass: 'upload-image-modal',
            backdrop: 'static'
          });
          that.imageModalRef.result.then((data) => {
            that.loadView = false;
            if (data == 'add') {
              $('[uuid="' + uuid + '"] .uploadImageBox').remove();
              $('.image-box-inner[uuid="' + uuid + '"]').html(`<div class="content-selected-image"><img src="${that.selectedImage}"/></div>`);
              that.globalService.initializeContentImageResize('[uuid="' + uuid + '"] .content-selected-image', '.image-box-inner[uuid="' + uuid + '"]');
              that.activeImage = -1;
              this.selectedImage = '';
            }
          },
            (error) => {
              console.log(error, 'data');
              // on error/dismiss
            });

          that.loadView = true;
        }, 700);
        $('.block-action-menu').addClass('hidden');
      });

      $(document).off('click', '.text-tag').on('click', '.text-tag', function () {
        if ($(this).parents('.tags').attr('selectedforrole') == '0') {
          $(this).addClass('hidden');
          $(this).next('input').removeClass('hidden').focus();
        }
      });

      $(document).off('blur', '.text-tag-editor').on('blur', '.text-tag-editor', function () {
        $(this).addClass('hidden');
        $(this).prev('span.text-tag').removeClass('hidden');
      });

      $(document).off('click', '.open-editor-menu').on('click', '.open-editor-menu', function (e) {
        var uuid = $(this).attr('uuid');
        var classtype = $(this).attr('classtype');
        var contenttype = $(this).attr('contenttype');
        var element = $('.editor'+uuid);
        $('.block-action-menu').addClass('hidden');
        $('.open-editor-menu').find('.block-action-menu').remove();
        if(!$(this).hasClass('other-popover')){
          $(this).find('svg').after(that.PopOver.appendListingPopover({uuid:uuid,classType:classtype,contentType:contenttype, element:element}));
        }else{
          $(this).find('svg').after(that.PopOver.appendImageBoxListPopover({uuid:uuid,type:classtype, element:element}));
        }        
        $(this).find('.block-action-menu').removeClass('hidden');
      });

      $(document).off('click', '.pdf-block-settings').on('click', '.pdf-block-settings', function () {
        var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
        that.showPdfBlockSettings(true, { uuid: uuid });
        $('.block-action-menu').addClass('hidden');
      });

      $(document).off('change', '.pdf-block-upload').on('change', '.pdf-block-upload', async function (e) {
        var pdfBlock = '';
        if (e.target.files.length > 0) {
          try {
            // $(document.body).css('cursor','wait');
            $('.upload-pdf-loader').removeClass('hidden');
            let convertApi = ConvertApi.auth('wMNhBWbfaiRCgith');
            let params = convertApi.createParams()
            params.add('file', e.target.files[0])
            let result = await convertApi.convert('pdf', 'png', params);
            for (const url of result.files) {
              pdfBlock += that.Blocks.pdfViewBlock({ url: url.Url });
            }
            $(e.target).parent().replaceWith(pdfBlock);
            $('.pdf-block:not(.landscape-pdf)').each(function () {
              var editor_uuid = $(this).find('.content-box-inner').attr('editor-uuid');
              var get_height_key = that.uuid();
              var pdfbg = $(this).css('--pdf-background-image').replace(/^url\(['"](.+)['"]\)/, '$1');
              var img = '<img style="display:none" id="temp_div_for_getting_height_' + get_height_key + '" key="' + get_height_key + '" src="' + pdfbg + '" width="810px">';
              $("body").append(img);
              that.setPdfBlockHeightWithPDFimgSize(get_height_key, pdfbg, this);
              that.globalService.initEditor({ uuid: editor_uuid });
            });
          } catch (error) {
            // $(document.body).css('cursor','default');
            $('.upload-pdf-loader').addClass('hidden');
          }
        } else {
          $(e.target).parent().remove();
        }
      });

      $(document).off('click', '.blockbreak').on('click', '.blockbreak', function () {
        var blockbreak = $(this).parents('.page-break-block');
        var value = $(this).attr('value');
        //blockbreak.attr('break') == 1
        if (value == 1) {
          $(this).attr('value', 2);
          blockbreak.attr('break', 2);
          $(this).find('.block-action-menu-list-label p').text('Page break')
        }
        else if (value == 2) {
          $(this).attr('value', 1);
          blockbreak.attr('break', 1);
          $(this).find('.block-action-menu-list-label p').text('PDF break')
        }
        setTimeout(() => {
          $('.block-action-menu').addClass('hidden');
        }, 100);
      });

      $(document).off('contextmenu', '.cke_editable').on('contextmenu', '.cke_editable', function (e) {
        var instance_name = $(this).attr('instance_name');
        if ($(this).hasClass('converted-to-table')) {
          $('.selected_column').removeClass('selected_column');
          $(e.target).addClass('selected_column');
          $('.tablePopover').remove();
          $('body').append(that.PopOver.tablePopover({ top: e.pageY, left: e.pageX, position: 'fixed' }));
          return false;
        }
        that.tagsBuilder.showTagList({ top: e.pageY - 13, left: e.pageX, position: 'fixed' }, instance_name)
        return false;
      });

      $(document).off('click', '.convert-to-table').on('click', '.convert-to-table', function (e) {
        e.stopPropagation();
        var currenctUuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
        var container = $('.editor' + currenctUuid);
        var instance_name = container.attr('instance_name');
        CKEDITOR.instances[instance_name].setData(that.Blocks.convertToTable({ uuid: currenctUuid }));
        container.removeClass('cke-editor-placeholder');
        container.addClass('converted-to-table');
        $('.block-action-menu').addClass('hidden');
        $('.block-action-menu[uuid="' + currenctUuid + '"] .convert-to-table').addClass('hidden');
        $('.block-action-menu[uuid="' + currenctUuid + '"] .content-table-settings').removeClass('hidden');
        that.globalService.initializColumnResizer($("[editor-uuid=" + currenctUuid + "] table"));
      });

      $(document).off('click', '.content-table-settings').on('click', '.content-table-settings', function (e) {
        e.stopPropagation();
        var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
        that.showContentTableSettings(true, { uuid: uuid });
        $('.block-action-menu').addClass('hidden');
      });

      $(document).off('click', '.product-table-settings').on('click', '.product-table-settings', function (e) {
        var uuid = $(this).parents('.block-action-menu').attr('uuid');
        that.showProductTableSettings(true, { uuid: uuid });
      });

      $(document).off('click', '.content-background-image').on('click', '.content-background-image', function(){
          var uuid = $(this).parents('.block-action-menu').attr('uuid');
          $('#content-background-image').attr('uuid',uuid).trigger('click');   
      });

      $(document).off('change', '#content-background-image').on('change', '#content-background-image', async function (e) {
        var uuid = $(this).attr('uuid');
        $('.block-action-menu').addClass('hidden');
        if (e.target.files.length > 0) {
          try {
            $('.upload-pdf-loader').removeClass('hidden');
            let convertApi = ConvertApi.auth('wMNhBWbfaiRCgith');
            let params = convertApi.createParams()
            params.add('file', e.target.files[0]);
            let result = await convertApi.convert('png', 'png', params);
            $('.editor'+uuid).parents('.content-editor-block').addClass('has-background-image').css('--content-background-image', `url(${result.files[0].Url})`);
            $('.upload-pdf-loader').addClass('hidden');
            $('.block-action-menu[uuid="' + uuid + '"] .content-background-image').addClass('hidden');
            $('.block-action-menu[uuid="' + uuid + '"] .remove-content-background-image').removeClass('hidden');
          }
          catch(error){
            $('.upload-pdf-loader').addClass('hidden');
          }
        }else{
        
        }
      });
      $(document).off('click', '.remove-content-background-image').on('click', '.remove-content-background-image', function(){
          var uuid = $(this).parents('.block-action-menu').attr('uuid');
          $('.block-action-menu').addClass('hidden');
          $('.editor'+uuid).parents('.content-editor-block').removeClass('has-background-image').css('--content-background-image', ``);
          $('.block-action-menu[uuid="' + uuid + '"] .content-background-image').removeClass('hidden');
          $('.block-action-menu[uuid="' + uuid + '"] .remove-content-background-image').addClass('hidden');
      });
      that.contentTab.initEeventList();
    })
  }


  setPdfBlockHeightWithPDFimgSize(uuid: any, pdfbg: any, currentElem: any) {
    var img = new Image();
    img.onload = function () {
      var pdfbg_height = $("#temp_div_for_getting_height_" + uuid).height();
      //if (pdfbg_height < 1000) {
        $(currentElem).find('.pdfbg').css("height", pdfbg_height + "px");
        $(currentElem).find(".pdf-height").css("min-height", pdfbg_height + "px");
      //}
      $(currentElem).addClass("landscape-pdf");
      $("#temp_div_for_getting_height_" + uuid).remove();
      // $(document.body).css('cursor','default');
      $('.upload-pdf-loader').addClass('hidden');
    }
    img.src = pdfbg;
  }

  intiDefaultEditor() {
    var that = this;
    that.toastr.toastrConfig.preventDuplicates = true;
    var count_id = that.uuid();
    const myElement = document.querySelector('.template-page')
    const editorinstance = document.createElement('div')
    editorinstance.className = 'content-box content-box-block content-editor-block marginminus70'
    editorinstance.innerHTML = `<div class="sort-helper">
    <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
    <div class="highlightSortContentBox">
        <p>Content Box</p>
        <svg viewBox="0 0 24 24" role="presentation"><path d="M4 4h8v2H9v6H7V6H4V4zM4 16V14h16v2l-16 .001zM4 20v-2h16v2H4zm9-8v-2h7v2h-7z" fill-rule="evenodd"></path></svg>
    </div></div>
    <div class="content-box-row"><div class="rowItem">
    ${that.PopOver.listingPopover({ classType: 'content-box', uuid: count_id })}
    <div class="sort-helper-ss"><div class="sort-helper sort-helper-inner">
    <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
    <div class="highlightSortContentBox">
      <p>Content Box</p>
      <svg viewBox="0 0 24 24" role="presentation"><path d="M4 4h8v2H9v6H7V6H4V4zM4 16V14h16v2l-16 .001zM4 20v-2h16v2H4zm9-8v-2h7v2h-7z" fill-rule="evenodd"></path></svg>
    </div>
    </div></div><div class="content-box-inner" editor-uuid="${count_id}" type="content box"><div class="editor${count_id} cke-editor-placeholder cke-editor-box" editor-uuid="${count_id}" contenteditable="true" cke-placeholder="Type / for quick actions"><div></div></div></div>`
    myElement.appendChild(editorinstance)
    that.globalService.initEditor({ uuid: count_id });
    this.globalService.contentBoxSort()
  }

  showContentBoxPopover(element, target) {
    var targetContent = ($(target).hasClass('restricted_box') ? $(target) : $(target).parents('.content-box-inner'));
    var uuid = targetContent.attr('editor-uuid');
    var instance_name = targetContent.find('.cke_editable').attr('instance_name');
    var contentType = '';
    var contentBox = '';
    var innerPosition = { left: 0, top: 0 };
    var boxWidth = 0;
    // || $(element).hasClass('pdf-block')
    if ($(element).hasClass('form-builder-block') || $(target).parents('.content-field-inner').length) {
      contentType = 'settings';
    }
    $('.contentBox-popover-container').remove();
    if ($(target).parents('.image-box-inner, .attachment-box-inner').length && !$(target).hasClass('restricted_box')) {
      uuid = $(target).parents('.image-box-inner, .attachment-box-inner').attr('uuid');
      var type = $(target).parents('.image-box-inner, .attachment-box-inner').attr('type');
      boxWidth = $(target).parents('.image-box-inner, .attachment-box-inner').width() / 2;
      innerPosition = $(target).parents('.image-box-inner, .attachment-box-inner').offset();
      //contentBox = this.PopOver.imageBoxPopover({ uuid: uuid, instance_name: instance_name, contentType: contentType, type: type, element: $(element), target: $(target) });
      $('.content-box-block').removeClass('active-border');
      $('.content-selected-image').removeClass('active');
      $('[uuid="' + uuid + '"] .content-selected-image').addClass('active');
    } else if ($(target).parents('.content-box-inner').length && !$(target).hasClass('restricted_box')) {
      boxWidth = $(target).parents('.content-box-inner').width() / 2;
      innerPosition = $(target).parents('.content-box-inner').offset();
      //contentBox = this.PopOver.contentBoxPopover({ uuid: uuid, instance_name: instance_name, contentType: contentType, element: $(element), target: $(target) });
    } else if ($(target).hasClass('restricted_box')) {
      boxWidth = $(target).width() / 2;
      innerPosition = $(target).offset();
      //contentBox = this.PopOver.contentBoxPopover({ uuid: uuid, instance_name: instance_name, contentType: contentType, element: $(element), target: $(target) });
    }
    $('body').append(contentBox);
    const getContentBoxPopoverHeight = $('.contentBox-popover-container').height() + 5;
    $('.contentBox-popover-container').css({ 'top': (innerPosition?.top - getContentBoxPopoverHeight), left: (innerPosition?.left + boxWidth) });

    if ($(element).hasClass('pdf-block')) {
      this.showPdfBlockSettings(true, { uuid: uuid });
    }
  }

  closeExternalRightMenu(){
    this.rightMenuType = 'DEFAULT';
  }

  showPopOverSettingView(boolean, fieldSettings: any = {}) {
    // this.isFromBuilderSettings = false;
    // this.defaultMenu = false;
    // this.isFeildSettingPopOver = boolean;
    this.rightMenuType = 'POPOVERSETTINGMENU';
    this.fieldSettings = fieldSettings;
    this.activatedNav = this.activatedNav;
  }

  closePopOverSettingView(boolean, fieldSettings: any = {}) {
    // this.defaultMenu = true;
    // this.isFeildSettingPopOver = boolean;
    this.rightMenuType = 'DEFAULT';
  }

  showFormBuilderSettings(boolean, fieldSettings: any = {}) {
    // this.defaultMenu = false;
    // this.isFeildSettingPopOver = false;
    // this.isFromBuilderSettings = boolean;
    this.rightMenuType = 'FORMBUILDERSETTINGMENU';
    this.fieldSettings = fieldSettings;
  }

  closeFormBuilderSettings(boolean) {
    // this.defaultMenu = true;
    // this.isFromBuilderSettings = boolean;
    this.rightMenuType = 'DEFAULT';
  }

  showPdfBlockSettings(boolean, fieldSettings: any = {}) {
    // this.defaultMenu = false;
    // this.isFeildSettingPopOver = false;
    // this.isFromBuilderSettings = false;
    // this.isPdfBlockSettings = boolean;
    this.rightMenuType = 'PDFSETTINGMENU';
    this.fieldSettings = fieldSettings;
  }

  closePdfBlockSettings(boolean) {
    // this.defaultMenu = true;
    // this.isFeildSettingPopOver = false;
    // this.isFromBuilderSettings = false;
    // this.isPdfBlockSettings = boolean;
    this.rightMenuType = 'DEFAULT';
  }

  showContentTableSettings(boolean, fieldSettings: any = {}) {
    // this.defaultMenu = false;
    // this.isFeildSettingPopOver = false;
    // this.isFromBuilderSettings = false;
    // this.isContentTableSettings = boolean;
    this.rightMenuType = 'CONTENTTABLESETTINGMENU';
    this.fieldSettings = fieldSettings;
  }

  closeContentTableSettings(boolean) {
    // this.defaultMenu = true;
    // this.isFeildSettingPopOver = false;
    // this.isFromBuilderSettings = false;
    // this.isContentTableSettings = boolean;
    this.rightMenuType = 'DEFAULT';
  }

  showProductTableSettings(boolean, fieldSettings: any = {}) {
    // this.defaultMenu = false;
    // this.isFeildSettingPopOver = false;
    // this.isFromBuilderSettings = false;
    // this.isContentTableSettings = boolean;
    this.rightMenuType = 'PRODUCTTABLESETTINGMENU';
    this.fieldSettings = fieldSettings;
  }

  documentPirceSettings() {
    this.showPopOverSettingView(true, { uuid: 0, field_name: 'Price settings', selected_field: 'document_price' });
  }

  private get contentTab() {
    return this.injector.get(ContentTabComponent);
  }

  closeAttachmentModal(action: any) {
    this.imageModalRef.close(action);
  }

  selectImage(image, index) {
    this.selectedImage = image.uri;
    this.activeImage = index;
  }

  attachmentPOpUp() {
    this.imageModalRef = this.modalService.open(this.attachmentsModal, {
      size: 'lg',
      windowClass: 'attachment-upload-modal',
      backdrop: 'static'
    });
  }

  detectChildChanges(event: any) {
    if (event.viewType == 'detail') {
      this.showBackbutton = true;
    }
  }

  backToRecipientView() {
    this.showBackbutton = false;
    this.hideBackButton = 'hide';
    document.getElementById('backToRecipientView').click()
  }

  menuChange(event: any) {
    this.showBackbutton = false;
    this.hideBackButton = 'hide';
  }

  saveTemplate(){
    var that = this;
    that.savingTemaplte = true;
    setTimeout(() => {
      localStorage.setItem('template',$('.template-page').prop("innerHTML").replaceAll('\n',''));
      that.savingTemaplte = false;
    }, 1500);
  }

}

const getCaretCoordinates = () => {
  let x, y;
  const selection = window.getSelection();
  if (selection.rangeCount !== 0) {
    const range = selection.getRangeAt(0).cloneRange();
    range.collapse(false);
    const rect = range.getClientRects()[0];
    if (rect) {
      x = rect.left;
      y = rect.top;
    }
  }
  return { x, y };
};