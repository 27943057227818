<div class="card addonBoxContainer">
    <div class="card-body">
        <h5>Schoolsoft Integration</h5>
        <p class="card-text font-small-3 addonboxDiscription">Send your documents to as many people as you want.</p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'send'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>