<div class="d-flex justify-content-between align-items-center content-sidebar-header py-75 px-1 hidden">
    <div class="d-flex justify-content-start align-items-center">
       <div class="user-page-info">
          <h5 class="mb-0"> Settings </h5>
       </div>
    </div>
    <div>

    </div>
</div>

<div class="setting-tab-scroll" [perfectScrollbar]>
    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Convert to e-form</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Settings</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="convertToEform" />
                <label class="custom-control-label" for="convertToEform"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Activate credit safe services</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Settings</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="activeCreditSafeService" />
                <label class="custom-control-label" for="activeCreditSafeService"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Share template</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">No</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="shareTemplate" />
                <label class="custom-control-label" for="shareTemplate"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Import retargeting code</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Edit code settings</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="importRetargetingCode" />
                <label class="custom-control-label" for="importRetargetingCode"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Activate BCC recipients</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Manage emails</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="activeBbcRecipients" />
                <label class="custom-control-label" for="activeBbcRecipients"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Save a copy to Simple Storage</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Select folder</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="selectFolder" />
                <label class="custom-control-label" for="selectFolder"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Template redirect URL</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Off</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="templateRedUrl" />
                <label class="custom-control-label" for="templateRedUrl"></label>
            </div>
        </div>
    </div>
</div>