<div class="content-wrapper editContentLibrary-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="editContentLibPage-container">
                    <!-- app-breadcrumb component -->
                    <app-breadcrumb [breadcrumb]="breadcrumbeditContentLibrary" class="breadcrumbeditContentLibrary"></app-breadcrumb>
                    <!--/ app-breadcrumb component -->

                    <!-- editContentLibPage -->
                    <div class="editContentLibPage"></div>
                    <!-- editContentLibPage -->

                    <!-- editContentLibrightmenu -->
                    <app-content-library-right-menu></app-content-library-right-menu>
                    <!-- editContentLibrightmenu -->
                </div>
            </div>
        </div>
    </div>
</div>