import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from 'app/layout/components/content-header/breadcrumb/breadcrumb.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaService } from 'ng2-dragula';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';


@Component({
  selector: 'app-contract-control-detail-view',
  templateUrl: './contract-control-detail-view.component.html',
  styleUrls: ['./contract-control-detail-view.component.scss']
})
export class ContractControlDetailViewComponent implements OnInit {

  public breadcrumbContractControl: Breadcrumb;
  public showReportBasic = true;

   // modal Open Form
   addCetagoryModalOpenClick(addCetagoryModal) {
    this.modalService.open(addCetagoryModal);
  }
  // modal Open Form
  addCompanyModalOpenClick(addCompanyModal) {
    this.modalService.open(addCompanyModal);
  }
  // modal Open Form
  CC_AudittrailModalOpen(CC_AudittrailModal) {
    this.modalService.open(CC_AudittrailModal);
  }
  // modal Open Form
  CC_IndexDataModalOpen(CC_indexDataModal) {
    this.modalService.open(CC_indexDataModal,{
      size: 'lg'
    });
  }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  constructor(private _coreSidebarService: CoreSidebarService,private modalService: NgbModal,private dragulaService: DragulaService) {
    // Drag And Drop With Handle
   
   }

 
ngOnInit(): void {
    // breadcrumb default
    this.breadcrumbContractControl = {
      links: [
        {
          name: 'All Contract',
          isLink: true,
          link: '/contract-control', 
        },
        {
          name: 'Contract name',
          isLink: false,
        },
      ]
    };
    // this.dragulaService.createGroup('handle-list', {
    //   moves: function (el, container, handle) {
    //     return handle.classList.contains('handle');
    //   }
    // });
  
    // this.dragulaService.createGroup('badge-list-copy', {
    //   copy: true
    // });
  }



}
