<div class="content-wrapper bulksendInnerView-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="bulksendInnerViewPage-container">
                    <!-- app-breadcrumb component -->
                    <app-breadcrumb [breadcrumb]="breadcrumbBulksend" class="breadcrumbBulksendInnerView"></app-breadcrumb>
                    <!--/ app-breadcrumb component -->

                    <!-- bulkSendInnerLeftContentPage -->
                    <div class="bulkSendInnerLeftContentPage">
                        <div class="addBulkSendRightContent text-center px-2 py-2">
                            <button type="button" class="btn btn-flat-success" rippleEffect>Add row</button>
                            <div class="divider">
                                <div class="divider-text">OR</div>
                            </div>
                            <p>Select a file to upload (CSV Only)</p>
                            <button type="button" class="btn btn-gradient-success">Select CSV</button>
                        </div>
                        <!-- <div class="table-responsive">
                        
                            <table class="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="form-group mb-0">
                                                <label for="customSelect">Choose template</label>
                                                <ng-select [items]="choosetemplate" container="body" bindLabel="name" [readonly]="readonly"
                                                    [searchable]="searchable" placeholder="Choose template">
                                                </ng-select>
                                            </div>
                                        </th>
                                        <th>TOTAL ROWS</th>
                                        <th>Created date</th>
                                        <th class="align-items-center justify-content-end text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="tableDataRow">
                                        <td>
                                            <span class="">Demo name</span>
                                        </td>
                                        <td>
                                            <span class="">5</span>
                                        </td>
                                        <td>
                                            <span class="">2022 Dec 292:13PM</span>
                                        </td>
                                        <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                            <div ngbDropdown container="body">
                                                <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle
                                                    data-toggle="dropdown">
                                                    <i data-feather="more-vertical"></i>
                                                </button>
                                                <div ngbDropdownMenu class="dropdown-menu">
                                                    <a ngbDropdownItem href="javascript:void(0);"><i data-feather="trash"
                                                            class="mr-50"></i>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>



                                </tbody>
                            </table>

                        </div> -->
                    </div>
                    <!-- bulkSendInnerLeftContentPage -->

                    <!-- bulkSendInnerViewrightmenu -->
                    <div class="content-wrapper bulkSendInnerRightMenu-wrapper container-xxl p-0">
                        <div class="content-body">
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <div class="bulkSendInnerRightMenu-container card">
                                        <div class="p-1 border-bottom">
                                            <button type="button" class="btn btn-success btn-block">Save changes</button>
                                        </div>
                                        <div class="p-1 editContentLibRightMenu-fields">
                                            <div class="form-group mb-75">
                                                <label class="position-relative w-100" for="SMSOriginator">List Name
                                                    <i class="feather icon-alert-circle bulksendRightMenuInfoIcon"
                                                        placement="top" container="body" placement="auto" ngbTooltip="Create the roles for this content block. These roles will be available for each element you add to this content box. When you import this content box into a template, and these roles exist on that template, they will automatically be mapped."></i>
                                                </label>
                                                <input type="text" class="form-control" id="" placeholder="List Name" />
                                            </div>
                                            <div class="form-group mb-75">
                                                <label class="position-relative w-100" for="SMSOriginator">Role
                                                        <i class="feather icon-alert-circle bulksendRightMenuInfoIcon"
                                                        placement="top" container="body" placement="auto" ngbTooltip="Create the roles for this content block. These roles will be available for each element you add to this content box. When you import this content box into a template, and these roles exist on that template, they will automatically be mapped."></i>
                                                </label>
                                                <input type="text" class="form-control" id="" placeholder="Add bulk role" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- bulkSendInnerViewrightmenu -->
                </div>
            </div>
        </div>
    </div>
</div>