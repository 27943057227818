import { Injectable, Inject, Injector } from '@angular/core';
import feather from 'feather-icons';
import uuid from 'short-unique-id';
@Injectable({
    providedIn: 'root',
})
export class Fields {
    public uuid = new uuid({ length: 6, dictionary: 'number' });
    /**
     * text field html append into ckeditor
     * @param params 
     */
    textFeildHtml(params: any = {}) {
        return `<div style="top:${params.top}px;left:${params.left}px;" selected-field="1" field-name="Text Field" field="text-field" class="drag-fields text-field resize-e draggable content-draggable-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" allow_multi_lines="0" selectedtype="full_name" selecttypetext="Full name" selectedtypeid="1" selectedforrole="0">
                    <label class="recipient-role">${String('Sender').substring(0,3).toLocaleUpperCase()}</label>
                    <div class="drag-fields-inner-container text-field-inner-container fields-ele-container">
                        <label class="field-style-label selected-label">Full name</label>
                        <!--<span class="field-label selected-label hidden">Full name</span>-->
                        <input type="text" class="text-field-input field-label" placeholder="Full name">
                        <textarea class="hidden text-field-textarea" placeholder="Full name"></textarea>
                    </div>
                </div>`;
    }
    /**
     * checkbox field html append into ckeditor
     * @param params 
     */
    checkboxFeildHtml(params: any = {}) {
        return `<div style="top:${params.top}px;left:${params.left}px;" selected-field="2" field-name="Checkbox" field="checkbox-field" class="drag-fields checkbox-field draggable content-draggable-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" pre_checked="0" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedforrole="0">
                <label class="recipient-role">${String('Sender').substring(0,3).toLocaleUpperCase()}</label>
                <div class="drag-fields-inner-container checkbox-field-inner-container fields-ele-container">
                    <div class="checkbox-field-box">
                        ${feather.icons.check.toSvg({ class: 'cursor-pointer checkbox-checkIcon', width: 14, height: 14 })}
                    </div>
                    <span class="field-label checkbox-field-label selected-label">New field</span>
                </div>
            </div>
        `;
    }

    /**
     * checkbox field html append into ckeditor
     * @param params 
     */
    droplistFeildHtml(params: any = {}) {
        var uuid = params.uuid;
        return `<div style="top:${params.top}px;left:${params.left}px;" class="drag-fields resize-e draggable droplist-field content-draggable-field" selected-field="3" field-name="Droplist" field="droplist-field" uuid="${uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedforrole="0">
                <label class="recipient-role">${String('Sender').substring(0,3).toLocaleUpperCase()}</label>
                <div class="drag-fields-inner-container droplist-field-inner-container droplist-menu fields-ele-container">
                    <span class="field-label selected-label selected-option-label" droplistplaceholder="Select option">Select option</span>
                    <!--<script id="${uuid}" type="text/json">${JSON.stringify([])}</script>-->
                    <div id="${uuid}" data-options='${JSON.stringify([])}'></div>
                    <ng-dropdown-panel role="listbox" aria-label="Options list" class="ng-dropdown-panel ng-select-bottom droplist-dropdown hidden">
                        <div class="ng-dropdown-panel-items scroll-host">
                            <div class="ng-option not-found pointer-none" option_uuid="0">
                                <span class="ng-option-label">No option found</span>
                            </div>
                        </div>
                    </ng-dropdown-panel>
                </div>
            </div>
        `;
    }

    /**
     * checkbox field html append into ckeditor
     * @param params 
     */
    datepickerFeildHtml(params: any = {}) {
        return `<div style="top:${params.top}px;left:${params.left}px;" class="drag-fields resize-e draggable datepicker-field content-draggable-field" selected-field="4" field-name="Datepicker" field="datepicker-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedforrole="0">
                <label class="recipient-role">${String('Sender').substring(0,3).toLocaleUpperCase()}</label>
                <div class="drag-fields-inner-container datepicker-field-inner-container fields-ele-container">
                    <label class="field-style-label selected-label">New field</label>
                    <!--<span class="field-label">New field</span>-->
                    <input type="text" class="text-field-input field-label" placeholder="New field">
                </div>
            </div>
        `;
    }


    /**
     * signature field html append into ckeditor
     * @param params 
     */
    signatureFeildHtml(params: any = {}) {
        return `<div style="top:${params.top}px;left:${params.left}px;" selected-field="5" field-name="Signature" field="signature-field" class="drag-fields resize-e draggable signature-field content-draggable-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" selectedforrole="0">
                <label class="recipient-role">${String('Sender').substring(0,3).toLocaleUpperCase()}</label>
                <div class="drag-fields-inner-container signature-field-inner-container fields-ele-container">
                    <span class="field-label" selected-label>Signature</span>
                </div>
            </div>
        `;
    }

    /**
     * general popover for all fields
     * @param params 
     */
    fieldPopOver(params: any = {}) {
        return `<div class="${params.class}-popover-container fieldsPopover fieldsSetting-popover-container">
                ${feather.icons.sliders.toSvg({ class: 'cursor-pointer field_settings', width: 16, height: 16 })}
                ${feather.icons.trash.toSvg({ class: 'cursor-pointer remove_field', width: 16, height: 16 })}
            </div>`
    }

    /**
     * multi choice field html append into ckeditor
     * multiChoice-active
     * @param params 
     */
    multiChoiceFeildHtml(params: any = {}) {
        var uuid = params.uuid;
        var defaultOptions = [{key:'Option 1',value:this.uuid()},{key:'Option 2',value:this.uuid()}];
        var options = '';
        for (const option of defaultOptions) {
            options += `
                    <div class="multiChoice-item" option_uuid="${option.value}">
                        <div class="multiChoice-box">
                            <div class="multiChoice-inner-circle"></div>
                            ${feather.icons.check.toSvg({ class: 'cursor-pointer multiChoice-inner-check', width: 14, height: 14 })}
                        </div>
                        <label class="multiChoice-label-text">${option.key}</label>
                    </div>`;
        }
        return `<div style="top:${params.top}px;left:${params.left}px;" selected-field="6" field-name="List" field="multichoice-field" class="drag-fields resize-e draggable multichoice-field multiChoice-radio content-draggable-field" uuid="${uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" multi_choice="0" hide_label="0" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedforrole="0">
                <label class="recipient-role">${String('Sender').substring(0,3).toLocaleUpperCase()}</label>
                <span class="field-label selected-label">Select Option</span>
                <!--<script id="${uuid}" type="text/json">${JSON.stringify(defaultOptions)}</script>-->
                <div id="${uuid}" data-options='${JSON.stringify(defaultOptions)}'></div>
                <div class="multiChoice-item-container fields-ele-container">
                    ${options}
                </div>
            </div>
        `;
    }

    /**
     * singal multi choice field html append into ckeditor
     * multiChoice-active
     * @param params 
     */
    appendMultiChoiceField(params: any = {}) {
        return `
            <div class="multiChoice-item" option_uuid="${params.uuid}">
                <div class="multiChoice-box">
                    <div class="multiChoice-inner-circle"></div>
                    ${feather.icons.check.toSvg({ class: 'cursor-pointer multiChoice-inner-check', width: 14, height: 14 })}
                </div>
                <label class="multiChoice-label-text">${params.key}</label>
            </div>`
    }

    /**
     * singal droplist field html append into ckeditor
     * multiChoice-active
     * @param params 
     */
    appendDroplistField(params: any = {}) {
        return `
            <div class="ng-option" option_uuid="${params.uuid}">
                <span class="ng-option-label">${params.key}</span>
            </div>`;
    }
    
}