import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-pipedrive-addon',
  templateUrl: './pipedrive-addon.component.html',
  styleUrls: ['./pipedrive-addon.component.scss'],
 encapsulation: ViewEncapsulation.Emulated,

})
export class PipedriveAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
