<div class="card-header">
  <h4 class="card-title">Most Active Users</h4>
</div>
<div class="card-body">
  <div class="transaction-item">
    <div class="media align-items-center">
      <div class="avatar bg-light-success rounded">
        <div class="avatar-content">
          <i class="feather font-medium-3 icon-users"></i>
        </div>
      </div>
      <div class="media-body">
        <h6 class="transaction-title">John Doe</h6>
      </div>
    </div>
    <div><small class="text-muted">3</small></div>
  </div>
  <div class="transaction-item">
    <div class="media align-items-center">
      <div class="avatar bg-light-success rounded">
        <div class="avatar-content">
          <i class="feather font-medium-3 icon-users"></i>
        </div>
      </div>
      <div class="media-body">
        <h6 class="transaction-title">Louisa Norton</h6>
      </div>
    </div>
    <div><small class="text-muted">2</small></div>
  </div>
  <div class="transaction-item">
    <div class="media align-items-center">
      <div class="avatar bg-light-success rounded">
        <div class="avatar-content">
          <i class="feather font-medium-3 icon-users"></i>
        </div>
      </div>
      <div class="media-body">
        <h6 class="transaction-title">Helena Payne</h6>
      </div>
    </div>
    <div><small class="text-muted">10</small></div>
  </div>
</div>
