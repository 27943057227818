import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
@Component({
  selector: 'app-recipientfields',
  templateUrl: './recipientfields.component.html',
  styleUrls: ['./recipientfields.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'RecipientFieldMainContainer' }
})

export class RecipientfieldsComponent implements OnInit {

  public recipientRoleForm: FormGroup;
  public pageBasic = 1;
  public readonly = false;
  public searchable = false;
  public selectedInvitationthrough = 1;
  public invitationthrough = [
    { id: 1, name: 'Email' },
    { id: 2, name: 'Email and SMS' },
    { id: 3, name: 'POS' },
    { id: 4, name: 'In-meeting' },
    { id: 5, name: 'Get Link' },
    { id: 6, name: 'SMS' }
  ];
  public selectedInvitationOrder = 1;
  public invitationOrder = [
    { id: 1, name: 2 }
  ];
  public selectedAuthentication = 1;
  public authentication = [
    { id: 1, name: 'E-signature' },
    { id: 2, name: 'BankID' },
    { id: 3, name: 'E-sign & SMS PIN' },
    { id: 4, name: 'Read only' },
    { id: 5, name: 'Attest' },
    { id: 6, name: 'Norwegian BankID' },
    { id: 7, name: 'Danish BankID' },
    { id: 8, name: 'Finnish BankID' },
    { id: 9, name: 'Danish MitID' }
  ];
  public selectedConfirmation = 1;
  public confirmation = [
    { id: 1, name: 'Email' },
    { id: 2, name: 'Email and SMS' },
    { id: 3, name: 'No confirmation' }
  ];
  public authenticationRequired = true;
  /**
  * Constructor
  *
  * @param {TodoService} _todoService
  * @param {CoreSidebarService} _coreSidebarService
  */
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
  ) { }

  /**
   * Toggle Sidebar
   *
   * @param nameRef
   */
  createNewTodo(nameRef, closeNameRef): void {
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
    this._coreSidebarService.getSidebarRegistry(closeNameRef).toggleOpen();
  }
  /**
  * Toggle Sidebar
  *
  * @param nameRef
  */
  toggleSidebar(nameRef): void {
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
  }

  // modal Open Form
  addPersonFieldModalOpen(addPersonFieldModal) {
    this.modalService.open(addPersonFieldModal);
  }

  addOrgFieldModalOpen(addOrgFieldModal) {
    this.modalService.open(addOrgFieldModal);
  }

  ngOnInit(): void {
    this.initForm({});
  }

  initForm(data: any) {
    this.recipientRoleForm = this._formBuilder.group({
      id: [(data.id ?? '')],
      role_name: [data.role_name ?? 'Recipient 1'],
      invitation_type : [data.invitation_type ?? this.selectedInvitationthrough],
      order_type: [data?.order_type ?? this.selectedInvitationOrder],
      authentication_type: [data.authentication_type ?? this.selectedAuthentication],
      authentication_required: [data.authentication_required ?? true],
      confirmation_type: [data.confirmation_type ?? this.selectedConfirmation],
      signature_confirmation: [data.signature_confirmation],
      personfields: this._formBuilder.array([]),
      orgfields: this._formBuilder.array([])
    });

    var fields = [
      {
          is_enable : 1,
          key : 'full_name',
          name: 'Full name',
          value: '',
          required : 0,
          is_default:1
      },
      {
          is_enable : 0,
          key : 'email',
          name: 'Email',
          value: '',
          required : 0,
          is_default:1
      },
      {
          is_enable : 0,
          key : 'personal_number',
          name: 'Personal ID number',
          value: '',
          required : 0,
          is_default:1
      },
    ];
    for (const field of fields) {
      const personfeilds = this._formBuilder.group({
        is_enable:[field.is_enable],
        key:[field.key],
        name:[field.name],
        value:[field.value],
        required:[field.required],
        is_default:[field.is_default],
      });
      this.personfeilds.push(personfeilds) 
    }
  }

  /** 
  * Convenience getter for easy access to form fields 
  */
  get form() {
    return this.recipientRoleForm.controls;
  }

  get personfeilds(){
    return this.recipientRoleForm.controls["personfields"] as FormArray;
  }

  onSaveRecipientRole(){
    console.log(this.recipientRoleForm.value);
  }

}
