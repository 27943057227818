import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import uuid from 'short-unique-id';
import { productTable } from '../../general-editor-tabs/product-table/productTable';
import { GlobalService } from 'app/global/global.service';
import {currencies} from './currencies';
declare var $: any;

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-product-table-modals',
  templateUrl: './product-table-modals.component.html',
  styleUrls: ['./product-table-modals.component.scss']
})
export class ProductTableModalsComponent implements OnInit {
  @ViewChild('modalProductTableSetting') public modalProductTableSetting;
  @ViewChild('addColumnProductTableSettings') public addColumnProductTableSettings;
  @ViewChild('addFromProducts') public addFromProducts;
  public uuid = new uuid({ length: 6, dictionary: 'number' });
  public tableUuid = 0;
  public tableInstance = '';
  public productlibraryitemdetails = false;
  public productLibraryItemListingAppend = false;
  public showDesccripitonCheck = true;
  public selectedRolesAllowedPermission = 3;
  public selectedProductCurrency = 'USD';
  public selectedTaxInCurrency = '%';
  public selectedDiscountInCurrency = 1;
  public selectedProductTableFontSize = 6;
  public selectedProductTableFontStyle = 1;
  public selectedProductTableDescriptionFontSize = 5;
  public selectedProductTableAddColumn = 1;
  public numberOfDecimals = 2;
  public taxSettings = {
    totalTax:1,
    addTax:0,
    taxType: '%',
    taxValue : '',
    editTaxTitle: 0,
    applyTaxType:'percentage'
  }
  productCurrency = currencies;

  taxInCurrency = [
    { id: 1, name: '%' },
    { id: 2, name: 'USD' },
  ];

  discountInCurrency = [
    { id: 1, name: '%' },
    { id: 2, name: 'USD' },
  ];

  productTableFontStyle = [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'Italic' },
  ];

  productTableFontSize = [
    { id: 1, name: '8' },
    { id: 2, name: '9' },
    { id: 3, name: '10' },
    { id: 4, name: '11' },
    { id: 5, name: '12' },
    { id: 6, name: '14' },
    { id: 7, name: '16' },
    { id: 8, name: '18' },
    { id: 9, name: '20' },
    { id: 10, name: '22' },
    { id: 11, name: '24' },
    { id: 12, name: '26' },
    { id: 13, name: '28' },
    { id: 14, name: '30' },
  ];

  productTableDescriptionFontSize = [
    { id: 1, name: '8' },
    { id: 2, name: '9' },
    { id: 3, name: '10' },
    { id: 4, name: '11' },
    { id: 5, name: '12' },
    { id: 6, name: '14' },
    { id: 7, name: '16' },
    { id: 8, name: '18' },
    { id: 9, name: '20' },
    { id: 10, name: '22' },
    { id: 11, name: '24' },
    { id: 12, name: '26' },
    { id: 13, name: '28' },
    { id: 14, name: '30' },
  ];

  productTableAddColumn = [
    { id: 1, name: 'Select Column' },
  ];
  title = 'colorPicker';
  color: string = '#3b3b3b'
  arrayColors: any = {
    color1: '#3b3b3b',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)'
  };
  selectedColor: string = 'color1';
  constructor(
    private modalService: NgbModal,
    private productTable: productTable,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    var that = this;
    $(document).ready(function () {
      
      $(document).off('click', '.add-column-product-table').on('click', '.add-column-product-table', function (e) {
        that.tableUuid = $(this).attr('table-uuid');
        that.openAddColumnProductTableSettings();
      });

      $(document).off('click', '.addfromlib').on('click', '.addfromlib', function (e) {
        that.tableUuid = $(this).attr('table-uuid');
        that.openAddFromProducts();
      });

      $(document).off('click', '#addNewTableRows').on('click', '#addNewTableRows', function () {
        that.tableUuid = $(this).attr('table-uuid');
        const {tableDesignSettings, totalSettingsOfTable} = that.getTableSettings(that.tableUuid);
        $('table[table-uuid="' + that.tableUuid + '"] tbody:first').append(that.productTable.addRow({ uuid: that.uuid(), tableUuid: that.tableUuid, is_desc:tableDesignSettings.is_desc,totalSettingsOfTable:totalSettingsOfTable }));
        that.globalService.initializColumnResizer($('table[table-uuid="' + that.tableUuid + '"]'));
      });

      $(document).off('click', '.delete-product-row').on('click', '.delete-product-row', function () {
        that.tableUuid = $(this).parents('.item_row:first').attr('table-uuid');
        $(this).parents('.item_row:first').remove();
        that.globalService.initializColumnResizer($('table[table-uuid="' + that.tableUuid + '"]'));
      });

      $(document).off('click', '.delete-product-column').on('click', '.delete-product-column', function () {
        that.tableUuid = $(this).parents('.extra-heading:first').attr('table-uuid');
        var headingUuid = $(this).parents('.extra-heading:first').attr('heading-uuid');
        var index = $('[heading-uuid="' + headingUuid + '"]').index();
        $('[heading-uuid="'+headingUuid+'"]').closest('table').find('tr.item_row').each(function() {
            this.removeChild(this.cells[ index ]);
        });
        $('[heading-uuid="' + headingUuid + '"]').remove();
        that.globalService.initializColumnResizer($('table[table-uuid="' + that.tableUuid + '"]'));
      });
    });
  }

  goToDetailFields() {
    this.productLibraryItemListingAppend = !this.productLibraryItemListingAppend;
  }

  openProductTableSettings() {
    this.applySettings();
    this.modalService.open(this.modalProductTableSetting, {
      size: 'lg',
      windowClass: 'product-table-setting-modal',
      backdrop: 'static'
    });
  }

  applySettings(){
    const {decimalsallowed, currencysymbol, taxSettings} = this.getTableSettings(this.tableUuid);
    this.numberOfDecimals = decimalsallowed;
    this.selectedProductCurrency = currencysymbol;
    this.taxInCurrency = [
        { id: 1, name: '%' },
        { id: 2, name: currencysymbol },
    ];
    this.taxSettings = taxSettings;
  }

  openAddColumnProductTableSettings() {
    this.modalService.open(this.addColumnProductTableSettings, {
      windowClass: 'product-table-addColumn-modal',
      backdrop: 'static'
    });
  }

  openAddFromProducts() {
    this.modalService.open(this.addFromProducts, {
      size: 'lg',
      windowClass: 'product-table-addProduct-modal',
      backdrop: 'static'
    });
  }

  createColumn(modal: any) {
    modal.dismiss('Cross click');
    var uuid = this.uuid();
    var table = $('table[table-uuid="' + this.tableUuid + '"]');
    $(this.productTable.addColumn({ uuid: uuid, tableUuid: this.tableUuid })).insertBefore(table.find('.itemTotalheading'));
    var columnRowHtml = '<td heading-uuid="' + uuid + '" contenteditable="true" class="extra-cell eachtd"></td>';
    $(columnRowHtml).insertBefore(table.find('.item_total'));
    this.globalService.initializColumnResizer(table);
  }


  changeCurrency(currency:any){
    this.selectedProductCurrency = currency.code;
    $('table[table-uuid="' + this.tableUuid + '"]').attr({currencysymbol:currency.code})
    $('table[table-uuid="' + this.tableUuid + '"]').css('--currencysymbol',"'"+currency.code+"'");
    $('table[total-table-uuid="' + this.tableUuid + '"]').css('--currencysymbol',"'"+currency.code+"'");
    const {taxSettings} = this.getTableSettings(this.tableUuid);
    if(taxSettings.taxType != '%'){
      this.updateTaxSettings('taxType',this.selectedProductCurrency);
    }
  }

  changeCostDecimal(decimal:any){
    var that = this;
    $('table[table-uuid="' + this.tableUuid + '"]').attr('decimalsallowed',decimal.target.value);
    for (let index = 0; index < document.querySelectorAll('table[table-uuid="' + this.tableUuid + '"] tbody tr.item_row').length; index++) {
      var tr = document.querySelectorAll('table[table-uuid="' + this.tableUuid + '"] tbody tr.item_row')[index];
      var currentitemprice = $(tr).find('td.item_price:first').text();
      var returnDecimal = that.convertocaleStringFormat($('table[table-uuid="' + this.tableUuid + '"]'),currentitemprice,2);
      $(tr).find('td.item_price:first').text(returnDecimal);
    }
  }

  addTax(value){
    this.updateTaxSettings('addTax',value);
    if(value == 1){
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').removeClass('hidden');
    }else {
      $('table[table-uuid="' + this.tableUuid + '"]').attr('taxsettings',JSON.stringify({totalTax:1,addTax:0,taxType: '%',taxValue : '',editTaxTitle: 0,applyTaxType:'percentage'}));
      $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').addClass('hidden');
    }
  }
  
  taxType(value){
    this.updateTaxSettings('totalTax',value);
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.total_inlineTax').toggleClass('hidden',(value == 0 ? true : false));
  }

  changeTaxValue(taxvalue){
    this.updateTaxSettings('taxValue',taxvalue.target.value);
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_tax_percentage').text(taxvalue.target.value);
  }

  changeTaxType(type){
    this.updateTaxSettings('taxType',type.name);
    this.updateTaxSettings('applyTaxType',type.name == '%' ? 'percentage' : 'flat');
    $('table[total-table-uuid="' + this.tableUuid + '"]').find('.calculate_tax_percentage_main').toggleClass('hidden',(type.name == '%' ? false : true));
  }

  allowEditTaxTitle(event:any){
    this.updateTaxSettings('editTaxTitle',(event.target.checked ? 1 : 0));
  }

  updateTaxSettings(key,value){
    this.taxSettings[key] = value;
    $('table[table-uuid="' + this.tableUuid + '"]').attr('taxsettings',JSON.stringify(this.taxSettings));
  }

  convertocaleStringFormat(tableInstance:any,cost:any,maxDigits:any=''){
    var decimalsallowed = $(tableInstance).attr('decimalsallowed');
    var options = {
        minimumFractionDigits: decimalsallowed,
        maximumFractionDigits: (maxDigits == '' ? decimalsallowed : maxDigits)
    };
    console.log(cost,decimalsallowed);
    return Number(cost).toLocaleString('es-US', options);
  }

  getTableSettings(tableUuid:any){
    var settings = $('table[table-uuid="' + tableUuid + '"]');
    var decimalsallowed = settings.attr('decimalsallowed');
    var currencysymbol = settings.attr('currencysymbol');
    var taxSettings = JSON.parse(settings.attr('taxsettings') || {});
    var discountSettings = JSON.parse(settings.attr('discountsettings') || {});
    var tableDesignSettings = JSON.parse(settings.attr('tabledesignsettings') || {});
    var totalSettingsOfTable = JSON.parse(settings.attr('totaltabsettingsoftable') || {});
    return {
      decimalsallowed: decimalsallowed,
      currencysymbol: currencysymbol,
      taxSettings: taxSettings,
      discountSettings: discountSettings,
      tableDesignSettings: tableDesignSettings,
      totalSettingsOfTable: totalSettingsOfTable,
    }
  }

}
