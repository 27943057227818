import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-groups-addon',
  templateUrl: './data-groups-addon.component.html',
  styleUrls: ['./data-groups-addon.component.scss']
})
export class DataGroupsAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
