import { Component, OnInit, ViewEncapsulation, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import intlTelInput from 'intl-tel-input';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecipientsService } from '../recipients.service';
import { GlobalService } from 'app/global/global.service';
import { OrganizationService } from '../../organizations/organization.service';

declare var intlTelInputUtils: any;
@Component({
  selector: 'app-recipients-right-sidebar',
  templateUrl: './recipients-right-sidebar.component.html',
  styleUrls: ['./recipients-right-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecipientsRightSidebarComponent implements OnInit, OnDestroy {

  @Output('fetchRecipient') fetchRecipient: EventEmitter<any> = new EventEmitter();
  @Output('deleteRecipient') deleteRecipient: EventEmitter<any> = new EventEmitter();
  @Output('updateListing') updateListing : EventEmitter<any> = new EventEmitter();

  public hiddenFields: boolean = false;
  public changeText = "Show more";
  private intl: any;
  public recipientForm: FormGroup;
  public loading = false;
  public submitted = false;
  public phone_no_valid = true;
  public recipientData: any = {};
  public index = -1;
  public changesDetect: boolean = false;
  public isDeleted: boolean = false;
  public objectLength = Object.keys;
  public connectedOrganization: any = [];
  public isLoading: boolean = false;

  keyword = 'name';
  public data:any = []; 
  showDocumentFilter = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];


  constructor(
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService,
    private _formBuilder: FormBuilder,
    private recipientsService: RecipientsService,
    private globalService: GlobalService,
    private organizationService: OrganizationService
  ) { }


  /**
   * Open link organization modal
   * @param linkedOrganizationeModal 
   */
  linkOrganizationModalOpen(linkedOrganizationeModal) {
    this.modalService.open(linkedOrganizationeModal);
  }
 
  /**
   * Open Upload Attachment Modal
   * @param uploadAttachmentsModal 
   */
  uploadAttachmentsModalOpen(uploadAttachmentsModal) {
    this.modalService.open(uploadAttachmentsModal, {
      size: 'lg'
    });
  }


  /**
   * Toggle side bar with name
   * @param name 
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
   * convenience getter for easy access to form fields
   */
  get form() {
    return this.recipientForm.controls;
  }

  ngOnInit(): void {
    this.initRecipientsForm({});
  }


  /**
   * Init formGroup with data
   * @param data 
   */
  initRecipientsForm(data: any = {}) {
    this.changesDetect = false;
    this.index = data?.index;
    this.recipientData = data;
    this.connectedOrganization = data.organization ?? [];
    this.recipientForm = this._formBuilder.group({
      id: [data?.id],
      firstname: [data?.firstname, Validators.required],
      lastname: [data?.lastname ?? ''],
      email: [data?.email, [Validators.required, Validators.email]],
      phone_no: [data?.phone_no],
      address: [data?.address],
      persnol_no: [data?.persnol_no],
      zipcode: [data?.zipcode],
      city: [data?.city],
    });
    const initialValue = this.recipientForm.value;
    this.recipientForm.valueChanges.subscribe((value: any) => {
      this.changesDetect = Object.keys(initialValue).some(
        (key) => this.recipientForm.controls[key].value !=
          initialValue[key]
      );
    });
    if (Object.keys(data).length) {
      setTimeout(() => {
        if (this.intl) this.intl.destroy();
        const input = document.querySelector("#editphone");
        this.intl = intlTelInput(input, {
          separateDialCode: true,
          initialCountry: "se",
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.0/build/js/utils.js"
        });
        if (data.phone_no != null) {
          this.intl.setNumber(data.phone_no);
        }
      }, 0)
    }
  }


  /**
   * Upadate recipient function
   * @returns 
   */
  async updateRecipient() {
    var error = this.intl.getValidationError();
    var number = this.intl.getNumber(intlTelInputUtils.numberFormat.E164)
    console.log(this.intl.isValidNumber(), error, number);
    this.submitted = true;
    var form = this.recipientForm.value;
    // stop here if form is invalid
    if (this.recipientForm.invalid) {
      return;
    } else if (!this.intl.isValidNumber() && this.intl.getNumber(intlTelInputUtils.numberFormat.E164) != "") {
      this.phone_no_valid = false;
      console.log(this.submitted && !this.phone_no_valid);
      return;
    }
    if (this.recipientData?.email == form.email) {
      delete form.email;
    }
    this.recipientForm.patchValue({ phone_no: this.intl.getNumber(intlTelInputUtils.numberFormat.E164) });
    this.loading = true;
    console.log(form, 'this.recipientForm.value');
    const recipient = await this.recipientsService.updateRecipient(form);
    if (recipient.status != 200) {
      this.globalService.toastrError(recipient.data.message);
      this.loading = false;
      return;
    }
    this.globalService.toastrSuccess(recipient.data.message);
    this.loading = false;
    this.submitted = false;
    this.recipientForm.reset();
    this.toggleSidebar('recipient-right-sidebar');
    this.fetchRecipient.emit();
  }

  /**
   * Remove recipient function
   * @returns 
   */
  async removeRecipient() {
    this.isDeleted = true;
    const recipient = await this.recipientsService.deleteRecipient(this.recipientForm.value?.id);
    if (recipient.status != 200) {
      this.globalService.toastrError(recipient.data.message);
      this.isDeleted = false;
      return;
    }
    this.deleteRecipient.emit({ id: this.index });
    this.globalService.toastrSuccess(recipient.data.message);
    this.isDeleted = false;
    this.toggleSidebar('recipient-right-sidebar');
  }

  /**
   * Show hidden fields
   */
  showHiddenFields() {
    this.hiddenFields = !this.hiddenFields;
    this.changeText = (this.hiddenFields ? 'Hide' : 'Show more');
  }

  ngOnDestroy() {
    console.log('destory');
  }

  /**
   * Navigation change function behalf on event param
   * @param event 
   */  
  navChange(event: any) {
    if (event.nextId == "private") {
      this.initRecipientsForm(this.recipientData);
    }
  }


  /**
   * Select event
   * @param item 
   * @returns 
   */
 async selectEvent(item) {
    var formData = {id: this.recipientForm.value?.id, organization_id: item.id};
    const responseData = await this.organizationService.link(formData);
    if(responseData?.status != 200){
      this.globalService.toastrWarning(responseData.data.message);
      return;
    }
    this.globalService.toastrSuccess(responseData.data.message);
    this.connectedOrganization = item;
    this.updateListing.emit({data: item, index: this.index});
    this.modalService.dismissAll('linkedOrganizationeModal');
  }


  /**
   * Auto complete search event
   * @param val 
   * @returns 
   */
  async onChangeSearch(val: string) {
    if(val == ''){
      this.data = [];
      return;
    }
    this.isLoading = true;
    const organizationData = await this.organizationService.fetchOrganization(1, val);
    this.data  = organizationData.data.data.data || [];
    this.isLoading = false;
  }

  onFocused(e) {
   
  }


  /**
   * Unlink organization event
   * @param $event 
   * @returns 
   */
  async unlinkOrganization($event){
    var formData = {id: this.recipientForm.value?.id, organization_id: 0};
    const responseData = await this.organizationService.link(formData);
    if(responseData?.status != 200){
      this.globalService.toastrWarning(responseData.data.message);
      return;
    }
    this.globalService.toastrSuccess('Organization unlinked');
    this.connectedOrganization = this.connectedOrganization;
    this.connectedOrganization = [];
    this.updateListing.emit({data: this.connectedOrganization, index: this.index});
    this.modalService.dismissAll('linkedOrganizationeModal');
  }

}
