<div *ngIf="section == 'default'">
    <div class="ph-item contactlisting-placeholer-loader">
        <div class="ph-col-12" *ngFor="let loader of loop">
            <div class="ph-picture"></div>
            <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-2 empty big"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-8 empty"></div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="section == 'document_type'">
    <div class="ph-item contactlisting-placeholer-loader">
        <div class="ph-col-12" *ngFor="let loader of loop">
            <div class="ph-picture"></div>
            <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-4 empty big"></div>
                <div class="ph-col-2 empty big"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-8 empty"></div>
            </div>
        </div>
    </div>
</div>