import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-content-library-right-menu',
  templateUrl: './content-library-right-menu.component.html',
  styleUrls: ['./content-library-right-menu.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ContentLibraryRightMenuComponent implements OnInit {

  constructor() { }
  public readonly = false;
  public searchable = false;
  chooseCategory = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  ngOnInit(): void {
  }

}
