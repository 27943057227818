import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cc-related-contract',
  templateUrl: './cc-related-contract.component.html',
  styleUrls: ['./cc-related-contract.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CcRelatedContractComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
