<div class="todo-app-list">
    <div class="app-fixed-search d-flex align-items-center">
        <div class="sidebar-toggle d-block d-lg-none ml-1">
            <button class="btn p-0" (click)="toggleSidebar('todo-sidebar-main')">
            <i class="feather icon-menu"></i>
            </button>
        </div>
    
        <div class="d-flex align-content-center justify-content-between w-100">
            <div class="input-group input-group-merge">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="feather icon-search text-muted"></i></span>
            </div>
            <input
                type="text"
                class="form-control"
                id="templates-search"
                placeholder="Search templates"
                aria-label="Search..."
                aria-describedby="templates-search"
            />
            </div>
        </div>
    </div>
</div>

<div class="table-listing">
    <div class="table-responsive">
        <!-- Hoverable rows -->

        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Document</th>
                    <th>Shared with</th>
                    <th>Document Type</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr class="tableDataRow">
                    <td>
                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Proposal Template For Any Business 
                        </a>
                        <small placement="top" ngbTooltip="Last Event"> 2021 Dec 29 2:13PM </small>
                    </td>
                    <td>
                        <div class="avatar-group">
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Vinnie Mostowy"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-5.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div 
                                placement="top" 
                                class="avatar pull-up" 
                                ngbTooltip="Elicia Rieske" 
                                placement="top" 
                                container="body">
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-7.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Julee Rossignol"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-10.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                        </div>                          
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td class="">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch1" />
                            <label class="custom-control-label" for="customSwitch1">Published</label>
                        </div>
                        <div ngbDropdown container="body" class="actionTD template-listing-action-btn ">
                            <button type="button" class="btn btn-sm hide-arrow"
                                ngbDropdownToggle data-toggle="dropdown">
                                <i data-feather="more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="send" [size]="16" class="mr-50"></i>
                                    <span>Send for signature</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Edit</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="copy" [size]="16" class="mr-50"></i>
                                    <span>Duplicate</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Rename</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);"><i
                                        data-feather="trash" [size]="16" class="mr-50"></i>
                                    <span>Delete permanently</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Search Engine Optimization Proposal 
                        </a>
                        <small placement="top" ngbTooltip="Last Event"> 2021 Dec 29 2:13PM </small>
                    </td>
                    <td>
                        <div class="avatar-group">
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Vinnie Mostowy"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-5.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div 
                                placement="top" 
                                class="avatar pull-up" 
                                ngbTooltip="Elicia Rieske" 
                                placement="top" 
                                container="body">
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-7.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Julee Rossignol"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-10.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                        </div>   
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td class="">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="customSwitch2" />
                            <label class="custom-control-label" for="customSwitch2">Draft</label>
                        </div>
                        <div ngbDropdown container="body" class="actionTD template-listing-action-btn ">
                            <button type="button" class="btn btn-sm hide-arrow"
                                ngbDropdownToggle data-toggle="dropdown">
                                <i data-feather="more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="send" [size]="16" class="mr-50"></i>
                                    <span>Send for signature</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Edit</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="copy" [size]="16" class="mr-50"></i>
                                    <span>Duplicate</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Rename</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);"><i
                                        data-feather="trash" [size]="16" class="mr-50"></i>
                                    <span>Delete permanently</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Freelance Contract 
                        </a>
                        <small placement="top" ngbTooltip="Last Event"> 2021 Dec 29 2:13PM </small>
                    </td>
                    <td>
                        <div class="avatar-group">
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Vinnie Mostowy"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-5.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div 
                                placement="top" 
                                class="avatar pull-up" 
                                ngbTooltip="Elicia Rieske" 
                                placement="top" 
                                container="body">
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-7.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Julee Rossignol"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-10.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                        </div>   
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td class="">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch3" />
                            <label class="custom-control-label" for="customSwitch3">Published</label>
                        </div>
                        <div ngbDropdown container="body" class="actionTD template-listing-action-btn ">
                            <button type="button" class="btn btn-sm hide-arrow"
                                ngbDropdownToggle data-toggle="dropdown">
                                <i data-feather="more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="send" [size]="16" class="mr-50"></i>
                                    <span>Send for signature</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Edit</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="copy" [size]="16" class="mr-50"></i>
                                    <span>Duplicate</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Rename</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);"><i
                                        data-feather="trash" [size]="16" class="mr-50"></i>
                                    <span>Delete permanently</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Search Engine Optimization Proposal 
                        </a>
                        <small placement="top" ngbTooltip="Last Event"> 2021 Dec 29 2:13PM </small>
                    </td>
                    <td>
                        <div class="avatar-group">
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Vinnie Mostowy"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-5.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div 
                                placement="top" 
                                class="avatar pull-up" 
                                ngbTooltip="Elicia Rieske" 
                                placement="top" 
                                container="body">
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-7.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Julee Rossignol"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-10.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                        </div>   
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td class="">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="customSwitch2" />
                            <label class="custom-control-label" for="customSwitch2">Draft</label>
                        </div>
                        <div ngbDropdown container="body" class="actionTD template-listing-action-btn ">
                            <button type="button" class="btn btn-sm hide-arrow"
                                ngbDropdownToggle data-toggle="dropdown">
                                <i data-feather="more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="send" [size]="16" class="mr-50"></i>
                                    <span>Send for signature</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Edit</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="copy" [size]="16" class="mr-50"></i>
                                    <span>Duplicate</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Rename</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);"><i
                                        data-feather="trash" [size]="16" class="mr-50"></i>
                                    <span>Delete permanently</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Freelance Contract 
                        </a>
                        <small placement="top" ngbTooltip="Last Event"> 2021 Dec 29 2:13PM </small>
                    </td>
                    <td>
                        <div class="avatar-group">
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Vinnie Mostowy"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-5.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div 
                                placement="top" 
                                class="avatar pull-up" 
                                ngbTooltip="Elicia Rieske" 
                                placement="top" 
                                container="body">
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-7.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Julee Rossignol"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-10.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                        </div>   
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td class="">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch3" />
                            <label class="custom-control-label" for="customSwitch3">Published</label>
                        </div>
                        <div ngbDropdown container="body" class="actionTD template-listing-action-btn ">
                            <button type="button" class="btn btn-sm hide-arrow"
                                ngbDropdownToggle data-toggle="dropdown">
                                <i data-feather="more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="send" [size]="16" class="mr-50"></i>
                                    <span>Send for signature</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Edit</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="copy" [size]="16" class="mr-50"></i>
                                    <span>Duplicate</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Rename</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);"><i
                                        data-feather="trash" [size]="16" class="mr-50"></i>
                                    <span>Delete permanently</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Data Processing Agreement 
                        </a>
                        <small placement="top" ngbTooltip="Last Event"> 2021 Dec 29 2:13PM </small>
                    </td>
                    <td>
                        <div class="avatar-group">
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Vinnie Mostowy"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-5.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div 
                                placement="top" 
                                class="avatar pull-up" 
                                ngbTooltip="Elicia Rieske" 
                                placement="top" 
                                container="body">
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-7.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Julee Rossignol"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-10.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                        </div>   
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td class="">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="customSwitch2" />
                            <label class="custom-control-label" for="customSwitch2">Draft</label>
                        </div>
                        <div ngbDropdown container="body" class="actionTD template-listing-action-btn ">
                            <button type="button" class="btn btn-sm hide-arrow"
                                ngbDropdownToggle data-toggle="dropdown">
                                <i data-feather="more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="send" [size]="16" class="mr-50"></i>
                                    <span>Send for signature</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Edit</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="copy" [size]="16" class="mr-50"></i>
                                    <span>Duplicate</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Rename</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);"><i
                                        data-feather="trash" [size]="16" class="mr-50"></i>
                                    <span>Delete permanently</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="#" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Studio Rental Agreement 
                        </a>
                        <small placement="top" ngbTooltip="Last Event"> 2021 Dec 29 2:13PM </small>
                    </td>
                    <td>
                        <div class="avatar-group">
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Vinnie Mostowy"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-5.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div 
                                placement="top" 
                                class="avatar pull-up" 
                                ngbTooltip="Elicia Rieske" 
                                placement="top" 
                                container="body">
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-7.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                            <div
                                placement="top"
                                class="avatar pull-up"
                                ngbTooltip="Julee Rossignol"
                                placement="top"
                                container="body"
                            >
                                <img
                                class="media-object"
                                src="assets/images/portrait/small/avatar-s-10.jpg"
                                alt="Avatar"
                                height="32"
                                width="32"
                                />
                            </div>
                        </div>   
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td class="">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch3" />
                            <label class="custom-control-label" for="customSwitch3">Published</label>
                        </div>
                        <div ngbDropdown container="body" class="actionTD template-listing-action-btn ">
                            <button type="button" class="btn btn-sm hide-arrow"
                                ngbDropdownToggle data-toggle="dropdown">
                                <i data-feather="more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="send" [size]="16" class="mr-50"></i>
                                    <span>Send for signature</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Edit</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="copy" [size]="16" class="mr-50"></i>
                                    <span>Duplicate</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);">
                                    <i data-feather="edit-2" [size]="16" class="mr-50"></i>
                                    <span>Rename</span>
                                </a>
                                <a ngbDropdownItem href="javascript:void(0);"><i
                                        data-feather="trash" [size]="16" class="mr-50"></i>
                                    <span>Delete permanently</span>
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <ngb-pagination class="d-flex justify-content-end mt-1 mr-1" [collectionSize]="30" [(page)]="pageBasic" aria-label="Default pagination">
            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                    [size]="18" [class]="'font-weight-bolder'"></span>
            </ng-template>
            <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'"
                    [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
        </ngb-pagination>
    </div>
</div>