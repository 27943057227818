<div class="card addonBoxContainer" (click)="modalOpen(bulksendSettingModal)">
    <div class="card-body">
        <h5>BulkSend</h5>
        <p class="card-text font-small-3 addonboxDiscription">Send your documents to as many people as you want.</p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <span [data-feather]="'send'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #bulksendSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">BulkSend</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                BulkSend is the easy-to-use feature for sending one document thousands of times. Simply
                                upload a CSV file, which can be created in Excel or Google Sheets easily, with your
                                existing contacts and then define each column on a drop list. Simple Sign will then
                                send the same document to each contact on your list.
                                <br><br>
                                Combine BulkSend with recipient fields to add recipient specific data such as names,
                                addresses, company names, etc. automatically into the document so that with one click
                                every recipient on the list gets a document with information entered into the document
                                specific to them.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\MultiSend\MultiSend1.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\MultiSend\MultiSend2.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\MultiSend\MultiSend3.png"
                                    alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->