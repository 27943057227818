import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-connect-your-google-drive-addon',
  templateUrl: './connect-your-google-drive-addon.component.html',
  styleUrls: ['./connect-your-google-drive-addon.component.scss']
})
export class ConnectYourGoogleDriveAddonComponent implements OnInit {

  // modal Basic
  modalOpen(ConnectYourGoogleDriveSettingModal) {
    this.modalService.open(ConnectYourGoogleDriveSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }
  // swiper
  public swiperPaginations: SwiperConfigInterface = {
    pagination: true,
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // }
  };
  
  
  
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
