import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-contract-control',
  templateUrl: './contract-control.component.html',
  styleUrls: ['./contract-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})
export class ContractControlComponent implements OnInit {

  public pageBasic = 1;
  hiddenChartViewCont: boolean = false;
  hiddenTableListViewCont: boolean = true;
  CC_showHideFilters: boolean = false;
  advanceFiltersShowHide: boolean = false;


  CC_showHideFiltersClick() {
    this.CC_showHideFilters = !this.CC_showHideFilters;
  }

  showChartViewCode() {
    this.hiddenChartViewCont = true;
    this.hiddenTableListViewCont = false;
  }

  showTableListViewCode() {
    this.hiddenTableListViewCont = true;
    this.hiddenChartViewCont = false;
    
  }
  AdvanceFiltersShowHideClick() {
    this.advanceFiltersShowHide = !this.advanceFiltersShowHide;
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
    * Constructor
    *
    * @param {TodoService} _todoService
    * @param {CoreSidebarService} _coreSidebarService
    */
  constructor(private _coreSidebarService: CoreSidebarService,) { }

  ngOnInit(): void {
  }

}
