import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { CoreSidebarModule } from '@core/components';

import { NgSelectModule } from '@ng-select/ng-select';

import { CoreCommonModule } from '@core/common.module';
import { RecipientsComponent } from './recipients/recipients.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { RecipientsRightSidebarComponent } from './recipients/recipients-right-sidebar/recipients-right-sidebar.component';
import { AddRecipientSidebarComponent } from './recipients/add-recipient-sidebar/add-recipient-sidebar.component';
import { AddOrganizationSidebarComponent } from './organizations/add-organization-sidebar/add-organization-sidebar.component';
import { OrganizationRightSidebarComponent } from './organizations/organization-right-sidebar/organization-right-sidebar.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ShortNamePipe } from 'app/pipes/short-name.pipe';
import { MomentPipe } from 'app/pipes/moment.pipe';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// routing
const routes: Routes = [
  {
    path: 'contacts/recipients',
    component: RecipientsComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'contacts/organizations',
    component: OrganizationsComponent,
    data: { animation: 'misc' }
  }
];

@NgModule({
  declarations: [
    RecipientsComponent, 
    OrganizationsComponent, 
    RecipientsRightSidebarComponent, 
    AddRecipientSidebarComponent, AddOrganizationSidebarComponent, OrganizationRightSidebarComponent,
    ShortNamePipe,
    MomentPipe,
    SkeletonComponent
  ],
  imports: [
    CommonModule, 
    RouterModule.forChild(routes), 
    CoreCommonModule,
    NgbModule,
    CoreSidebarModule,
    PerfectScrollbarModule,
    NgSelectModule,
    AutocompleteLibModule

  ],
})
export class ContactsModule {}
