<div class="d-flex justify-content-between align-items-center content-sidebar-header py-75 px-1 hidden">
    <div class="d-flex justify-content-start align-items-center">
       <div class="user-page-info">
          <h5 class="mb-0"> Details </h5>
       </div>
    </div>
    <div>

    </div>
</div>

<div class="detail-tab-scroll" [perfectScrollbar]>
    <div class="form-group px-1">
        <label class="col-form-label pt-0" for="basicInput">Visible document name</label>
        <input type="text" class="form-control" id="basicInput" placeholder="New template" />
    </div>

    <div class="form-group px-1">
        <label class="col-form-label pt-0" for="name">User language</label>
        <ng-select [items]="languages" bindLabel="name" bindValue="id" [(ngModel)]="selectedLanguage">
        </ng-select>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0" for="basicInput">Signing deadline in</label>
        <div class="input-group input-group-merge"> 
            <input
                type="text"
                class="form-control"
                placeholder="-"
                aria-label="-"
            />
            <div class="input-group-append">
                <span class="input-group-text">Days</span>
            </div>
        </div>
    </div>

    <div class="form-group px-1">
        <label class="col-form-label pt-0" for="basicInput">Signing reminder in</label>
        <div class="input-group input-group-merge"> 
            <input
                type="text"
                class="form-control"
                placeholder="-"
                aria-label="-"
            />
            <div class="input-group-append">
                <span class="input-group-text">Days</span>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">
    
    <div class="form-group px-1">
        <label class="col-form-label pt-0" for="basicTextarea">Your invitation message</label>
        <textarea class="form-control" id="basicTextarea" rows="3" placeholder="Write your message here"></textarea>
    </div>

    <div class="form-group px-1">
        <label class="col-form-label pt-0" for="basicTextarea">Confirmation message</label>
        <textarea class="form-control" id="basicTextarea" rows="3" placeholder="Write your message here"></textarea>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Custom SMS invitation</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Edit message</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="CsmsInv" />
                <label class="custom-control-label" for="CsmsInv"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Document key</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Password</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="Dk" />
                <label class="custom-control-label" for="Dk"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Save to specific folder</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Select folder</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="Stsf" />
                <label class="custom-control-label" for="Stsf"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Forward</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Allow forward</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="Forward" />
                <label class="custom-control-label" for="Forward"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Chat</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Allow chat</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="chat" />
                <label class="custom-control-label" for="chat"></label>
            </div>
        </div>
    </div>

    <hr class="mt-2 mb-2">

    <div class="form-group px-1">
        <label class="col-form-label pt-0 pb-0" for="name">Document workflow</label>
        <div class="form-group d-flex justify-content-between align-items-center pt-25">
            <label class="pt-0 pb-0 mb-0" for="name">Workflow</label>
            <div class="custom-control custom-control-primary custom-switch ">
                <input type="checkbox" class="custom-control-input" id="Documentworkflow" />
                <label class="custom-control-label" for="Documentworkflow"></label>
            </div>
        </div>
    </div>
</div>