<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- superAccessToken -->
        <section class="settingsSections">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="card mb-2">
                        <div class="card-body collapse-icon">
                            <h4 class="card-title mb-75">Super access token</h4>
                            <p class="card-text">Use this access token to perform all the operations such as add,
                                update, and delete through Simple Sign's API.</p>
                            <hr>
                            <ngb-accordion #acc="ngbAccordion">
                                <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                        <span class="lead collapse-title"> Client credentials (click to show)
                                        </span>
                                    </ng-template>
                                    <ng-template ngbPanelContent class="p-0">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 pl-0">
                                                <div class="border rounded p-2">
                                                    <h5 class="mb-1">Client credentials identifier</h5>gKhxbwIG23Ff98OD62wZ6QT7LAAh3kCaVSeRQNl8
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 pr-0">
                                                <div class="border rounded p-2">
                                                    <h5 class="mb-1">Client credentials</h5>
                                                    <p class="text-primary client-credentials cursor-pointer mb-0">107646081361e6a69dcb047</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>

                            
                            <button type="button" class="btn btn-success float-right" rippleEffect>Regenerate</button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!--/ superAccessToken -->
        <!-- basicAccessToken -->
        <section class="settingsSections">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="card mb-2">
                        <div class="card-body collapse-icon">
                            <h4 class="card-title mb-75">Basic access token</h4>
                            <p class="card-text">With this access token client can only view.</p>
                            <hr>
                            <ngb-accordion #acc="ngbAccordion">
                                <ngb-panel>
                                    <ng-template ngbPanelTitle>
                                        <span class="lead collapse-title"> Client credentials (click to show)
                                        </span>
                                    </ng-template>
                                    <ng-template ngbPanelContent class="p-0">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 pl-0">
                                                <div class="border rounded p-2">
                                                    <h5 class="mb-1">Client credentials identifier</h5>gKhxbwIG23Ff98OD62wZ6QT7LAAh3kCaVSeRQNl8
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 pr-0">
                                                <div class="border rounded p-2">
                                                    <h5 class="mb-1">Client credentials</h5>
                                                    <p class="text-primary client-credentials cursor-pointer mb-0">107646081361e6a69dcb047</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                            <button type="button" class="btn btn-success float-right" rippleEffect>Regenerate</button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!--/ basicAccessToken -->
        <!-- Webhook URL -->
        <section class="settingsSections">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="card mb-2">
                        <div class="card-body">
                            <h4 class="card-title mb-75">Webhook URL</h4>
                            <p class="card-text">Add endpoint URL</p>
                            <hr>
                            <div class="row">
                                <div class="col-xl-6 col-md-6 col-12 mb-1">
                                    <div class="form-group mb-0">
                                        <label for="WebhookURL">Webhook URL</label>
                                        <input type="text" class="form-control" id="" placeholder="Enter your Webhook URL" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-12 mb-1">
                                    <div class="form-group mb-0">
                                        <label for="customSelect">Choose template</label>
                                        <ng-select [items]="choosetemplate" bindLabel="name" [readonly]="readonly"
                                            [searchable]="searchable" placeholder="Choose template"> </ng-select>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-12 mb-1">
                                    <div class="custom-control custom-switch mb-75">
                                        <label class="mb-0 d-block">Choose Trigger Event</label>
                                        <input type="checkbox" class="custom-control-input" id="documentSign" />
                                        <label class="custom-control-label" for="documentSign">Document sign</label>
                                    </div>
                                    <div class="custom-control custom-switch mb-75">
                                        <input type="checkbox" class="custom-control-input" id="documentSend" />
                                        <label class="custom-control-label" for="documentSend">Document send</label>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-12 mt-2 pt-50">
                                    <div class="custom-control custom-switch mb-75">
                                        <input type="checkbox" checked class="custom-control-input" id="documentRejection" />
                                        <label class="custom-control-label" for="documentRejection">Document rejection</label>
                                    </div>
                                    <div class="custom-control custom-switch mb-75">
                                        <input type="checkbox" checked class="custom-control-input" id="documentView" />
                                        <label class="custom-control-label" for="documentView">Document view</label>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-success float-right" rippleEffect>Save</button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <!--/ Webhook URL -->
        <!-- Time zone -->
        <section class="settingsSections">
            <div class="row">
                <div class="col-md-12 col-lg-12">
                    <div class="card mb-2">
                        <div class="card-body">
                            <h4 class="card-title mb-75">Time zone</h4>
                            <p class="card-text">Set API time zone</p>
                            <hr>
                            <div class="row">
                                <div class="col-xl-6 col-md-6 col-12 mb-1">
                                    <div class="form-group mb-0">
                                        <label for="customSelect">Choose time zone</label>
                                        <ng-select [items]="chooseTimeZone" bindLabel="name" [readonly]="readonly"
                                            [searchable]="searchable" placeholder="Choose time zone"> </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--/ Time zone -->
    </div>
</div>