<div class="container eform-recipientFields-container">
    <div class="row">
        <div class="col-lg-12 col-12 p-0">
            <div class="eform-recipient-header-area">
                Recipient
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-12">
            <div class="eform-recipient-fieldsBox">
                <div class="row">
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Name</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Email</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Mobile No</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Address</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Zip Code</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">City</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Personal ID number</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Organization</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                        <div class="form-group">
                            <label for="basicInput">Reg No</label>
                            <input type="text" class="form-control" id="basicInput" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>