<div class="card-header">
  <div class="d-flex align-items-center">
    <h4 class="card-title">Send a Document</h4>
  </div>
</div>
<div class="card-body">
  <div class="py-5 text-center font-medium-5 text-uppercase file-drop-zone">
    Drop files here or click to upload
  </div>
</div>
