<div class="card addonBoxContainer" (click)="modalOpen(brandingSettingModal)">
    <div class="card-body">
        <h5>Branding</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Brand your account with your company profile.
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <span [data-feather]="'tag'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #brandingSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Branding</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                Add additional branding and appearance options to your account.
                                <br>
                                <br>
                                - Replace Simple Sign logotype on emails
                                <br>
                                - Replace Simple Sign logotype on signing pages
                                <br>
                                - Brand the SMS prefix (change the sender's name on all text messages)
                                <br>
                                - Replace the Simple Sign favicon with your own
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\Branding\Branding1.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\Branding\Branding2.png"
                                    alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->