import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation-order-addon',
  templateUrl: './invitation-order-addon.component.html',
  styleUrls: ['./invitation-order-addon.component.scss']
})
export class InvitationOrderAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
