<div class="card addonBoxContainer" (click)="modalOpen(getContactFromAPISettingModal)">
    <div class="card-body">
        <h5>Get Contacts from API</h5>
        <p class="card-text font-small-3 addonboxDiscription">Connect to our API to input your customers' data
            automatically into Simple Sign.</p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'settings'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #getContactFromAPISettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Get Contacts from API </h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                Import your existing customers to Simple Sign automatically through our API. Transfer
                                all of your existing users into Simple Sign contacts. Works both for organizations and
                                people.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets/images/addons-images/no-thumbnail.jpg" alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <div class="form-group">
                                <label for="basicInput">API URL</label>
                                <input type="text" class="form-control" id="basicInput" placeholder="API URL" />
                            </div>
                            <p class="font-small-3">
                                Important note:
                                <br>
                                Make sure to provide API URL that returns data in JSON format <a class="getContactFromAPIExampleLink"
                                    href="#">View example</a>
                                <br>
                                Name and email are mandatory
                            </p>
                            <button type="button" class="btn btn-success" rippleEffect>Connect</button>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->