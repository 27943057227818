import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-simple-storage-addon',
  templateUrl: './simple-storage-addon.component.html',
  styleUrls: ['./simple-storage-addon.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class SimpleStorageAddonComponent implements OnInit {

   // modal Basic
   modalOpen(simpleStorageSettingModal) {
    this.modalService.open(simpleStorageSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }
  // swiper
  public swiperPaginations: SwiperConfigInterface = {
    pagination: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
