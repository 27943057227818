<div class="signing-page-wrapper">
    <span class="signing-sidebar-openIcon" (click)="toggleSidebar('signing-right-sidebar')">
        <i data-feather="list" [size]="22"></i>
    </span>

    <div class="template-page-container">
        <div class="template-page" [innerHTML]="templateContent">
        </div>
    </div>

    <!-- <div class="eform-recipientFields-wrapper">
        <app-eform-recipient-fields></app-eform-recipient-fields>
    </div> -->

    <div class="bankId-box-wrapper">
        <app-sign-bank-id-box></app-sign-bank-id-box>
    </div>

    <div class="signature-box-wrapper">
        <app-signature-box></app-signature-box>
    </div>

    <div class="signing-buttons-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12 signing-left-btn">
                    <button type="button" class="btn btn-success signing-info-btn">
                        <span class="signingInfoBtnCheck"></span>
                        <span class="signingInfoText">I certify that I have read and understood the content in this document and certify that I have the power to continue</span>
                    </button>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 signing-right-btn">
                    <button type="button" class="btn btn-success signing-process-btn">Sign</button>
                </div>
            </div>
        </div>
    </div>

    <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade signing-right-sidebar show" name="signing-right-sidebar">
        <div class="slideout-content">
            <div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
                <div class="modal-dialog">
                    <form class="add-new-user modal-content pt-0">
                        <div class="modal-header mb-1 d-flex justify-content-between align-items-center px-2 py-1">
                            <div class="d-flex justify-content-end align-items-center text-dark cursor-pointer">
                                <!-- <span placement="auto" container="body" ngbTooltip="Close" (click)="toggleSidebar('signing-right-sidebar')">
                                    <i data-feather="x" [size]="16" data-dismiss="modal" aria-label="Close"></i>
                                </span> -->
                            </div>
                        </div>
                        <div class="modal-body flex-grow-1 p-0">
                            <div class="signing-sidebar-wrapper">
                                <app-signing-sidebar></app-signing-sidebar>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </core-sidebar>
</div>

<!-- Add Signature Signing Modal Modal Sart -->
<ng-template #addSignatureSigningModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add Signature</h4>
        <button type="button" class="close" (click)="closeModal('close')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0" tabindex="0" ngbAutofocus>
        <app-signature-box></app-signature-box>
    </div>
    <div class="modal-footer">
        <button [disabled]="activeImage == -1" type="button" class="btn btn-primary" (click)="closeModal('close')" rippleEffect>
            Add
        </button>
    </div>
</ng-template>
<!-- / Add Signature Signing Modal Modal End -->