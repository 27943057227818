import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-micro-agreement',
  templateUrl: './micro-agreement.component.html',
  styleUrls: ['./micro-agreement.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class MicroAgreementComponent implements OnInit {

  public pageBasic = 1;
  constructor() { }

  ngOnInit(): void {
  }

}
