import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Breadcrumb } from 'app/layout/components/content-header/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-bulksend-inner-view',
  templateUrl: './bulksend-inner-view.component.html',
  styleUrls: ['./bulksend-inner-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BulksendInnerViewComponent implements OnInit {


  public breadcrumbBulksend: Breadcrumb;
 
  public readonly = false;
  public searchable = false;
  choosetemplate = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  // @param {NgbModal} modalService;
  constructor() { }

  ngOnInit(): void {

    // breadcrumb default
    this.breadcrumbBulksend = {
      links: [
        {
          name: 'All groups',
          isLink: true,
          link: '/settings/bulksend', 
        },
        {
          name: 'Groups name',
          isLink: false,
        },
      ]
    };
  }

}
