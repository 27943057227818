import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { TemplateMainSidebarComponent } from './template-main-sidebar/template-main-sidebar.component';
import { TemplateListingComponent } from './template-listing/template-listing.component';
import { CoreCommonModule } from '@core/common.module';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
// import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TemplateMainSidebarComponent,
    TemplateListingComponent,
  ],
  imports: [
    NgbModule,
    CoreCommonModule,
    PerfectScrollbarModule,
    NgSelectModule,
    // FormsModule
  ],
  exports: [
    TemplateMainSidebarComponent,
    TemplateListingComponent
  ]
})
export class TemplatesModule {}
