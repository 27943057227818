import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';


@Component({
  selector: 'app-documentfieldssidebar',
  templateUrl: './documentfieldssidebar.component.html',
  styleUrls: ['./documentfieldssidebar.component.scss']
})
export class DocumentfieldssidebarComponent implements OnInit {

  constructor(private _coreSidebarService: CoreSidebarService) { }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  public readonly = false;
  public searchable = false;

  includeOrFieldtype = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  ngOnInit(): void {
  }

}
