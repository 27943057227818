import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hub-spot-addon',
  templateUrl: './hub-spot-addon.component.html',
  styleUrls: ['./hub-spot-addon.component.scss'],
 encapsulation: ViewEncapsulation.Emulated,

})
export class HubSpotAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
