<div class="card addonBoxContainer" (click)="modalOpen(pointOfSellSettingModal)">
    <div class="card-body">
        <h5>Point of Sale (POS)</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Let your customers sign your documents right in your store by using a tablet.
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'monitor'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>


<!-- Modal -->
<ng-template #pointOfSellSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Point of Sale (POS)</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                Connect your account to a tablet of your choice. Then, simply send a document from your
                                account directly to that tablet. It’s the perfect easy-to-use solution for in-store
                                signing. POS is widely used for membership agreements, product insurance, and returns.
                                <br><br>
                                This add-on allows you to easily get started with POS or to integrate Simple Sign with
                                your already existing POS system.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations addon-swiper-Cont" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets/images/addons-images/addon-thumbnails/PointofSale/PointofSale1.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets/images/addons-images/addon-thumbnails/PointofSale/PointofSale2.png"
                                    alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row border-top border-bottom">
                        <div class="col-md-12 col-lg-12 mt-1">
                            <div class="custom-control custom-switch form-group">
                                <div class="d-flex justify-content-between">
                                    <small>Stickey Signature Box</small>
                                    <input type="checkbox" class="custom-control-input" id="SignatureBox" />
                                    <label class="custom-control-label" for="SignatureBox"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->