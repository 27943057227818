<div class="table-listing">
    <div class="table-responsive">
        <!-- Hoverable rows -->

        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Document</th>
                    <th>Owner</th>
                    <th>Recipient</th>
                    <th>Organization</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr class="tableDataRow" (click)="toggleSidebar('document-right-sidebar')">
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Proposal Template For Any Business
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span>Korrie O'Crevy</span>
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td>
                        <span class="">Simplesign</span>
                    </td>
                    <td class="">
                        <div class="badge badge-pill badge-light-primary">Viewed</div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Search Engine Optimization Proposal
                        </a>
                        <small placement="top" ngbTooltip="Type"> Other</small>
                    </td>
                    <td>
                        <span>Korrie O'Crevy</span>
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td>
                        <span class="">Simplesign</span>
                    </td>
                    <td class="">
                        <div class="badge badge-pill badge-light-success">Signed</div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Freelance Contract
                        </a>
                        <small placement="top" ngbTooltip="Type">Manager</small>
                    </td>
                    <td>
                        <span>Korrie O'Crevy</span>
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td>
                        <span class="">Simplesign</span>
                    </td>
                    <td class="">
                        <div class="badge badge-pill badge-light-warning">Delivered</div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Search Engine Optimization Proposal
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span>Korrie O'Crevy</span>
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td>
                        <span class="">Simplesign</span>
                    </td>
                    <td class="">
                        <div class="badge badge-pill badge-light-primary">Approved</div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Freelance Contract
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span>Korrie O'Crevy</span>
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td>
                        <span class="">Simplesign</span>
                    </td>
                    <td class="">
                        <div class="badge badge-pill badge-light-secondary">Revoke</div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Data Processing Agreement
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span>Korrie O'Crevy</span>
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td>
                        <span class="">Simplesign</span>
                    </td>
                    <td class="">
                        <div class="badge badge-pill badge-light-info">Draft</div>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Studio Rental Agreement
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span>Korrie O'Crevy</span>
                    </td>
                    <td>
                        <span class="">Other</span>
                    </td>
                    <td>
                        <span class="">Simplesign</span>
                    </td>
                    <td class="">
                        <div class="badge badge-pill badge-light-primary">Viewed</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <ngb-pagination class="d-flex justify-content-end mt-1 mr-1" [collectionSize]="30" [(page)]="pageBasic"
            aria-label="Default pagination">
            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [size]="18" [class]="'font-weight-bolder'"></span>
            </ng-template>
            <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'" [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
        </ngb-pagination>
    </div>
</div>