import { CommonModule } from '@angular/common';
 import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { AuthenticationModule } from './authentication/authentication.module';
import { MiscellaneousModule } from './miscellaneous/miscellaneous.module';
import {ContactsModule} from './contacts/contacts.module';
import {SettingsModule} from './settings/settings.module';
import {AddonsModule} from './addons/addons.module';
import {UsersModule} from './users/users.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
//general editor tabs
import { GeneralPopoverSettingsComponent } from './general-editor-tabs/general-popover-settings/general-popover-settings.component';
import { ContentTabComponent } from './general-editor-tabs/content-tab/content-tab.component';
import { RecipientsTabComponent } from './general-editor-tabs/recipients-tab/recipients-tab.component';
import { RecipientSettingsComponent } from './general-editor-tabs/recipient-settings/recipient-settings.component';
import { DetailsTabComponent } from './general-editor-tabs/details-tab/details-tab.component';
import { FormBuilderSettingsComponent } from './general-editor-tabs/form-builder-settings/form-builder-settings.component';
import { ContentLibraryTabComponent } from './general-editor-tabs/content-library-tab/content-library-tab.component';
import { PdfBlockSettingsComponent } from './general-editor-tabs/pdf-block-settings/pdf-block-settings.component';
import { ContentTableSettingsComponent } from './general-editor-tabs/content-table-settings/content-table-settings.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { from } from 'rxjs';
import { ProductTableModalsComponent } from './general-modals/product-table-modals/product-table-modals.component';
import { ProductTableSettingsComponent } from './general-editor-tabs/product-table-settings/product-table-settings.component';

@NgModule({
  declarations: [
    GeneralPopoverSettingsComponent,
    ContentTabComponent,
    RecipientsTabComponent,
    RecipientSettingsComponent,
    DetailsTabComponent,
    FormBuilderSettingsComponent,
    ContentLibraryTabComponent,
    PdfBlockSettingsComponent,
    ContentTableSettingsComponent,
    ProductTableModalsComponent,
    ProductTableSettingsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgbModule,
    NgSelectModule,
    PerfectScrollbarModule,
    FormsModule,
    AuthenticationModule,
    MiscellaneousModule,
    ContactsModule,
    SettingsModule,
    AddonsModule,
    ColorPickerModule,
    UsersModule,
    CoreTouchspinModule
  ],
  exports: [
    GeneralPopoverSettingsComponent,
    ContentTabComponent,
    RecipientsTabComponent,
    RecipientSettingsComponent,
    DetailsTabComponent,
    ContentLibraryTabComponent,
    FormBuilderSettingsComponent,
    PdfBlockSettingsComponent,
    ContentTableSettingsComponent,
    ProductTableModalsComponent,
    ProductTableSettingsComponent
  ],
  providers: []
})
export class PagesModule {}
