<div class="content-wrapper userslisting-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="card mb-2">
                    <div class="p-2">
                        <div class="row">
                            <div class="col-md-6 col-lg-5">
                                <div class="d-flex align-content-center justify-content-between w-100">
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i data-feather="search"></i></span>
                                        </div>
                                        <input type="text" class="form-control" id="email-search" placeholder="Search user"
                                            aria-label="Search..." aria-describedby="email-search" />
                                    </div>
                                </div>
                            </div>
                            <div class="m-0 d-flex align-items-center justify-content-end header-section-btn col-sm-12 col-md-6 col-lg-7">
                                <button type="button" class="btn btn-dark float-right userslistingAddtBtn" (click)="toggleSidebar('user-right-sidebar')"
                                    rippleEffect>Invite
                                    more users</button>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <!-- Hoverable rows -->
                        <div class="row" id="table-hover-row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="table-responsive">
                                        <!-- Hoverable rows -->
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th>NAME</th>
                                                    <th>EMAIL</th>
                                                    <th>LAST LOGIN</th>
                                                    <th>STATUS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="tableDataRow" (click)="toggleSidebar('user-right-sidebar')">
                                                    <td>
                                                        <div class="avatar mr-1">
                                                            <img src="assets/images/portrait/small/avatar-s-10.jpg" alt="avatar"
                                                                width="32" height="32" />
                                                            <span class="avatar-status-online"></span>
                                                        </div>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">2023 Apr 27 05:12:45</span>
                                                    </td>
                                                    <td class="userActionTD">
                                                        <div class="text-center" placement="top" ngbTooltip="Active">
                                                            <img class="rounded-circle" src="assets/images/pages/active.png"
                                                                height="25" width="25" alt="active-icon" />
                                                        </div>
                                                        <div placement="top" ngbTooltip="Details" container="body"
                                                            class="userRowDetailIcon">
                                                            <span [data-feather]="'chevron-right'" [size]="16" [class]="'font-weight-bolder'"></span>
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr class="tableDataRow" (click)="toggleSidebar('user-right-sidebar')">
                                                    <td>
                                                        <div class="avatar bg-light-success mr-1">
                                                            <span class="avatar-content">MK</span>
                                                        </div>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">2023 Apr 27 05:12:45</span>
                                                    </td>
                                                    <td class="userActionTD">
                                                        <div class="text-center" placement="top" ngbTooltip="Active">
                                                            <img class="rounded-circle" src="assets/images/pages/active.png"
                                                                height="25" width="25" alt="active-icon" />
                                                        </div>
                                                        <div placement="top" ngbTooltip="Details" container="body"
                                                            class="userRowDetailIcon">
                                                            <span [data-feather]="'chevron-right'" [size]="16" [class]="'font-weight-bolder'"></span>
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr class="tableDataRow" (click)="toggleSidebar('user-right-sidebar')">
                                                    <td>
                                                        <div class="avatar mr-1">
                                                            <img src="assets/images/portrait/small/avatar-s-10.jpg" alt="avatar"
                                                                width="32" height="32" />
                                                            <span class="avatar-status-online"></span>
                                                        </div>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">2023 Apr 27 05:12:45</span>
                                                    </td>
                                                    <td class="userActionTD">
                                                        <div class="text-center" placement="top" ngbTooltip="Active">
                                                            <img class="rounded-circle" src="assets/images/pages/active.png"
                                                                height="25" width="25" alt="active-icon" />
                                                        </div>
                                                        <div placement="top" ngbTooltip="Details" container="body"
                                                            class="userRowDetailIcon">
                                                            <span [data-feather]="'chevron-right'" [size]="16" [class]="'font-weight-bolder'"></span>
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr class="tableDataRow" (click)="toggleSidebar('user-right-sidebar')">
                                                    <td>
                                                        <div class="avatar bg-light-success mr-1">
                                                            <span class="avatar-content">MK</span>
                                                        </div>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">2023 Apr 27 05:12:45</span>
                                                    </td>
                                                    <td class="userActionTD">
                                                        <div class="text-center" placement="top" ngbTooltip="Active">
                                                            <img class="rounded-circle" src="assets/images/pages/active.png"
                                                                height="25" width="25" alt="active-icon" />
                                                        </div>
                                                        <div placement="top" ngbTooltip="Details" container="body"
                                                            class="userRowDetailIcon">
                                                            <span [data-feather]="'chevron-right'" [size]="16" [class]="'font-weight-bolder'"></span>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-between align-content-center mt-1">
                                            <div class="d-flex justify-content-start font-small-3 text-muted flex-column text-left pl-2">
                                                <span>Total users: 3</span>
                                                <span>Remaining users: 2</span>
                                                <span>Inactive users: 0</span>
                                            </div>
                                            <ngb-pagination class="d-flex justify-content-end mt-1 mr-1"
                                                [collectionSize]="30" [(page)]="pageBasic" aria-label="Default pagination">
                                                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                                        [size]="18" [class]="'font-weight-bolder'"></span>
                                                </ng-template>
                                                <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'"
                                                        [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
                                            </ngb-pagination>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- add-attachments-sidebar -->
<core-sidebar class="modal modal-slide-in usersidebar-modal fade" name="user-right-sidebar" overlayClass="modal-backdrop">
    <app-users-rightmenu></app-users-rightmenu>
</core-sidebar>
<!--/ add-attachments-sidebar -->