import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contentlibrary',
  templateUrl: './contentlibrary.component.html',
  styleUrls: ['./contentlibrary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentlibraryComponent implements OnInit {

  public pageBasic = 1;

  editContentLibrary(event) {
    if(event.target.nodeName != 'svg'){
    this.router.navigateByUrl('settings/contentlibrary/edit-content-lirbrary');
    // event.preventDefault();
    event.stopPropagation();
    }
    return;
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
