import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacts-addon',
  templateUrl: './contacts-addon.component.html',
  styleUrls: ['./contacts-addon.component.scss']
})
export class ContactsAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
