import { Injectable, Inject, Injector } from '@angular/core';
import feather from 'feather-icons';
import uuid from 'short-unique-id';
import { GlobalService } from 'app/global/global.service';
import { Blocks } from '../blocks/blocks';
import { tagsBuilder } from '../tags-builder/tagsBuilder';
import { PopOver } from '../popover/popover';
import { productTable } from '../product-table/productTable';

declare var $: any;
declare var CKEDITOR: any;
@Injectable({
    providedIn: 'root',
})
export class formBuilder {
    public instance_name = '';
    public uuid = new uuid({ length: 6, dictionary: 'number' });
    public keydownCount = 0;
    public selectMenuIsOpen = false;
    constructor(@Inject(Injector) private readonly injector: Injector) {
        var that = this;
        // that.toastr.toastrConfig.preventDuplicates = true;
        $(document).ready(function () {
            // $(document).off('click','.form-builder-menu-list').on('click','.form-builder-menu-list',function(){
            //     var form_type = $(this).attr('form-type');

            //     if(form_type == 'text'){
            //         $('.form-builder-parent-container').append(that.textFormBuilderHtml({uuid:that.uuid()}));
            //     }
            // });
            $(document).off('click', '.content-builder-menu-list').on('click', '.content-builder-menu-list', function () {
                var form_type = $(this).attr('form-type');
                var instance_name = $(this).parent().attr('instance_name');
                var lastSelection = $(CKEDITOR.instances[instance_name].getSelection().getStartElement().$);
                lastSelection.html(lastSelection.html().replace(/(\/+?)$/, '<span class="last">$1</span>'));
                $('span.last:last').remove();
                if ($(lastSelection).is(':empty')) {
                    $(lastSelection).html('<br/>');
                    $(CKEDITOR.instances[instance_name].element.$).addClass('cke-editor-placeholder');
                }
                $('.content-builder-menu').addClass('hidden').css({ top: 0, left: 0, position: 'fixed' });
                that.selectMenuIsOpen = false;
                that.keydownCount = 0;
                if (form_type == 'formbuilder') {
                    that.formBuilderBox({ type: 'formbuilder' });
                } else if (form_type == 'content') {
                    that.blocksFunc.createContentBox({ type: 'slashcommand' });
                } else if (form_type == 'pagebreak') {
                    that.blocksFunc.addPageBreak({ type: 'slashcommand' })
                } else if (form_type == 'producttable') {
                    that.productTableFunc.productTableBox({ type: 'slashcommand' })
                } else if (form_type == 'attachment') {
                    that.blocksFunc.addAttachmentBox({ type: 'slashcommand' })
                }
            });
        })
    }
    onKeyDownHandler(instance_name, data) {
        var builderClass = '';
        console.log('data.key=',data.key,'data.keyCode=',data.keyCode, 'match/=',data.target.innerText.match(/\/[_a-zA-Z0-9À-ž]*$/), 'match[=',data.target.innerText.match(/\[[_a-zA-Z0-9À-ž]*$/));
        if ($('[instance_name="' + instance_name + '"]').hasClass('form-builder')) {
            builderClass = '.form-builder-menu';
        } else {
            builderClass = '.content-builder-menu';
        }
        if (data.key == '/' || data.target.innerText.match(/\/[_a-zA-Z0-9À-ž]*$/)) {
            this.keydownCount++;
            setTimeout(() => {
                var { x, y } = getCaretCoordinates();
                $(builderClass).removeClass('hidden').attr('instance_name', instance_name).css({ top: y, left: x, position: 'fixed' });
            }, 200);
            this.selectMenuIsOpen = true;
        }else if(data.key == '[' || data.target.innerText.match(/\[[_a-zA-Z0-9À-ž]*$/)){
            this.keydownCount++;
            setTimeout(() => {
                var { x, y } = getCaretCoordinates();
                this.tagsBuilderFunc.showTagList({ top: y, left: x, position: 'fixed' },instance_name);
            }, 200);
            this.selectMenuIsOpen = true;
        } else {
            $(builderClass).addClass('hidden').attr('instance_name', instance_name).css({ top: 0, left: 0, position: 'fixed' });
            $('.tags-list-menu').addClass('hidden').attr('instance_name', instance_name).css({ top: 0, left: 0, position: 'fixed' });
            this.selectMenuIsOpen = false;
            this.keydownCount = 0;
        }
        // else if (data.keyCode == 8) {
        //     if (this.selectMenuIsOpen) {
        //         this.keydownCount--;
        //     }
        //     console.log(this.keydownCount);
        //     if (this.keydownCount == 0) {
        //         $(builderClass).addClass('hidden').attr('instance_name',instance_name).css({ top: 0, left: 0, position: 'fixed' });
        //         this.selectMenuIsOpen = false;
        //         this.keydownCount = 0;
        //     }
        // } else if (data.keyCode == 32 || data.keyCode == 13) {
        //     $(builderClass).addClass('hidden').attr('instance_name',instance_name).css({ top: 0, left: 0, position: 'fixed' });
        //     this.selectMenuIsOpen = false;
        //     this.keydownCount = 0;
        // } else {
        //     if (this.selectMenuIsOpen) {
        //         this.keydownCount++;
        //     }
        // }
    }

    textFormBuilderHtml(params: any = {}) {
        return `
        <div contenteditable="false" class="form-builder-table drag-fields" selected-field="1" field-name="Text Field" field="text-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" allow_multi_lines="0" slashcommandtable="1" column="50" selectedtype="full_name" selecttypetext="Full name" selectedtypeid="1" selectedtypeid="0">
            <div class="custum-form-builder-box fields-ele-container">
                <label class="custum-form-builder-label selected-label">Full name</label>
                <input class="custum-form-builder-field form-control form-builder-field-label" type="text" placeholder="Full name">
                <textarea class="hidden text-field-textarea form-control" placeholder="Full name"></textarea>
            </div>
        </div>
     `;
    }

    datepickerFormBuilderHtml(params: any = {}) {
        return `
        <div contenteditable="false" class="form-builder-table drag-fields" selected-field="4" field-name="Datepicker" field="datepicker-field" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" slashcommandtable="1" column="50" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedtypeid="0">
            <div class="custum-form-builder-box fields-ele-container">
                <label class="custum-form-builder-label selected-label">New field</label>
                <input class="custum-form-builder-field form-control form-builder-field-label datepicker-field" type="text" placeholder="New field">
            </div>
        </div>
        `;
    }

    checkboxFeildFormBuilderHtml(params: any = {}) {
        return `<div selected-field="2" field-name="Checkbox" field="checkbox-field" class="form-builder-table checkbox-field drag-fields" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" pre_checked="0" column="100" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedtypeid="0">
                <div class="drag-fields-inner-container checkbox-field-inner-container fields-ele-container">
                    <div class="checkbox-field-box">
                        ${feather.icons.check.toSvg({ class: 'cursor-pointer checkbox-checkIcon', width: 14, height: 14 })}
                    </div>
                    <span class="field-label checkbox-field-label selected-label">New field</span>
                </div>
            </div>
        `;
    }


    droplistFeildFormBuilder(params: any = {}) {
        var uuid = params.uuid;
        return `<div selected-field="3" field-name="Droplist" field="droplist-field" class="form-builder-table droplist-field drag-fields" uuid="${uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" column="50" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" optionlabel="" optionvalue="0" selectedtypeid="0">
                <div class="drag-fields-inner-container droplist-field-inner-container fields-ele-container">
                    <span class="custum-form-builder-label field-label selected-label">Select Option</span>
                    <div class="droplist-inner-box droplist-menu">
                        <span class="selected-option-label droplist-option-label form-builder-field-label" droplistplaceholder="Select Option"></span>
                        ${feather.icons['chevron-down'].toSvg({ class: 'cursor-pointer droplist-arrowDown', width: 14, height: 14 })}
                        <ng-dropdown-panel role="listbox" aria-label="Options list" class="ng-dropdown-panel ng-select-bottom droplist-dropdown hidden">
                            <div class="ng-dropdown-panel-items scroll-host">
                                <div class="ng-option not-found pointer-none" option_uuid="0">
                                    <span class="ng-option-label">No option found</span>
                                </div>
                            </div>
                        </ng-dropdown-panel>
                    </div>
                    <!--<script id="${uuid}" type="text/json">${JSON.stringify([])}</script>-->
                    <div id="${uuid}" data-options='${JSON.stringify([])}'></div>
                </div>
                </div>
            </div>
        `;
    }

    multiChoiceFeildFormBuilder(params: any = {}) {
        var uuid = params.uuid;
        var defaultOptions = [{ key: 'Option 1', value: this.uuid() }, { key: 'Option 2', value: this.uuid() }];
        var options = '';
        for (const option of defaultOptions) {
            options += `
                        <div class="multiChoice-item" option_uuid="${option.value}">
                            <div class="multiChoice-box">
                                <div class="multiChoice-inner-circle"></div>
                                ${feather.icons.check.toSvg({ class: 'cursor-pointer multiChoice-inner-check', width: 14, height: 14 })}
                            </div>
                            <label class="multiChoice-label-text">${option.key}</label>
                        </div>`;
        }
        return `<div selected-field="6" field-name="List" field="multichoice-field" class="form-builder-table multichoice-field multiChoice-radio drag-fields" uuid="${uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" multi_choice="0" hide_label="0" column="50" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedtypeid="0">
                    <div class="custum-form-builder-box">
                        <span class="custum-form-builder-label field-label selected-label">Select Option</span>
                        <!--<script id="${uuid}" type="text/json">${JSON.stringify(defaultOptions)}</script>-->
                        <div id="${uuid}" data-options='${JSON.stringify(defaultOptions)}'></div>
                        <div class="multiChoice-item-container fields-ele-container">
                            ${options}
                        </div>
                    </div>
                </div>
            `;
    }

    signatureFeildFormBuilder(params: any = {}) {
        return `<div selected-field="5" field-name="Signature" field="signature-field" class="form-builder-table signature-field drag-fields" uuid="${params.uuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" column="100" selectedtypeid="0">
                <div class="drag-fields-inner-container signature-field-inner-container fields-ele-container">
                    <span class="field-label selected-label">Signature</span>
                    <div class="clear-signature-btn" uuid="${params.uuid}">${feather.icons['rotate-ccw'].toSvg({ class: 'cursor-pointer', width: 20, height: 20 })}</div>
                    <canvas id="signaturepad${params.uuid}" width="100%" height=320></canvas>
                </div>
            </div>
        `;
    }

    formBuilderBox(params: any = {}) {
        var that = this;
        var count_id = this.uuid();
        var buildForm = that.textFormBuilderHtml({ uuid: that.uuid(), parentdiv: 1 });
        const formBuilderElement = document.createElement('div')
        formBuilderElement.className = 'content-box content-box-block form-builder-block marginminus70';
        formBuilderElement.innerHTML = `<div class="sort-helper">
        <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
        <div class="highlightSortContentBox"><p>Form builder</p><svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" class="Icon"><path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z" clip-rule="evenodd"></path></svg></div></div><div class="content-box-row"><div class="rowItem">${that.popOverFunc.listingPopover({classType:'form-builder',uuid:count_id,contentType:'settings'})}<div class="sort-helper-ss"><div class="sort-helper sort-helper-inner"><svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg><div class="highlightSortContentBox"><p>Form builder</p><svg width="28" height="28" viewBox="0 0 28 28" fill="currentColor" class="Icon"><path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z" clip-rule="evenodd"></path></svg></div></div></div><div class="content-box-inner" editor-uuid="${count_id}" type="form"><div class="editor${count_id} cke-editor-placeholder cke-editor-box form-builder" editor-uuid="${count_id}" ><div class="form-builder-parent-container" contenteditable="false">${buildForm}</div></div></div></div></div>`;
        if ($('.dropIndicator-bottom').length == 0 && $('.template-page').hasClass('addContentBox')) {
            $('.template-page').append(formBuilderElement)
        }else if($('.content-box-inner.active-border') && params.type == 'formbuilder'){
            $(formBuilderElement).insertAfter($('.content-box-inner.active-border').parents('.content-box'));
        } else {
            $(formBuilderElement).insertAfter(
                $('.dropIndicator-bottom').parent('.content-box'),
            )
        }
        that.GlobalServiceFunc.contentBoxSort();
        setTimeout(() => {
            $('.editor' + count_id).parents('.content-box-inner').addClass('active-border');
        }, 200);
    }

    private get GlobalServiceFunc() {
        return this.injector.get(GlobalService);
    }

    private get blocksFunc() {
        return this.injector.get(Blocks);
    }

    private get tagsBuilderFunc(){
        return this.injector.get(tagsBuilder);
    }

    private get popOverFunc(){
        return this.injector.get(PopOver);
    }

    private get productTableFunc(){
        return this.injector.get(productTable);
    }

}

const getCaretCoordinates = () => {
    let x, y;
    const selection = window.getSelection();
    if (selection.rangeCount !== 0) {
        const range = selection.getRangeAt(0).cloneRange();
        range.collapse(false);
        const rect = range.getClientRects()[0];
        if (rect) {
            x = rect.left;
            y = rect.top;
        }
    }
    return { x, y };
};