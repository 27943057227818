import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';


@Component({
  selector: 'app-documentfields',
  templateUrl: './documentfields.component.html',
  styleUrls: ['./documentfields.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DocumentfieldsComponent implements OnInit {

  public pageBasic = 1;

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  constructor(
    // private _userListService: UserListService,
    private _coreSidebarService: CoreSidebarService,
    // private _coreConfigService: CoreConfigService
  ) {  }
  ngOnInit(): void {
  }
}
