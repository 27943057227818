import {Injectable ,Component, OnInit, OnChanges, SimpleChanges, Input, ElementRef } from '@angular/core';
import { EditTemplateComponent } from '../../edit-template/edit-template.component';
import { formBuilder } from '../form-builder/formBuilder';
import uuid from 'short-unique-id';
import { GlobalService } from 'app/global/global.service';

declare var $: any;
@Injectable({
    providedIn: 'root'
})
@Component({
  selector: 'app-form-builder-settings',
  templateUrl: './form-builder-settings.component.html',
  styleUrls: ['./form-builder-settings.component.scss'],
  providers: [formBuilder]
})

export class FormBuilderSettingsComponent implements OnChanges {

  constructor(
    private template: EditTemplateComponent,
    private formBuilder: formBuilder,
    private elementRef: ElementRef,
    private globalService: GlobalService,
  ) { }
  public uuid = new uuid({ length: 6, dictionary: 'number' });
  @Input() data: any[];
  public formBuilderUuid = 0;
  public selectedFormFieldType = 0;
  public formBuilderFieldsPopup: boolean = false;
  formFieldTypes = [
    { id: 0, name: 'Add field', type: 'addfield', },
    { id: 1, name: 'Text field', type: 'text', },
    { id: 2, name: 'Datepicker', type: 'datepicker' },
    { id: 3, name: 'Droplist', type: 'droplist' },
    { id: 4, name: 'Checkbox', type: 'checkbox' },
    { id: 5, name: 'List', type: 'multichoice' },
    { id: 6, name: 'Signing Box', type: 'signature' },
  ];

  public selectedFontSize = '14';
  selectFontSize = [
    { id: 1, name: '8', fieldClass: '8' },
    { id: 2, name: '10', fieldClass: '10' },
    { id: 3, name: '12', fieldClass: '12' },
    { id: 4, name: '14', fieldClass: '14' },
    { id: 5, name: '16', fieldClass: '16' },
    { id: 6, name: '18', fieldClass: '18' },
    { id: 7, name: '20', fieldClass: '20' },
    { id: 8, name: '22', fieldClass: '22' },
    { id: 9, name: '24', fieldClass: '24' },
    { id: 10, name: '26', fieldClass: '26' },
    { id: 11, name: '28', fieldClass: '28' },
    { id: 12, name: '30', fieldClass: '30' },
  ];

  public formBuilders = [
    {
      "id": "1",
      "name": "Text Field",
      "type": "text",
      "field": "text-field",
      "column": "50"
    },
    {
      "id": "2",
      "name": "Checkbox",
      "type": "checkbox",
      "field": "checkbox-field",
      "column": "100"
    },
    {
      "id": "3",
      "name": "Droplist",
      "type": "droplist",
      "field": "droplist-field",
      "column": "50"
    },
    {
      "id": "4",
      "name": "List",
      "type": "multichoice",
      "field": "multichoice-field",
      "column": "50"
    },
    {
      "id": "5",
      "name": "Datepicker",
      "type": "datepicker",
      "field": "datepicker-field",
      "column": "50"
    },
    {
      "id": "6",
      "name": "Signature",
      "type": "signature",
      "field": "signature-field",
      "column": "100"
    },
  ];

  public formBuilderFields = [];

  ngOnInit(): void {
    // console.log('FormBuilderSettingsComponent',this.data)
  }

  updateSettings() {
    this.formBuilderUuid = this.data['uuid'];
    this.createFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.updateSettings();
    }
  }

  clsoe() {
    this.template.closeFormBuilderSettings(false);
  }

  addFormFeilds(form) {
    var uuid = this.uuid();
    var mainElement = $('.editor' + this.formBuilderUuid).find('.form-builder-parent-container');
    var compoenet = '';
    var fieldName = '';
    if (form.field == 'text-field') {
      compoenet = this.formBuilder.textFormBuilderHtml({ parentdiv: 0, uuid: uuid });
      fieldName = 'Full name';
    } else if (form.field == 'datepicker-field') {
      compoenet = this.formBuilder.datepickerFormBuilderHtml({ uuid: uuid });
      fieldName = 'New field';
    } else if (form.field == 'checkbox-field') {
      compoenet = this.formBuilder.checkboxFeildFormBuilderHtml({ uuid: uuid });
      fieldName = 'New field';
    } else if (form.field == 'droplist-field') {
      compoenet = this.formBuilder.droplistFeildFormBuilder({ uuid: uuid });
      fieldName = 'Droplist';
    } else if (form.field == 'multichoice-field') {
      compoenet = this.formBuilder.multiChoiceFeildFormBuilder({ uuid: uuid });
      fieldName = 'Select option';
    } else if (form.field == 'signature-field') {
      compoenet = this.formBuilder.signatureFeildFormBuilder({ uuid: uuid });
      fieldName = 'Signature';
    }
    mainElement.append(compoenet);
    this.FormFieldsPopup(false);
    this.formBuilderFields.push({ name: form.name, type: form.field, uuid: uuid, columnSize:form.column, fieldName:fieldName });
    if (form.field == 'datepicker-field') {
      this.globalService.initializeFlatpicker($('[uuid="' + uuid + '"] input'));
    }else if(form.field == 'signature-field'){
      this.globalService.initializeSignature(document.getElementById('signaturepad'+uuid),uuid);
    }
  }

  FormFieldsPopup(boolean) {
    this.formBuilderFieldsPopup = boolean;
  }

  createFields() {
    var that = this;
    var addedFormFields = document.querySelectorAll('.editor' + this.formBuilderUuid + ' .form-builder-parent-container > *');
    this.formBuilderFields = [];
    for (let index = 0; index < addedFormFields.length; index++) {
      let uuid = addedFormFields[index].getAttribute('uuid');
      let name = addedFormFields[index].getAttribute('field-name');
      let formType = addedFormFields[index].getAttribute('field');
      let columnSize = addedFormFields[index].getAttribute('column');
      let fieldName = addedFormFields[index].querySelector('.selected-label').textContent;
      this.formBuilderFields.push({ name: name, type: formType, uuid: uuid, columnSize:columnSize, fieldName:fieldName });
    }
    $('.added-form-fields-box').sortable(
      {
        handle:'.sort-form-fields',
        update : function(){
          that.autoSortFormBuilderFields();
        }
      }
    );
  }

  autoSortFormBuilderFields(){
    console.time('sort');
    var recreateFeilds = '';
    var signaturePoints = [];
    for (let index = 0; index < this.elementRef.nativeElement.querySelectorAll('.added-form-fields-box > *').length; index++) {
      const element = this.elementRef.nativeElement.querySelectorAll('.added-form-fields-box > *')[index];
      const formElement = document.querySelector('.form-builder-table[uuid="'+element.getAttribute('uuid')+'"]');
      formElement.querySelector('input')?.setAttribute('value',formElement.querySelector('input').value);
      if(formElement.querySelector('textarea')){
        formElement.querySelector('textarea').innerHTML = formElement.querySelector('textarea').value;
      }
      if(formElement.classList.contains('signature-field')){
        if(this.globalService.signatureObjects.hasOwnProperty(formElement.getAttribute('uuid'))){
          signaturePoints.push(this.globalService.signatureObjects[formElement.getAttribute('uuid')].toData());  
        }
      }
      recreateFeilds += formElement.outerHTML;
    }
    document.querySelector('.editor' + this.formBuilderUuid + ' .form-builder-parent-container').innerHTML = recreateFeilds;
    this.globalService.initializeFlatpicker(document.querySelectorAll('.editor' + this.formBuilderUuid + ' .form-builder-parent-container .datepicker-field'));

    for (let index = 0; index < document.querySelectorAll('.editor' + this.formBuilderUuid + ' .form-builder-parent-container .signature-field').length; index++) {
      const element = document.querySelectorAll('.editor' + this.formBuilderUuid + ' .form-builder-parent-container .signature-field')[index];
      const uuid = element.getAttribute('uuid');
      this.globalService.initializeSignature(element.querySelector('#signaturepad'+uuid),uuid,signaturePoints[index]);
      console.log(signaturePoints);
    }
    console.timeEnd('sort');
  }
}
