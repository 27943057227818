<div>
    <!-- Header -->
    <div class="d-flex justify-content-between align-items-center content-sidebar-header py-75 px-1 mt-50">
        <div class="cursor-pointer" *ngIf="productSettingsMenu != 'Default'" (click)="changeProductSettingsMenu('Default')">
            <i data-feather="arrow-left" [size]="16" class="ml-0"></i>
        </div>
        <div class="linked-organization-inner">
            <div class="d-flex justify-content-start align-items-center">
                <div class="user-page-info">
                    <h5 class="mb-0">
                        {{productSettingsMenu == 'Default' ? 'Product table Settings' : productSettingsMenu}}
                    </h5>
                </div>
            </div>
        </div>
        <div class="cursor-pointer closeMenu" (click)="close()">
            <i data-feather="x" [size]="16" class="ml-0"></i>
        </div>
    </div>

    <div class="productsSettings-scroll-content-container" [perfectScrollbar] *ngIf="productSettingsMenu == 'Default'">
        <div class="form-group px-1">
            <label class="col-form-label pt-0" for="product_name">Table Name</label>
            <input [value]="productTableName" type="text" class="form-control" id="product_name" placeholder="Product name" (keyup)="changeProductTableName($event)" />
        </div>

        <div class="product-table-settingsOptions px-1">
            <div class="product-table-settingslist" (click)="changeProductSettingsMenu('Currency')">
                <div class="product-table-settingslist-columnleft">
                    <h6 class="font-weight-normal d-block text-nowrap"> Currency </h6>
                    <small> Change table settings: currency, decimals. </small>
                </div>
                <div class="recipient-setting-arrowIcon">
                    <i data-feather="chevron-right" [size]="20"></i>
                </div>
            </div>

            <div class="product-table-settingslist" (click)="changeProductSettingsMenu('Tax')">
                <div class="product-table-settingslist-columnleft">
                    <h6 class="font-weight-normal d-block text-nowrap"> Tax </h6>
                    <small> Add item tax: total tax, per line tax. </small>
                </div>
                <div class="recipient-setting-arrowIcon">
                    <i data-feather="chevron-right" [size]="20"></i>
                </div>
            </div>

            <div class="product-table-settingslist" (click)="changeProductSettingsMenu('Discount')">
                <div class="product-table-settingslist-columnleft">
                    <h6 class="font-weight-normal d-block text-nowrap"> Discount </h6>
                    <small> Add item discount: total discount, per line discount. </small>
                </div>
                <div class="recipient-setting-arrowIcon">
                    <i data-feather="chevron-right" [size]="20"></i>
                </div>
            </div>

            <div class="product-table-settingslist" (click)="changeProductSettingsMenu('Design')">
                <div class="product-table-settingslist-columnleft">
                    <h6 class="font-weight-normal d-block text-nowrap"> Design </h6>
                    <small> Customize styles for individual table and override theme settings. </small>
                </div>
                <div class="recipient-setting-arrowIcon">
                    <i data-feather="chevron-right" [size]="20"></i>
                </div>
            </div>

            <div class="product-table-settingslist" (click)="changeProductSettingsMenu('Totals')">
                <div class="product-table-settingslist-columnleft">
                    <h6 class="font-weight-normal d-block text-nowrap"> Totals </h6>
                    <small> Permision for individual table: hide: show subtotal, show total, Show quantity etc. </small>
                </div>
                <div class="recipient-setting-arrowIcon">
                    <i data-feather="chevron-right" [size]="20"></i>
                </div>
            </div>

            <div class="product-table-settingslist" (click)="changeProductSettingsMenu('Category')">
                <div class="product-table-settingslist-columnleft">
                    <h6 class="font-weight-normal d-block text-nowrap"> Product categories </h6>
                    <small> Allow products list base on categories. </small>
                </div>
                <div class="recipient-setting-arrowIcon">
                    <i data-feather="chevron-right" [size]="20"></i>
                </div>
            </div>
            
            <div class="product-table-settingslist" (click)="changeProductSettingsMenu('Permissions')">
                <div class="product-table-settingslist-columnleft">
                    <h6 class="font-weight-normal d-block text-nowrap"> Permissions </h6>
                    <small> Permisions for individual table: allow roles to add product, remove, edit quantity, add new row </small>
                </div>
                <div class="recipient-setting-arrowIcon">
                    <i data-feather="chevron-right" [size]="20"></i>
                </div>
            </div>
        </div>
    </div>

    <div class="productsSettings-scroll-content-container" [perfectScrollbar] *ngIf="productSettingsMenu != 'Default'">
        <!-- Currency Setting -->
        <div class="currency-panel-area px-1" *ngIf="productSettingsMenu == 'Currency'">
            <div class="product-table-currency">
                <p class="mb-50 content-title font-weight-500">Currency</p>
                <div class="form-group col-lg-12 p-0">
                    <ng-select [items]="productCurrency" bindLabel="name" bindValue="code" [(ngModel)]="selectedProductCurrency" [clearable]="false" (change)="changeCurrency($event)">
                    </ng-select>
                </div>
            </div>
            <div class="product-table-currency mt-2">
                <p class="mb-0 content-title font-weight-500">Decimals</p>
                <div class="d-flex align-items-center decimal-setting-box">
                    <p class="mb-0 mr-75 content-title">Number of decimals</p>
                    <div class="form-group p-0 mb-0">
                        <input type="number" class="form-control" id="decimal" [placeholder]="numberOfDecimals" [value]="numberOfDecimals" max="2" min="0" (change)="changeCostDecimal($event)"/>
                    </div>
                </div>
                <small class="mt-1 d-block">
                    Number of decimals for this product table, maximum 2 
                </small>
            </div>
        </div>
        <!-- / Currency Setting -->

        <!-- Tax Setting -->
        <div class="tax-panel-area px-1" *ngIf="productSettingsMenu == 'Tax'">
            <div class="tax-radio" *ngIf="taxSettings.addTax == 1">
                <p class="mb-1 content-title font-weight-500">Tax</p>
                <div class="radio-box-container">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="0" [checked]="(taxSettings.totalTax == 0 ? true : false)" (change)="taxType(0)" />
                        <label class="form-check-label" for="inlineRadio1">Line item tax</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="1" [checked]="(taxSettings.totalTax == 1 ? true : false)" (change)="taxType(1)" />
                        <label class="form-check-label" for="inlineRadio2">Total tax</label>
                    </div>
                </div>
                <div class="row m-0 mt-1" *ngIf="taxSettings.totalTax == 1">
                    <div class="form-group col-lg-8 p-0 mb-0">
                        <input type="number" class="form-control" id="taxValue" placeholder="0" [value]="taxSettings.taxValue" (keyup)="changeTaxValue($event)" (change)="changeTaxValue($event)" min="0" />
                    </div>
                    <div class="form-group col-lg-4 mb-0 pr-0">
                        <ng-select [items]="taxInCurrency" bindLabel="name" bindValue="name" [(ngModel)]="taxSettings.taxType" [clearable]="false" (change)="changeTaxType($event)">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="product-tax-title mt-2" *ngIf="taxSettings.addTax == 1">
                <p class="mb-50 content-title font-weight-500">Edit your tax title</p>
                <p class="mb-1 d-block">You can change you tax title as (VAT, GST, HST, etc.)</p>
                <div class="totals-switchries-container">
                    <div class="custom-control custom-switch custom-control-inline">
                        <input type="checkbox" class="custom-control-input" id="editTaxTitle" [checked]="taxSettings.editTaxTitle" (change)="allowEditTaxTitle($event)"/>
                        <label class="custom-control-label" for="editTaxTitle"></label>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center" *ngIf="taxSettings.addTax == 0" (click)="addTax(1)">
                <i data-feather="plus-square" [size]="16" class="ml-0 text-success"></i>
                <p class="cursor-pointer text-success font-weight-bold d-inline m-0 pl-50">Add Tax</p>
            </div>
            <div class="d-flex align-items-center" *ngIf="taxSettings.addTax == 1" (click)="addTax(0)">
                <i data-feather="minus-square" [size]="16" class="ml-0 text-danger"></i>
                <p class="cursor-pointer text-danger font-weight-bold d-inline m-0 pl-50">Remove Tax</p>
            </div>
        </div>
        <!-- / Tax Setting -->

        <!-- Discount Setting -->
        <div class="discount-panel-area px-1" *ngIf="productSettingsMenu == 'Discount'">
            <div class="tax-radio" *ngIf="discountSettings.addDiscount == 1">
                <p class="mb-1 content-title font-weight-500">Discount</p>
                <div class="radio-box-container">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="lineitemdiscount" id="lineitemdiscount" value="1" [checked]="(discountSettings.totalDiscount == 0 ? true : false)" (change)="discountType(0)"/>
                        <label class="form-check-label" for="lineitemdiscount">Line item discount</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="totaldiscount" id="totaldiscount" value="1" [checked]="(discountSettings.totalDiscount == 1 ? true : false)" (change)="discountType(1)"/>
                        <label class="form-check-label" for="totaldiscount">Total discount</label>
                    </div>
                </div>
                <div class="row m-0 mt-1" *ngIf="discountSettings.totalDiscount == 1">
                    <div class="form-group col-lg-8 p-0 mb-0">
                        <input type="number" class="form-control" id="discountValue" placeholder="0" [value]="discountSettings.discountValue" (keyup)="changeDiscountValue($event)" (change)="changeDiscountValue($event)" min="0" />
                    </div>
                    <div class="form-group col-lg-4 mb-0 pr-0">
                        <ng-select [items]="discountInCurrency" bindLabel="name" bindValue="name" [(ngModel)]="discountSettings.discountType" [clearable]="false" (change)="changeDiscountType($event)" [clearable]="false">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-2 align-items-center" *ngIf="discountSettings.addDiscount == 0" (click)="addDiscount(1)">
                <i data-feather="plus-square" [size]="16" class="ml-0 text-success"></i>
                <p class="cursor-pointer text-success font-weight-bold d-inline m-0 pl-50">Add Discount</p>
            </div>
            <div class="d-flex mt-2 align-items-center" *ngIf="discountSettings.addDiscount == 1" (click)="addDiscount(0)">
                <i data-feather="minus-square" [size]="16" class="ml-0 text-danger"></i>
                <p class="cursor-pointer text-danger font-weight-bold d-inline m-0 pl-50">Remove Discount</p>
            </div>
        </div>
        <!-- / Discount Setting -->

        <!-- Designs Setting -->
        <div class="design-panel-area" *ngIf="productSettingsMenu == 'Design'">
            <div class="table-design-sec px-1">
                <div class="product-table-design w-full">
                    <p class="mb-50 content-title font-weight-500">Header</p>
                    <div class="totals-switchries-container mb-0">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="header" [checked]="tableDesignSettings.showHeader" (change)="showHideHeader($event)" />
                            <label class="custom-control-label" for="header">Show header</label>
                        </div>
                    </div>
                </div>
                <div class="product-table-design w-full mt-1">
                    <p class="mb-50 content-title font-weight-500">Borders</p>
                    <div class="totals-switchries-container mb-0">
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="vertical" [checked]="tableDesignSettings.showVerticalborder" (change)="showHideVerticalBorder($event)"/>
                            <label class="custom-control-label" for="vertical">Vertical</label>
                        </div>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="horizontal" [checked]="tableDesignSettings.showHorizontalborder" (change)="showHideHorizontalBorder($event)"/>
                            <label class="custom-control-label" for="horizontal">Horizontal</label>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mb-2 mt-1" />

            <div class="table-design-sec px-1">
                <div class="product-table-design">
                    <p class="mb-50 content-title font-weight-500">Headlines</p>
                    <div class="row m-0">
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font size</label>
                            <ng-select [items]="productTableFontSize" bindLabel="size" bindValue="size" [(ngModel)]="tableDesignSettings.header_fontsize" appendTo="body" [clearable]="false" (change)="changeFontSize($event,'header_fontsize','--heading-font-size')">
                            </ng-select>
                        </div>
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font style</label>
                            <ng-select [items]="productTableFontStyle" bindLabel="name" bindValue="name" [(ngModel)]="tableDesignSettings.header_fontstyle" appendTo="body" [clearable]="false" (change)="changeFontStyle($event,'header_fontstyle','--heading-font-style')">
                            </ng-select>
                        </div>
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">background color</label>
                            <div class="product-table-design-ele table-setting-color-box position-relative">
                                <input class="form-control" [style.background]="tableDesignSettings.header_bgcolor" [value]="tableDesignSettings.header_bgcolor" readonly />

                                <div class="color-picker-ts-box">
                                    <div [style.background]="tableDesignSettings.header_bgcolor" [(colorPicker)]="tableDesignSettings.header_bgcolor" class="color-picker-ts" [cpOutputFormat]="'hex'" [value]="tableDesignSettings.header_bgcolor" (colorPickerChange)="changeColor($event,'header_bgcolor','--heading-background-color')"></div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font color</label>
                            <div class="product-table-design-ele table-setting-color-box position-relative">
                                <input class="form-control" [style.background]="tableDesignSettings.header_fontcolor"  [cpOutputFormat]="'hex'" [value]="tableDesignSettings.header_fontcolor" readonly />

                                <div class="color-picker-ts-box">
                                    <div [style.background]="tableDesignSettings.header_fontcolor" [(colorPicker)]="tableDesignSettings.header_fontcolor" class="color-picker-ts" [cpOutputFormat]="'hex'" [value]="tableDesignSettings.header_fontcolor" (colorPickerChange)="changeColor($event,'header_fontcolor','--heading-font-color')"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mb-2 mt-1" />

            <div class="table-design-sec px-1">
                <div class="product-table-design">
                    <p class="mb-50 content-title font-weight-500">Title</p>
                    <div class="row m-0">
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font size</label>
                            <ng-select [items]="productTableFontSize" bindLabel="size" bindValue="size" [(ngModel)]="tableDesignSettings.title_fontsize" appendTo="body" [clearable]="false" (change)="changeFontSize($event,'title_fontsize','--title-font-size')">
                            </ng-select>
                        </div>
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font style</label>
                            <ng-select [items]="productTableFontStyleWithBold" bindLabel="name" bindValue="name" [(ngModel)]="tableDesignSettings.title_fontstyle" appendTo="body" [clearable]="false" (change)="changeFontStyle($event,'title_fontstyle','--title-font-style')">
                            </ng-select>
                        </div>
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font color</label>
                            <div class="product-table-design-ele table-setting-color-box position-relative">
                                <input class="form-control" [style.background]="tableDesignSettings.title_fontcolor" [cpOutputFormat]="'hex'" [value]="tableDesignSettings.title_fontcolor" readonly />

                                <div class="color-picker-ts-box">
                                    <div [style.background]="tableDesignSettings.title_fontcolor" class="color-picker-ts" [(colorPicker)]="tableDesignSettings.title_fontcolor" [cpOutputFormat]="'hex'" [value]="tableDesignSettings.title_fontcolor" (colorPickerChange)="changeColor($event,'title_fontcolor','--title-font-color')"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mb-2 mt-1" />

            <div class="table-design-sec mb-50 px-1">
                <div class="product-table-design">
                    <p class="mb-50 content-title font-weight-500">Description</p>
                    <div class="form-group mb-1 mt-1">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" [checked]="tableDesignSettings.is_desc" class="custom-control-input" id="showDesccripiton" (change)="toggleDescriptoin($event)"/>
                            <label class="custom-control-label product-table-dec-label" for="showDesccripiton">Show Description</label>
                        </div>
                    </div>
                    <div class="row m-0" *ngIf="tableDesignSettings.is_desc">
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font size</label>
                            <ng-select [items]="productTableFontSize" bindLabel="size" bindValue="size" [(ngModel)]="tableDesignSettings.desc_fontsize" appendTo="body" [clearable]="false" (change)="changeFontSize($event,'desc_fontsize','--desc-font-size')">
                            </ng-select>
                        </div>
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font style</label>
                            <ng-select [items]="productTableFontStyleWithBold" bindLabel="name" bindValue="name" [(ngModel)]="tableDesignSettings.desc_fontstyle" appendTo="body" [clearable]="false" (change)="changeFontStyle($event,'desc_fontstyle','--desc-font-style')">
                            </ng-select>
                        </div>
                        <div class="form-group col-lg-12 mb-0 pl-0 pr-0 mb-1">
                            <label class="col-form-label pt-0">Font color</label>
                            <div class="product-table-design-ele table-setting-color-box position-relative">
                                <input class="form-control" [style.background]="tableDesignSettings.desc_fontcolor" [cpOutputFormat]="'hex'" [value]="tableDesignSettings.desc_fontcolor" readonly />

                                <div class="color-picker-ts-box">
                                    <div [style.background]="tableDesignSettings.desc_fontcolor" class="color-picker-ts" [(colorPicker)]="tableDesignSettings.desc_fontcolor" [cpOutputFormat]="'hex'" [value]="tableDesignSettings.title_fontcolor" (colorPickerChange)="changeColor($event,'desc_fontcolor','--desc-font-color')"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Designs Setting -->

        <!-- Totals -->
        <div class="totals-panel-area" *ngIf="productSettingsMenu == 'Totals'">
            <div class="product-table-totals">
                <!-- <p class="mb-1 content-title font-weight-500 px-1">Totals</p> -->
                <div class="totals-switchries-container">
                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Subtotal (below table)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_subtotal" [checked]="totalSettingsOfTable.show_subtotal" (change)="toggleSubTotal($event)" />
                            <label class="custom-control-label" for="show_subtotal"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Total (below table)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_total" [checked]="totalSettingsOfTable.show_total" (change)="toggleTotal($event)" />
                            <label class="custom-control-label" for="show_total"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Quantity (below table)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_totalquantity" [checked]="totalSettingsOfTable.show_totalquantity" (change)="toggleMainQuantity($event)" />
                            <label class="custom-control-label" for="show_totalquantity"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Item Tax (below table)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_inlinetaxttotal" [checked]="totalSettingsOfTable.show_inlinetaxttotal" (change)="toggleMainTax($event)"/>
                            <label class="custom-control-label" for="show_inlinetaxttotal"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Item Discount (below table)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_inlinediscounttotal" [checked]="totalSettingsOfTable.show_inlinediscounttotal" (change)="toggleMainDiscount($event)"/>
                            <label class="custom-control-label" for="show_inlinediscounttotal"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Include in grand total</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="addtablein_grandtotal" [checked]="totalSettingsOfTable.addtablein_grandtotal" (change)="toggleGrandTotal($event)"/>
                            <label class="custom-control-label" for="addtablein_grandtotal"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Price (on row)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_priceperunit" [checked]="totalSettingsOfTable.show_priceperunit" (change)="togglePriceOnRow($event)"/>
                            <label class="custom-control-label" for="show_priceperunit"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Quantity (on row)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_itemquantities" [checked]="totalSettingsOfTable.show_itemquantities" (change)="toggleQuantityOnRow($event)"/>
                            <label class="custom-control-label" for="show_itemquantities"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Show Totals (on row)</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="show_inlineitemtotal" [checked]="totalSettingsOfTable.show_inlineitemtotal" (change)="toggleTotalOnRow($event)"/>
                            <label class="custom-control-label" for="show_inlineitemtotal"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Add/Remove Products</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="allow_addremoveproducts" [checked]="totalSettingsOfTable.allow_addremoveproducts" (change)="toggleAddRemoveProduct($event)"/>
                            <label class="custom-control-label" for="allow_addremoveproducts"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Add Rows</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="allow_addnewrows" [checked]="totalSettingsOfTable.allow_addnewrows" (change)="toggleAddRows($event)"/>
                            <label class="custom-control-label" for="allow_addnewrows"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center px-1">
                        <p for="name" class="pt-0 pb-0 mb-0">Add Columns</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="allow_addnewcolumns" [checked]="totalSettingsOfTable.allow_addnewcolumns" (change)="toggleAddColumns($event)"/>
                            <label class="custom-control-label" for="allow_addnewcolumns"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Totals -->

        <!-- Product categories -->
        <div class="product-categories-panel-area px-1" *ngIf="productSettingsMenu == 'Category'">
            <div class="product-table-categories">
                <p class="mb-50 content-title font-weight-500">Categories</p>
                <p class="mb-75 d-block">Show products as per category seleceted</p>
                <div class="totals-switchries-container d-flex justify-content-between align-items-center">
                    <label for="name" class="pt-0 pb-0 mb-0">Others</label>
                    <div class="custom-control custom-switch custom-control-inline">
                        <input type="checkbox" class="custom-control-input" id="others" />
                        <label class="custom-control-label" for="others"></label>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Product categories -->

        <!-- Permissions -->
        <div class="permissions-panel-area px-1" *ngIf="productSettingsMenu == 'Permissions'">
            <div class="mb-2">
                <p class="mb-75 content-title font-weight-500">Which roles are allowed to edit the product table?</p>
                <div class="form-group col-lg-12 p-0">
                    <ng-select [items]="rolesAllowedPermission" bindLabel="name" bindValue="id" [(ngModel)]="tablePermissionForRole.whocanedit" [clearable]="false" (change)="changePermissionSettings($event,'whocanedit')">
                    </ng-select>
                </div>
            </div>
            <div class="product-table-totals">
                <p class="mb-75 content-title font-weight-500">Allow product table to work on signing page</p>

                <div class="permissions-switchries-container">
                    <div class="form-group d-flex justify-content-between align-items-center">
                        <p for="name" class="pt-0 pb-0 mb-0">Allow to add products</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="permissionaddproduct" [checked]="tablePermissionForRole.permissionaddproduct" (change)="changePermissionSettings($event,'permissionaddproduct')"/>
                            <label class="custom-control-label" for="permissionaddproduct"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center">
                        <p for="name" class="pt-0 pb-0 mb-0">Allow to remove products</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="permissionremoveproduct" [checked]="tablePermissionForRole.permissionremoveproduct" (change)="changePermissionSettings($event,'permissionremoveproduct')"/>
                            <label class="custom-control-label" for="permissionremoveproduct"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center">
                        <p for="name" class="pt-0 pb-0 mb-0">Allow to add new row</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="permissionaddrow" [checked]="tablePermissionForRole.permissionaddrow" (change)="changePermissionSettings($event,'permissionaddrow')"/>
                            <label class="custom-control-label" for="permissionaddrow"></label>
                        </div>
                    </div>

                    <div class="form-group d-flex justify-content-between align-items-center">
                        <p for="name" class="pt-0 pb-0 mb-0">Allow to edit quantity</p>
                        <div class="custom-control custom-switch custom-control-inline">
                            <input type="checkbox" class="custom-control-input" id="permissioneditqty" [checked]="tablePermissionForRole.permissioneditqty" (change)="changePermissionSettings($event,'permissioneditqty')"/>
                            <label class="custom-control-label" for="permissioneditqty"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Permissions -->
    </div>
</div>