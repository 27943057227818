<div class="content-area-wrapper container-xxl p-0">
    <!-- addons main Sidebar (Filter) -->
    <core-sidebar class="sidebar-left" name="todo-sidebar-main" overlayClass="body-content-overlay" collapsibleSidebar="bs-gt-lg">
        <!-- Main Sidebar -->
        <div class="main-sidebar sidebar">
            <div class="sidebar-content todo-sidebar">
                <div class="todo-app-menu">
                    <div class="sidebar-menu-list pt-2">
                        <div class="list-group list-group-filters">
                            <a class="list-group-item list-group-item-action active">
                                <i size="18" class="mr-50 feather icon-copy align-middle"></i>
                                <span class="align-middle">All Add-Ons</span>
                            </a>
                            <a class="list-group-item list-group-item-action">
                                <i size="18" class="mr-50 feather icon-check-circle align-middle"></i>
                                <span class="align-middle">Enabled Add-Ons</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Main Sidebar -->
    </core-sidebar>
    <!--/ addons main Sidebar -->

    <!-- content-right -->
    <div class="content-right">
        <div class="content-wrapper container-xxl p-0">
            <div class="content-body">
                <div class="todo-app-list">
                    <div class="app-fixed-search d-flex align-items-center">
                        <div class="sidebar-toggle d-block d-lg-none ml-1">
                            <button class="btn p-0" (click)="toggleSidebar('todo-sidebar-main')">
                                <i class="feather icon-menu"></i>
                            </button>
                        </div>

                        <div class="d-flex align-content-center justify-content-between w-100">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="feather icon-search text-muted"></i></span>
                                </div>
                                <input type="text" class="form-control" id="addons-search" placeholder="Search Add-ons"
                                    aria-label="Search..." aria-describedby="addons-search" />
                            </div>
                        </div>
                    </div>

                    <div class="addonsMiddleSection addonMainView-scroll-content-container p-2" [perfectScrollbar]>
                        <div class="row">
                            <!-- addons cpmponents -->
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-pdfbreak-addon></app-pdfbreak-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-auto-save-changes-addon></app-auto-save-changes-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-simple-storage-addon></app-simple-storage-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-document-workflow-addon></app-document-workflow-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-get-contactsfrom-apiaddon></app-get-contactsfrom-apiaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-connect-your-dropbox-addon></app-connect-your-dropbox-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-content-library-addon></app-content-library-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-droplist-settings-addon></app-droplist-settings-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-product-table-addon></app-product-table-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-document-fields-addon></app-document-fields-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-contract-control-addon></app-contract-control-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-connect-your-google-drive-addon></app-connect-your-google-drive-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-dsrplugand-play-addon></app-dsrplugand-play-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-document-key-addon></app-document-key-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-bulk-send-addon></app-bulk-send-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-schoolsoft-integration-addon></app-schoolsoft-integration-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-csvexportin-document-addon></app-csvexportin-document-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-branding-addon></app-branding-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-pointof-sale-addon></app-pointof-sale-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-ftpupload-addon></app-ftpupload-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-in-meeting-signing-addon></app-in-meeting-signing-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-get-link-addon></app-get-link-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-credit-safe-addon></app-credit-safe-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-revoke-addon></app-revoke-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-apiaddon></app-apiaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-task-management-addon></app-task-management-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-automatical-deletionof-documents-addon></app-automatical-deletionof-documents-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-time-wave-addon></app-time-wave-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-eps-addon></app-eps-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-swedish-bank-idaddon></app-swedish-bank-idaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-finnish-bank-idaddon></app-finnish-bank-idaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-danish-bank-idaddon></app-danish-bank-idaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-norwegian-bank-idaddon></app-norwegian-bank-idaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-send-pdfword-excel-addon></app-send-pdfword-excel-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-invitation-order-addon></app-invitation-order-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-contacts-addon></app-contacts-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-video-attachment-addon></app-video-attachment-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-attachment-addon></app-attachment-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-transfer-signature-role-addon></app-transfer-signature-role-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-attest-participant-addon></app-attest-participant-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-expiration-date-addon></app-expiration-date-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-editor-addon></app-editor-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-share-point-addon></app-share-point-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-groups-and-permissions-addon></app-groups-and-permissions-addon>
                            </div>
<!-- ---------new----- -->
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-azure-active-directory-addon></app-azure-active-directory-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-document-chat-addon></app-document-chat-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-document-plus-addon></app-document-plus-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-verification-using-bsiaddon></app-verification-using-bsiaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-samlidpauthentication-addon></app-samlidpauthentication-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-two-factor-authentication-addon></app-two-factor-authentication-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-lock-optionfortemplate-details-addon></app-lock-optionfortemplate-details-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-pipedrive-addon></app-pipedrive-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-hub-spot-addon></app-hub-spot-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-microsoft-dynamics365-addon></app-microsoft-dynamics365-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-hide-optionfor-documentfields-addon></app-hide-optionfor-documentfields-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-viewer-party-addon></app-viewer-party-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-document-type-addon></app-document-type-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-document-price-addon></app-document-price-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-custom-smsinvitation-addon></app-custom-smsinvitation-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-data-groups-addon></app-data-groups-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-smtpaddon></app-smtpaddon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-upload-files-after-signing-addon></app-upload-files-after-signing-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-billecta-data-addon></app-billecta-data-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-template-redirect-addon></app-template-redirect-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-signature-popup-message-addon></app-signature-popup-message-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-select-typeof-signature-addon></app-select-typeof-signature-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-widgetwith-billecta-addon></app-widgetwith-billecta-addon>
                            </div>
                            <div class="col-md-6 col-sm-6 col-lg-4 addonContainer">
                                <app-upsales-addon></app-upsales-addon>
                            </div>



                            <!--/ addons cpmponents -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>