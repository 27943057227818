import { Injectable } from "@angular/core";
import { ApiService } from "app/apiService/api.service";
import axios from "axios";

@Injectable({
    providedIn: 'root'
})

export class OrganizationService {
    constructor (private apiService: ApiService) {
        this.apiService.initAxios();
    }


    fetchOrganization(page:any,query:any){
        return axios.get(`organizations?page=${page}&q=${query}`);
    }

    addOrganization(formData:any) {
        return axios.post('organizationadd', formData);
    }

    updateOrganization(formData:any){
        return axios.put('organizationupdate', formData);
    }

    deleteOrganization(id:any) {
        return axios.delete('organizationdelete?id='+ id);
    }

    searchRecipient(query:any) {
        return axios.get('recipientsearchorganization?q='+ query);
    }

    link(formData:any){
        return axios.put('link', formData);
    }
};