import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-pdfword-excel-addon',
  templateUrl: './send-pdfword-excel-addon.component.html',
  styleUrls: ['./send-pdfword-excel-addon.component.scss']
})
export class SendPDFWordExcelAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
