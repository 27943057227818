import { Component, OnInit, Injectable, Input, OnChanges, SimpleChanges } from '@angular/core';
import 'jquery-ui-dist/jquery-ui';
import uuid from 'short-unique-id';
import { EditTemplateComponent } from '../../edit-template/edit-template.component';
import { ContentTabComponent } from '../content-tab/content-tab.component';

declare var $: any;
@Injectable({
    providedIn: 'root',
})
@Component({
  selector: 'app-pdf-block-settings',
  templateUrl: './pdf-block-settings.component.html',
  styleUrls: ['./pdf-block-settings.component.scss']
})
export class PdfBlockSettingsComponent implements OnChanges {
  @Input() data: any[];
  public pdf_uuid = 0;
  constructor(
    private template: EditTemplateComponent,
    private contenttab: ContentTabComponent
  ) { }

  ngOnInit(): void {
    var that = this;
    that.pdf_uuid = that.data['uuid'];
    $(document).ready(function () {
        that.contenttab.initDragFields();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.pdf_uuid = changes['data'].currentValue['uuid'];
      this.contenttab.initDragFields();
    }
  }

  clsoe() {
    this.template.closePdfBlockSettings(false);
  }

}
