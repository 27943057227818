<div class="content-wrapper editContentLibraryRightMenu-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="editContentLibRightMenu-container card">
                    <div class="p-1 border-bottom">
                        <button type="button" class="btn btn-success btn-block">Save changes</button>
                    </div>
                    <div class="p-1 editContentLibRightMenu-fields">
                        <div class="form-group mb-75">
                            <label for="SMSOriginator">Template</label>
                            <input type="text" class="form-control" id="" placeholder="Template" />
                        </div>
                        <div class="form-group mb-75">
                            <label for="SMSOriginator">Attribute name</label>
                            <input type="text" class="form-control" id="" placeholder="Attribute name" />
                        </div>
                        <div class="form-group mb-75">
                            <label for="customSelect">Category</label>
                            <ng-select [items]="chooseCategory" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                                placeholder="Category"> </ng-select>
                        </div>
                        <div class="form-group mb-75">
                            <label for="SMSOriginator">Roles</label>
                            <input type="text" class="form-control" id="" placeholder="Roles" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>