import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import intlTelInput from 'intl-tel-input';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecipientsService } from '../recipients.service';
import { GlobalService } from 'app/global/global.service';

declare var intlTelInputUtils: any;

@Component({
  selector: 'app-add-recipient-sidebar',
  templateUrl: './add-recipient-sidebar.component.html',
  styleUrls: ['./add-recipient-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})

export class AddRecipientSidebarComponent implements OnInit {
  @Output('fetchRecipient') fetchRecipient : EventEmitter<any> = new EventEmitter();
  size = 30;
  public hiddenFields: boolean = false;
  public changeText = "Show more";
  private intl: any;
  public recipientForm: FormGroup;
  public loading = false;
  public submitted = false;
  public phone_no_valid = true;
  ngOnInit(): void {

    this.recipientForm = this._formBuilder.group({
      firstname: ['',Validators.required],
      lastname: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_no: [''],
      address: [''],
      persnol_no: [''],
      zipcode: [''],
      city: [''],
    });

    const input = document.querySelector("#phone");
    this.intl = intlTelInput(input, {
      separateDialCode: true,
      initialCountry: "se",
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.0/build/js/utils.js"
    });
  }

  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _formBuilder: FormBuilder,
    private recipientsService: RecipientsService,
    private globalService: GlobalService,
  ) { }

  // convenience getter for easy access to form fields
  get form() {
    return this.recipientForm.controls;
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  showHiddenFields() {
    this.hiddenFields = !this.hiddenFields;
    this.changeText = (this.hiddenFields ? 'Hide' : 'Show more');
  }

  async saveRecipient() {
    var error = this.intl.getValidationError();
    var number = this.intl.getNumber(intlTelInputUtils.numberFormat.E164)
    console.log(this.intl.isValidNumber(), error, number);
    this.submitted = true;

    // stop here if form is invalid
    if (this.recipientForm.invalid) {
      return;
    }else if(!this.intl.isValidNumber() && this.intl.getNumber(intlTelInputUtils.numberFormat.E164) != ""){
      this.phone_no_valid = false;
      console.log(this.submitted && !this.phone_no_valid);
      return;
    }
    this.recipientForm.patchValue({phone_no:this.intl.getNumber(intlTelInputUtils.numberFormat.E164)});
    this.loading = true;
    const recipient = await this.recipientsService.addRecipient(this.recipientForm.value);
    if(recipient.status != 200){
      this.globalService.toastrError(recipient.data.message);
      this.loading = false;
      return;
    }
    this.globalService.toastrSuccess(recipient.data.message);
    this.loading = false;
    this.submitted = false;
    this.recipientForm.reset();
    this.toggleSidebar('add-recipient-right-sidebar');
    this.fetchRecipient.emit();
  }

}
