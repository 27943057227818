import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-dsrplugand-play-addon',
  templateUrl: './dsrplugand-play-addon.component.html',
  styleUrls: ['./dsrplugand-play-addon.component.scss']
})
export class DSRPlugandPlayAddonComponent implements OnInit {

   // modal Basic
modalOpen(dsrPlugandPlaySettingModal) {
  this.modalService.open(dsrPlugandPlaySettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
  pagination: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};

chooseAuthentication = [
  { id: 1, name: 'Vilnius' },
  { id: 2, name: 'Kaunas' },
  { id: 3, name: 'Pavilnys' },
  { id: 4, name: 'Pabradė' },
  { id: 5, name: 'Klaipėda' }
];
chooseLanguage = [
  { id: 1, name: 'Vilnius' },
  { id: 2, name: 'Kaunas' },
  { id: 3, name: 'Pavilnys' },
  { id: 4, name: 'Pabradė' },
  { id: 5, name: 'Klaipėda' }
];
chooseRecipient = [
  { id: 1, name: 'Vilnius' },
  { id: 2, name: 'Kaunas' },
  { id: 3, name: 'Pavilnys' },
  { id: 4, name: 'Pabradė' },
  { id: 5, name: 'Klaipėda' }
];

hiddenQRcodeCont: boolean = false;

showQRCode() {
  this.hiddenQRcodeCont = !this.hiddenQRcodeCont;
}

constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
