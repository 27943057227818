import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DocumentTypeService } from './document-type.service';
import { GlobalService } from 'app/global/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documenttype',
  templateUrl: './documenttype.component.html',
  styleUrls: ['./documenttype.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DocumenttypeComponent implements OnInit {
  @ViewChild('addDocumentTypeModal', { static: false }) addDocumentTypeModal: ElementRef; elm: HTMLElement;
  public page: any = 1;
  public documentTypeForm: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;
  public documentTypes: any = [];
  public pagination: any = [];
  public isRecordFetched: boolean = true;


  constructor( 
    private modalService: NgbModal, 
    private formBuilder: FormBuilder, 
    private documentTypeService: DocumentTypeService,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.initForm({});
    this.getDocumentTypes();
  }

  async getDocumentTypes(page = 1){
    this.page = page;
    const documentTypes = await this.documentTypeService.getDocumentTypes(page);
    this.documentTypes = documentTypes.data.data.data ?? [];
    this.pagination    = documentTypes.data.data;
    this.isRecordFetched = false;
  }


   /**
   * Show modal
   * @param addDocumentTypeModal 
   */
  addDocumentTypeModalOpen(addDocumentTypeModal) {
    this.modalService.open(addDocumentTypeModal);
  }


  /**
   * Init form with data;
   * @param data 
   */
  initForm(data:any) {
    this.documentTypeForm = this.formBuilder.group({
      id: [data.id ?? ''],
      document_type: [data.document_type ?? '', [Validators.required]]
    });
  }

/** 
  * Convenience getter for easy access to form fields 
  */
  get form() {
    return this.documentTypeForm.controls;
  }


  /**
   * Close modal and reseting form values
   */
  closeModel() {
    this.modalService.dismissAll();
    this.documentTypeForm.reset();
  }


  /**
   * Passing data to form which exist in modal
   * @param documentType 
   */
  editDocumentType (documentType:any) {
    this.initForm(documentType);
    this.addDocumentTypeModalOpen(this.addDocumentTypeModal);
  }


  /**
   * Submiting form and creating or updateing document types
   * @returns 
   */
  async submitDocumentType() {
    this.submitted = true;
    if(this.documentTypeForm.invalid){
      return;
    }
    this.loading = true;
    const documenttype = await this.documentTypeService.submitDocumentTypes(this.documentTypeForm.value);
    if(documenttype.status != 200){
      this.loading = false;
      this.submitted = false;
      this.globalService.toastrError(documenttype.data.message);
      return;
    }
    console.log(this.documentTypes, 'this.documentTypes')
    this.documentTypes.push(documenttype.data.data)
    this.globalService.toastrSuccess(documenttype.data.message);
    this.loading = false;
    this.submitted = false;
    this.modalService.dismissAll();
    this.documentTypeForm.reset();
  }

  confirmationAlert(data:any, index:any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This document type will be deleted.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#ef0016',
      cancelButtonText: 'No',
    }).then(async (result) => {
      if (result.value) {
       const documentType = await this.documentTypeService.deleteDocumentType(data.id);
       if(documentType.status != 200){
        this.globalService.toastrError(documentType.data.message);
        return;
       } 
       this.globalService.toastrSuccess(documentType.data.message);
       this.documentTypes.splice(index, 1);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        
      }
    });
  }

}
