<div class="slideout-content">
    <!-- Modal to Document right sidebar starts-->
    <div class="modalsd modal-slide-in sdfade Link-document-right-sidebar" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <div class="progress-wrapper linkDocumentSideBarProgress mb-0">
                    <ngb-progressbar type="primary" class="progress-bar-primary" [value]="45"></ngb-progressbar>
                </div>
                <div class="modal-header mb-1 d-flex justify-content-between align-items-center px-2 py-1">
                    <button type="button" class="btn btn-outline-primary btn-sm" rippleEffect>Signed</button>
                    <div class="d-flex justify-content-between align-items-center text-dark">
                        <!-- <i class="feather icon-x ml-1" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('document-right-sidebar')"></i> -->

                        <i class="feather font-medium-1 icon-mail ml-1 cursor-pointer" placement="auto" ngbTooltip="Send SMS or Email reminder to recipient"></i>
                        <i class="feather font-medium-1 icon-send ml-1 cursor-pointer" placement="auto" ngbTooltip="Send a new document by reusing by this one"></i>
                        <i class="feather font-medium-1 icon-download ml-1 cursor-pointer" placement="auto" ngbTooltip="PDF"></i>
                        <div class="documentRightMenuDropdown" ngbDropdown>
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow ml-1 d-flex" id="todoActions"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="feather font-medium-1 icon-more-vertical cursor-pointer text-body" placement="auto"
                                    ngbTooltip="More"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-maximize"></i>
                                    Fullscreen</a>
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-file-plus"></i>
                                    Linked with bugs in template</a>
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-calendar"></i>
                                    Internal reminder</a>
                                <a ngbDropdownItem class="sort-asc text-danger">
                                    <i class="feather text-danger font-medium-1 mr-50 icon-trash"></i>
                                    Delete permanently</a>
                            </div>
                        </div>
                        <i class="feather font-medium-1 icon-x ml-1 cursor-pointer" placement="auto" ngbTooltip="Close"
                            data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('document-right-sidebar')"></i>
                    </div>
                    <!-- <h5 class="modal-title" id="exampleModalLabel">Create attachment</h5> -->
                </div>

                <div class="modal-body flex-grow-1 p-0">
                    <ul ngbNav #navJustified="ngbNav" class="nav-tabs nav-justified document-right-sidebarTabs">
                        <li ngbNavItem>
                            <a ngbNavLink>Details</a>
                            <ng-template ngbNavContent>
                                <div class="documentDetailTabMainContainer mt-2 px-2">
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Document name</small></div>
                                        <div>
                                            <p class="mb-0">Proposal Template For Any Business</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Saved in folder</small></div>
                                        <div>
                                            <p class="text-primary mb-0">Sent</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Document type</small></div>
                                        <div>
                                            <p class="text-primary mb-0">Other</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Get shareable link</small></div>
                                        <div>
                                            <p class="mb-0">The lint is <a class="text-primary">private</a></p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="detailFirstColumn"><small>Due Date</small></div>
                                        <div class="docRightMenuDatePicker">
                                            <div class="form-group mb-0">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                                            [ngClass]="'firstsecond'" [config]="basicDateOptions" type="button"
                                                            rippleEffect></button>
                                                    </div>
                                                    <ng2-flatpickr placeholder="No date select" [ngClass]="'firstsecond'"
                                                        [config]="basicDateOptions" name="basicDate"></ng2-flatpickr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="form-group">
                                            <input type="text" placeholder="Add tags" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Document</a>
                            <ng-template ngbNavContent>
                                <div class="documentTabThumbnailContainer mt-1 px-2">
                                    <div class="d-flex justify-content-center px-2 py-1 document-thumbnail-box cursor-pointer">
                                        <img src="assets/images/pages/documentThumb.jpg" alt="">
                                    </div>
                                </div>
                            </ng-template>
                        </li>


                    </ul>
                    <div [ngbNavOutlet]="navJustified" class="mt-0"></div>
                    <!-- <button type="submit" class="btn btn-primary mr-1" rippleEffect>Save</button>
                    <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('document-right-sidebar')" rippleEffect>
                        Cancel
                    </button> -->
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to Document right sidebar Ends-->
</div>