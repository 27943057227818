import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-tab',
  templateUrl: './details-tab.component.html',
  styleUrls: ['./details-tab.component.scss']
})
export class DetailsTabComponent implements OnInit {

  constructor() { }

  public selectedLanguage = 1;
  languages = [
      { id: 1, name: 'English' },
      { id: 2, name: 'Swedish' },
      { id: 3, name: 'German' },
      { id: 4, name: 'Finnish' },
      { id: 5, name: 'French' },
      { id: 6, name: 'Finnish' },
      { id: 7, name: 'Norwegian' },
      { id: 8, name: 'Danish' },
      { id: 9, name: 'Spanish' },
      { id: 10, name: 'Polish' },
      { id: 11, name: 'Slovak' },
  ];

  ngOnInit(): void {
  }

}
