<div class="card addonBoxContainer" (click)="modalOpen(craditSafeSettingModal)">
    <div class="card-body">
        <h5>Credit safe</h5>
        <p class="card-text font-small-3 addonboxDiscription">Get an overview of all your documents and set reminders
            based on their content.</p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <img src="assets/images/addons-images/creditsafe.png" class="addonThumbnailImg" alt="addonThumbnail" />
    </div>
</div>

<!-- Modal -->
<ng-template #craditSafeSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Credit safe</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                Use Credit Safe functionalitites directly inside Simple Sign. Take automatic credit
                                checks on your recipients before sending them your agreements.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations swiper-container" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets/images/addons-images/no-thumbnail.jpg" alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <h5 class="mb-1">Connect Credit Safe Account</h5>
                            <div class="form-group">
                                <label for="basicInput">Username</label>
                                <input type="text" class="form-control" id="Username" placeholder="Username" />
                            </div>
                            <div class="form-group">
                                <label for="basicInput">Password</label>
                                <input type="password" class="form-control" id="" placeholder="password" />
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" class="btn btn-primary float-right" rippleEffect>Save Credentials</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->