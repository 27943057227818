import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cc-contacts',
  templateUrl: './cc-contacts.component.html',
  styleUrls: ['./cc-contacts.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CCContactsComponent implements OnInit {
  
  hiddenFields: boolean = false;
  changeText = "Show more";
  // modal Open Form
  CC_addContactsModalOpen(CC_addContactsModal) {
    this.modalService.open(CC_addContactsModal);
  }

  showHiddenFields() {
    this.hiddenFields = !this.hiddenFields;
    this.hiddenFields ? this.changeText = "Hide" : this.changeText = "Show more";
  }
  constructor(private modalService: NgbModal) { }


  ngOnInit(): void {
  }

}
