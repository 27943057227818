import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'addonsViewMainContainer' }
})
export class AddonsComponent implements OnInit {

  public pageBasic = 1;

  /**
  * Constructor
  *
  * @param {TodoService} _todoService
  * @param {CoreSidebarService} _coreSidebarService
  */
 constructor(
   
   private _coreSidebarService: CoreSidebarService,
 ) { }


 /**
  * Toggle Sidebar
  *
  * @param nameRef
  */
 createNewTodo(nameRef, closeNameRef): void {
   this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
   this._coreSidebarService.getSidebarRegistry(closeNameRef).toggleOpen();
 }
 /**
 * Toggle Sidebar
 *
 * @param nameRef
 */
toggleSidebar(nameRef): void {
 this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
}


  ngOnInit(): void {
  }

}
