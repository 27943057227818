import { Injectable, Inject, Injector } from '@angular/core';
import feather from 'feather-icons';
import uuid from 'short-unique-id';
// import * as InlineEditor from '../../ckCustumBuild/build/ckeditor.js';
// import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'app/global/global.service';
import { formBuilder } from '../form-builder/formBuilder';
import { PopOver } from '../popover/popover';

declare var $: any;
declare var CKEDITOR: any;
@Injectable({
    providedIn: 'root'
})
export class Blocks {
    public uuid = new uuid({ length: 6, dictionary: 'number' });
    constructor(
        private toastr: ToastrService,
        private GlobalService: GlobalService,
        private PopOver:PopOver,
        @Inject(Injector) private readonly injector: Injector
    ) { }

    createContentBox(params: any = {}) {
        var that = this;
        that.toastr.toastrConfig.preventDuplicates = true;
        var count_id = this.uuid();
        const editorinstance = document.createElement('div')
        editorinstance.className = 'content-box content-box-block content-editor-block marginminus70'
        editorinstance.innerHTML = `<div class="sort-helper">
        <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
        <div class="highlightSortContentBox">
            <p>Content Box</p>
            <svg viewBox="0 0 24 24" role="presentation"><path d="M4 4h8v2H9v6H7V6H4V4zM4 16V14h16v2l-16 .001zM4 20v-2h16v2H4zm9-8v-2h7v2h-7z" fill-rule="evenodd"></path></svg>
        </div>
        </div>
        <div class="content-box-row">
                <div class="rowItem">
                ${that.PopOver.listingPopover({classType:'content-box',uuid:count_id})}
                <div class="sort-helper-ss">
                <div class="sort-helper sort-helper-inner">
                <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
                <div class="highlightSortContentBox">
                    <p>Content Box</p>
                    <svg viewBox="0 0 24 24" role="presentation"><path d="M4 4h8v2H9v6H7V6H4V4zM4 16V14h16v2l-16 .001zM4 20v-2h16v2H4zm9-8v-2h7v2h-7z" fill-rule="evenodd"></path></svg>
                </div>
                </div>
                </div>
                <div class="content-box-inner" editor-uuid="${count_id}" type="content box"><div class="editor${count_id} cke-editor-placeholder cke-editor-box ${(params.type == 'formbuilder' ? 'form-builder' : '')}" editor-uuid="${count_id}" contenteditable="true" cke-placeholder="Type / for quick actions">
                </div>
            </div>
        </div>
        </div>`
        // myElement.insertAfter( $(".dropIndicator-bottom") );

        if ($('.dropIndicator-bottom').length == 0 && $('.template-page').hasClass('addContentBox')) {
            $('.template-page').append(editorinstance)
        } else if ($('.content-box-inner.active-border') && params.type == 'slashcommand') {
            $(editorinstance).insertAfter($('.content-box-inner.active-border').parents('.content-box'));
        } else {
            $(editorinstance).insertAfter(
                $('.dropIndicator-bottom').parent('.content-box'),
            )
        }
        that.GlobalService.initEditor({ uuid: count_id });
        that.GlobalService.contentBoxSort();
    }

    contentBoxZone(position:any){
        var that = this;
        var count_id = this.uuid();
        if (position == 'left') {
            $(`<div class="rowItem">
            ${that.PopOver.listingPopover({classType:'content-box',uuid:count_id})}
            <div class="sort-helper-ss"><div class="sort-helper sort-helper-inner">
            <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
            <div class="highlightSortContentBox">
                <p>Content Box</p>
                <svg viewBox="0 0 24 24" role="presentation"><path d="M4 4h8v2H9v6H7V6H4V4zM4 16V14h16v2l-16 .001zM4 20v-2h16v2H4zm9-8v-2h7v2h-7z" fill-rule="evenodd"></path></svg>
            </div>
            </div>
            </div><div class="content-box-inner" editor-uuid="${count_id}" type="content box"><div class="editor${count_id} cke-editor-placeholder cke-editor-box" editor-uuid="${count_id}" contenteditable="true" cke-placeholder="Type / for quick actions"></div></div></div>`).insertBefore($('.content-box-enter').find('.rowItem:first'));
        } else if (position == 'right') {
            $(`<div class="rowItem">
            ${that.PopOver.listingPopover({classType:'content-box',uuid:count_id})}
            <div class="sort-helper-ss"><div class="sort-helper sort-helper-inner">
            <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
            <div class="highlightSortContentBox">
                <p>Content Box</p>
                <svg viewBox="0 0 24 24" role="presentation"><path d="M4 4h8v2H9v6H7V6H4V4zM4 16V14h16v2l-16 .001zM4 20v-2h16v2H4zm9-8v-2h7v2h-7z" fill-rule="evenodd"></path></svg>
            </div>
            </div>
            </div><div class="content-box-inner" editor-uuid="${count_id}" type="content box"><div class="editor${count_id} cke-editor-placeholder cke-editor-box" editor-uuid="${count_id}" contenteditable="true" cke-placeholder="Type / for quick actions"></div></div></div>`).insertAfter($('.content-box-enter').find('.rowItem:last'));
        }
        this.GlobalService.initEditor({ uuid: count_id });
    }

    addContentBox(params: any = {}) {
        if (params.position == 'left' || params.position == 'right') {
            this.contentBoxZone(params.position);
        } else {
            this.createContentBox();
        }
    }

    pageBreakView() {
        return `<div class="content-box page-break-block marginminus70" break="1">
        <div class="sort-helper">
                <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
                <div class="highlightSortContentBox">
            <p>Page Break</p>
            <svg
            viewBox="0 0 24 24"
            role="presentation"
            ><path
            d="M5 18c.55 0 1-.45 1-1s-.45-1-1-1c-.56 0-1 .45-1 1s.44 1 1 1zM5 8c.55 0 1-.45 1-1s-.45-1-1-1c-.56 0-1 .45-1 1s.44 1 1 1zm12.7-1.29l-5.61 5.62 5.61 5.62c-.78.78-2.05.78-2.83 0l-4.21-4.21-2.72 2.72c.03.18.06.36.06.54 0 1.66-1.35 3-3 3-1.66 0-3-1.34-3-3s1.34-3 3-3c.73 0 1.39.26 1.9.69l2.36-2.36-2.74-2.74c-.44.26-.96.41-1.52.41-1.66 0-3-1.34-3-3s1.34-3 3-3c1.65 0 3 1.34 3 3 0 .37-.08.72-.2 1.04l2.87 2.88h.01l4.19-4.21c.78-.78 2.05-.78 2.83 0zM16 13v-2h2v2h-2zm4 0v-2h1.97v2H20z"
            fill-rule="evenodd"
            /></svg>
        </div>
                </div>
        <div class="sort-helper-ss">
        <div class="sort-helper sort-helper-inner">
        <svg
            viewBox="0 0 24 24"
            role="presentation"
            width="25"
            height="25"
        ><path
            d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z"
            fill-rule="evenodd"
        /></svg>
        <div class="highlightSortContentBox">
            <p>Page Break</p>
            <svg
            viewBox="0 0 24 24"
            role="presentation"
            ><path
            d="M5 18c.55 0 1-.45 1-1s-.45-1-1-1c-.56 0-1 .45-1 1s.44 1 1 1zM5 8c.55 0 1-.45 1-1s-.45-1-1-1c-.56 0-1 .45-1 1s.44 1 1 1zm12.7-1.29l-5.61 5.62 5.61 5.62c-.78.78-2.05.78-2.83 0l-4.21-4.21-2.72 2.72c.03.18.06.36.06.54 0 1.66-1.35 3-3 3-1.66 0-3-1.34-3-3s1.34-3 3-3c.73 0 1.39.26 1.9.69l2.36-2.36-2.74-2.74c-.44.26-.96.41-1.52.41-1.66 0-3-1.34-3-3s1.34-3 3-3c1.65 0 3 1.34 3 3 0 .37-.08.72-.2 1.04l2.87 2.88h.01l4.19-4.21c.78-.78 2.05-.78 2.83 0zM16 13v-2h2v2h-2zm4 0v-2h1.97v2H20z"
            fill-rule="evenodd"
            /></svg>
        </div>
        </div>
        </div>
        <div class="block-action-btn open-editor-menu">
            ${feather.icons['more-vertical'].toSvg({ class: 'cursor-pointer', width: 14, height: 14 })}
            <div class="block-action-menu hidden">
                <div class="block-action-menu-list blockbreak" value="1">
                    <div class="block-action-menu-list-icon">
                    ${feather.icons['scissors'].toSvg({ class: 'cursor-pointer', width: 15, height: 15 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>PDF break</p>
                    </div>
                </div>
                <div class="block-action-menu-list delete-page-break">
                    <div class="block-action-menu-list-icon">
                    ${feather.icons.trash.toSvg({ class: 'cursor-pointer delete-page-break', width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Delete</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-longpage-break" />
    </div>`
    }

    addPageBreak(params: any = {}) {
        if ($('.content-box-inner.active-border') && (params.hasOwnProperty('type') && params.type == 'slashcommand')) {
            $(this.pageBreakView()).insertAfter($('.content-box-inner.active-border').parents('.content-box'));
        } else {
            $(this.pageBreakView()).insertAfter($('.dropIndicator-bottom').parent('.content-box'))
        }
    }

    attachmentBoxView() {
        var uuid = this.uuid();
        return `<div class="content-box content-box-block attachment-block marginminus70" type="attachment">
        <div class="sort-helper">
                <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
                <div class="highlightSortContentBox">
                                <p>Attachment</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
                            </div>
                </div>
                    <div class="content-box-row">
                    <div class="rowItem">
                    ${this.PopOver.imageBoxListPopover({type:'attachment',uuid:uuid})}
                    <div class="sort-helper-ss">
                        <div class="sort-helper sort-helper-inner">
                            <svg
                                viewBox="0 0 24 24"
                                role="presentation"
                                width="25"
                                height="25"
                            ><path
                                d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z"
                                fill-rule="evenodd"
                            /></svg>
                            <div class="highlightSortContentBox">
                                <p>Attachment</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
                            </div>
                        </div>
                        </div>
                        <div class="content-box-inner attachment-box-inner" uuid="${uuid}" type="attachment">
                            <div class="attachment-boxInner attachmentBox">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-75 feather feather-upload">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                                </svg>
                                <span>Click to add an Attachment</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>`
    }

    addAttachmentBox(params: any = {}) {
        if ($('.content-box-inner.active-border') && (params.hasOwnProperty('type') && params.type == 'slashcommand')) {
            $(this.attachmentBoxView()).insertAfter($('.content-box-inner.active-border').parents('.content-box'));
        } else {
            $(this.attachmentBoxView()).insertAfter($('.dropIndicator-bottom').parent('.content-box'))
        }
    }

    imageBoxView() {
        var uuid = this.uuid();
        return `<div class="content-box content-box-block image-block marginminus70" type="image">
        <div class="sort-helper">
                <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
                <div class="highlightSortContentBox">
                    <p>Image</p>
                    <svg width="18px" height="18px" viewBox="0 0 24 24" role="presentation">
                        <path d="M5 17l5.25-6 3.5 4.003 1.75-2.006L19 17H5zM15.5 7c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5S14 9.325 14 8.5 14.675 7 15.5 7zM2 20h20V4H2v16z" fill-rule="evenodd"></path>
                    </svg>
                </div>
                </div>
                    <div class="content-box-row">
                            <div class="rowItem">
                            ${this.PopOver.imageBoxListPopover({type:'image',uuid:uuid})}
                            <div class="sort-helper-ss">
                                <div class="sort-helper sort-helper-inner">
                                    <svg
                                        viewBox="0 0 24 24"
                                        role="presentation"
                                        width="25"
                                        height="25"
                                    ><path
                                        d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z"
                                        fill-rule="evenodd"
                                    /></svg>
                                    <div class="highlightSortContentBox">
                                        <p>Image</p>
                                        <svg width="18px" height="18px" viewBox="0 0 24 24" role="presentation">
                                            <path d="M5 17l5.25-6 3.5 4.003 1.75-2.006L19 17H5zM15.5 7c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5S14 9.325 14 8.5 14.675 7 15.5 7zM2 20h20V4H2v16z" fill-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="content-box-inner image-box-inner" uuid="${uuid}" type="image">
                                <div class="image-boxInner uploadImageBox">
                                    <svg width="20px" height="20px" class="mr-75" style="fill: #6e6b7b;" viewBox="0 0 24 24" role="presentation">
                                        <path d="M5 17l5.25-6 3.5 4.003 1.75-2.006L19 17H5zM15.5 7c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5S14 9.325 14 8.5 14.675 7 15.5 7zM2 20h20V4H2v16z" fill-rule="evenodd"></path>
                                    </svg>
                                    <span>Click to add an Image</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`
    }

    imageBoxZone() {
        var uuid = this.uuid();
        return `<div class="rowItem">
        ${this.PopOver.imageBoxListPopover({type:'image',uuid:uuid})}
        <div class="sort-helper-ss">
        <div class="sort-helper sort-helper-inner">
                                    <svg
                                        viewBox="0 0 24 24"
                                        role="presentation"
                                        width="25"
                                        height="25"
                                    ><path
                                        d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z"
                                        fill-rule="evenodd"
                                    /></svg>
                                    <div class="highlightSortContentBox">
                                        <p>Image</p>
                                        <svg width="18px" height="18px" viewBox="0 0 24 24" role="presentation">
                                            <path d="M5 17l5.25-6 3.5 4.003 1.75-2.006L19 17H5zM15.5 7c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5S14 9.325 14 8.5 14.675 7 15.5 7zM2 20h20V4H2v16z" fill-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                </div>
                                </div>
                    <div class="content-box-inner image-box-inner" uuid="${uuid}" type="image">
                        <div class="image-boxInner uploadImageBox">
                            <svg width="20px" height="20px" class="mr-75" style="fill: #6e6b7b;" viewBox="0 0 24 24" role="presentation">
                                <path d="M5 17l5.25-6 3.5 4.003 1.75-2.006L19 17H5zM15.5 7c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5S14 9.325 14 8.5 14.675 7 15.5 7zM2 20h20V4H2v16z" fill-rule="evenodd"></path>
                            </svg>
                            <span>Click to add an Image</span>
                        </div>
                    </div>
                </div>`;
    }

    addImageBox(params: any = {}) {
        if (params.position == 'left') {
            $(this.imageBoxZone()).insertBefore($('.content-box-enter').find('.rowItem:first'));
        } else if (params.position == 'right') {
            $(this.imageBoxZone()).insertAfter($('.content-box-enter').find('.rowItem:last'));
        } else {
            $(this.imageBoxView()).insertAfter($('.dropIndicator-bottom').parent('.content-box'))
        }
    }


    pdfViewBlock(params:any = {}){
        var that = this;
        var uuid = this.uuid();
        const pdfView = `<div class="content-box content-box-block pdf-block" style="--pdf-background-image:url('${params.url}')"><div class="sort-helper">
        <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
        <div class="highlightSortContentBox">
            <p>PDF Box</p>
            <svg id="Capa_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18" height="18" viewBox="0 0 31.256 31.256" class="contentpdfIcon"><g><g><path d="M13.153,12.339c0.169,1.766,0.251,5.123-1.091,7.763c-0.109,0.213-0.217,0.42-0.325,0.618c-2.334,0.716-4.799,2.072-5.21,3.565c-0.161,0.59-0.013,1.18,0.406,1.619c0.437,0.455,0.98,0.697,1.574,0.697c1.437,0,2.931-1.371,4.563-4.187c0.592-0.146,4.003-0.562,4.72-0.554c1.967,2.011,3.559,2.988,4.859,2.988c0.816,0,1.519-0.412,1.929-1.133c0.284-0.504,0.285-1.086,0.003-1.597c-0.816-1.474-3.994-2.074-5.9-2.211c-0.164-0.179-0.33-0.365-0.5-0.558c-1.952-2.23-2.75-5.506-3.045-7.218c-0.037-0.392-0.079-0.718-0.114-0.957c-0.126-0.906-0.695-1.042-1.023-1.042c-0.285,0-0.558,0.123-0.747,0.338c-0.305,0.346-0.259,0.72-0.229,0.97C13.051,11.686,13.095,12.01,13.153,12.339z M8.591,24.6c0.187-0.207,0.552-0.512,1.105-0.842C9.18,24.318,8.8,24.549,8.591,24.6z M22.545,22.857c-0.167-0.021-0.485-0.105-0.995-0.418C21.99,22.582,22.325,22.73,22.545,22.857z M14.886,17.92c0.365,0.741,0.771,1.416,1.217,2.021c-0.218,0.021-1.756,0.22-1.892,0.237C14.495,19.482,14.72,18.727,14.886,17.92z"></path><path d="M11.089,0L2.995,8.546v22.71h25.266V0H11.089z M10.375,3.594v3.787H6.788L10.375,3.594z M26.306,29.302H4.948V9.335h7.381V1.954h13.979v27.348H26.306z"></path></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
        </div></div>
        <div class="content-box-row">
        <div class="rowItem">
        ${that.PopOver.listingPopover({classType:'pdf-block',uuid:uuid})}
        <div class="sort-helper-ss"><div class="sort-helper sort-helper-inner">
        <svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg>
        <div class="highlightSortContentBox">
            <p>PDF Box</p>
            <svg id="Capa_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18" height="18" viewBox="0 0 31.256 31.256" class="contentpdfIcon"><g><g><path d="M13.153,12.339c0.169,1.766,0.251,5.123-1.091,7.763c-0.109,0.213-0.217,0.42-0.325,0.618c-2.334,0.716-4.799,2.072-5.21,3.565c-0.161,0.59-0.013,1.18,0.406,1.619c0.437,0.455,0.98,0.697,1.574,0.697c1.437,0,2.931-1.371,4.563-4.187c0.592-0.146,4.003-0.562,4.72-0.554c1.967,2.011,3.559,2.988,4.859,2.988c0.816,0,1.519-0.412,1.929-1.133c0.284-0.504,0.285-1.086,0.003-1.597c-0.816-1.474-3.994-2.074-5.9-2.211c-0.164-0.179-0.33-0.365-0.5-0.558c-1.952-2.23-2.75-5.506-3.045-7.218c-0.037-0.392-0.079-0.718-0.114-0.957c-0.126-0.906-0.695-1.042-1.023-1.042c-0.285,0-0.558,0.123-0.747,0.338c-0.305,0.346-0.259,0.72-0.229,0.97C13.051,11.686,13.095,12.01,13.153,12.339z M8.591,24.6c0.187-0.207,0.552-0.512,1.105-0.842C9.18,24.318,8.8,24.549,8.591,24.6z M22.545,22.857c-0.167-0.021-0.485-0.105-0.995-0.418C21.99,22.582,22.325,22.73,22.545,22.857z M14.886,17.92c0.365,0.741,0.771,1.416,1.217,2.021c-0.218,0.021-1.756,0.22-1.892,0.237C14.495,19.482,14.72,18.727,14.886,17.92z"></path><path d="M11.089,0L2.995,8.546v22.71h25.266V0H11.089z M10.375,3.594v3.787H6.788L10.375,3.594z M26.306,29.302H4.948V9.335h7.381V1.954h13.979v27.348H26.306z"></path></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
        </div>
        </div>
        </div>        
        <div class="content-box-inner" editor-uuid="${uuid}" type="PDF upload"><div class="editor${uuid} cke-editor-placeholder cke-editor-box pdf-block-editor-box pdf-height" editor-uuid="${uuid}" contenteditable="true">
        </div>
        </div>
        </div>
        </div></div>`
        // myElement.insertAfter( $(".dropIndicator-bottom") );
        return pdfView;
        // if (false && $('.dropIndicator-bottom').length == 0 && $('.template-page').hasClass('addContentBox')) {
        //     $('.template-page').append(pdfView)
        // } else {
        //     $(pdfView).insertAfter(
        //         $('.dropIndicator-bottom').parent('.content-box'),
        //     )
        // }
    }

    pdfBlockUploader(params:any = {}){
         var pdfView = `<div uuid="${params.uuid}" class="hidden">
                    <input type="file" name="${params.uuid}" class="hidden pdf-block-upload" accept="application/pdf"/>
                </div>`;
        $(pdfView).insertAfter(
            $('.dropIndicator-bottom').parent('.content-box'),
        )
    }

    convertToTable(params:any = {}){
        return `<table class="cke_show_border general-convert-to-table table-border-solid" style="table-layout:auto; width: 100%;" header-color="rgb(189, 189, 189)" footer-color="rgb(255, 255, 255)" even-color="rgb(255, 255, 255)" odd-color="rgb(243, 243, 243)" activated-theme="0" border-style="1" text-color="#000000" has-header="1" has-footer="0" border-color="#d3d3d3" border-radius="0" uuid="${params.uuid}" secondary-footer-color="rgb(222, 222, 222)">
                    <thead>
                        <tr class="table_headingsRow" style="background-color: rgb(189, 189, 189);height: 40px;">
                            <th style="font-weight: normal;"></th>
                            <th style="font-weight: normal;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="height: 40px;">
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>`;
    }

    private get formBuilderFun() {
        return this.injector.get(formBuilder);
    }

}