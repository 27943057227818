import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-microsoft-dynamics365-addon',
  templateUrl: './microsoft-dynamics365-addon.component.html',
  styleUrls: ['./microsoft-dynamics365-addon.component.scss']
})
export class MicrosoftDynamics365AddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
