import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})

export class TemplatesComponent implements OnInit {

  constructor(
  ) {}

  ngOnInit(): void {
  }
}
