import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-document-workflow-addon',
  templateUrl: './document-workflow-addon.component.html',
  styleUrls: ['./document-workflow-addon.component.scss']
})
export class DocumentWorkflowAddonComponent implements OnInit {

// modal Basic
modalOpen(docWorkflowSettingModal) {
  this.modalService.open(docWorkflowSettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
  pagination: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};

constructor(private modalService: NgbModal) { }
  ngOnInit(): void {
  }

}
