<div class="content-wrapper dashboard-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="row match-height">
      <div class="col-lg-12 col-12">
        <div class="card card-transition">
          <!-- Upload Document Widget -->
          <app-uploaddocumentwidget></app-uploaddocumentwidget>
        </div>
      </div>
    </div>

    <div class="row match-height">
      <div class="col-lg-6 col-12">
        <div class="card card-transition">
          <!-- Latest Activity Widget -->
          <app-latestactivitywidget></app-latestactivitywidget>
        </div>
      </div>

      <div class="col-lg-6 col-12">
        <div class="card card-transition">
          <!-- Task & Reminders Widget -->
          <app-taskandreminderswidget></app-taskandreminderswidget>
        </div>
      </div>
    </div>

    <div class="row match-height">
      <div class="col-lg-6 col-12">
        <div class="card card-transition autorenewalon-widget">
          <!-- Auto Renewal On Widget -->
          <app-autorenewalonwidget></app-autorenewalonwidget>
        </div>
      </div>

      <div class="col-lg-6 col-12">
        <div class="card card-transition expireson-widget">
          <!-- Expires Widget -->
          <app-expiresonwidget></app-expiresonwidget>
        </div>
      </div>
    </div>

    <div class="row match-height">
      <div class="col-lg-4 col-12">
        <div class="card card-transition forgotDocument-widget">
          <!-- Forgottend Document Widget -->
          <app-forgottendocumentwidget></app-forgottendocumentwidget>
        </div>
      </div>

      <div class="col-lg-4 col-12">
        <div class="card card-transition lastSignedDocument-widget">
          <!-- Last Signed Document Widget -->
          <app-lastsigneddocumentwidget></app-lastsigneddocumentwidget>
        </div>
      </div>

      <div class="col-lg-4 col-12">
        <div class="card card-transition mostActiveUsers-widget">
          <!-- Most Active Users Widget -->
          <app-mostactiveuserswidget></app-mostactiveuserswidget>
        </div>
      </div>
    </div>

    <div class="row match-height">
      <div class="col-lg-4 col-12">
        <div class="card card-transition unreadChatMessage-widget">
          <!-- Unread Chat Message Widget -->
          <app-unreadchatmessagewidget></app-unreadchatmessagewidget>
        </div>
      </div>

      <div class="col-lg-4 col-12">
        <div class="card card-transition aboutToExpire-widget">
          <!-- About To Expire Widget -->
          <app-abouttoexpirewidget></app-abouttoexpirewidget>
        </div>
      </div>

      <div class="col-lg-4 col-12">
        <div class="card card-transition emailBounced-widget">
          <!-- Emai Bbounced Widget -->
          <app-emailbouncedwidget></app-emailbouncedwidget>
        </div>
      </div>
    </div>

    <div class="row match-height">
      <div class="col-lg-4 col-12">
        <div class="card card-transition mostUsedTemplates-widget">
          <!-- Most Used Template Widget -->
          <app-mostusedtemplateswidget></app-mostusedtemplateswidget>
        </div>
      </div>

      <div class="col-lg-8 col-12">
        <div class="card card-transition -widget">
          <!-- Contract Control Widget -->
          <app-contractcontrolwidget></app-contractcontrolwidget>
        </div>
      </div>
    </div>
  </div>
</div>
