<div class="content-wrapper brandng-wrapper container-xxl p-0">
    <div class="content-body">
        <form [formGroup]="brandingForm" (ngSubmit)="submitBrandingData()">
            <!-- Brandng main view -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-2 text-center">Add your logotype</h4>
                                <div *ngIf="!backgroundLogo" (click)="logoupload.click()"
                                    class="py-4 text-center font-medium-5 text-uppercase file-drop-zone col-md-6 col-lg-6 col-sm-12">
                                    Drop files here or click to upload
                                </div>
                                <div *ngIf="backgroundLogo"
                                    class="d-flex justify-content-center logo-image-preview position-relative p-2 border w-50 m-auto">
                                    <img src="{{backgroundLogo}}" alt="logo">
                                    <div class="upload-image-action">
                                        <button type="button" placement="top" ngbTooltip="Delete" (click)="removeMedia('logo')" class="btn btn-icon btn-warning delete-image-btn waves-effect waves-float waves-light">
                                            <i data-feather="trash-2" class="cursor-pointer lighten-2"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input type="file" hidden (change)="getLogo($event, 'logo')" name="logoupload" #logoupload>
                </div>
            </section>
            <!-- Signing page -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Signing page</h4>
                                <p class="card-text">Brand the browser that is used when signing your documents</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                                        <div class="form-group mb-0">
                                            <label for="PageTitle">Page Title</label>
                                            <input type="text" class="form-control" formControlName="title"
                                                placeholder="Enter your Page Title"
                                                [ngClass]="{ 'is-invalid error': submitted && form.title.errors }" />
                                            <div *ngIf="submitted && form.title.errors" class="invalid-feedback"
                                                [ngClass]="{ 'd-block': submitted && form.title.errors }">
                                                <div *ngIf="form.title.errors.required">Page title is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-0">
                                        <fieldset class="form-group">
                                            <label for="Favicon">Favicon</label>
                                            <div class="custom-file faviconFile">
                                                <div *ngIf="!favicon">
                                                    <i data-feather="image"
                                                        class="cursor-pointer lighten-2 text-secondary favIconPreviewbefore"></i>
                                                </div>
                                                <img src="{{favicon}}"
                                                    class="favIconPreviewafter {{ favicon ? '' : 'd-none'}}"
                                                    alt="fav icon">
                                                <input type="file" class="custom-file-input"
                                                    (change)="getLogo($event, 'favicon')" type="file" ng2FileSelect
                                                    id="Favicon" />
                                                <label class="custom-file-label">Click to upload icon</label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <button type="button" (click)="removeMedia('favicon')"
                                    class="btn btn-success float-right" rippleEffect>Reset fevicon</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Signing page -->
            <!-- Originator -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Originator</h4>
                                <p class="card-text">Customize the SMS sender name</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                                        <div class="form-group mb-0">
                                            <label for="SMSOriginator">SMS Originator</label>
                                            <input type="text" class="form-control" formControlName="sms_originator"
                                                placeholder="Enter your SMS Originator" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Originator -->
            <!-- Customize Headings -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Customize Headings</h4>
                                <p class="card-text">Customize headings (H1,H2,H3) when you create document or templates
                                </p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Heading</label>
                                            <ng-select [items]="chooseheading" bindLabel="heading" bindValue="heading"
                                                [readonly]="readonly" [searchable]="searchable"
                                                formControlName="heading" placeholder="Heading"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Font size</label>
                                            <ng-select [items]="choosefontsize" bindLabel="font_size"
                                                bindValue="font_size" [readonly]="readonly" [searchable]="searchable"
                                                formControlName="font_size" placeholder="Font size"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-1">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Font Style</label>
                                            <ng-select [items]="choosefontstyle" bindLabel="font_style"
                                                bindValue="font_style" [readonly]="readonly" [searchable]="searchable"
                                                formControlName="font_style" placeholder="Font Style"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-50">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Font color</label>
                                            <div class="table-setting-color-box mb-75">
                                                <input style="color: white;" class="form-control"
                                                    [style.background]="form.font_color.value" value="Font color"
                                                    readonly />
                                                <div class="color-picker-ts-box">
                                                    <div [style.background]="form.font_color.value"
                                                        [cpOutputFormat]="'rgba'" [colorPicker]="form.font_color.value"
                                                        class="color-picker-ts"
                                                        (colorPickerChange)="form.font_color.setValue($event)"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-success float-right" rippleEffect><span
                                        *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> {{ form.id
                                    ? 'Update': 'Submit' }}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Customize Headings -->
            <!--/ Brandng main view -->
        </form>
    </div>
</div>