<div class="card-header">
  <h4 class="card-title">Last Signed Documents</h4>
</div>
<div class="card-body">
  <div class="transaction-item">
    <div class="media align-items-center">
      <div class="avatar bg-light-primary rounded">
        <div class="avatar-content">
          <i class="feather font-medium-3 icon-file-text"></i>
        </div>
      </div>
      <div class="media-body">
        <h6 class="transaction-title">Rental Aggrement</h6>
      </div>
    </div>
    <div><small class="text-muted">3 mon ago</small></div>
  </div>
  <div class="transaction-item">
    <div class="media align-items-center">
      <div class="avatar bg-light-primary rounded">
        <div class="avatar-content">
          <i class="feather font-medium-3 icon-file-text"></i>
        </div>
      </div>
      <div class="media-body">
        <h6 class="transaction-title">Offer</h6>
      </div>
    </div>
    <div><small class="text-muted">2 days ago</small></div>
  </div>
  <div class="transaction-item">
    <div class="media align-items-center">
      <div class="avatar bg-light-primary rounded">
        <div class="avatar-content">
          <i class="feather font-medium-3 icon-file-text"></i>
        </div>
      </div>
      <div class="media-body">
        <h6 class="transaction-title">Document</h6>
      </div>
    </div>
    <div><small class="text-muted">10 mon ago</small></div>
  </div>
</div>
