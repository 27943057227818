<div class="card mb-1 p-50 card-transaction CC_CustomBoxContainer draggable">
    <div class="collapse-icon">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">Custom box</span>
                    <div class="addContactIcon" placement="auto" container="body" (click)="customBoxAddNewFeildModalClick(customBoxAddNewFeildModal)"
                        ngbTooltip="Add new field">
                        <i data-feather="plus-circle" class="font-medium-4 mb-25 cursor-pointer"></i>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent class="p-0">
                    <div class="transaction-item border-bottom CC_CustomBoxListHover">
                        <div class="media">
                            <div class="media-body d-flex align-items-center justify-content-between">
                                <h6 class="transaction-title my-50">Leasingbil</h6>
                                <div class="input-group ml-2" *ngIf="hiddenValueField">
                                    <input type="text" class="form-control form-control-sm" id="" placeholder="add value"
                                        aria-label="add value" />
                                </div>
                            </div>
                        </div>
                        <div class="font-weight-bolder listOptionHide">
                            <span placement="auto" container="body" ngbTooltip="Add value" (click)="ShowValueFieldClick()">
                                <i class="cursor-pointer mr-50" [size]="18" data-feather="plus-square"></i>
                            </span>
                            <span placement="auto" container="body" ngbTooltip="Edit value">
                                <i class="cursor-pointer" [size]="16" data-feather="edit"></i>
                            </span>
                        </div>
                    </div>
                    <div class="transaction-item border-bottom CC_CustomBoxListHover">
                        <div class="media">
                            <div class="media-body d-flex align-items-center justify-content-between">
                                <h6 class="transaction-title my-50">Leasingbil</h6>
                                <p class=" text-primary ml-2 my-50">Demo value text</p>
                                <!-- <div class="input-group ml-2" *ngIf="hiddenValueField">
                                    <input type="text" class="form-control form-control-sm" id="" placeholder="add value"
                                        aria-label="add value" />
                                </div> -->
                            </div>
                        </div>
                        <div class="font-weight-bolder listOptionHide">
                            <span placement="auto" container="body" ngbTooltip="Add value">
                                <i class="cursor-pointer mr-50" [size]="18" data-feather="plus-square"></i>
                            </span>
                            <span placement="auto" container="body" ngbTooltip="Edit value">
                                <i class="cursor-pointer" [size]="16" data-feather="edit"></i>
                            </span>
                        </div>
                    </div>
                    <div class="transaction-item border-bottom CC_CustomBoxListHover">
                        <div class="media">
                            <div class="media-body d-flex align-items-center justify-content-between">
                                <h6 class="transaction-title my-50">Leasingbil</h6>
                                <!-- <div class="input-group ml-2" *ngIf="hiddenValueField">
                                    <input type="text" class="form-control form-control-sm" id="" placeholder="add value"
                                        aria-label="add value" />
                                </div> -->
                            </div>
                        </div>
                        <div class="font-weight-bolder listOptionHide">
                            <span placement="auto" container="body" ngbTooltip="Add value">
                                <i class="cursor-pointer mr-50" [size]="18" data-feather="plus-square"></i>
                            </span>
                            <span placement="auto" container="body" ngbTooltip="Edit value">
                                <i class="cursor-pointer" [size]="16" data-feather="edit"></i>
                            </span>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>

<!-- add contacts Modal -->
<ng-template #customBoxAddNewFeildModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add Document field</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="form-group mb-1">
            <label for="Firstname">Name</label>
            <input type="text" class="form-control" id="Firstname" placeholder="Name" />
        </div>
        <div class="form-group mb-1">
            <label for="">Type of field</label>
            <ng-select [items]="chooseType" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                placeholder="Setect type">
            </ng-select>
        </div>
        <div class="form-group mb-1">
            <label for="Lastname">API Attribute</label>
            <input type="text" class="form-control" id="Lastname" placeholder="API Attribute" />
        </div>
        <div class="custom-control custom-switch form-group">
            <label class="mb-50">Required?</label>
            <div class="d-flex justify-content-between">
                <label>No / Yes</label>
                <input type="checkbox" class="custom-control-input" id="automaticDeletion" />
                <label class="custom-control-label" for="automaticDeletion"></label>
            </div>
        </div>
        <div class="form-group mb-1">
            <label for="basicTextarea">Description</label>
            <fieldset class="form-group">
                <textarea class="form-control" id="basicTextarea" rows="3" placeholder="Description"></textarea>
            </fieldset>
        </div>



    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / add contacts Modal -->