<div class="card mb-1 p-50 card-transaction CC_costContainer draggable">
    <div class="collapse-icon">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="lead collapse-title">Cost
                    </span>
                </ng-template>
                <ng-template ngbPanelContent class="p-0">
                    <div>
                        <form class="">
                            <div class="row">
                                <div class="col-xl-8 col-md-8 col-8 mb-1">
                                    <div class="form-group mb-0">
                                        <label for="Cost">Cost</label>
                                        <input type="number" class="form-control" id="" placeholder="Cost" />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-4 mb-1 pl-0">
                                    <div class="form-group mb-0">
                                        <label for="">Currency</label>
                                        <ng-select #SelectCurrency [items]="chooseCurrency" bindLabel="name" [readonly]="readonly"
                                            [searchable]="searchable" placeholder="">
                                            <ng-template ng-header-tmp>
                                                <input class="form-control w-100" type="text" (input)="SelectCurrency.filter($event.target.value)" />
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-12 col-md-12 col-12 mb-1 p-0" *ngIf="hiddenStartupCost">
                                <div class="form-group mb-0">
                                    <label for="Startup">Startup Cost</label>
                                    <input type="number" class="form-control" id="" placeholder="Startup Cost" />
                                    <small class="text-primary cursor-pointer" (click)="hideStartupCost()">Hide</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-8 col-md-8 col-8 mb-1">
                                    <div class="form-group mb-0">
                                        <label for="WebhookURL">Payment period</label>
                                        <input type="number" class="form-control" id="" placeholder="Payment period" />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-4 mb-1 pl-0">
                                    <div class="form-group mb-0">
                                        <label for="">Days/Month</label>
                                        <ng-select [items]="chooseDaysMounth" bindLabel="name" [readonly]="readonly"
                                            [searchable]="searchable" placeholder=""> </ng-select>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn p-0 mr-auto btn-sm mt-0 startupCost btn-transparent text-primary"
                                *ngIf="hiddenStartupCostBtn" (click)="addStartupCost()">Startup cost</button>
                        </form>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>

    </div>
</div>