import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-unreadchatmessagewidget',
  templateUrl: './unreadchatmessagewidget.component.html',
  styleUrls: ['./unreadchatmessagewidget.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class UnreadchatmessagewidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
