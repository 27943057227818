import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mostusedtemplateswidget',
  templateUrl: './mostusedtemplateswidget.component.html',
  styleUrls: ['./mostusedtemplateswidget.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class MostusedtemplateswidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
