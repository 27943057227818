import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FlatpickrOptions } from 'ng2-flatpickr';

@Component({
  selector: 'app-link-document-sidebar',
  templateUrl: './link-document-sidebar.component.html',
  styleUrls: ['./link-document-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
  
})
export class LinkDocumentSidebarComponent implements OnInit {

  public basicDateOptions: FlatpickrOptions = {
    altInput: true
  }
  constructor(private _coreSidebarService: CoreSidebarService) { }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
  }

}
