import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Breadcrumb } from 'app/layout/components/content-header/breadcrumb/breadcrumb.component';


@Component({
  selector: 'app-edit-content-lirbrary',
  templateUrl: './edit-content-lirbrary.component.html',
  styleUrls: ['./edit-content-lirbrary.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class EditContentLirbraryComponent implements OnInit {

  public breadcrumbeditContentLibrary: Breadcrumb;

  constructor() { }

  ngOnInit(): void {
    // breadcrumb default
    this.breadcrumbeditContentLibrary = {
      links: [
        {
          name: 'Library',
          isLink: true,
          link: '/settings/contentlibrary', 
        },
        {
          name: 'Content name',
          isLink: false,
        },
      ]
    };
  }

}
