import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';


@Component({
  selector: 'app-productlibrarysidebar',
  templateUrl: './productlibrarysidebar.component.html',
  styleUrls: ['./productlibrarysidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductlibrarysidebarComponent implements OnInit {

  constructor(private _coreSidebarService: CoreSidebarService) { }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  hiddenFields: boolean = false;
  hiddenCatagoryFields: boolean = false;
  hiddenCustomColumnFields: boolean = false;
  changeText = "Show more";
  public readonly = false;
  public searchable = false;

  selectCurrency = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];

  showHiddenFields() {
    this.hiddenFields = !this.hiddenFields;
    this.hiddenFields ? this.changeText = "Hide" : this.changeText = "Show more";
  }

  addCategoryField() {
    this.hiddenCatagoryFields = !this.hiddenCatagoryFields;
  }
  addCustomColumnField() {
    this.hiddenCustomColumnFields = !this.hiddenCustomColumnFields;
  }

  ngOnInit(): void {
  }

}
