import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-samlidpauthentication-addon',
  templateUrl: './samlidpauthentication-addon.component.html',
  styleUrls: ['./samlidpauthentication-addon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class SAMLIDPAuthenticationAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
