import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-branding-addon',
  templateUrl: './branding-addon.component.html',
  styleUrls: ['./branding-addon.component.scss']
})
export class BrandingAddonComponent implements OnInit {

   // modal Basic
modalOpen(brandingSettingModal) {
  this.modalService.open(brandingSettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
  pagination: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};

constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
