<div class="container sign-bankId-container">
    <div class="row">
        <div class="col-lg-12 col-12">
            <div class="bankId-box-header">
                <h2>Sign New document with Swedish BankID</h2>
            </div>
            
            <ul ngbNav #navJustified="ngbNav" class="nav-tabs">
                <li ngbNavItem="mobileBankId">
                    <a ngbNavLink>Mobile BankID</a>
                    <ng-template ngbNavContent>
                        <div class="bankId-box-tab-content">
                            <div class="bankId-tab-content-left">
                                <h5 class="bankId-tab-title">
                                    <strong> Asad outlook Shah 333, </strong>please complete your signature using BankID
                                </h5>

                                <button type="button" class="btn btn-success signWithBankId-process-btn">
                                    Sign with BankID
                                    <img src="../../assets/images/icons/bankid-iconSigning.svg">
                                </button>
                            </div>

                            <div class="bankId-tab-content-right">
                                <h5> How to log in with a mobile BankID </h5>
                                <p>To log in, you need a mobile phone or tablet with BankID security app installed.</p>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem ngbNavItem="BankID">
                    <a ngbNavLink>BankID</a>
                    <ng-template ngbNavContent>
                        <div class="bankId-box-tab-content">
                            <div class="bankId-tab-content-left">
                                <h5 class="bankId-tab-title">
                                    <strong> Asad outlook Shah 333, </strong>please complete your signature using BankID
                                </h5>

                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="personalId">
                                            <i class="bi bi-person-badge"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Personal ID" aria-label="Personal ID" aria-describedby="personalId" />
                                </div>

                                <button type="button" class="btn btn-success signWithBankId-process-btn">
                                    Sign with BankID
                                    <img src="../../assets/images/icons/bankid-iconSigning.svg">
                                </button>
                            </div>

                            <div class="bankId-tab-content-right">
                                <p>To sign with a card, you need a card reader and a computer with the BankID security app installed.</p>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="navJustified" class=""></div>
        </div>
    </div>
</div>