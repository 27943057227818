import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-content-library-addon',
  templateUrl: './content-library-addon.component.html',
  styleUrls: ['./content-library-addon.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ContentLibraryAddonComponent implements OnInit {

  
 // modal Basic
 modalOpen(contentLibrarySettingModal) {
  this.modalService.open(contentLibrarySettingModal, {
    // centered: true,
    size: 'lg' 
  });
}
// swiper
public swiperPaginations: SwiperConfigInterface = {
  pagination: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
};



constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
