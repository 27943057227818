import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-signing-sidebar',
  templateUrl: './signing-sidebar.component.html',
  styleUrls: ['./signing-sidebar.component.scss']
})
export class SigningSidebarComponent implements OnInit {

  constructor(private modalService: NgbModal,private _coreSidebarService: CoreSidebarService) { }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    $('body').removeClass('sidebar-opened');
  }

  ngOnInit(): void {
  }

  modalOpen(modalInviteToView) {
    this.modalService.open(modalInviteToView, {
      size: 'lg' // size: 'xs' | 'sm' | 'lg' | 'xl'
    });
  }
  
  modalOpenReject(modalInviteToView) {
    this.modalService.open(modalInviteToView, {
    });
  }

}
