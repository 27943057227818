<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container content">
  <div class="bookmark-wrapper simpleSignMainNavbar">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <ul class="navbar-nav nav">
      <li ngbDropdown container="body" class="nav-item d-none d-lg-block">
        <a ngbDropdownToggle type="button" id="sendDropdown" class="nav-link nav-link-style btn d-flex align-items-center px-1 dropdown-toggle" data-toggle="dropdown">
          <!-- <i class="feather font-medium-5 icon-send mr-50"></i> -->
          <span class="font-weight-normal">Send New</span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu-right dropdown-menu mt-2" aria-labelledby="sendDropdown">
          <a ngbDropdownItem class="sort-asc" (click)="modalOpen(modalBasic)">
            <i data-feather="file-text" [size]="16" class="text-body mr-50"></i>
            <span>Select Template
              <small class="mt-50 form-text">Fill out a template and send</small>
            </span>
          </a>
          <a ngbDropdownItem class="sort-asc">
            <i data-feather="mail" [size]="16" class="text-body mr-50"></i>

            <span>Upload Document 
              <small class="mt-50 form-text">Upload document</small>
            </span>
          </a>
          <a ngbDropdownItem class="sort-asc">
            <i data-feather="file" [size]="16" class="text-body mr-50"></i>            
            <span>Blank Page 
              <small class="mt-50 form-text">Create document and send</small>
            </span>
          </a>
        </div>
      </li>
      <li class="nav-item d-none d-lg-block" [routerLinkActive]="['active-nav']">
        <a type="button" class="nav-link nav-link-style btn d-flex align-items-center px-1" [routerLink]="['/documents']">
          <!-- <i class="feather font-medium-5 icon-file-text mr-50"></i> -->
          <span class="font-weight-normal">Document</span>
        </a>
      </li>
      <li class="nav-item d-none d-lg-block" [routerLinkActive]="['active-nav']">
        <a type="button" class="nav-link nav-link-style btn d-flex align-items-center px-1" [routerLink]="['./contract-control']">
          <!-- <i class="feather font-medium-5 icon-sliders mr-50"></i> -->
          <span class="font-weight-normal">Contract Control</span>
        </a>
      </li>
    </ul>
    <!--/ Toggle skin -->
  </div>

  <ul class="nav navbar-nav align-items-center d-flex justify-content-end">
    <!-- ? Language selection | Uncomment if needed-->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i><span class="selected-language">{{
          languageOptions[_translateService.currentLang].title }}</span></a>
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle id="navbarUserDropdown"
        aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">John Doe</span><span class="user-status">Sample</span>
        </div>
        <span class="avatar"><img class="round" src="../../../../assets/images/portrait/small/avatar-s-11.jpg" alt="avatar"
            height="40" width="40" /><span class="avatar-status-online"></span></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu profileDropdownUL dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/profileSetting']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a>
        <div class="dropdown-divider"></div>        
        <div class="profileDropdownSignature-Container d-flex flex-column w-100">
          <div class="SignatureInnerCont d-flex justify-content-center align-items-center">
            <h3 class="font-small-3 text-muted">Signature here</h3>
          </div>
          <p class="cursor-pointer font-small-2 d-flex text-primary justify-content-end align-items-center mr-1 mb-0">Change your signature</p>
        </div>
        <div class="dropdown-divider"></div>        
        <a ngbDropdownItem [routerLink]="['/users']"><span [data-feather]="'users'" [class]="'mr-50'"></span> User</a><a
          ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Billing</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'edit-3'" [class]="'mr-50'"></span> Signature left <span class="float-right font-small-2 mt-25 text-muted">20</span></a><a
        ngbDropdownItem [routerLink]="['/']"><span [data-feather]="'mail'" [class]="'mr-50'"></span> SMS available <span class="float-right font-small-2 mt-25 text-muted">20</span></a>
        <div class="dropdown-divider"></div>

        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span>Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

<!-- Select Template Modal Start -->
<ng-template #modalBasic let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Select template</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="select-template-head">
      <div class="col-md-4 col-12 p-0">
        <div class="input-group">
          <div class="input-group-prepend" id="">
            <button class="btn btn-outline-primary template-filter-icon-search" type="button" rippleEffect>
              <i data-feather="search" size="16"></i>
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            placeholder="Search templates"
            aria-describedby="button-addon2"
          />
          <div class="input-group-append" id="">
            <button class="btn btn-outline-primary template-filter-icon" type="button" rippleEffect>
              <i data-feather="filter" size="18"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="select-template-box">
      <div class="col-lg-12 p-0">
        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12 col-xl-3">
          <div class="card mb-0">
            <div class="row g-0">
              <div class="col-md-4 template-thumbnail">
                <img class="card-img card-img-left" src="../assets/images/pages/documentThumb.jpg" alt="Card image">
              </div>
              <div class="col-md-8 pl-0 template-info">
                <div class="card-body">
                  <h5 class="card-title">Internship Agreement</h5>
                  <p class="card-text templatesThumbnail-footer-text">
                    <small>HR</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12 col-xl-3">
          <div class="card mb-0">
            <div class="row g-0">
              <div class="col-md-4 template-thumbnail">
                <img class="card-img card-img-left" src="../assets/images/pages/documentThumbOne.jpg" alt="Card image">
              </div>
              <div class="col-md-8 pl-0 template-info">
                <div class="card-body">
                  <h5 class="card-title">Proposal Template For Any Business</h5>
                  <p class="card-text templatesThumbnail-footer-text">
                    <small>HR</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12 col-xl-3">
          <div class="card mb-0">
            <div class="row g-0">
              <div class="col-md-4 template-thumbnail">
                <img class="card-img card-img-left" src="../assets/images/pages/documentThumb.jpg" alt="Card image">
              </div>
              <div class="col-md-8 pl-0 template-info">
                <div class="card-body">
                  <h5 class="card-title">Freelance Contract</h5>
                  <p class="card-text templatesThumbnail-footer-text">
                    <small>HR</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="template-pagination-row">
      <ngb-pagination [collectionSize]="40" [(page)]="pageBasic" aria-label="Default pagination">
        <ng-template ngbPaginationPrevious>
          <span [data-feather]="'chevron-left'" [size]="18" [class]="'font-weight-bolder'"></span>
        </ng-template>
        <ng-template ngbPaginationNext>
          <span [data-feather]="'chevron-right'" [size]="18" [class]="'font-weight-bolder'"></span>
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
</ng-template>
<!-- / Select Template Modal End -->