<div class="card addonBoxContainer" (click)="modalOpen(timeWaveSettingModal)">
    <div class="card-body">
        <h5>Time wave</h5>
        <p class="card-text font-small-3 addonboxDiscription"> When you send documents you can search and collect
            employee data from inactive employees.
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <img src="assets/images/addons-images/timewave.png" class="addonThumbnailImg" alt="addonThumbnail" />
    </div>
</div>

<!-- Modal -->
<ng-template #timeWaveSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Time wave</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                This integration collects employee information from timewave.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations swiper-container" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets/images/addons-images/no-thumbnail.jpg" alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-6 col-lg-6 col-sm-12">
                            <div class="form-group">
                                <label for="basicInput">Client secret</label>
                                <input type="text" class="form-control" id="Client secret" placeholder="Client secret" />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12">
                            <div class="form-group">
                                <label for="basicInput">client id</label>
                                <input type="number" class="form-control" id="client id" placeholder="client id" />
                            </div>
                        </div>
                        
                        <div class="col-12">
                            <button type="button" class="btn btn-success float-right" rippleEffect>Save</button>
                        </div>

                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->