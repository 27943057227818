<div class="content-wrapper edit-template-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- for content background upload -->
              <input type="file" name="image-upload" accept="image/*"  id="content-background-image" class="hidden"/>
        <!-- / for content background upload -->
        <!-- Upload PDF loader -->
        <div class="upload-pdf-loader hidden">
            <span role="status" aria-hidden="true" class="spinner-grow spinner-grow-sm"></span>
            <span class="ml-50 align-middle">Uploading...</span>
        </div>
        <!-- / Upload PDF loader -->

        <!-- Editor Area -->
        <div class="template-page-container">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header py-50 px-1 page-document-header">
                <div class="page-document-header-right">
                    <span class="document-name m-0" contenteditable > New Document </span>
                    <span class="document-total-price m-0 pl-50 text-success cursor-pointer" (click)="documentPirceSettings()">
                        0,00 USD </span>
                </div>
                <div class="page-document-header-right">
                    <div ngbDropdown>
                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow d-flex" id="todoActions"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i data-feather="more-vertical" [size]="18" class="text-body"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                            <a ngbDropdownItem class="sort-asc">
                                <i class="feather font-medium-3 mr-50 icon-eye"></i>
                                Preview as recipient</a>
                            <a ngbDropdownItem class="sort-asc">
                                <i class="feather font-medium-3 mr-50 icon-pocket"></i>
                                Save as draft</a>
                            <a ngbDropdownItem class="sort-asc">
                                <i class="feather font-medium-3 mr-50 icon-file-text"></i>
                                Save as reusable template</a>
                            <a ngbDropdownItem class="sort-asc">
                                <i class="feather font-medium-3 mr-50 icon-upload"></i>
                                Export as PDF</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Content Builder Dropdown -->
            <div class="content-builder-menu hidden">
                <div class="content-builder-menu-list" *ngFor="let form of contentBuilders" [attr.form-type]="form.type">
                    <div class="content-builder-menu-list-icon">
                        <svg *ngIf="form.type == 'formbuilder'" width="28" height="28" viewBox="0 0 28 28" fill="currentColor"
                            class="Icon">
                            <path fill-rule="evenodd" d="M2 13V1h24v12H2zm14 14v-2h10v2H16zm0-6v2h10v-2H16zm0-2v-2h10v2H16zM2 17v10h10V17H2zm2-6V3h20v8H4zm0 8v6h6v-6H4z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <svg *ngIf="form.type == 'content'" viewBox="0 0 24 24" role="presentation">
                            <path d="M4 4h8v2H9v6H7V6H4V4zM4 16V14h16v2l-16 .001zM4 20v-2h16v2H4zm9-8v-2h7v2h-7z"
                                fill-rule="evenodd"></path>
                        </svg>
                        <svg *ngIf="form.type == 'contentlibrary'" viewBox="0 0 24 24" role="presentation">
                            <defs>
                                <path id="ContentLibrary_svg__a" d="M4 21v-8h16v8H4zm2-1.999h12.001V15H6v4.001zM4 11V3h8v8H4zm13-1a3 3 0 110-6 3 3 0 010 6z"></path>
                            </defs>
                            <use xlink:href="#ContentLibrary_svg__a" fill-rule="evenodd"></use>
                        </svg>
                        <svg *ngIf="form.type == 'attachment'" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-paperclip">
                            <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                        </svg>
                        <svg *ngIf="form.type == 'pagebreak'" viewBox="0 0 24 24" role="presentation">
                            <path d="M5 18c.55 0 1-.45 1-1s-.45-1-1-1c-.56 0-1 .45-1 1s.44 1 1 1zM5 8c.55 0 1-.45 1-1s-.45-1-1-1c-.56 0-1 .45-1 1s.44 1 1 1zm12.7-1.29l-5.61 5.62 5.61 5.62c-.78.78-2.05.78-2.83 0l-4.21-4.21-2.72 2.72c.03.18.06.36.06.54 0 1.66-1.35 3-3 3-1.66 0-3-1.34-3-3s1.34-3 3-3c.73 0 1.39.26 1.9.69l2.36-2.36-2.74-2.74c-.44.26-.96.41-1.52.41-1.66 0-3-1.34-3-3s1.34-3 3-3c1.65 0 3 1.34 3 3 0 .37-.08.72-.2 1.04l2.87 2.88h.01l4.19-4.21c.78-.78 2.05-.78 2.83 0zM16 13v-2h2v2h-2zm4 0v-2h1.97v2H20z"
                                fill-rule="evenodd"></path>
                        </svg>
                        <svg *ngIf="form.type == 'producttable'" viewBox="0 0 26 26" role="presentation">
                            <path d="M2 20h20V4H2v16zm11-7h7v-3h-7v3zm-9 0h7v-3H4v3zm9 5.001h7V15h-7v3.001zm-9 0h7V15H4v3.001z"
                                fill-rule="evenodd"></path>
                        </svg>
                    </div>
                    <div class="content-builder-menu-list-label">
                        <p>{{form.name}}</p>
                        <p>Insert a new <span class="content-builder-menu-inner-label">{{form.secondary | titlecase}}</span></p>
                    </div>
                </div>
            </div>
            <!-- / Content Builder Dropdown -->
            <!-- tags list -->
            <ul class="tags-list-menu hidden" role="listbox">
                <li class="tags-box tags-builder-menu-list" tag-type="textfield" role="option">
                    <p>Text field</p>
                    <p>Insert a new<span class="content-builder-menu-inner-label">Tag</span></p>
                </li>
                <li class="tags-box tags-builder-menu-list" tag-type="datepicker" role="option">
                    <p>Datepicker</p>
                    <p>Insert a new<span class="content-builder-menu-inner-label">Tag</span></p>
                </li>
                <li class="tags-box tags-builder-menu-list" tag-type="droplist" role="option">
                    <p>Droplist</p>
                    <p>Insert a new<span class="content-builder-menu-inner-label">Tag</span></p>
                </li>
            </ul>
            <!-- / tags list -->

            <div class="template-page">
            </div>
        </div>
        <!-- / Editor Area -->

        <!-- Editor Right Setting Options -->
        <div class="editor-options-rightmenubar">
            <div class="document-rightmenu-settings" *ngIf="rightMenuType == 'DEFAULT'">
                <div class="p-1">
                    <button [disabled]="savingTemaplte" type="button" class="btn btn-success btn-block" rippleEffect (click)="saveTemplate()"> <span *ngIf="savingTemaplte" class="spinner-border spinner-border-sm mr-1"></span> Save changes </button>
                </div>
                <div class="document-tabs-class">
                    <ul ngbNav #navWithIcons="ngbNav" class="nav-tabs nav-justified" [(activeId)]="activatedNav" (navChange)="menuChange($event)">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink placement="top" ngbTooltip="Recipients">
                                <div class="d-flex justify-content-start align-items-center back-to-rec-Icon" (click)="backToRecipientView()" *ngIf="showBackbutton">
                                    <span [data-feather]="'chevron-left'" [size]="20"></span>
                                </div>
                                <span [data-feather]="'users'" [size]="20"></span></a>
                            <ng-template ngbNavContent>
                                <app-recipients-tab (detectChange)="detectChildChanges($event)" ></app-recipients-tab>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink placement="top" ngbTooltip="Details"><span [data-feather]="'server'" [size]="20"></span></a>
                            <ng-template ngbNavContent>
                                <app-details-tab></app-details-tab>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink placement="top" ngbTooltip="Content"><span [data-feather]="'plus-circle'"
                                    [size]="20"></span></a>
                            <ng-template ngbNavContent>
                                <app-content-tab></app-content-tab>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink placement="top" ngbTooltip="Settings"><span [data-feather]="'settings'"
                                    [size]="20"></span></a>
                            <ng-template ngbNavContent>
                                <app-settings-tab></app-settings-tab>
                            </ng-template>
                        </li>
                        <!-- <li [ngbNavItem]="5">
                            <a ngbNavLink placement="top"
                            ngbTooltip="Content library"><span [data-feather]="'book'" [size]="20"></span></a>
                            <ng-template ngbNavContent>
                                <app-content-library-tab></app-content-library-tab>
                            </ng-template>
                        </li> -->
                    </ul>
                    <div [ngbNavOutlet]="navWithIcons" class="p-0 pt-0"></div>
                </div>
            </div>

            <div class="fields-settings-container" *ngIf="rightMenuType == 'POPOVERSETTINGMENU'">
                <app-general-popover-settings [data]="fieldSettings"></app-general-popover-settings>
            </div>

            <div class="formbuilder-settings-container" *ngIf="rightMenuType == 'FORMBUILDERSETTINGMENU'">
                <app-form-builder-settings [data]="fieldSettings"></app-form-builder-settings>
            </div>

            <div class="formbuilder-settings-container" *ngIf="rightMenuType == 'PDFSETTINGMENU'">
                <app-pdf-block-settings [data]="fieldSettings"></app-pdf-block-settings>
            </div>

            <div class="formbuilder-settings-container" *ngIf="rightMenuType == 'CONTENTTABLESETTINGMENU'">
                <app-content-table-settings [data]="fieldSettings"></app-content-table-settings>
            </div>

            <div class="formbuilder-settings-container" *ngIf="rightMenuType == 'PRODUCTTABLESETTINGMENU'">
                <app-product-table-settings [data]="fieldSettings"></app-product-table-settings>
            </div>
        </div>
        <!-- / Editor Right Setting Options -->
    </div>
    <div id="guide-h" class="guide"></div>
    <div id="guide-v" class="guide"></div>
    <div id="guide-hb" class="guide"></div>
    <div id="guide-vr" class="guide"></div>
</div>

<!-- Attachment Modal Start -->
<ng-template #attachmentsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Attachment settings</h4>
        <button type="button" class="close" (click)="closeAttachmentModal('close')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="upload-attachment-content-container">
            <div class="nav-vertical">
                <ul ngbNav #navVertical="ngbNav" class="nav nav-tabs nav-left flex-column">
                    <li ngbNavItem>
                        <a ngbNavLink>Add attachments</a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane-padding">
                                <div class="attachment-upload-box">
                                    <div>
                                        <p class="attachment-box-titile">Select the files you would like to upload</p>
                                    </div>
                                    <button type="button" class="btn btn-success round" rippleEffect>Select File</button>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Request attachments</a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane-padding">
                                <div class="row">
                                    <div class="req-atta-left"></div>
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Attachment</label>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Include or exclude?</label>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Request from</label>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Uploading is</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="req-atta-right"></div>
                                </div>
                                <div class="row req-attachment-row">
                                    <div class="req-atta-left">
                                        <div class="form-group mb-0">
                                            <div class="custom-control custom-checkbox" placement="top" container="body" ngbTooltip="Allow multiple">
                                                <input type="checkbox" [checked]="allowMultipleCheck" class="custom-control-input" id="customCheck1" />
                                                <label class="custom-control-label" for="customCheck1"></label>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Attachment</label>
                                                    <input type="text" class="form-control" id="basicInput" />
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Include or exclude?</label>
                                                    <ng-select [items]="includeExcludeDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="includeExcludeDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Request from</label>
                                                    <ng-select [items]="RequestFromDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="RequestFromDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Uploading is</label>
                                                    <ng-select [items]="UploadingIsDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="UploadingIsDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-right">
                                        <i data-feather="trash" [size]="16" class="cursor-pointer"></i>
                                    </div>
                                </div>
                                <div class="row req-attachment-row">
                                    <div class="req-atta-left">
                                        <div class="form-group mb-0">
                                            <div class="custom-control custom-checkbox" placement="top" container="body" ngbTooltip="Allow multiple">
                                                <input type="checkbox" [checked]="allowMultipleCheck" class="custom-control-input" id="customCheck2" />
                                                <label class="custom-control-label" for="customCheck2"></label>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Attachment</label>
                                                    <input type="text" class="form-control" id="basicInput" />
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Include or exclude?</label>
                                                    <ng-select [items]="includeExcludeDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="includeExcludeDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Request from</label>
                                                    <ng-select [items]="RequestFromDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="RequestFromDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Uploading is</label>
                                                    <ng-select [items]="UploadingIsDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="UploadingIsDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-right">
                                        <i data-feather="trash" [size]="16" class="cursor-pointer"></i>
                                    </div>
                                </div>
                                <div class="row req-attachment-row">
                                    <div class="req-atta-left"></div>
    
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <button type="button" class="btn btn-success round w-100" rippleEffect>Add request attachment</button>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-right"></div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Reusable attachments</a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane-padding">
                                <div class="row">
                                    <div class="req-atta-left"></div>
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Attachment</label>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Include or exclude?</label>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Request from</label>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <label class="col-form-label pt-0" for="name">Uploading is</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="req-atta-right"></div>
                                </div>
                                <div class="row req-attachment-row">
                                    <div class="req-atta-left">
                                        <div class="form-group mb-0">
                                            <div class="custom-control custom-checkbox" placement="top" container="body" ngbTooltip="Allow multiple">
                                                <input type="checkbox" [checked]="allowMultipleCheck" class="custom-control-input" id="customCheck1" />
                                                <label class="custom-control-label" for="customCheck1"></label>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Attachment</label>
                                                    <ng-select [items]="selectAttachmentDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="selectAttachmentDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Include or exclude?</label>
                                                    <ng-select [items]="includeExcludeDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="includeExcludeDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Request from</label>
                                                    <ng-select [items]="RequestFromDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="RequestFromDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Uploading is</label>
                                                    <ng-select [items]="UploadingIsDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="UploadingIsDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-right">
                                        <i data-feather="trash" [size]="16" class="cursor-pointer"></i>
                                    </div>
                                </div>
                                <div class="row req-attachment-row">
                                    <div class="req-atta-left">
                                        <div class="form-group mb-0">
                                            <div class="custom-control custom-checkbox" placement="top" container="body" ngbTooltip="Allow multiple">
                                                <input type="checkbox" [checked]="allowMultipleCheck" class="custom-control-input" id="customCheck2" />
                                                <label class="custom-control-label" for="customCheck2"></label>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Attachment</label>
                                                    <ng-select [items]="selectAttachmentDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="selectAttachmentDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Include or exclude?</label>
                                                    <ng-select [items]="includeExcludeDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="includeExcludeDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Request from</label>
                                                    <ng-select [items]="RequestFromDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="RequestFromDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3">
                                                <div class="form-group mb-0">
                                                    <label class="col-form-label pt-0 hidden" for="name">Uploading is</label>
                                                    <ng-select [items]="UploadingIsDropdownItem" bindLabel="name" bindValue="id" [(ngModel)]="UploadingIsDropdown" appendTo="body">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-right">
                                        <i data-feather="trash" [size]="16" class="cursor-pointer"></i>
                                    </div>
                                </div>
                                <div class="row req-attachment-row">
                                    <div class="req-atta-left"></div>
    
                                    <div class="req-atta-center">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3">
                                                <button type="button" class="btn btn-success round w-100" rippleEffect>Add attachments</button>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="req-atta-right"></div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Design</a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane-padding">
                                <div class="attachment-design-setting">
                                    <p class="attachment-box-titile mt-0 mb-75">Title</p>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0" for="name">Font size</label>
                                                <ng-select [items]="attachmentFontSize" bindLabel="name" bindValue="id"
                                                    [(ngModel)]="selectedattachmentFontSize" appendTo="body">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0" for="name">Font style</label>
                                                <ng-select [items]="attachmentFontStyle" bindLabel="name" bindValue="id"
                                                    [(ngModel)]="selectedattachmentFontStyle" appendTo="body">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group mb-0">
                                                <label class="col-form-label pt-0" for="name">Font color</label>
                                                <input class="form-control" [(colorPicker)]="color" [style.background]=""
                                                    [cpOutputFormat]="'hsla'" [value]="color" />
                                                <div [style.background]="color" class="color-picker-ss"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="attachment-design-setting mt-1">
                                    <p class="attachment-box-titile mt-0 mb-75">Description</p>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0" for="name">Font size</label>
                                                <ng-select [items]="attachmentFontSize" bindLabel="name" bindValue="id"
                                                    [(ngModel)]="selectedattachmentFontSize" appendTo="body">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label class="col-form-label pt-0" for="name">Font style</label>
                                                <ng-select [items]="attachmentFontStyle" bindLabel="name" bindValue="id"
                                                    [(ngModel)]="selectedattachmentFontStyle" appendTo="body">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group mb-0">
                                                <label class="col-form-label pt-0" for="name">Font color</label>
                                                <input class="form-control" [(colorPicker)]="color1" [style.background]=""
                                                    [cpOutputFormat]="'hsla'" [value]="color1" />
                                                <div [style.background]="color1" class="color-picker-ss"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="navVertical"></div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeAttachmentModal('add')" rippleEffect>
            Accept
        </button>
    </div>
</ng-template>
<!-- Attachment Modal End -->

<!-- Upload Image Modal Sart -->
<ng-template #uploadImageModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Choose Image</h4>
        <button type="button" class="close" (click)="closeAttachmentModal('close')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="upload-image-content-container">
            <!-- <div class="upload-image-menu"></div>
            <div class="upload-image-content"></div> -->

            <div class="nav-vertical" *ngIf="loadView">
                <ul ngbNav #navVertical="ngbNav" class="nav nav-tabs nav-left flex-column">
                    <li ngbNavItem>
                        <a ngbNavLink>Upload</a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane-padding">
                                <div class="image-upload-drop-box">
                                    <img src="../../../../assets/images/icons/upload-image.png">
                                    <div>
                                        <p class="drag-image-titile">Drag and Drop files to upload</p>
                                        <p class="mb-0">Or</p>
                                    </div>
                                    <button type="button" class="btn btn-primary round" rippleEffect>Browse</button>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>My images</a>
                        <ng-template ngbNavContent>
                            <div class="images-box-container upload-tab-scroll" [perfectScrollbar]>

                                <div class="uploaded-images-box" *ngFor="let image of myImages; let i = index" (click)="selectImage(image,i)">
                                    <div class="uploaded-images" [class.active]="i == activeImage" [ngStyle]="{'background-image':'url('+image.uri+')'}">
                                        <div class="upload-image-action">
                                            <button type="button" class="btn btn-icon btn-warning delete-image-btn"
                                                rippleEffect>
                                                <span [data-feather]="'trash'"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="upload-image-info">
                                        <p>{{image.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Company logo</a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane-padding">
                                <p>

                                </p>
                            </div>
                        </ng-template>
                    </li>
                    <li ngbNavItem [disabled]="true">
                        <a ngbNavLink>
                            AI generate images
                            <span class="ai-image-info-icon" placement="left" ngbTooltip="Coming Soon"><span
                                    [data-feather]="'info'" [size]="16"></span></span>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="tab-pane-padding">
                                <p>

                                </p>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="navVertical"></div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button [disabled]="activeImage == -1" type="button" class="btn btn-primary" (click)="closeAttachmentModal('add')"
            rippleEffect>
            Add
        </button>
    </div>
</ng-template>
<!-- / Upload Image Modal End -->

<!-- product table modals -->
<app-product-table-modals></app-product-table-modals>
<!-- product table modals end -->