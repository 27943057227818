import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
@Component({
  selector: 'app-userslisting',
  templateUrl: './userslisting.component.html',
  styleUrls: ['./userslisting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserslistingComponent implements OnInit {

  public pageBasic = 1;
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  constructor(
    private _coreSidebarService: CoreSidebarService,
  ) {  }

  ngOnInit(): void {
  }

}
