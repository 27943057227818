import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attachment-addon',
  templateUrl: './attachment-addon.component.html',
  styleUrls: ['./attachment-addon.component.scss']
})
export class AttachmentAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
