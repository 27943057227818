import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { ColorPickerModule } from 'ngx-color-picker';
import { BreadcrumbModule } from 'app/layout/components/content-header/breadcrumb/breadcrumb.module';
import { CsvModule } from '@ctrl/ngx-csv';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TabsModule } from 'app/main/components/tabs/tabs.module';

import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';

import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { ApisettingsComponent } from './apisettings/apisettings.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { AddAttachmentsSidebarComponent } from './attachments/add-attachments-sidebar/add-attachments-sidebar.component';
import { BrandingComponent } from './branding/branding.component';
import { BulksendComponent } from './bulksend/bulksend.component';
import { CompanyComponent } from './company/company.component';
import { ContentlibraryComponent } from './contentlibrary/contentlibrary.component';
import { BulksendInnerViewComponent } from './bulksend/bulksend-inner-view/bulksend-inner-view.component';
import { EditContentLirbraryComponent } from './contentlibrary/edit-content-lirbrary/edit-content-lirbrary.component';
import { ContentLibraryRightMenuComponent } from './contentlibrary/edit-content-lirbrary/content-library-right-menu/content-library-right-menu.component';
import { DatagroupsComponent } from './datagroups/datagroups.component';
import { DatagroupsmodalComponent } from './datagroups/datagroupsmodal/datagroupsmodal.component';
import { DocumentfieldsComponent } from './documentfields/documentfields.component';
import { DocumentfieldssidebarComponent } from './documentfields/documentfieldssidebar/documentfieldssidebar.component';
import { DocumenttypeComponent } from './documenttype/documenttype.component';
import { ProductlibraryComponent } from './productlibrary/productlibrary.component';
import { ProductlibrarysidebarComponent } from './productlibrary/productlibrarysidebar/productlibrarysidebar.component';
import { RecipientfieldsComponent } from './recipientfields/recipientfields.component';
import { MicroAgreementComponent } from './micro-agreement/micro-agreement.component';
import { IDcheckComponent } from './idcheck/idcheck.component';

// routing
const routes: Routes = [
  {
    path: 'settings/apisettings',
    component: ApisettingsComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/attachments',
    component: AttachmentsComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/branding',
    component: BrandingComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/bulksend',
    component: BulksendComponent,
    data: { animation: 'misc' }
  },
    {
      path: 'settings/bulksend/listing',
      component: BulksendInnerViewComponent,
      data: { animation: 'misc' }
    },
  {
    path: 'settings/company',
    component: CompanyComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/contentlibrary',
    component: ContentlibraryComponent,
    data: { animation: 'misc' }
  },
    {
      path: 'settings/contentlibrary/edit-content-lirbrary',
      component: EditContentLirbraryComponent,
      data: { animation: 'misc' }
    },
  {
    path: 'settings/datagroups',
    component: DatagroupsComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/documentfields',
    component: DocumentfieldsComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/documenttype',
    component: DocumenttypeComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/productlibrary',
    component: ProductlibraryComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/recipientfields',
    component: RecipientfieldsComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/micro-agreement',
    component: MicroAgreementComponent,
    data: { animation: 'misc' }
  },
  {
    path: 'settings/idcheck',
    component: IDcheckComponent,
    data: { animation: 'misc' }
  },
];

@NgModule({
  declarations: [
    ApisettingsComponent,
    AttachmentsComponent,
    AddAttachmentsSidebarComponent,
    BrandingComponent,
    BulksendComponent,
    CompanyComponent,
    ContentlibraryComponent,
    BulksendInnerViewComponent,
    EditContentLirbraryComponent,
    ContentLibraryRightMenuComponent,
    DatagroupsComponent,
    DatagroupsmodalComponent,
    DocumentfieldsComponent,
    DocumentfieldssidebarComponent,
    DocumenttypeComponent,
    ProductlibraryComponent,
    ProductlibrarysidebarComponent,
    RecipientfieldsComponent,
    MicroAgreementComponent,
    IDcheckComponent,
    
  ],
  imports: [CommonModule, RouterModule.forChild(routes),ColorPickerModule,TabsModule, PerfectScrollbarModule, CsvModule, BreadcrumbModule, TranslateModule,NgxDatatableModule,  CoreDirectivesModule,FileUploadModule, CorePipesModule, CoreSidebarModule, CoreCommonModule, NgbModule, ContentHeaderModule, CardSnippetModule,NgSelectModule]
})
export class SettingsModule {}
