import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'app/global/global.service';
import { RecipientsService } from './recipients.service';
import { RecipientsRightSidebarComponent } from './recipients-right-sidebar/recipients-right-sidebar.component';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent implements OnInit {
  @ViewChild(RecipientsRightSidebarComponent) recipientRightBar;
  public contentHeader: object;
  public loading = false;
  public recipients = [];
  public page = 1;
  public pagination:any = [];
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private modalService: NgbModal,
    public globalService: GlobalService,
    private recipientsService: RecipientsService,
  ) { }

  async ngOnInit() {
    this.fetchRecipient();
  }

  /**
   * Toggle side bar with name and passing recipient data to form
   * @param name 
   * @param recipientObject 
   * @param index 
   */
  toggleSidebar(name, recipientObject = {}, index = 0): void {
    recipientObject['index'] = index;
    this.recipientRightBar.initRecipientsForm(recipientObject);
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  
  /**
   * Bulk upload model form
   * @param bulkUploadModal 
   */
  bulkUploadModalOpen(bulkUploadModal) {
    this.modalService.open(bulkUploadModal, {
      size: 'xl'
    });
  }

  /**
   * Fetching recipient with query params
   * @param page 
   * @param query 
   */
  async fetchRecipient(page:any = 1,query="") {
    this.page = page;
    this.loading = true;
    const recipients = await this.recipientsService.fetchRecipient(this.page,query);
    this.recipients = recipients.data.data.data || [];
    this.pagination = recipients.data.data;
    this.loading = false;
  }


  /**
   * This function is emiting from recipient right side bar for splicing row from listing
   * @param $event 
   */
  deleteRecipient($event: any) {
    const id = $event.id;
    this.recipients.splice(id, 1);
  }

  /**
   * Update listing data
   * @param $event 
   */
  updateListing($event:any){
    var index = $event.index;
    var item = $event.data;
    this.recipients[index]['organization'] = item;
  }

}
