import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-productlibrary',
  templateUrl: './productlibrary.component.html',
  styleUrls: ['./productlibrary.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ProductlibraryComponent implements OnInit {

  public pageBasic = 1;

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  constructor(
    private _coreSidebarService: CoreSidebarService,
  ) {  }

  ngOnInit(): void {
  }

}
