<div class="content-area-wrapper container-xxl p-0">
    <!-- recipient fields main Sidebar (Filter) -->
    <core-sidebar class="sidebar-left" name="todo-sidebar-main" overlayClass="body-content-overlay" collapsibleSidebar="bs-gt-lg">
        <div class="main-sidebar sidebar">
            <div class="sidebar-content todo-sidebar">
                <div class="todo-app-menu">
                    <div class="add-task">
                        <button type="button" class="btn btn-success btn-block" rippleEffect>
                            Add new role
                        </button>
                    </div>
                    <div class="sidebar-menu-list">
                        <div class="list-group list-group-labels">
                            <div class="list-group list-group-labels">
                                <a class="list-group-item list-group-item-action d-flex align-items-center active">
                                    <span class="bullet bullet-sm mr-1 bullet-primary"></span>Recipients
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </core-sidebar>
    <!--/ recipient fields main Sidebar -->

    <!-- content-right -->
    <div class="content-right">
        <div class="content-wrapper container-xxl p-0">
            <div class="content-body">
                <div class="todo-app-list">
                    <form [formGroup]="recipientRoleForm" (ngSubmit)="onSaveRecipientRole()">
                        <div class="app-fixed-search d-flex align-items-center">
                            <div class="sidebar-toggle d-block d-lg-none ml-1">
                                <button class="btn p-0" (click)="toggleSidebar('todo-sidebar-main')">
                                    <i class="feather icon-menu"></i>
                                </button>
                            </div>

                            <div class="d-flex align-content-center justify-content-between w-100 position-relative">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-alert-circle text-muted recipientInfoIcon" placement="top" placement="auto" ngbTooltip="Edit the role name to better suit the purpose of your document. For e.g. client, employee etc."></i></span>
                                    </div>
                                    <input type="text" class="form-control" id="" placeholder="Recipient name" aria-label="Search roles" aria-describedby="" formControlName="role_name" />
                                </div>
                                <div class="position-absolute recipientFieldTrashIcon">
                                    <i class="feather icon-trash recipientInfoIcon cursor-pointer" placement="top" ngbTooltip="Delete"></i>
                                </div>
                            </div>
                        </div>

                        <div class="recipientFieldsMiddleSection">
                            <div class="row">
                                <div class="col-md-8 col-lg-8 col-sm-12 border-right pr-0">
                                    <ul ngbNav #nav="ngbNav" class="nav-tabs nav mt-1 justify-content-center">
                                        <li ngbNavItem>
                                            <a ngbNavLink>Person Fields</a>
                                            <ng-template ngbNavContent>
                                            <!-- recipient-person-fields -->
                                                <div class="personFieldsWraper" [perfectScrollbar]>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0 pt-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck0" checked />
                                                            <label class="custom-control-label" for="customCheck0"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <label class="ml-1" for="name">Full name</label>
                                                            <div class="d-flex align-content-center justify-content-between w-100">
                                                                <div class="input-group input-group-merge">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text border-0"><i class="feather icon-search text-muted"></i></span>
                                                                    </div>
                                                                    <input type="text" class="form-control border-0" id="templates-search" placeholder="Enter name or search from contacts"
                                                                        aria-label="Search..." aria-describedby="templates-search" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                            <label class="custom-control-label" for="customCheck1"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Email</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck2" />
                                                            <label class="custom-control-label" for="customCheck2"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Personal ID number</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck8" />
                                                                <label class="custom-control-label" for="customCheck8"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck4" />
                                                            <label class="custom-control-label" for="customCheck4"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Address</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck5" />
                                                            <label class="custom-control-label" for="customCheck5"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Zip code</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck6" />
                                                            <label class="custom-control-label" for="customCheck6"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">City</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck7" />
                                                            <label class="custom-control-label" for="customCheck7"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Salary</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="validationContainer d-flex">
                                                            <i class="feather icon-trash recipientInfoIcon cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn mb-1 mr-auto btn-sm mt-1 addFieldBtn btn-transparent text-primary" (click)="addPersonFieldModalOpen(addPersonFieldModal)"> Add person field</button>
                                                </div>
                                            <!--/ recipient-person-fields -->
                                            </ng-template>
                                        </li>
                                        <li ngbNavItem>
                                            <a ngbNavLink>Organization Fields</a>
                                            <ng-template ngbNavContent>
                                                <!-- recipient-Organization-fields -->
                                                <div class="personFieldsWraper" [perfectScrollbar]>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0 pt-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck0" checked />
                                                            <label class="custom-control-label" for="customCheck0"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <label class="ml-1" for="name">Organization</label>
                                                            <div class="d-flex align-content-center justify-content-between w-100">
                                                                <div class="input-group input-group-merge">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text border-0"><i class="feather icon-search text-muted"></i></span>
                                                                    </div>
                                                                    <input type="text" class="form-control border-0" id="templates-search" placeholder="Enter company name or search from contacts"
                                                                        aria-label="Search..." aria-describedby="templates-search" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                            <label class="custom-control-label" for="customCheck1"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Email</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck2" />
                                                            <label class="custom-control-label" for="customCheck2"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Personal ID number</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck8" />
                                                                <label class="custom-control-label" for="customCheck8"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck4" />
                                                            <label class="custom-control-label" for="customCheck4"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Address</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck5" />
                                                            <label class="custom-control-label" for="customCheck5"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Zip code</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck6" />
                                                            <label class="custom-control-label" for="customCheck6"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">City</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <!-- <div class="validationContainer d-flex">
                                                            <div class="badge badge-light-secondary">No validation</div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                                <label class="custom-control-label" for="customCheck3"></label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="fieldContainer d-flex flex-nowrap border-bottom pb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="customCheck7" />
                                                            <label class="custom-control-label" for="customCheck7"></label>
                                                        </div>
                                                        <div class="inputCont">
                                                            <div class="form-group mb-0">
                                                                <label class="ml-1" for="email">Salary</label>
                                                                <input type="text" class="form-control border-0" id="" placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div class="validationContainer d-flex">
                                                            <i class="feather icon-trash recipientInfoIcon cursor-pointer"></i>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn mb-1 mr-auto btn-sm mt-1 addFieldBtn btn-transparent text-primary" (click)="addOrgFieldModalOpen(addOrgFieldModal)"> Add Organization field</button>
                                                </div>
                                                <!--/ recipient-Organization-fields -->
                                            </ng-template>
                                        </li>

                                    </ul>
                                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                                </div>
                                <div class="col-md-4 col-lg-4 col-sm-4 pl-0 d-lg-block d-md-block d-none">
                                    <!-- recipient-filters -->
                                    <div class="px-1 py-1 recipientFilters-scroll-content-container" [perfectScrollbar]>
                                            <div class="form-group">
                                                <label class="d-block position-relative" for="customSelect">Invitation through
                                                    <i class="feather icon-alert-circle recipientFilterInfoIcon" placement="top" container="body" placement="auto" ngbTooltip="Select the option of how you want the recipient to receive their invitation: Email, Email and SMS, POS (Point Of Sale) or In-meeting."></i>
                                                </label>
                                                <ng-select [items]="invitationthrough" bindLabel="name" bindValue="id"   [clearable]="false" formControlName="invitation_type"> </ng-select>
                                            </div>
                                            <div class="form-group">
                                                <label class="d-block position-relative" for="customSelect">Invitation Order
                                                    <i class="feather icon-alert-circle recipientFilterInfoIcon" placement="top" container="body" placement="auto" ngbTooltip="Select the order of invitations. You can allow one recipient to first view and sign before the document is sent to the next recipient in order in order"></i>
                                                </label>
                                                <ng-select [items]="invitationOrder" bindLabel="name" [searchable]="searchable" placeholder="Invitation Order" [clearable]="false" bindLabel="name" bindValue="id" formControlName="order_type"> </ng-select>
                                            </div>
                                            <div class="form-group">
                                                <label class="d-block position-relative" for="customSelect">Authentication
                                                    <i class="feather icon-alert-circle recipientFilterInfoIcon" placement="top" container="body" placement="auto" ngbTooltip="After the document been signed it will be sent in PDF format to your recipients. You can also select to send this confirmation as a notification via SMS."></i>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="authentication_required" formControlName="authentication_required" [ngModel]="+form.authentication_required.value"/>
                                                        <label class="custom-control-label" for="authentication_required"></label>
                                                    </div>
                                                </label>
                                                <ng-select [items]="authentication" bindLabel="name" [searchable]="searchable"
                                                    placeholder="Authentication" [clearable]="false" bindLabel="name" bindValue="id" formControlName="authentication_type"> </ng-select>
                                            </div>
                                            <div class="form-group">
                                                <label class="d-block position-relative" for="customSelect">Confirmation
                                                    <i class="feather icon-alert-circle recipientFilterInfoIcon" placement="top" container="body" placement="auto" ngbTooltip="After the document been signed it will be sent in PDF format to your recipients. You can also select to send this confirmation as a notification via SMS."></i>
                                                </label>
                                                <ng-select [items]="confirmation" bindLabel="name" [searchable]="searchable"
                                                    placeholder="Confirmation" [clearable]="false" bindLabel="name" bindValue="id" formControlName="confirmation_type"> </ng-select>
                                            </div>
                                            <div class="form-group mb-0">
                                                <label for="Signatureconfirmation">Signature confirmation</label>
                                                <fieldset class="form-group">
                                                    <textarea class="form-control" id="" rows="4" placeholder="Signature confirmation message" formControlName="signature_confirmation"></textarea>
                                                </fieldset>
                                            </div>
                                            <div class="px-0">
                                                <button type="submit" class="btn btn-primary mr-1" rippleEffect>Save</button>
                                            </div>
                                    </div>
                                    <!-- recipient-filters -->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #addPersonFieldModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add a new field</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <label>Field name </label>
            <div class="form-group">
                <input type="text" placeholder="Field name" class="form-control" />
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')"
            rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Add
        </button>
    </div>
</ng-template>
<!-- / Modal -->

<!-- Modal -->
<ng-template #addOrgFieldModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add a new field</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <label>Field name </label>
            <div class="form-group">
                <input type="text" placeholder="Field name" class="form-control" />
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')"
            rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Add
        </button>
    </div>
</ng-template>
<!-- / Modal -->