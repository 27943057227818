import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UploaddocumentwidgetComponent } from './uploaddocumentwidget/uploaddocumentwidget.component';
import { LatestactivitywidgetComponent } from './latestactivitywidget/latestactivitywidget.component';
import { TaskandreminderswidgetComponent } from './taskandreminderswidget/taskandreminderswidget.component';
import { AutorenewalonwidgetComponent } from './autorenewalonwidget/autorenewalonwidget.component';
import { ExpiresonwidgetComponent } from './expiresonwidget/expiresonwidget.component';
import { ContractcontrolwidgetComponent } from './contractcontrolwidget/contractcontrolwidget.component';
import { ForgottendocumentwidgetComponent } from './forgottendocumentwidget/forgottendocumentwidget.component';
import { LastsigneddocumentwidgetComponent } from './lastsigneddocumentwidget/lastsigneddocumentwidget.component';
import { MostactiveuserswidgetComponent } from './mostactiveuserswidget/mostactiveuserswidget.component';
import { UnreadchatmessagewidgetComponent } from './unreadchatmessagewidget/unreadchatmessagewidget.component';
import { AbouttoexpirewidgetComponent } from './abouttoexpirewidget/abouttoexpirewidget.component';
import { EmailbouncedwidgetComponent } from './emailbouncedwidget/emailbouncedwidget.component';
import { MostusedtemplateswidgetComponent } from './mostusedtemplateswidget/mostusedtemplateswidget.component';

@NgModule({
  declarations: [
    UploaddocumentwidgetComponent,
    LatestactivitywidgetComponent,
    TaskandreminderswidgetComponent,
    AutorenewalonwidgetComponent,
    ExpiresonwidgetComponent,
    ContractcontrolwidgetComponent,
    ForgottendocumentwidgetComponent,
    LastsigneddocumentwidgetComponent,
    MostactiveuserswidgetComponent,
    UnreadchatmessagewidgetComponent,
    AbouttoexpirewidgetComponent,
    EmailbouncedwidgetComponent,
    MostusedtemplateswidgetComponent
  ],
  imports: [

  ],
  exports: [
    UploaddocumentwidgetComponent,
    LatestactivitywidgetComponent,
    TaskandreminderswidgetComponent,
    AutorenewalonwidgetComponent,
    ExpiresonwidgetComponent,
    ContractcontrolwidgetComponent,
    ForgottendocumentwidgetComponent,
    LastsigneddocumentwidgetComponent,
    MostactiveuserswidgetComponent,
    UnreadchatmessagewidgetComponent,
    AbouttoexpirewidgetComponent,
    EmailbouncedwidgetComponent,
    MostusedtemplateswidgetComponent
  ]
})
export class DashboardModule {}
