<div class="slideout-content ">
    <div class="modalsd modal-slide-in sdfade users-right-sidebar new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <div class="add-new-user modal-content pt-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('user-right-sidebar')">
                    ×
                </button>
                <div class="py-2 d-flex flex-column justify-content-center align-items-center mb-0">
                    <div class="avatar avatar-xl mb-1">
                        <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar" />
                    </div>
                    <p class="font-small-3 ">Drop image or click to upload</p>
                </div>
                <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-justified users-right-sidebarTabs">
                    <li ngbNavItem>
                        <a ngbNavLink>User info</a>
                        <ng-template ngbNavContent>
                            <div class="px-2 py-2 users-scroll-content-container" [perfectScrollbar]>
                                <form class="">
                                    <div class="form-group">
                                        <label class="form-label" for="Name">Name</label>
                                        <input type="text" class="form-control" id="Name" placeholder="Name" name=""
                                            aria-describedby="Name" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="Email">Email</label>
                                        <input type="email" class="form-control" id="" placeholder="Email" name="" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="Mobile no">Mobile no</label>
                                        <input type="text" class="form-control" id="Mobile no" placeholder="Mobile no"
                                            name="" />
                                    </div>
                                </form>
                                <button type="button" (click)="userAddFieldModalOpen(userAddFieldModal)" class="btn p-0 ml-auto mt-50 btn-sm d-flex text-primary btn-transparent">Add
                                    fields</button>
                                <hr>
                                <div class="custom-control custom-switch form-group">
                                    <div class="d-flex justify-content-between">
                                        <small>Deactivate user</small>
                                        <input type="checkbox" checked class="custom-control-input" id="deactivatUser" />
                                        <label class="custom-control-label pl-3" for="deactivatUser"></label>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between text-danger align-items-center">
                                    <label for="">Delete user</label>
                                    <div class="deleteUser" placement="auto" ngbTooltip="Delete" container="body">
                                        <span [data-feather]="'x-circle'" [size]="16" [class]="'font-weight-bolder cursor-pointer'"></span>
                                    </div>
                                </div>
                            </div>


                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Stats</a>
                        <ng-template ngbNavContent>
                            <div class="px-2 py-2 productLibraryCustomColumnTab-scroll-content-container"
                                [perfectScrollbar]>
                                <div class="d-flex justify-content-between align-items-center">
                                    <h6 class="mb-0">Last login:</h6>
                                    <small>2023 May 02 04:51:34</small>
                                </div>
                                <hr>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <p class="mb-0 font-small-3">Data Since: Apr 18 2022</p>
                                    <div class="badge badge-light-primary">345</div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <p class="mb-0 font-small-3">Active documents:</p>
                                    <div class="badge badge-light-primary">345</div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <p class="mb-0 font-small-3">Completed documents:</p>
                                    <div class="badge badge-light-primary">345</div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <p class="mb-0 font-small-3">Lost/rejected documents:</p>
                                    <div class="badge badge-light-primary">345</div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <p class="mb-0 font-small-3">User hit-rate:</p>
                                    <div class="badge badge-light-primary">345</div>
                                </div>
                            </div>

                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-0"></div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #userAddFieldModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add field</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <div class="form-group">
                <label>Field name </label>
                <div class="form-group">
                    <input type="text" placeholder="Field name" class="form-control" />
                </div>
            </div>
            <div class="form-group">
                <label for="customSelect">Select type</label>
                <ng-select [items]="userfieldtype" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                    placeholder="Select type"> </ng-select>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Add field
        </button>
    </div>
</ng-template>
<!-- / Modal -->