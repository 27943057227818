import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RolesComponent implements OnInit {

  // modal Open Form
  addRoleModalOpen(addRoleModal) {
    this.modalService.open(addRoleModal);
  }
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
