import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ftpupload-addon',
  templateUrl: './ftpupload-addon.component.html',
  styleUrls: ['./ftpupload-addon.component.scss']
})
export class FTPUploadAddonComponent implements OnInit {

  constructor(private modalService: NgbModal) { }
   // modal Basic
   modalOpen(FTPuploadSettingModal) {
    this.modalService.open(FTPuploadSettingModal, {
      // centered: true,
      size: 'lg' 
    });
  }

  chooseSecurity = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  choosespecifictemplates = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  chooseFormat = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  choosespecifictype = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  ngOnInit(): void {
  }

}
