import { CommonModule } from '@angular/common';
 import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { NgSelectModule } from '@ng-select/ng-select';
import { SettingsTabComponent } from './settings-tab/settings-tab.component';

@NgModule({
  declarations: [
    SettingsTabComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgbModule,
    CoreCommonModule,
    PerfectScrollbarModule,
    NgSelectModule,
    RouterModule,
  ],
  exports: [
    SettingsTabComponent,
  ]
})
export class EditTemplateModule {}
