<div class="slideout-content">
    <!-- Modal to add attachments starts-->
    <div class="modalsd modal-slide-in sdfade organization-right-sidebar" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <div class="modal-header mb-1 d-flex justify-content-between align-items-center px-2 py-1">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="avatar mr-1 bg-light-primary">
                            <div class="avatar-content">{{ organizationForm.value?.name | shortName }}</div>
                        </div>
                        <h5 class="modal-title" id="exampleModalLabel">{{ organizationForm.value?.name }}</h5>
                    </div>
                    <div class="d-flex justify-content-end align-items-center text-dark cursor-pointer">
                        <span placement="auto" *ngIf="!isDeleted" (click)="removeOrganization()" container="body" ngbTooltip="Delete">
                            <i data-feather="trash" [size]="16" class="mr-75"></i>
                        </span>
                        <span placement="auto" *ngIf="isDeleted" container="body" ngbTooltip="Delete">
                            <span class="spinner-border spinner-border-sm mr-1"></span>
                        </span>
                        <span placement="auto" container="body" ngbTooltip="Send new">
                            <i data-feather="send" [size]="16" class="mr-75"></i>
                        </span>
                        <span placement="auto" container="body" ngbTooltip="Close" (click)="toggleSidebar('organization-right-sidebar')">
                            <i data-feather="x" [size]="16" class="" data-dismiss="modal" aria-label="Close"></i>
                        </span>
                    </div>
                </div>
                <div class="modal-body flex-grow-1 p-0">
                    <ul ngbNav #navJustified="ngbNav" class="nav-tabs nav-justified organization-right-sidebarTabs">
                        <li ngbNavItem>
                            <a ngbNavLink>Organization</a>
                            <ng-template ngbNavContent>
                                <div class="px-2 py-2 organization-scroll-content-container" [perfectScrollbar]>
                                    <div>
                                        <form [formGroup]="organizationForm" (ngSubmit)="updateOrganization()">
                                            <div class="form-group">
                                                <label class="form-label" for="name">Name</label>
                                                <input type="text" class="form-control" name="" formControlName="name" aria-describedby="name" [ngClass]="{ 'is-invalid error': submitted && form.name.errors }" />
                                                <div *ngIf="submitted && form.name.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.name.errors }">
                                                    <div *ngIf="form.name.errors.required">Name is required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="reg-no">Reg No</label>
                                                <input type="text" class="form-control" formControlName="register_no" aria-describedby="reg-no" [ngClass]="{ 'is-invalid error': submitted && form.register_no.errors }" />
                                                <div *ngIf="submitted && form.register_no.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.register_no.errors }">
                                                    <div *ngIf="form.register_no.errors.required">Reg No is required</div>
                                                </div>
                                            </div>
                                            <div class="form-group" *ngIf="hiddenFields">
                                                <label class="form-label" for="mobile-number">Mobile No</label>
                                                <input type="text" class="form-control" formControlName="phone" aria-describedby="mobile-number" />
                                            </div>
                                            <div class="form-group" *ngIf="hiddenFields">
                                                <label class="form-label" for="address">Address</label>
                                                <input type="text" class="form-control" formControlName="address" aria-describedby="address" />
                                            </div>
                                            <div class="form-group" *ngIf="hiddenFields">
                                                <label class="form-label" for="zip-code">Zip Code</label>
                                                <input type="text" class="form-control" formControlName="zipcode" aria-describedby="zip-code" />
                                            </div>
                                            <div class="form-group" *ngIf="hiddenFields">
                                                <label class="form-label" for="city">City</label>
                                                <input type="text" class="form-control" formControlName="city" aria-describedby="city" />
                                            </div>
                                        </form>
                                        <button type="button" class="btn p-0 ml-auto btn-sm d-flex text-primary btn-transparent"
                                            (click)="showHiddenFields()">{{ changeText }}</button>
                                        <div class="d-flex mt-2">
                                            <button type="submit" [disabled]="loading || !changesDetect" class="btn btn-primary mr-1" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Update </button>
                                            <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('organization-right-sidebar')" rippleEffect>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                    <hr class="mt-2 mb-2">
                                    <div class="people-link-organization mb-0">
                                        <div class="user-page-info">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h6 class="mb-0"> People </h6>
                                                    <small class="mb-0" *ngIf="connectedRecipients.length == 0"> No linked people </small>
                                                </div>
                                                <button type="button" placement="auto" container="body" ngbTooltip="Link people"
                                                    class="btn btn-icon btn-primary btn-sm" (click)="linkPeopleModalOpen(linkedPeopleModal)"
                                                    rippleEffect>
                                                    <span [data-feather]="'user-plus'"></span></button>
                                            </div>
                                        </div>

                                        <div class="added-people-listing-wrapper">
                                            <div class="mb-0 d-flex justify-content-between align-items-center px-0 pb-50 pt-1" *ngFor="let recipient of connectedRecipients; let i = index;">
                                                <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                    <div class="avatar mr-1 bg-light-primary">
                                                        <div class="avatar-content">{{ recipient.firstname+' '+(recipient.lastname ?? '') | shortName }}</div>
                                                    </div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle"
                                                        id="exampleModalLabel">{{  recipient.firstname+' '+(recipient.lastname ?? '') }}</p>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center text-dark">
                                                    <span placement="auto" container="body" ngbTooltip="Send new">
                                                        <i data-feather="send" [size]="16" class="mr-50 cursor-pointer"></i>
                                                    </span>
                                                    <span placement="auto" container="body" ngbTooltip="Remove recipient" (click)="unlinkRecipient($event, recipient, i)">
                                                        <i data-feather="trash" [size]="16" class="cursor-pointer"
                                                            data-dismiss="modal" aria-label="Close"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Comments</a>
                            <ng-template ngbNavContent>
                                <div class="organizationchatContainer chat-widget">
                                    <!-- User Chat messages -->
                                    <section class="chat-app-window">
                                        <div class="user-chats" [perfectScrollbar]>
                                            <div class="chats">
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide
                                                                you such information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide
                                                                you such information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide
                                                                you such information.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat ClientChatContainer">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content InternalComment">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Submit Chat form -->
                                        <form class="chat-app-form justify-content-between">
                                            <div class="input-group input-group-merge mr-50 w-75 form-send-message">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <label for="attach-doc" class="attachment-icon mb-0">
                                                            <i data-feather="image" class="cursor-pointer lighten-2 text-secondary"></i>
                                                            <input type="file" id="attach-doc" hidden /> </label></span>
                                                </div>
                                                <input type="text" class="form-control message" placeholder="Type your message"
                                                    name="chat-message" />
                                            </div>
                                            <!-- <div class=" w-100 mr-50">
                                            <fieldset>
                                                <div class="input-group">
                                                    <div ngbDropdown class="input-group-prepend">
                                                        <button type="button" class="btn btn-outline-primary"
                                                            ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false" rippleEffect>
                                                            <i data-feather="edit-2"></i>
                                                        </button>
                                                        <div ngbDropdownMenu>
                                                            <a ngbDropdownItem>Internal comment</a>
                                                            <a ngbDropdownItem>Public comment</a>
                                                        </div>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type your message" />
                                                </div>
                                            </fieldset>
                                        </div> -->
                                            <button type="button" class="btn btn-primary send" rippleEffect>
                                                <i data-feather="send" class="d-lg-none"></i>
                                                <span class="d-none text-nowrap d-lg-block">Send</span>
                                            </button>
                                        </form>
                                        <!--/ Submit Chat form -->
                                    </section>
                                    <!-- User Chat messages -->
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Documents</a>
                            <ng-template ngbNavContent>
                                <div class="document-wrapper px-2 pt-1">

                                    <div class="uploaded-document-wrapper">
                                        <div class="mb-0 d-flex justify-content-between uploaded-doocuments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle"
                                                        id="exampleModalLabel">Dorolice
                                                        Crossman</p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeDocumentIcon"
                                                    ngbTooltip="Remove documents">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-doocuments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle"
                                                        id="exampleModalLabel">Dorolice
                                                        Crossman</p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeDocumentIcon"
                                                    ngbTooltip="Remove documents">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-doocuments-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle"
                                                        id="exampleModalLabel">Dorolice
                                                        Crossman</p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeDocumentIcon"
                                                    ngbTooltip="Remove documents">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Contracts</a>
                            <ng-template ngbNavContent>
                                <div class="Contracts-wrapper px-2 pt-1">
                                    <div class="uploaded-Contracts-wrapper">
                                        <div class="mb-0 d-flex justify-content-between uploaded-Contracts-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle"
                                                        id="exampleModalLabel">Dorolice
                                                        Crossman</p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeContractIcon"
                                                    ngbTooltip="Remove contract">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-Contracts-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle"
                                                        id="exampleModalLabel">Dorolice
                                                        Crossman</p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeContractIcon"
                                                    ngbTooltip="Remove contract">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-0 d-flex justify-content-between uploaded-Contracts-list align-items-center px-0 pb-50 pt-1">
                                            <div class="d-flex justify-content-start align-items-center linkedPeopleTitleWrapper">
                                                <div class="avatar bg-light-warning mr-75">
                                                    <div class="avatar-content">
                                                        <span [data-feather]="'link'" [class]="'avatar-icon'"></span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="modal-title font-small-4 cursor-pointer linkedPeopleTitle"
                                                        id="exampleModalLabel">Dorolice
                                                        Crossman</p>
                                                    <small class="text-muted font-italic">Linked</small>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center text-dark">
                                                <span placement="auto" container="body" class="removeContractIcon"
                                                    ngbTooltip="Remove contract">
                                                    <i data-feather="trash" [size]="16" class="cursor-pointer" data-dismiss="modal"
                                                        aria-label="Close"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="navJustified" class="mt-2"></div>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add attachments Ends-->
</div>


<!-- add contacts Modal -->
<ng-template #linkedPeopleModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Linked People</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body py-2 pb-3" tabindex="0" ngbAutofocus>
        <div class="ng-autocomplete">
            <div class="input-group-prepend">
                <span class="input-group-text"><i data-feather="briefcase"></i></span>
            </div>
            <ng-autocomplete class="organdRecAutoComp" #auto [data]="data" [searchKeyword]="keyword" [debounceTime]="500" placeholder="Name" (selected)='selectEvent($event);auto.clear()' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [isLoading]="isLoading">
                
            </ng-autocomplete>

            <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.firstname"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
            </ng-template>
        </div>
    </div>
</ng-template>
<!-- / add contacts Modal -->