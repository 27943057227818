<div class="card addonBoxContainer">
    <div class="card-body">
        <h5>Video Attachment</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Send your documents with a personalized video.
            Make a professional impression like never before!
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <span [data-feather]="'video'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>