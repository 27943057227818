import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { OrganizationService } from './organization.service';
import { OrganizationRightSidebarComponent } from './organization-right-sidebar/organization-right-sidebar.component';
import { GlobalService } from 'app/global/global.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
  @ViewChild(OrganizationRightSidebarComponent) organizationRightBar;
  public contentHeader: object;
  public page = 1;
  public pageBasicText = 3;
  public pageColor = 4;
  public pagePosition = 3;
  public pageSizes = 3;
  public pageAdvanced = 3;
  public pageAdvancedLink = 3;
  public pageAdvancedEllipses = 7;
  public pageAdvancedNoEllipses = 8;
  public organizationData:any = [];  
  public loading: boolean = false;
  public specificOrganizationData:any = {};
  public pagination:any = [];

  constructor(
    private _coreSidebarService: CoreSidebarService,
    private organizationService: OrganizationService,
    private globalService:GlobalService
  ) {  }

  ngOnInit(): void {
    this.fetchOrganization();
  }

  /**
   * Sidebar toggle function 
   * @param name 
   * @param object 
   * @param index 
   */
  toggleSidebar(name, object = {},index): void {
    object['index'] = index;
    this.organizationRightBar.initOrganizationForm(object);
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }


  /**
   * Fetching organization with pagination and filter
   * @param page 
   * @param query 
   */
  async fetchOrganization(page:any = 1,query:any = ''){
    var that = this;
    this.page = page;
    this.loading = true;
    const data = await this.organizationService.fetchOrganization(this.page,query)
    this.organizationData = data.data.data.data || [];
    this.pagination = data.data.data;
    this.loading = false;
    // .map(v => ({...v, color: this.globalService.generateRandomColor(),backgroundColor:this.globalService.generateRandomColor(0.5)}));
  }

  /**
   * Removing index from organization data when this event will emit from children
   * @param $event 
   */
  deleteOrganization($event:any){
    const id = $event.id;
    this.organizationData.splice(id, 1);
  }


  /**
   * Here we emiting this event from right sidebar for updating listing data
   * @param $event 
   */
  updateListing($event:any){
    // var index = $event.index;
    // var data  = $event.data;
    // this.organizationData[index].recipient ? this.organizationData[index].recipient.push(data) : this.organizationData[index].recipient = data;
  }

}
