<section class="p-2" id="Donut-and-scatter-charts">
    <div class="row">
        <!-- Donut Chart Starts -->
        <div class="col-lg-6 col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Active and inactive contracts</h4>
                </div>
                <div class="card-body">
                    <canvas class="doughnut-chart-ex chartjs mx-auto" baseChart [datasets]="doughnutChart.datasets"
                        [options]="doughnutChart.options" [chartType]="doughnutChart.chartType">
                    </canvas>
                    <div class="d-flex justify-content-between mt-3 mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="check-square" class="font-medium-2 text-primary"></i>
                            <span class="font-weight-bold ml-75 mr-25">Active Contract</span>
                            <span>- 80%</span>
                        </div>
                        <div>
                            <span>20%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="x-square" class="font-medium-2 text-warning"></i>
                            <span class="font-weight-bold ml-75 mr-25">Inactive Contract</span>
                            <span>- 10%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Donut Chart Starts -->
        <!-- Donut Chart Starts -->
        <div class="col-lg-6 col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Active and inactive contracts</h4>
                </div>
                <div class="card-body">
                    <canvas class="doughnut-chart-ex chartjs mx-auto" baseChart [datasets]="doughnutChart.datasets"
                        [options]="doughnutChart.options" [chartType]="doughnutChart.chartType">
                    </canvas>
                    <div class="d-flex justify-content-between mt-3 mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="check-square" class="font-medium-2 text-primary"></i>
                            <span class="font-weight-bold ml-75 mr-25">Active Contract</span>
                            <span>- 80%</span>
                        </div>
                        <div>
                            <span>20%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                        <div class="d-flex align-items-center">
                            <i data-feather="x-square" class="font-medium-2 text-warning"></i>
                            <span class="font-weight-bold ml-75 mr-25">Inactive Contract</span>
                            <span>- 10%</span>
                        </div>
                        <div>
                            <span>8%</span>
                            <i data-feather="arrow-up" class="text-success"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Donut Chart Starts -->
    </div>
</section>