import { Injectable, Inject, Injector } from '@angular/core';
import feather from 'feather-icons';
import uuid from 'short-unique-id';
import { ToastrService } from 'ngx-toastr';
// import { Blocks } from '../blocks/blocks';
import { GlobalService } from 'app/global/global.service';
import { Blocks } from '../blocks/blocks';
declare var $: any;
declare var CKEDITOR: any;
@Injectable({
    providedIn: 'root',
})
export class tagsBuilder {
    public instance_name = '';
    public uuid = new uuid({ length: 6, dictionary: 'number' });
    public keydownCount = 0;
    public selectMenuIsOpen = false;
    constructor(@Inject(Injector) private readonly injector: Injector) {
        var that = this;
        $(document).off('click', '.tags-builder-menu-list').on('click', '.tags-builder-menu-list', function () {
            var shortuuid = that.uuid();
            var tag_type = $(this).attr('tag-type');
            var instance_name = $(this).parent().attr('instance_name');
            var editor = CKEDITOR.instances[instance_name];
            var view = '';
            // var lastSelection = $(CKEDITOR.instances[instance_name].getSelection().getStartElement().$);
            // lastSelection.html(lastSelection.html().replace(/(\[+?)$/, '<cite class="last">$1</cite>'));
            // $('cite.last:last').remove();
            // if ($(lastSelection).is(':empty')) {
            //     $(lastSelection).html('<br/>');
            //     $(editor.element.$).addClass('cke-editor-placeholder');
            // }
            $(editor.element.$).removeClass('cke-editor-placeholder');
            $('.tags-list-menu').addClass('hidden').css({ top: 0, left: 0, position: 'fixed' });
            if (tag_type == 'textfield') {
                view = that.textTagView(shortuuid);
            }else if (tag_type == 'datepicker') {
                view = that.datepickerTagView(shortuuid);
            }else if (tag_type == 'droplist') {
                view = that.droplistTagView(shortuuid);
            }
            editor.fire('saveSnapshot');
            var range = that.createMarkerRange(editor);
            if(range){ 
                editor.getSelection().selectRanges( [ range.range ] );
            }
            editor.insertHtml(view);
            editor.fire('saveSnapshot');
            if (tag_type == 'datepicker'){
                that.GlobalServiceFunc.initializeFlatpicker($('[uuid="'+shortuuid+'"] .selected-label'));
            }
        });
    }

    showTagList(options, instance_name) {
        $('.tags-list-menu').removeClass('hidden').attr('instance_name', instance_name).css(options);
    }

    textTagView(shortuuid) {
        
        return `<span contenteditable="false" class="tags has-addons content-draggable-field drag-fields" selected-field="1" field-name="Text Field" field="text-field" uuid="${shortuuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" allow_multi_lines="0" selectedtype="full_name" selecttypetext="Full name" selectedtypeid="1" selectedforrole="0" tag_view="1" tagname="full_name">
                <span class="tag is-info tag-container">
                    <span class="selected-label text-tag" style="color: #0061ef;">[Full name]</span>
                    <input type="text" class="form-control text-tag-editor hidden">
                </span>
            </span>&nbsp;`;
    }

    datepickerTagView(shortuuid){
        return `<span contenteditable="false" class="tags has-addons content-draggable-field drag-fields" selected-field="4" field-name="Datepicker" field="datepicker-field" uuid="${shortuuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedforrole="0" tag_view="1" tagname="new_field">
            <span class="tag is-info tag-container">
                <span class="selected-label tag-date" style="color: #0061ef;">[New field]</span>
            </span>
        </span>&nbsp;`;
    }

    
    droplistTagView(shortuuid){
        return `<span contenteditable="false" class="tags has-addons content-draggable-field drag-fields"  selected-field="3" field-name="Droplist" field="droplist-field" uuid="${shortuuid}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" selectedtype="new_field" selecttypetext="New field" selectedtypeid="0" selectedforrole="0" tag_view="1" tagname="new_field">
                <span class="tag is-info tag-container">
                    <span class="drag-fields-inner-container droplist-field-inner-container droplist-menu fields-ele-container">
                        <span class="selected-label tag-date selected-option-label" style="color: #0061ef;">[New fields]</span>
                        <span class="hidden" id="${shortuuid}" data-options='${JSON.stringify([])}'>.</span>
                        <ng-dropdown-panel role="listbox" aria-label="Options list" class="ng-dropdown-panel ng-select-bottom droplist-dropdown hidden">
                            <div class="ng-dropdown-panel-items scroll-host">
                                <div class="ng-option not-found pointer-none" option_uuid="0">
                                    <span class="ng-option-label">No option found</span>
                                </div>
                            </div>
                        </ng-dropdown-panel>
                    </span>
                </span>
            </span>&nbsp;`;
    }

    private get GlobalServiceFunc() {
        return this.injector.get(GlobalService);
    }

    createMarkerRange(editor) {
        var that = this;
        var range = editor.getSelection().getRanges()[0];

        var collected = CKEDITOR.plugins.textMatch.match(range, function (text, offset) {
            // Get the text before the caret.
            var left = text.slice(0, offset),
                // Will look for a literal 'marker' character and at least two word characters.
                match = left.match(that.createPattern('[', 0));

            if (!match) {
                return null;
            }

            // be identified by the following offsets: { start: 18, end: 21 }.
            return { start: match.index, end: offset };
        });
        return collected;
    }

    createPattern(marker, minChars) {
        // Match also diacritic characters (#2491).
        var pattern = '\\' + marker + '[_a-zA-Z0-9À-ž]';

        if (minChars) {
            pattern += '{' + minChars + ',}';
        } else {
            pattern += '*';
        }

        pattern += '$';

        return new RegExp(pattern);
    }
}