<div class="form-group mb-1">
    <label for="customSelect">All contract</label>
    <ng-select [items]="chooseAllContract" bindLabel="name" [readonly]="readonly" [searchable]="false" placeholder="Select Contract">
    </ng-select>
</div>
<div class="form-group mb-1">
    <label for="customSelect">All Document type</label>
    <ng-select #SelectDocTypeSR [items]="chooseDocType" bindLabel="name" [readonly]="readonly" [searchable]="false" placeholder="Select Document type">
        <ng-template ng-header-tmp>
            <input class="form-control w-100" type="text" (input)="SelectDocTypeSR.filter($event.target.value)" />
        </ng-template>
    </ng-select>
</div>
<div class="form-group mb-1">
    <label for="customSelect">All Categories</label>
    <ng-select #SelectCategoriesSR [items]="chooseDocCategories" bindValue="name" bindLabel="name" [readonly]="readonly"
        [searchable]="false" placeholder="Select Categories">
        <ng-template ng-header-tmp>
            <input class="form-control w-100" type="text" (input)="SelectCategoriesSR.filter($event.target.value)" />
        </ng-template>
    </ng-select>
</div>