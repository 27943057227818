import { Component, OnInit, ViewChild, ViewEncapsulation, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Meta } from '@angular/platform-browser';

declare var $: any;
declare var CKEDITOR: any;

@Component({
  selector: 'app-signing-page',
  templateUrl: './signing-page.component.html',
  styleUrls: ['./signing-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SigningPageComponent implements OnInit {
  @ViewChild('addSignatureSigningModal') public addSignatureSigningModal;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
      console.log(window.outerWidth,$(event.target).parents('.template-page').length)
    if ($(event.target).parents('.template-page').length > 0) {
      if (window.outerWidth <= 911) {
        var width = $(window).width();
        var height = window.outerHeight;
        var templateHeight = 810;
        setTimeout(() => {
          $('.template-page-container').css({ 'transform': `translate3d(0px, 0px, 0px) scale3d(${(width / templateHeight)}, ${(width / templateHeight)}, 1)` });
          var templatePageHeight = $(".template-page")[0].getBoundingClientRect().height;
          $('.template-page-container').css('height', `${templatePageHeight}`);
        }, 10);
      } else {
        $('.template-page-container').css({ 'transform': `` });
        $('.template-page-container').css('height', ``);
      }
    }
  }
  public globalModalRef: NgbModalRef;
  public templateContent: any = '';
  public isMobile = false;
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private modalService: NgbModal, private _coreSidebarService: CoreSidebarService, private deviceService: DeviceDetectorService, private readonly meta: Meta) { }

  toggleSidebar(name): void {
    // this._coreSidebarService.getSidebarRegistry('signing-right-sidebar').isOpened = true;
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    $('body').toggleClass('sidebar-opened');
  }

  ngOnInit(): void {
    var that = this;
    let name;
    console.log(this.route.snapshot.paramMap.get('id'), 'detail id');
    const template = localStorage.getItem('template');
    if (template) {
      this.templateContent = this.sanitizer.bypassSecurityTrustHtml(template);
    }
    $(document).ready(function () {
      setTimeout(() => {
        for (name in CKEDITOR.instances) {
          CKEDITOR.instances[name].destroy(true);
        }
      }, 400);
      console.info(that.deviceService.isMobile(), 'that.deviceService.isMobile()')
      if (that.deviceService.isMobile()) {
        that.isMobile = true;
        that._coreSidebarService.getSidebarRegistry('signing-right-sidebar').isOpened = true;
        that._coreSidebarService.getSidebarRegistry('signing-right-sidebar').close();
        $('body').removeClass('sidebar-opened');
        //that._coreSidebarService.getSidebarRegistry('signing-right-sidebar').isOpened = false;
        // that.meta.removeTag('name="viewport"');
        // that.meta.addTag({ name: 'viewport', content: 'user-scalable=1' });
        // $(document.body).addClass('responsive-mode');
      } else {
        that.isMobile = false;
        setTimeout(() => {
          that._coreSidebarService.getSidebarRegistry('signing-right-sidebar').isOpened = true;
          $('body').addClass('sidebar-opened');
        }, 500);
      }
    });

    $(document).off('click', '.signature-field').on('click', '.signature-field', function () {
      that.globalModalRef = that.modalService.open(that.addSignatureSigningModal, {
        // centered: true,
        size: 'lg',
        windowClass: 'add-signature-modal',
        backdrop: 'static'
      });
    });
  }

  closeModal(action: any) {
    this.globalModalRef.close(action);
  }

}
