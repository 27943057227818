import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-expiresonwidget',
  templateUrl: './expiresonwidget.component.html',
  styleUrls: ['./expiresonwidget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpiresonwidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
