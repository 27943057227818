<div class="card mb-1 p-50 card-transaction CC_contactsContainer draggable">
    <div class="collapse-icon">
        <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    <span class="lead collapse-title"> Contacts
                    </span>
                    <div class="addContactIcon" placement="auto" container="body" ngbTooltip="Add new contact"  (click)="CC_addContactsModalOpen(CC_addContactsModal)">
                        <i data-feather="plus-circle" class="font-medium-4 mb-25 cursor-pointer" (click)="CC_addContactsModalOpen(CC_addContactsModal)"></i>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent class="p-0">
                    <div class="">
                        <div class="d-flex align-content-center justify-content-between mb-2 w-100">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i data-feather="search"></i></span>
                                </div>
                                <input type="text" class="form-control" id="search" placeholder="Search contacts.."
                                    aria-label="Search..." aria-describedby="search" />
                            </div>
                        </div>
                        <div class="transaction-item border-bottom contactsListHover">
                            <div class="media">
                                <div class="avatar mr-1">
                                    <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                        height="42" />
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Wallet</h6>
                                    <small>Anique4@yopmail.com</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder listOptionHide">
                                <div class="text-danger font-small-2 cursor-pointer">Delete</div>
                                <div>
                                    <i class="font-medium-2 cursor-pointer mr-50" data-feather="phone-call"></i>
                                    <i class="font-medium-2 cursor-pointer" data-feather="mail"></i>
                                </div>
                            </div>
                        </div>
                        <div class="transaction-item border-bottom contactsListHover">
                            <div class="media">
                                <div class="avatar mr-1">
                                    <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                        height="42" />
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Wallet</h6>
                                    <small>Anique4@yopmail.com</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder listOptionHide">
                                <div class="text-danger font-small-2 cursor-pointer">Delete</div>
                                <div>
                                    <i class="font-medium-2 cursor-pointer mr-50" data-feather="phone-call"></i>
                                    <i class="font-medium-2 cursor-pointer" data-feather="mail"></i>
                                </div>
                            </div>
                        </div>
                        <div class="transaction-item border-bottom contactsListHover">
                            <div class="media">
                                <div class="avatar mr-1">
                                    <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" width="42"
                                        height="42" />
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Wallet</h6>
                                    <small>Anique4@yopmail.com</small>
                                </div>
                            </div>
                            <div class="font-weight-bolder listOptionHide">
                                <div class="text-danger font-small-2 cursor-pointer">Delete</div>
                                <div>
                                    <i class="font-medium-2 cursor-pointer mr-50" data-feather="phone-call"></i>
                                    <i class="font-medium-2 cursor-pointer" data-feather="mail"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>
<!-- add contacts Modal -->
<ng-template #CC_addContactsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add new contact</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="form-group mb-1">
            <label for="Firstname">First name</label>
            <input type="text" class="form-control" id="Firstname" placeholder="First name" />
        </div>
        <div class="form-group mb-1">
            <label for="Lastname">Last name</label>
            <input type="text" class="form-control" id="Lastname" placeholder="Last name" />
        </div>
        <div class="form-group mb-1">
            <label for="email">Eamil</label>
            <input type="email" class="form-control" id="email" placeholder="Eamil" />
        </div>
        <div class="form-group mb-1">
            <label for="phone">Phone</label>
            <input class="form-control" placeholder="+46" type="text" prefix="+46 " mask="00 000 00 00" />
        </div>
        <div class="hiddenFields" *ngIf="hiddenFields">
            <div class="form-group mb-1">
                <label for="personalnumber">Personal ID number</label>
                <input type="text" class="form-control" id="personalnumber" placeholder="Personal ID number" />
            </div>
            <div class="form-group mb-1">
                <label for="address">Address</label>
                <input type="text" class="form-control" id="address" placeholder="Address" />
            </div>
            <div class="form-group mb-1">
                <label for="zipcode">Zip code</label>
                <input type="text" class="form-control" id="zipcode" placeholder="Zip code" />
            </div>
            <div class="form-group mb-1">
                <label for="city">City</label>
                <input type="text" class="form-control" id="city" placeholder="City" />
            </div>
        </div>


        <div class="float-right">
            <label (click)="showHiddenFields()" class="text-primary cursor-pointer" for="first-name-icon">{{changeText}}</label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / add contacts Modal -->