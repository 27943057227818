import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  constructor() { }


  getDocumentTypes(page:any){
    return axios.get(`documenttypes?page=${page}`);
  }


  submitDocumentTypes(formData:any){
    return axios.post('documenttypeadd', formData);
  }

  deleteDocumentType(id:any){
    return axios.delete(`documenttypedelete?id=${id}`);
  }

}
