import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-latestactivitywidget',
  templateUrl: './latestactivitywidget.component.html',
  styleUrls: ['./latestactivitywidget.component.scss']
})
export class LatestactivitywidgetComponent implements OnInit {

  showReportBasic=true
  constructor() { }

  ngOnInit(): void {
  }

}
