<div class="content-area-wrapper border-0 group-view container-xxl p-0">
    <!-- Todo Main Sidebar (Filter) -->
    <core-sidebar class="sidebar-left" name="group-sidebar-main" overlayClass="body-content-overlay"
        collapsibleSidebar="bs-gt-lg">
        <!-- Main Sidebar -->
        <div class="main-sidebar sidebar groupMainSideBar">
            <div class="sidebar-content todo-sidebar">
                <div class="todo-app-menu">
                    <div class="add-task">
                        <button type="button" class="btn btn-success btn-block" rippleEffect
                            (click)="addgroupModalOpen(addGroupModal)">
                            Add group
                        </button>
                    </div>
                    <div class="sidebar-menu-list">
                        <div class="list-group list-group-filters">
                            <a class="list-group-item list-group-item-action active">
                                <!-- <i size="18" class="mr-50 feather icon-file-text align-middle"></i> -->
                                <span class="align-middle">Defaut</span>
                            </a>
                            <a
                                class="list-group-item list-group-item-action d-flex justify-content-between align-items-center customgrouptab">
                                <!-- <i size="18" class="mr-50 feather icon-search align-middle"></i> -->
                                <span class="align-middle">Custom role text demo</span>
                                <div class="custom-group-action-cont d-flex">
                                    <div placement="auto" container="body" ngbTooltip="Edit role">
                                        <span [data-feather]="'edit-2'"
                                            [class]="'custom-group-action ml-50 cursor-pointer'"></span>
                                    </div>
                                    <div placement="auto" container="body" ngbTooltip="Delete role">
                                        <span [data-feather]="'x'"
                                            [class]="'custom-group-action cursor-pointer'"></span>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!--/ Main Sidebar -->
    </core-sidebar>
    <!--/ Todo Main Sidebar -->

    <!-- content-right -->
    <div class="content-right">
        <div class="todo-app-list">
            <div class="app-fixed-search d-flex align-items-center">
                <div class="sidebar-toggle d-block d-lg-none ml-1">
                    <button class="btn p-0" (click)="toggleSidebar('group-sidebar-main')">
                        <i class="feather icon-menu"></i>
                    </button>
                </div>

                <div class="d-flex align-content-center justify-content-between w-100">
                    <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="feather icon-search text-muted"></i></span>
                        </div>
                        <input type="text" class="form-control" id="group-search" placeholder="Search and add"
                            aria-label="Search..." aria-describedby="group-search" />
                    </div>
                    <div class="custom-control custom-checkbox pb-0 mt-1 mr-1">
                        <input type="checkbox" class="custom-control-input" id="groupMakeDefaultCheck" checked />
                        <label class="custom-control-label" for="groupMakeDefaultCheck"></label>
                        <div class="groupadminInfo" placement="auto" ngbTooltip="Make default" container="body">
                            <span [data-feather]="'info'" [size]="14"
                                [class]="'font-weight-bolder cursor-pointer'"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 col-12 pr-0">
                <ul class="groupsUl group-tabs-UL" [perfectScrollbar]>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox1" checked />
                                    <label class="custom-control-label" for="groupCheckbox1"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="avatar mr-1">
                                        <span class="avatar-content">MK</span>
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox2" />
                                    <label class="custom-control-label" for="groupCheckbox2"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="">
                                        <img class="rounded-circle mr-1"
                                            src="assets/images/portrait/small/avatar-s-12.jpg" height="35" width="35"
                                            alt="datatable-avatar" />
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox3" />
                                    <label class="custom-control-label" for="groupCheckbox3"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="avatar mr-1">
                                        <span class="avatar-content">MK</span>
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox4" />
                                    <label class="custom-control-label" for="groupCheckbox4"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="">
                                        <img class="rounded-circle mr-1"
                                            src="assets/images/portrait/small/avatar-s-12.jpg" height="35" width="35"
                                            alt="datatable-avatar" />
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox5" />
                                    <label class="custom-control-label" for="groupCheckbox5"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="avatar mr-1">
                                        <span class="avatar-content">MK</span>
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox6" />
                                    <label class="custom-control-label" for="groupCheckbox6"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="">
                                        <img class="rounded-circle mr-1"
                                            src="assets/images/portrait/small/avatar-s-12.jpg" height="35" width="35"
                                            alt="datatable-avatar" />
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox7" />
                                    <label class="custom-control-label" for="groupCheckbox7"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="avatar mr-1">
                                        <span class="avatar-content">MK</span>
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox8" />
                                    <label class="custom-control-label" for="groupCheckbox8"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="">
                                        <img class="rounded-circle mr-1"
                                            src="assets/images/portrait/small/avatar-s-12.jpg" height="35" width="35"
                                            alt="datatable-avatar" />
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox9" />
                                    <label class="custom-control-label" for="groupCheckbox9"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="avatar mr-1">
                                        <span class="avatar-content">MK</span>
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="py-1">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="groupAvatarAndName">
                                <div class="custom-control custom-checkbox mr-1">
                                    <input type="checkbox" class="custom-control-input" id="groupCheckbox10" />
                                    <label class="custom-control-label" for="groupCheckbox10"></label>
                                </div>
                                <div class="d-flex align-items-center ng-star-inserted">
                                    <div class="">
                                        <img class="rounded-circle mr-1"
                                            src="assets/images/portrait/small/avatar-s-12.jpg" height="35" width="35"
                                            alt="datatable-avatar" />
                                    </div>
                                    <div>
                                        <a href="#" class="font-weight-bold groupRoleNameText mb-0">
                                            Mubashir sami
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="groupDropdown col-5 pr-0">
                                <div class="form-group mb-0">
                                    <ng-select [items]="chooseRole" bindLabel="name" class="ng-select-size-sm" [readonly]="readonly"
                                        [searchable]="searchable" placeholder="Choose Role"> </ng-select>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-4 col-12 border-left groups-accordion collapse-icon pt-2">
                <div class="group-accordion-scroll-container" [perfectScrollbar]>
                    <h4 class="card-title font-small-4 mb-75">Sharing options for Default</h4>
                    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="">
                        <ngb-panel id="accordion-1">
                            <ng-template class="py-1" ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4"> Access to folders
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="form-group mb-1">
                                    <!-- <label for="customSelect">Choose template</label> -->
                                    <ng-select [multiple]="true" [closeOnSelect]="false" [items]="selectaccessFolderDropdown" bindLabel="name" [readonly]="readonly"
                                        [searchable]="searchable" #accessFolderDropdown placeholder="Choose folder">

                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let item of items | slice: 0:2">
                                                <span class="ng-value-label"> {{ item.name }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                            </div>
                                            <div class="ng-value" *ngIf="items.length > 2">
                                                <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                            <input class="form-control w-100" type="text" (input)="accessFolderDropdown.filter($event.target.value)" />
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item">
                                            <i data-feather="folder" class="mr-50 mb-25"></i>
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-2">
                            <ng-template class="py-1" ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4"> Access to templates
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="form-group mb-1">
                                    <!-- <label for="customSelect">Choose template</label> -->
                                    <ng-select [multiple]="true" [closeOnSelect]="false" [items]="selectaccesstemplateDropdown" bindLabel="name" [readonly]="readonly"
                                        [searchable]="searchable" #accessFolderDropdown placeholder="Choose template">

                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let item of items | slice: 0:2">
                                                <span class="ng-value-label"> {{ item.name }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                            </div>
                                            <div class="ng-value" *ngIf="items.length > 2">
                                                <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                            <input class="form-control w-100" type="text" (input)="accessFolderDropdown.filter($event.target.value)" />
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item">
                                            <i data-feather="folder" class="mr-50 mb-25"></i>
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div class="form-group mb-1">
                                    <!-- <label for="customSelect">Choose template</label> -->
                                    <ng-select [multiple]="true" [closeOnSelect]="false" [items]="selectaccessTypeDropdown" bindLabel="name" [readonly]="readonly"
                                        [searchable]="searchable" #accessFolderDropdown placeholder="Choose type">

                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let item of items | slice: 0:2">
                                                <span class="ng-value-label"> {{ item.name }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                            </div>
                                            <div class="ng-value" *ngIf="items.length > 2">
                                                <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                            <input class="form-control w-100" type="text" (input)="accessFolderDropdown.filter($event.target.value)" />
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item">
                                            <i data-feather="folder" class="mr-50 mb-25"></i>
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-3">
                            <ng-template class="py-1" ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4"> Access to contract types in Contract control
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="form-group mb-1">
                                    <!-- <label for="customSelect">Choose template</label> -->
                                    <ng-select [multiple]="true" [closeOnSelect]="false" [items]="selectaccessContractTypeDropdown" bindLabel="name" [readonly]="readonly"
                                        [searchable]="searchable" #accessFolderDropdown placeholder="Choose type">

                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let item of items | slice: 0:2">
                                                <span class="ng-value-label"> {{ item.name }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                            </div>
                                            <div class="ng-value" *ngIf="items.length > 2">
                                                <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                            <input class="form-control w-100" type="text" (input)="accessFolderDropdown.filter($event.target.value)" />
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item">
                                            <i data-feather="folder" class="mr-50 mb-25"></i>
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        
                        <ngb-panel id="accordion-4">
                            <ng-template class="py-1" ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4"> Document types shared within group
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="form-group mb-1">
                                    <!-- <label for="customSelect">Choose template</label> -->
                                    <ng-select [multiple]="true" [closeOnSelect]="false" [items]="selectdocumentTypeDropdown" bindLabel="name" [readonly]="readonly"
                                        [searchable]="searchable" #accessFolderDropdown placeholder="Choose type">

                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let item of items | slice: 0:2">
                                                <span class="ng-value-label"> {{ item.name }}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                            </div>
                                            <div class="ng-value" *ngIf="items.length > 2">
                                                <span class="ng-value-label">{{ items.length - 2 }} more...</span>
                                            </div>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                            <input class="form-control w-100" type="text" (input)="accessFolderDropdown.filter($event.target.value)" />
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item">
                                            <i data-feather="folder" class="mr-50 mb-25"></i>
                                            {{ item.name }}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </ng-template>
                        </ngb-panel>

                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>
    <!--/ content-right -->
</div>


<!-- Modal -->
<ng-template #addGroupModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add Groups</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <div class="form-group">
                <label>Group name </label>
                <div class="form-group">
                    <input type="text" placeholder="Group name" class="form-control" />
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / Modal -->