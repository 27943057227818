<div class="card mb-0">
    <div class="card-body position-relative">
        <div class="row">
            <div class="nav-vertical d-flex roles-tabs col-sm-12 col-md-7 pl-0 ">

                <ul ngbNav #navVertical="ngbNav" [perfectScrollbar] class="nav roles-tabs-UL col-sm-12 col-md-4 pt-4 p-0 m-0 nav-tabs border-right nav-left flex-column">
                    <div class="add-new-role-btn">
                        <button type="button" class="btn btn-success float-right" (click)="addRoleModalOpen(addRoleModal)"
                            rippleEffect>Add
                            role</button>
                    </div>
                    <li ngbNavItem>
                        <a ngbNavLink>Admin</a>
                        <ng-template ngbNavContent>
                            <div class="d-flex justify-content-between align-items-center mb-50">
                                <h2 class="font-medium-1 mb-0">Admin</h2>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="adminMakeDefaultCheck"
                                        checked />
                                    <label class="custom-control-label" for="adminMakeDefaultCheck"></label>
                                    <div class="rolesadminInfo" placement="auto" ngbTooltip="Make default for all new users"
                                        container="body">
                                        <span [data-feather]="'info'" [size]="14" [class]="'font-weight-bolder cursor-pointer'"></span>
                                    </div>
                                </div>
                            </div>

                            <p class="font-small-3">Admins have the highest level of permissions for
                                the default roles
                                provided by Simple Sign. They have complete full access. Full access is
                                defined as the ability to (when applicable) view, edit, and send data.
                                Admins have full access to shared templates, shared folders, user
                                history, user contacts, Simple Sign settings, users, and billing. <br>
                                <br>
                                With regards to Simple Sign settings, Admins have access to settings
                                regarding recipient fields, document fields, branding, API settings,
                                and custom messages. Additionally, Admins gain the ability to choose
                                which add-ons are enabled/disabled. <br> <br>
                                With regards to users, Admins have full access to view and edit all
                                user and group information, activate/deactivate users, and assign
                                roles. <br> <br>
                                Importantly, Admins are the only users with access to billing and
                                payment information. <br> <br>
                                These permissions are supplementary to all permissions given to
                                Managers and Members.
                            </p>

                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Member</a>
                        <ng-template ngbNavContent>
                            <div class="d-flex justify-content-between align-items-center mb-50">
                                <h2 class="font-medium-1 mb-0">Member</h2>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="memberMakeDefaultCheck"
                                        checked />
                                    <label class="custom-control-label" for="memberMakeDefaultCheck"></label>
                                    <div class="rolesadminInfo" placement="auto" ngbTooltip="Make default for all new users"
                                        container="body">
                                        <span [data-feather]="'info'" [size]="14" [class]="'font-weight-bolder cursor-pointer'"></span>
                                    </div>
                                </div>
                            </div>

                            <p class="font-small-3">

                                Members have full access to their own templates, folders, history, and
                                contacts. They can send templates shared to their group and have the
                                ability to view and comment on folders shared with their group. <br>
                                <br>
                                Furthermore, Members have access to all available add-ons (i.e. all
                                add-ons enabled by the admin).
                            </p>

                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink>Manager</a>
                        <ng-template ngbNavContent>
                            <div class="d-flex justify-content-between align-items-center mb-50">
                                <h2 class="font-medium-1 mb-0">Manager</h2>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="managerMakeDefaultCheck"
                                        checked />
                                    <label class="custom-control-label" for="managerMakeDefaultCheck"></label>
                                    <div class="rolesadminInfo" placement="auto" ngbTooltip="Make default for all new users"
                                        container="body">
                                        <span [data-feather]="'info'" [size]="14" [class]="'font-weight-bolder cursor-pointer'"></span>
                                    </div>
                                </div>
                            </div>

                            <p class="font-small-3">Managers have the ability to view the history of
                                users in their group. They can also view and comment on contacts added
                                by others within their group. <br>
                                <br>Managers also have all permissions and abilities that are given to
                                Members (i.e. shared templates, shared folders, and available add-ons).
                            </p>

                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink class="customroletab d-flex justify-content-between align-items-center">
                            <span>Custom role text demo</span>

                            <div class="custom-role-action-cont d-flex">
                                <div placement="auto" container="body" ngbTooltip="Edit role">
                                    <span [data-feather]="'edit-2'" [class]="'custom-role-action ml-50 cursor-pointer'"></span>
                                </div>
                                <div placement="auto" container="body" ngbTooltip="Delete role">
                                    <span [data-feather]="'x'" [class]="'custom-role-action cursor-pointer'"></span>
                                </div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="d-flex justify-content-between align-items-center mb-50">
                                <h2 class="font-medium-1 mb-0">Custom role</h2>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="managerMakeDefaultCheck"
                                        checked />
                                    <label class="custom-control-label" for="managerMakeDefaultCheck"></label>
                                    <div class="rolesadminInfo" placement="auto" ngbTooltip="Make default for all new users"
                                        container="body">
                                        <span [data-feather]="'info'" [size]="14" [class]="'font-weight-bolder cursor-pointer'"></span>
                                    </div>
                                </div>
                            </div>

                            <p class="font-small-3">Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                            </p>

                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink class="customroletab d-flex justify-content-between align-items-center">
                            <span>Custom role</span>
                            <div class="custom-role-action-cont d-flex">
                                <div placement="auto" container="body" ngbTooltip="Edit role">
                                    <span [data-feather]="'edit-2'" [class]="'custom-role-action ml-50 cursor-pointer'"></span>
                                </div>
                                <div placement="auto" container="body" ngbTooltip="Delete role">
                                    <span [data-feather]="'x'" [class]="'custom-role-action cursor-pointer'"></span>
                                </div>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="d-flex justify-content-between align-items-center mb-50">
                                <h2 class="font-medium-1 mb-0">Custom role

                                </h2>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="managerMakeDefaultCheck"
                                        checked />
                                    <label class="custom-control-label" for="managerMakeDefaultCheck"></label>
                                    <div class="rolesadminInfo" placement="auto" ngbTooltip="Make default for all new users"
                                        container="body">
                                        <span [data-feather]="'info'" [size]="14" [class]="'font-weight-bolder cursor-pointer'"></span>
                                    </div>
                                </div>
                            </div>

                            <p class="font-small-3">Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                            </p>

                        </ng-template>
                    </li>

                </ul>

                <div [ngbNavOutlet]="navVertical" class="mt-50"></div>
            </div>

            <div class="col-sm-12 col-md-5 p-0 border-left roles-accordion collapse-icon">
                <div class="roles-accordion-scroll-container" [perfectScrollbar]>
                    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="">
                        <ngb-panel id="accordion-1">
                            <ng-template class="py-1" ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4"> Templates shared to group
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="templatesshared1" name="templatesshared" class="custom-control-input"
                                            checked />
                                        <label class="custom-control-label" for="templatesshared1">No access</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="templatesshared2" name="templatesshared" class="custom-control-input" />
                                        <label class="custom-control-label" for="templatesshared2">Can send</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="templatesshared3" name="templatesshared" class="custom-control-input" />
                                        <label class="custom-control-label" for="templatesshared3">Can edit template
                                            content before sending</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="templatesshared4" name="templatesshared" class="custom-control-input" />
                                        <label class="custom-control-label" for="templatesshared4">Full access</label>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-2">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4"> Folders shared to group
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="foldersshared1" name="foldersshared" class="custom-control-input"
                                            checked />
                                        <label class="custom-control-label" for="foldersshared1">No access</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="foldersshared2" name="foldersshared" class="custom-control-input" />
                                        <label class="custom-control-label" for="foldersshared2">Can view & comment</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="foldersshared3" name="foldersshared" class="custom-control-input" />
                                        <label class="custom-control-label" for="foldersshared3">
                                            Can view, comment, edit & upload
                                        </label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="foldersshared4" name="foldersshared" class="custom-control-input" />
                                        <label class="custom-control-label" for="foldersshared4">Full access</label>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-3">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4">Documents of group members</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="groupmembers1" name="groupmembers" class="custom-control-input"
                                            checked />
                                        <label class="custom-control-label" for="groupmembers1">No access</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="groupmembers2" name="groupmembers" class="custom-control-input" />
                                        <label class="custom-control-label" for="groupmembers2">Can view</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="groupmembers3" name="groupmembers" class="custom-control-input" />
                                        <label class="custom-control-label" for="groupmembers3">
                                            Can view, chat and edit
                                        </label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="groupmembers4" name="groupmembers" class="custom-control-input" />
                                        <label class="custom-control-label" for="groupmembers4">Full access</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="groupmembers5" name="groupmembers" class="custom-control-input" />
                                        <label class="custom-control-label" for="groupmembers5">Full access including
                                            main
                                            user documents</label>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-4">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4">Contacts added by others
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="contactsadded1" name="contactsadded" class="custom-control-input"
                                            checked />
                                        <label class="custom-control-label" for="contactsadded1">No access</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="contactsadded2" name="contactsadded" class="custom-control-input" />
                                        <label class="custom-control-label" for="contactsadded2">Can view & comment</label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="contactsadded3" name="contactsadded" class="custom-control-input" />
                                        <label class="custom-control-label" for="contactsadded3">
                                            Can view, and edit, comment
                                        </label>
                                    </div>
                                    <div class="custom-control custom-radio mb-75">
                                        <input type="radio" id="contactsadded4" name="contactsadded" class="custom-control-input" />
                                        <label class="custom-control-label" for="contactsadded4">Full access</label>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-5">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4">Access to settings
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Recipient fields</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch1" />
                                            <label class="custom-control-label" for="roleSwitch1"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Document fields</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch2" />
                                            <label class="custom-control-label" for="roleSwitch2"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Branding</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch3" />
                                            <label class="custom-control-label" for="roleSwitch3"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">API Settings</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch4" />
                                            <label class="custom-control-label" for="roleSwitch4"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Add-Ons</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch5" />
                                            <label class="custom-control-label" for="roleSwitch5"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Company</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch6" />
                                            <label class="custom-control-label" for="roleSwitch6"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Content Library</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch7" />
                                            <label class="custom-control-label" for="roleSwitch7"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Document type</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch8" />
                                            <label class="custom-control-label" for="roleSwitch8"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Product Library</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch9" />
                                            <label class="custom-control-label" for="roleSwitch9"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Attachments</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch10" />
                                            <label class="custom-control-label" for="roleSwitch10"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-0 pt-0 mb-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Schoolsoft schools</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch11" />
                                            <label class="custom-control-label" for="roleSwitch11"></label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-6">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4">Restrict access to
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Edit recipient information on signing page</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch12" />
                                            <label class="custom-control-label" for="roleSwitch12"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Edit signing method on signing page</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch13" />
                                            <label class="custom-control-label" for="roleSwitch13"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Activate or use chat</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch14" />
                                            <label class="custom-control-label" for="roleSwitch14"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Video recording</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch15" />
                                            <label class="custom-control-label" for="roleSwitch15"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Build templates</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch16" />
                                            <label class="custom-control-label" for="roleSwitch16"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Send documents (not templates)</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch17" />
                                            <label class="custom-control-label" for="roleSwitch17"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Copy and resend sent documents</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch18" />
                                            <label class="custom-control-label" for="roleSwitch18"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Edit sent document</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch19" />
                                            <label class="custom-control-label" for="roleSwitch19"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Download sent documents</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch20" />
                                            <label class="custom-control-label" for="roleSwitch20"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Send reminders manually</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch21" />
                                            <label class="custom-control-label" for="roleSwitch21"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group border-bottom pb-1 pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Delete signed documents</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch22" />
                                            <label class="custom-control-label" for="roleSwitch22"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group border-bottom pb-1 pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Edit role in groups</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch23" />
                                            <label class="custom-control-label" for="roleSwitch23"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group border-bottom pb-1 pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Revoke document</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch24" />
                                            <label class="custom-control-label" for="roleSwitch24"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-0 pt-0 mb-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Manage documents (via Task Management)</p>
                                            <input type="checkbox" class="custom-control-input" id="roleSwitch25" />
                                            <label class="custom-control-label" for="roleSwitch25"></label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-7">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4">Users
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Full access to users</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch26" />
                                            <label class="custom-control-label" for="roleSwitch26"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Full access to roles</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch27" />
                                            <label class="custom-control-label" for="roleSwitch27"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-0 pt-0 mb-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Full access to groups</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch28" />
                                            <label class="custom-control-label" for="roleSwitch28"></label>
                                        </div>
                                    </div>

                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-8">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4">Contract Control
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Access to settings</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch29" />
                                            <label class="custom-control-label" for="roleSwitch29"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Access Contract Control</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch30" />
                                            <label class="custom-control-label" for="roleSwitch30"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Add new contracts</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch31" />
                                            <label class="custom-control-label" for="roleSwitch31"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-1 border-bottom pt-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Edit contracts added by others</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch32" />
                                            <label class="custom-control-label" for="roleSwitch32"></label>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-switch form-group pb-0 pt-0 mb-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Delete documents added by others</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch33" />
                                            <label class="custom-control-label" for="roleSwitch33"></label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="accordion-9">
                            <ng-template ngbPanelTitle>
                                <span class="lead collapse-title text-primary font-small-4">Billing & Payments
                                </span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="d-flex flex-column">
                                    <div class="custom-control custom-switch form-group pb-0 pt-0 mb-0">
                                        <div class="d-flex justify-content-between">
                                            <p class="font-small-3 mb-0">Can manage billing & payments</p>
                                            <input type="checkbox" checked class="custom-control-input" id="roleSwitch34" />
                                            <label class="custom-control-label" for="roleSwitch34"></label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>

        </div>

    </div>
</div>

<!-- Modal -->
<ng-template #addRoleModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add role</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <div class="form-group">
                <label>Role name </label>
                <div class="form-group">
                    <input type="text" placeholder="Role name" class="form-control" />
                </div>
            </div>
            <div class="form-group">
                <label for="basicTextarea">Description</label>
                <fieldset class="form-group">
                    <textarea class="form-control" id="" rows="3" placeholder="Description"></textarea>
                </fieldset>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Add role
        </button>
    </div>
</ng-template>
<!-- / Modal -->