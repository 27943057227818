import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})
export class UsersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
