<div class="card addonBoxContainer">
    <div class="card-body">
        <h5>Two-Factor Authentication</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Add an extra layer of security with two-factor authentication login
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <img src="assets/images/addons-images/Authentication.png" class="addonThumbnailImg" alt="addonThumbnail" />
    </div>
</div>