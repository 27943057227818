import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-credit-safe-addon',
  templateUrl: './credit-safe-addon.component.html',
  styleUrls: ['./credit-safe-addon.component.scss']
})
export class CreditSafeAddonComponent implements OnInit {

    // modal Basic
    modalOpen(getLinkSettingModal) {
      this.modalService.open(getLinkSettingModal, {
        // centered: true,
        size: 'lg' 
      });
    }
    // swiper
    public swiperPaginations: SwiperConfigInterface = {
      // pagination: true
    };
  
    
  
    constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
