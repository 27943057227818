import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule, Routes } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';




import { DocumentRightSidebarComponent } from './document-right-sidebar/document-right-sidebar.component';
import { DocumentListingComponent } from './document-listing/document-listing.component';
import { DocumentMainSidebarComponent } from './document-main-sidebar/document-main-sidebar.component';
import { DocumentFolderViewComponent } from './document-folder-view/document-folder-view.component';
import { DocumentGraphViewComponent } from './document-graph-view/document-graph-view.component';


@NgModule({
  declarations: [
    DocumentListingComponent,
    DocumentMainSidebarComponent,
    DocumentRightSidebarComponent,
    DocumentFolderViewComponent,
    DocumentGraphViewComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    CoreCommonModule,
    PerfectScrollbarModule,
    ChartsModule,
    Ng2FlatpickrModule,
    NgSelectModule,
    FormsModule
    
  ],
  exports: [
    DocumentListingComponent,
    DocumentMainSidebarComponent,
    DocumentRightSidebarComponent,
    DocumentFolderViewComponent,
    DocumentGraphViewComponent,
    NgSelectModule
  ]
})
export class DocumentsModule {}
