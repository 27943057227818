import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contract-control-listing',
  templateUrl: './contract-control-listing.component.html',
  styleUrls: ['./contract-control-listing.component.scss']
})
export class ContractControlListingComponent implements OnInit {


  public pageBasic = 1;
  constructor( private router: Router) { }


  contractControlDetailViewClick() {
    this.router.navigateByUrl('contract-control/contract-control-detail-view');
    // event.preventDefault();
  }
  ngOnInit(): void {
  }

}
