<div class="d-flex justify-content-between align-items-center content-sidebar-header pt-1 px-1 pb-75 hidden">
    <div class="d-flex justify-content-start align-items-center">
       <div class="user-page-info d-flex justify-content-start align-items-center">
            <div class="d-flex justify-content-start align-items-center" id="backToRecipientView" (click)="backToRecipientView()">
                <i data-feather="chevron-left" [size]="20" class="ml-0 mr-50 cursor-pointer"></i>
            </div>
            <h5 class="mb-0"> Recipient(s) </h5>
       </div>   
    </div>
    <div>

    </div>
</div>

<div class="recipient-setting-tab-scroll pt-75" [perfectScrollbar]>

    <div>
        <div class="form-group px-1">
            <label class="col-form-label pt-0 pb-0" for="basicInput">Name</label>
            <input type="text" class="form-control" id="basicInput" placeholder="Enter name or search from contacts" />
        </div>

        <div class="form-group px-1">
            <label class="col-form-label pt-0 pb-0" for="basicInput">Email</label>
            <input type="email" class="form-control" id="basicInput" />
        </div>

        <div class="form-group px-1">
            <label class="col-form-label pt-0 pb-0" for="basicInput">Address</label>
            <input type="text" class="form-control" id="basicInput" />
        </div>

        <div class="form-group px-1">
            <label class="col-form-label pt-0 pb-0" for="basicInput">Zip Code</label>
            <input type="text" class="form-control" id="basicInput" />
        </div>

        <div class="form-group px-1">
            <label class="col-form-label pt-0 pb-0" for="basicInput">City</label>
            <input type="text" class="form-control" id="basicInput" />
        </div>
    </div>

    <button type="button" class="btn p-0 ml-auto btn-sm d-flex px-1 cursor-pointer justify-content-end btn-transparent text-primary" (click)="modalOpen(modalRolesfieldEdit)">Manage fields</button>

    <!-- <hr class="mt-2 mb-2"> -->

    <div class="form-group px-1 mb-0 mt-2">
        <label class="col-form-label pt-0" for="name">Invitation through</label>
        <ng-select [items]="type" bindLabel="name" bindValue="id" [(ngModel)]="selectedInvitationType">
        </ng-select>
    </div>

    <div class="form-group px-1 mb-0 mt-1" *ngIf="invitaionBoxHide">
        <label class="col-form-label pt-0" for="name">Invitation order</label>
        <ng-select [items]="order" bindLabel="name" bindValue="id" [(ngModel)]="selectedInvitationOrder">
        </ng-select>
    </div>

    <div class="form-group px-1 mb-0 mt-1" *ngIf="invitaionBoxHide">
        <label class="col-form-label pt-0" for="name">Confirmation</label>
        <ng-select [items]="confirmation" bindLabel="name" bindValue="id" [(ngModel)]="selectedConfirmation">
        </ng-select>
    </div>

    <button type="button" class="btn p-0 ml-auto btn-sm d-flex px-1 pt-75 cursor-pointer justify-content-end btn-transparent text-primary" *ngIf="invitaionBoxHide" (click)="invitationCollapseHideFunc()">Hide</button>
    <button type="button" class="btn p-0 ml-auto btn-sm d-flex px-1 pt-75 cursor-pointer justify-content-end btn-transparent text-primary" *ngIf="showBtn" (click)="invitationCollapseShowFunc()">Show more</button>
        
    <hr class="mt-2 mb-2">

    <div class="form-group px-1 mb-0">
        <label class="col-form-label pt-0" for="name">Authentication</label>
        <ng-select [items]="authentication" bindLabel="name" bindValue="id" [(ngModel)]="selectedAuthentication">
        </ng-select>
    </div>

    <div class="form-group px-1 mb-0 mt-1" *ngIf="authenticationBoxHide">
        <label class="col-form-label pt-0" for="name">Request attachments</label>
        <ng-select [items]="requestattachments" bindLabel="name" bindValue="id" [(ngModel)]="selectedRequestaAttachments">
        </ng-select>
    </div>

    <button type="button" class="btn p-0 ml-auto btn-sm d-flex px-1 pt-75 cursor-pointer justify-content-end btn-transparent mb-2 text-primary" *ngIf="authenticationBoxHide" (click)="authenticationCollapseHideFunc()">Hide</button>
    <button type="button" class="btn p-0 ml-auto btn-sm d-flex px-1 pt-75 cursor-pointer justify-content-end btn-transparent mb-2 text-primary" *ngIf="authenticationShowBtn" (click)="authenticationCollapseShowFunc()">Show more</button>
</div>

<!-- Edit role & field settings Modal Start -->
<ng-template #modalRolesfieldEdit let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Edit role & field settings</h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>

  </div>
</ng-template>
<!-- / Edit role & field settings Modal End -->
