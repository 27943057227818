import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verification-using-bsiaddon',
  templateUrl: './verification-using-bsiaddon.component.html',
  styleUrls: ['./verification-using-bsiaddon.component.scss']
})
export class VerificationUsingBSIAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
