<div class="slideout-content">
    <!-- Modal to Document right sidebar starts-->
    <div class="modalsd modal-slide-in sdfade document-right-sidebar" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <div class="modal-header mb-1 d-flex justify-content-between align-items-center px-2 py-1">
                    <button type="button" class="btn btn-outline-primary btn-sm" rippleEffect>Signed</button>
                    <div class="d-flex justify-content-between align-items-center text-dark">
                        <!-- <i class="feather icon-x ml-1" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('document-right-sidebar')"></i> -->

                        <i class="feather font-medium-1 icon-mail ml-1 cursor-pointer" placement="auto" ngbTooltip="Send SMS or Email reminder to recipient"></i>
                        <i class="feather font-medium-1 icon-send ml-1 cursor-pointer" placement="auto" ngbTooltip="Send a new document by reusing by this one"></i>
                        <i class="feather font-medium-1 icon-download ml-1 cursor-pointer" placement="auto" ngbTooltip="PDF"></i>
                        <i class="feather font-medium-1 icon-x-circle ml-1 cursor-pointer" placement="auto" ngbTooltip="Revoke"></i>
                        <div class="documentRightMenuDropdown" ngbDropdown>
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow ml-1 d-flex" id="todoActions"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="feather font-medium-1 icon-more-vertical cursor-pointer text-body" placement="auto"
                                    ngbTooltip="More"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-maximize"></i>
                                    Fullscreen</a>
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-file-plus"></i>
                                    Add to contract control</a>
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-calendar"></i>
                                    Internal reminder</a>
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-edit"></i>
                                    Edit document</a>
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-1 mr-50 icon-database"></i>
                                    Index data</a>
                                <a ngbDropdownItem class="sort-asc text-danger">
                                    <i class="feather text-danger font-medium-1 mr-50 icon-trash"></i>
                                    Delete permanently</a>
                            </div>
                        </div>
                        <i class="feather font-medium-1 icon-x ml-1 cursor-pointer" placement="auto" ngbTooltip="Close"
                            data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('document-right-sidebar')"></i>
                    </div>
                    <!-- <h5 class="modal-title" id="exampleModalLabel">Create attachment</h5> -->
                </div>
                <div class="modal-body flex-grow-1 p-0">
                    <ul ngbNav #navJustified="ngbNav" class="nav-tabs nav-justified document-right-sidebarTabs">
                        <li ngbNavItem>
                            <a ngbNavLink>Details</a>
                            <ng-template ngbNavContent>
                                <div class="documentDetailTabMainContainer mt-2 px-2">
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Document name</small></div>
                                        <div>
                                            <p class="mb-0">Proposal Template For Any Business</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Saved in folder</small></div>
                                        <div>
                                            <p class="text-primary mb-0">Sent</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Document type</small></div>
                                        <div>
                                            <p class="text-primary mb-0">Other</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="detailFirstColumn"><small>Get shareable link</small></div>
                                        <div>
                                            <p class="mb-0">The lint is <a class="text-primary">private</a></p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center mb-1">
                                        <div class="detailFirstColumn"><small>Due Date</small></div>
                                        <div class="docRightMenuDatePicker">
                                            <div class="form-group mb-0">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                                            [ngClass]="'firstsecond'" [config]="basicDateOptions" type="button"
                                                            rippleEffect></button>
                                                    </div>
                                                    <ng2-flatpickr placeholder="No date select" [ngClass]="'firstsecond'"
                                                        [config]="basicDateOptions" name="basicDate"></ng2-flatpickr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="form-group">
                                            <input type="text" placeholder="Add tags" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Document</a>
                            <ng-template ngbNavContent>
                                <div class="documentTabThumbnailContainer mt-1 px-2">
                                    <div class="d-flex justify-content-center px-2 py-1 document-thumbnail-box cursor-pointer">
                                        <img src="assets/images/pages/documentThumb.jpg" alt="">
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Participants</a>
                            <ng-template ngbNavContent>
                                <div class="participantsTabMainContainer mt-1 participantsTabMainContainerScroll"
                                    [perfectScrollbar]>
                                    <div class="media-list">
                                        <div class="media d-flex align-items-start pt-50">
                                            <div class="avatar mr-1">
                                                <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                    width="32" height="32" />
                                                <span class="avatar-status-online"></span>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="font-weight-bold d-block text-nowrap mb-0"> Katherine
                                                    Kennedy <span class="text-success">(Created)</span>
                                                </h6>
                                                <small>tatejennifer@allen.net</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="media-list">
                                        <div class="media d-flex align-items-start pt-50">
                                            <div class="avatar mr-1 bg-light-success">
                                                <span class="avatar-content">MK</span>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="font-weight-bold d-block text-nowrap mb-0"> Dalila Ouldcott
                                                    <span>(Delivered)</span>
                                                </h6>
                                                <small>douldcottj@yellowpages.com</small><br>
                                                <small>Reminder sent on: <span>2021 Dec 08 8:50AM</span></small><br>
                                                <small>Reminder sent on: <span>2021 Dec 08 8:50AM</span></small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="media-list">
                                        <div class="media d-flex align-items-start pt-50">
                                            <div class="avatar mr-1 bg-light-success">
                                                <span class="avatar-content">AB</span>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="font-weight-bold d-block text-nowrap mb-0"> Monica Fuller
                                                    <span>(Signed)</span>
                                                </h6>
                                                <small>gdurham@lee.com</small><br>
                                                <small>Reminder sent on: <span>2021 Dec 08 8:50AM</span></small><br>
                                                <small>Reminder will be sent on: <span>2021 Dec 08 8:50AM</span></small><br>
                                                <small>Delivered on: <span>2021 Dec 08 8:50AM</span></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li ngbNavItem>
                            <a ngbNavLink>Activities</a>
                            <ng-template ngbNavContent>
                                <div class="documentActivitieschatContainer chat-widget">
                                    <!-- User Chat messages -->
                                    <section class="chat-app-window">
                                        <div class="user-chats" [perfectScrollbar]>
                                            <div class="chats">
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide
                                                                you such information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide
                                                                you such information.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat chat-left">
                                                    <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                            <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                            <!---->
                                                            <!----></span></div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">We need to check if we can provide
                                                                you such information.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat ClientChatContainer">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="chat">
                                                    <div class="chat-avatar">
                                                        <span class="avatar box-shadow-1 cursor-pointer">
                                                            <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                                height="36" width="36" />
                                                            <ng-template>
                                                                <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                                    alt="avatar" height="36" width="36" />
                                                            </ng-template>
                                                        </span>
                                                    </div>
                                                    <div class="chat-body">
                                                        <div class="chat-content InternalComment">
                                                            <p class="font-small-3">Hey John, I am looking for the best
                                                                admin template.
                                                                Could you please help me to find it out?</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Submit Chat form -->
                                        <form class="chat-app-form justify-content-between">
                                            <!-- <div class="input-group input-group-merge mr-50 w-75 form-send-message">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">
                                                        <label for="attach-doc" class="attachment-icon mb-0">
                                                            <i data-feather="image" class="cursor-pointer lighten-2 text-secondary"></i>
                                                            <input type="file" id="attach-doc" hidden /> </label></span>
                                                </div>
                                                <input type="text" class="form-control message" placeholder="Type your message"
                                                    name="chat-message" />
                                            </div> -->
                                            <div class=" w-100 mr-50">
                                                <fieldset>
                                                    <div class="input-group">
                                                        <div ngbDropdown class="input-group-prepend">
                                                            <button type="button" class="btn btn-outline-primary"
                                                                ngbDropdownToggle data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false" rippleEffect>
                                                                <i data-feather="edit-2"></i>
                                                            </button>
                                                            <div ngbDropdownMenu>
                                                                <a ngbDropdownItem>Internal comment</a>
                                                                <a ngbDropdownItem>Public comment</a>
                                                            </div>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="Type your message" />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <button type="button" class="btn btn-primary send" rippleEffect>
                                                <i data-feather="send" class="d-lg-none"></i>
                                                <span class="d-none text-nowrap d-lg-block">Send</span>
                                            </button>
                                        </form>
                                        <!--/ Submit Chat form -->
                                    </section>
                                    <!-- User Chat messages -->
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="navJustified" class="mt-0"></div>
                    <!-- <button type="submit" class="btn btn-primary mr-1" rippleEffect>Save</button>
                    <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('document-right-sidebar')" rippleEffect>
                        Cancel
                    </button> -->
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to Document right sidebar Ends-->
</div>