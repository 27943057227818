import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sign-bank-id-box',
  templateUrl: './sign-bank-id-box.component.html',
  styleUrls: ['./sign-bank-id-box.component.scss']
})
export class SignBankIdBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
