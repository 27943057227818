import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-attest-participant-addon',
  templateUrl: './attest-participant-addon.component.html',
  styleUrls: ['./attest-participant-addon.component.scss']
})
export class AttestParticipantAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
