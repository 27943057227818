import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-abouttoexpirewidget',
  templateUrl: './abouttoexpirewidget.component.html',
  styleUrls: ['./abouttoexpirewidget.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class AbouttoexpirewidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
