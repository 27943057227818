import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-document-folder-view',
  templateUrl: './document-folder-view.component.html',
  styleUrls: ['./document-folder-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentFolderViewComponent implements OnInit {


  // modal Open Form
  addNewFolderModalOpenClick(addNewFolderModal) {
    this.modalService.open(addNewFolderModal);
  }
  manageFolderModalOpenClick(manageFolderModal) {
    this.modalService.open(manageFolderModal,{
      size: 'lg'
    });
  }
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

}
