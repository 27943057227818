import { Component, OnInit, Injectable } from '@angular/core';
import 'jquery-ui-dist/jquery-ui';
import uuid from 'short-unique-id';
import { EditTemplateComponent } from '../../edit-template/edit-template.component';
import { Fields } from '../fields/fields';
import { Blocks } from '../blocks/blocks';
import { formBuilder } from '../form-builder/formBuilder';
import { productTable } from '../product-table/productTable';
import { GlobalService } from 'app/global/global.service';
import { contentFields } from '../content-fields/contentFields';
declare var $: any;
@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-content-tab',
  templateUrl: './content-tab.component.html',
  styleUrls: ['./content-tab.component.scss'],
  providers: [Fields, Blocks, formBuilder, contentFields, productTable]
})
export class ContentTabComponent implements OnInit {
  public uuid = new uuid({ length: 6, dictionary: 'number' });
  constructor(
    public Fields: Fields,
    public Blocks: Blocks,
    public formBuilder: formBuilder,
    public productTable: productTable,
    public contentFields: contentFields,
    public template: EditTemplateComponent,
    public GlobalService: GlobalService,
  ) { }

  ngOnInit(): void {
    var that = this;
    $(document).ready(function () {
      $('.content-drag').draggable({
        cursorAt: { left: -2, top: -2 },
        // container: '.template-page',
        helper: 'clone',
        start() {
          $('.editorTabScroll.ps').addClass('overflow-visible')
          $('.ui-draggable-dragging').addClass('clone-show')
          $('.content-box-inner').addClass('content-box-hovered')
          $(this).css('opacity', '0.5')
          that.GlobalService.initializeHoverOnDragStart({ block: $(this).attr('block') });
        },
        drag(event, ui) {
          // console.log(event,'event');
        },
        stop() {
          $('.editorTabScroll.ps').removeClass('overflow-visible')
          $('.ui-draggable-dragging').removeClass('clone-show')
          $(this).css('opacity', '')
          $('.ui-draggable-dragging').removeClass('clone-show')
          var position = $('.template-page').attr('position');
          if ($(this).attr('block') == 'content-box') {
            // that.Blocks.createContentBox();
            that.Blocks.addContentBox({ position: position });
            if(position == 'right' || position == 'right'){
              that.initSortOnImage($('.content-box-enter').find('.content-box-row'));
            }
            that.GlobalService.adjustInnerContentBoxWidthOnAdd();
          } else if ($(this).attr('block') == 'page-break') {
            that.Blocks.addPageBreak();
          } else if ($(this).attr('block') == 'add-attachment') {
            that.Blocks.addAttachmentBox();
          } else if ($(this).attr('block') == 'image-block') {
            that.Blocks.addImageBox({ position: position });
            if(position == 'right' || position == 'right'){
              that.initSortOnImage($('.content-box-enter').find('.content-box-row'));
            }
            that.GlobalService.adjustInnerContentBoxWidthOnAdd();
          } else if ($(this).attr('block') == 'form-builder') {
            that.formBuilder.formBuilderBox();
          } else if ($(this).attr('block') == 'upload-pdf') {
            var uuid = that.uuid();
            that.Blocks.pdfBlockUploader({ uuid: uuid });
            $('[name="' + uuid + '"]').trigger('click');
          } else if ($(this).attr('block') == 'product-table') {
            that.productTable.productTableBox();
          } else {
            alert('Oopss')
          }

          $('div.content-box').unbind('mouseenter')
          $('.dropIndicator-bottom').remove();
          $('.dropIndicator-right').remove();
          $('.dropIndicator-left').remove();
          $('.template-page').removeAttr('position');
          $('.top-cornor,.bottom-cornor,.right-cornor,.left-cornor').unbind("mousemove");
          $('.content-box-inner').removeClass('content-box-hovered');
          that.GlobalService.contentBoxSort();
        },
      })
      that.initDragFields();
      that.initEeventList();
      console.log('here');
    })
  }

  initDragFields() {
    var that = this;
    $('.fields-drag').draggable({
      cursorAt: { left: -2, top: -2 },
      helper() {
        if ($(this).attr('field') == 'text-field') {
          return $('<div class="drag-fields"><span class="placeholder-field-label">Full name</span></div>')
        }
        if ($(this).attr('field') == 'checkbox-field') {
          return $('<div class="drag-fields checkbox-field"><div class="drag-fields-inner-container checkbox-field-inner-container"><div class="checkbox-field-box"></div><span class="field-label">Full name</span></div></div>')
        }
        if ($(this).attr('field') == 'datepicker-field') {
          return $('<div class="drag-fields datepicker-field"><span class="placeholder-field-label">Datepicker</span></div>')
        }
        if ($(this).attr('field') == 'signature-field') {
          return $('<div class="drag-fields signature-field"><span class="placeholder-field-label">Signature</span></div>')
        }
        if ($(this).attr('field') == 'droplist-field') {
          return $('<div class="drag-fields droplist-field"><span class="placeholder-field-label">Select option</span></div>')
        }
        if ($(this).attr('field') == 'multichoice-field') {
          return $('<div class="drag-fields multichoice-field multiChoice-radio content-draggable-field"><span class="placeholder-field-label placeholder-multiChoicefield-label">Select Option</span><div class="multiChoice-item-container"><div class="multiChoice-item"><div class="multiChoice-box"><div class="multiChoice-inner-circle"></div></div><label class="multiChoice-label-text">Option 1</label></div><div class="multiChoice-item"><div class="multiChoice-box"><div class="multiChoice-inner-circle"></div></div><label class="multiChoice-label-text">Option 2</label></div></div></div>')
        }
        if ($(this).attr('field') == 'image-field') {
          return $('<div field="image-field" class="content-listItem" style=""><div class="content-item-box"><div class="content-item-box-dragIcon"><svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg></div><div class="content-item-box-text"><span>Image Box</span><div class="content-item-svgicon"><svg viewBox="0 0 24 24" role="presentation"><path d="M5 17l5.25-6 3.5 4.003 1.75-2.006L19 17H5zM15.5 7c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5S14 9.325 14 8.5 14.675 7 15.5 7zM2 20h20V4H2v16z" fill-rule="evenodd"></path></svg></div></div></div></div>')
        }
      },
      start() {
        $('.editorTabScroll.ps').addClass('overflow-visible')
        $('.ui-draggable-dragging').addClass('clone-show')
        $('.content-box-inner').addClass('content-box-hovered')
        $(this).css('opacity', '0.5')
        that.GlobalService.initializeFieldHoverDragStart()
      },
      drag(event, ui) { },
      stop(event, ui) {
        $('.editorTabScroll.ps').removeClass('overflow-visible')
        $('.ui-draggable-dragging').removeClass('clone-show')
        $('.content-box-inner').removeClass('content-box-hovered')
        $(this).css('opacity', '')
        $('div.content-box').unbind('mouseenter')
        if ($('.addElement').length > 0 && !$('.addElement').hasClass('restricted_box') && $(this).attr('field-type') == 'default') {

          const getContentInnerBoxTop = parseInt($('.addElement').find('.content-box-inner.draging-started').position().top)
          const getContentInnerBoxLeft = parseInt($('.addElement').find('.content-box-inner.draging-started').position().left)
          const getContentInnerMarginTop = parseInt($('.addElement').find('.content-box-inner.draging-started').css('margin-top'))

          const getContentBoxTop = parseInt($('.addElement').offset().top)
          const getContentBoxLeft = parseInt($('.addElement').offset().left)

          that.addField(
            ui.offset.top - getContentBoxTop - getContentInnerBoxTop - getContentInnerMarginTop - 1,
            ui.offset.left - getContentBoxLeft - getContentInnerBoxLeft - 21,
            $(this).attr('field'),
          );
        } else if ($(this).attr('field-type') == 'pdfblock' && $(event.originalEvent.target).parents('.content-box-inner').attr('editor-uuid') == $(this).attr('pdf-uuid')) {
          var pdfBlock = $('.content-box-inner[editor-uuid="' + $(this).attr('pdf-uuid') + '"]').parents('.pdf-block');
          const getContentBoxTop = parseInt(pdfBlock.offset().top);
          const getContentBoxLeft = parseInt(pdfBlock.offset().left);
          console.log(getContentBoxTop, getContentBoxLeft, 'getContentBoxLeft');
          that.addField(
            ui.offset.top - getContentBoxTop - 1,
            ui.offset.left - getContentBoxLeft - 21,
            $(this).attr('field'),
            { pdfblock: $('.content-box-inner[editor-uuid="' + $(this).attr('pdf-uuid') + '"]') }
          );
        }
      },
    });
    that.initContentDragFields();
    that.initEeventList();
  }

  initEeventList() {
    var that = this;
    $(document).off('click', '.content-draggable-field').on('click', '.content-draggable-field', function (e) {
      e.preventDefault();
      e.stopPropagation();
      if ($(this).parents('.form-builder')) {
        $('.form-builder').parents('.content-box-inner').removeClass('active-border');
        $(this).parents('.form-builder').parents('.content-box-inner').addClass('active-border');
      }
      that.fieldsPopOver($(this), that.Fields);
    })
    $(document).off('click', '.remove_field').on('click', '.remove_field', function (e) {
      var uuid = $(this).parent('.fieldsPopover').attr('uuid');
      that.removeField(uuid);
    });
  }

  removeField(uuid) {
    $(".fieldsPopover[uuid='" + uuid + "']").remove();
    $(".drag-fields[uuid='" + uuid + "']").remove();
    this.template.closePopOverSettingView(false);
    this.GlobalService.removeSignature(uuid);
  }

  fieldsPopOver(element, field) {
    var fieldComponent = '';
    var fieldType = '';
    if ($(element).attr('field') == 'text-field') {
      fieldComponent = field.fieldPopOver({ class: 'textFieldSetting' });
      fieldType = 'textFieldSetting';
    } else if ($(element).attr('field') == 'checkbox-field') {
      fieldComponent = field.fieldPopOver({ class: 'checkBoxFieldSetting' });
      fieldType = 'checkBoxFieldSetting';
    } else if ($(element).attr('field') == 'droplist-field') {
      fieldComponent = field.fieldPopOver({ class: 'droplistFieldSetting' });
      fieldType = 'droplistFieldSetting';
    } else if ($(element).attr('field') == 'datepicker-field') {
      fieldComponent = field.fieldPopOver({ class: 'datepickerFieldSetting' });
      fieldType = 'datepickerFieldSetting';
    } else if ($(element).attr('field') == 'signature-field') {
      fieldComponent = field.fieldPopOver({ class: 'signatureFieldSetting' });
      fieldType = 'signatureFieldSetting';
    } else if ($(element).attr('field') == 'multichoice-field') {
      fieldComponent = field.fieldPopOver({ class: 'multiChoiceFieldSetting' });
      fieldType = 'multiChoiceFieldSetting';
    }
    // if(!$(element).find('.fieldsPopover').is(':visible')){
    $('.fieldsPopover').remove()
    // }
    $('.contentBox-popover-container').remove()
    $('.formBlock-popover-container').remove()
    $('.pageBreak-popover-container').remove()
    setTimeout(() => {
      $(document.body).append(fieldComponent)
      var top = parseInt($(element).offset().top);
      var left = parseInt($(element).offset().left);
      var width = parseInt($(element).width()) / 2;
      const getPopoverHeight = $('.' + fieldType + '-popover-container').height() + 5
      $('.' + fieldType + '-popover-container').css({ 'top': top - getPopoverHeight, 'left': left + width + 10 }).attr('uuid', $(element).attr('uuid'));
    }, 1);
    $(".draggable").find("textarea, input").on('mousedown', function (e) {
      var mdown = new MouseEvent("mousedown", {
        screenX: e.screenX,
        screenY: e.screenY,
        clientX: e.clientX,
        clientY: e.clientY,
        view: window
      });
      $(this).closest('.draggable')[0].dispatchEvent(mdown);
    }).on('click', function (e) {
      var $draggable = $(this).closest('.draggable');
      if ($draggable.data("preventBehaviour")) {
        e.preventDefault();
        $draggable.data("preventBehaviour", false)
      }
    });
  }


  addField(top, left, field_type, opts: any = {}) {
    var uuid = this.uuid();
    var fieldSettings = { top: top, left: left, uuid: uuid };
    let fieldComponent = ''
    if (field_type == 'text-field') {
      fieldComponent = this.Fields.textFeildHtml(fieldSettings);
    } else if (field_type == 'checkbox-field') {
      fieldComponent = this.Fields.checkboxFeildHtml(fieldSettings);
    } else if (field_type == 'datepicker-field') {
      fieldComponent = this.Fields.datepickerFeildHtml(fieldSettings);
    } else if (field_type == 'signature-field') {
      fieldComponent = this.Fields.signatureFeildHtml(fieldSettings);
    } else if (field_type == 'droplist-field') {
      fieldComponent = this.Fields.droplistFeildHtml(fieldSettings);
    } else if (field_type == 'image-field') {

    } else if (field_type == 'multichoice-field') {
      fieldComponent = this.Fields.multiChoiceFeildHtml(fieldSettings);
    }
    if (opts.hasOwnProperty('pdfblock')) {
      opts.pdfblock.append(fieldComponent);
      $('.drag-fields[uuid="' + uuid + '"]').attr('pdfblock', 1);
    } else {
      $('.addElement').find('.content-box-inner.draging-started').append(fieldComponent);
    }
    $('div.content-box').removeClass('addElement')
    $('div.content-box').unbind('mouseenter')
    $('div.content-box').unbind('mouseleave')

    this.GlobalService.initializeDragOnEditorFields(field_type, $('.drag-fields[uuid="' + uuid + '"]'));
    if (field_type == 'datepicker-field') {
      this.GlobalService.initializeFlatpicker($('[uuid="' + uuid + '"] input'))
    }
  }


  initContentDragFields() {
    var that = this;
    $('.content-fields-drag').draggable({
      cursorAt: { left: -2, top: -2 },
      helper() {
        if ($(this).attr('field') == 'text-field') {
          return $('<div class="drag-fields"><span class="placeholder-field-label">Full name</span></div>')
        }
        if ($(this).attr('field') == 'checkbox-field') {
          return $('<div class="drag-fields checkbox-field"><div class="drag-fields-inner-container checkbox-field-inner-container"><div class="checkbox-field-box"></div><span class="field-label">Full name</span></div></div>')
        }
        if ($(this).attr('field') == 'datepicker-field') {
          return $('<div class="drag-fields datepicker-field"><span class="placeholder-field-label">Datepicker</span></div>')
        }
        if ($(this).attr('field') == 'signature-field') {
          return $('<div class="drag-fields signature-field"><span class="placeholder-field-label">Signature</span></div>')
        }
        if ($(this).attr('field') == 'droplist-field') {
          return $('<div class="drag-fields droplist-field"><span class="placeholder-field-label">Select option</span></div>')
        }
        if ($(this).attr('field') == 'multichoice-field') {
          return $('<div class="drag-fields multichoice-field multiChoice-radio content-draggable-field"><span class="placeholder-field-label placeholder-multiChoicefield-label">Select Option</span><div class="multiChoice-item-container"><div class="multiChoice-item"><div class="multiChoice-box"><div class="multiChoice-inner-circle"></div></div><label class="multiChoice-label-text">Option 1</label></div><div class="multiChoice-item"><div class="multiChoice-box"><div class="multiChoice-inner-circle"></div></div><label class="multiChoice-label-text">Option 2</label></div></div></div>')
        }
        if ($(this).attr('field') == 'image-field') {
          return $('<div field="image-field" class="content-listItem" style=""><div class="content-item-box"><div class="content-item-box-dragIcon"><svg viewBox="0 0 24 24" role="presentation" width="25" height="25"><path d="M9 7h2v2H9V7zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V7zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" fill-rule="evenodd"></path></svg></div><div class="content-item-box-text"><span>Image Box</span><div class="content-item-svgicon"><svg viewBox="0 0 24 24" role="presentation"><path d="M5 17l5.25-6 3.5 4.003 1.75-2.006L19 17H5zM15.5 7c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5S14 9.325 14 8.5 14.675 7 15.5 7zM2 20h20V4H2v16z" fill-rule="evenodd"></path></svg></div></div></div></div>')
        }
      },
      start() {
        $('.editorTabScroll.ps').addClass('overflow-visible')
        $('.ui-draggable-dragging').addClass('clone-show')
        $('.content-box-inner').addClass('content-box-hovered')
        $(this).css('opacity', '0.5')
        that.GlobalService.initializeHoverOnDragStart({ block: 'content-fields' });
      },
      drag(event, ui) { },
      stop(event, ui) {
        $('.editorTabScroll.ps').removeClass('overflow-visible')
        $('.ui-draggable-dragging').removeClass('clone-show')
        $('.content-box-inner').removeClass('content-box-hovered')
        $(this).css('opacity', '');
        var position = $('.template-page').attr('position');
        var type = $(this).attr('type');
        that.contentFields.addFieldBlock({position:position,type:type}, function(uuid,element){
          if(position == 'right' || position == 'right'){
              that.initSortOnImage($('.content-box-enter').find('.content-box-row'));
          }
          if (type == 'datepicker') {
            that.GlobalService.initializeFlatpicker($('[uuid="' + uuid + '"] input'))
          }else if(type == 'signature'){
            that.GlobalService.initializeSignature(document.getElementById('signaturepad'+uuid),uuid);
          }
        });
        $('div.content-box').unbind('mouseenter')
        $('.dropIndicator-bottom').remove();
        $('.dropIndicator-right').remove();
        $('.dropIndicator-left').remove();
        $('.template-page').removeAttr('position');
        $('.top-cornor,.bottom-cornor,.right-cornor,.left-cornor').unbind("mousemove");
        $('.content-box-inner').removeClass('content-box-hovered');
        that.GlobalService.adjustInnerContentBoxWidthOnAdd();
        that.GlobalService.contentBoxSort();
      },
    });
  }

  initSortOnImage(element){
    $(element).sortable({
        axis: 'x',
        cancel: '.cke_editable, .cke-editor-box'
    })
  }

}
