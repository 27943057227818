import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-two-factor-authentication-addon',
  templateUrl: './two-factor-authentication-addon.component.html',
  styleUrls: ['./two-factor-authentication-addon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class TwoFactorAuthenticationAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
