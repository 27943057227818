import { Injectable } from '@angular/core';
import axios, { AxiosError } from 'axios';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  constructor() { }

  getBrandingData(){
    return axios.get('brands');
  }


  createBrandingData(formData:any){
    return axios.post('brandadd', formData);
  }

}
