import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-control-filters',
  templateUrl: './contract-control-filters.component.html',
  styleUrls: ['./contract-control-filters.component.scss']
})
export class ContractControlFiltersComponent implements OnInit {

  constructor() { }
  public readonly = false;
  public searchable = false;

  chooseAllContract = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  chooseDocType = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  chooseDocCategories = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];


  ngOnInit(): void {
  }

}
