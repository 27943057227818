import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-document-listing',
  templateUrl: './document-listing.component.html',
  styleUrls: ['./document-listing.component.scss']
})
export class DocumentListingComponent implements OnInit {

  // Public

  public contentHeader: object;
  public pageBasic = 1;
  public pageBasicText = 3;
  public pageColor = 4;
  public pagePosition = 3;
  public pageSizes = 3;
  public pageAdvanced = 3;
  public pageAdvancedLink = 3;
  public pageAdvancedEllipses = 7;
  public pageAdvancedNoEllipses = 8;


  // documentFolderViewClick() {
  //   this.router.navigateByUrl('documents/document-folder-view');
  // }
  /**
   * Constructor
   *
   * @param {TodoService} _todoService
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(
    private _coreSidebarService: CoreSidebarService,private router: Router
  ) {}

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Sidebar
   *
   * @param nameRef
   */
  createNewTodo(nameRef, closeNameRef): void {
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
    this._coreSidebarService.getSidebarRegistry(closeNameRef).toggleOpen();
  }

  /**
   * Toggle Sidebar
   *
   * @param nameRef
   */
  toggleSidebar(nameRef): void {
    this._coreSidebarService.getSidebarRegistry(nameRef).toggleOpen();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {
    
  }
}
