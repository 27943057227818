import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { formBuilder } from 'app/main/pages/general-editor-tabs/form-builder/formBuilder';
import flatpickr from "flatpickr";
import SignaturePad from 'signature_pad';
import uuid from 'short-unique-id';
// import * as InlineEditor from '../../assets/ckeditor5/build/ckeditor.js';
declare var $: any;
declare var CKEDITOR: any;
@Injectable({
  providedIn: 'any'
})
export class GlobalService {
  public basePath = 'https://api.simplesign.se/';
  constructor(
    public toastr: ToastrService,
    public formBuilder: formBuilder,
  ) {
    var that = this;
    $(document).off('keyup', '.cke_editable_inline').on('keyup', '.cke_editable_inline', function (e) {
      var instance_name = $(this).attr('instance_name');
      //console.log(CKEDITOR.instances[instance_name].element.getText().replace(/[\n]+/g, ''),$(this).text(),'test');
      if(!$(this).hasClass('converted-to-table')){
        if ($(this).text().length > 0) {
          $(this).removeClass('cke-editor-placeholder');
          //$(CKEDITOR.instances[instance_name].element.$).removeClass('cke-editor-placeholder');
        } else {
          $(this).addClass('cke-editor-placeholder');
          //$(CKEDITOR.instances[instance_name].element.$).addClass('cke-editor-placeholder');
        }
      }
      if (e.target.localName != 'td') {
        that.formBuilder.onKeyDownHandler(instance_name, e);
      }
      if ($(e.target).hasClass('text-tag-editor')) {
        var tagVal = $(e.target).val();
        $(e.target).prev('span.text-tag').text(tagVal);
        if (e.key == 'Enter') {
          $(e.target).addClass('hidden');
          $(e.target).prev('span.text-tag').removeClass('hidden');
        }
      }
    });

  }
  public tags = tags();
  public editorConfig = {
    removePlugins: 'forms,elementspath,contextmenu,liststyle,tabletools,tableselection,magicline',
    extraPlugins: 'mentions',
    toolbarLocation: 'top',
    title: '',
    // magicline_color: '#28c76f',
    // magicline_tabuList:[ 'data-tabu' ],
    enterMode: CKEDITOR.ENTER_DIV,
    toster: this.toastrWarning.bind(this),
    // extraAllowedContent:'div style;*id',
    ignoreEmptyParagraph: false,
    // extraAllowedContent: 'br(*)',
    // editorplaceholder: 'Type / for quick actions',
    toolbar: [
      { name: 'styles', items: ['Format', 'Font'] },
      { name: 'insert', items: ['TextColor', 'BGColor'] },
      // { name: 'styles2', items: ['FontSize', 'tagcombo'] },
      { name: 'clipboard', items: ['Undo', 'Redo'] },
      //{ name: 'editing', items: ['Scayt'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
      // { name: 'links', items: ['Link', 'Unlink'] },
    ],
    allowedContent: true,
    // extraAllowedContent : 'p[id]',
    fillEmptyBlocks: true,
    // protectedSource : [/<script[\s\S]*?<\/script>/gi ], 
    // mentions: [
    //   {
    //     marker: '@',
    //     feed: dataCallback,
    //     minChars: 0,
    //     followingSpace: true,
    //     itemTemplate: '<li class="tags-box" data-id="{id}"><p>{fullName}</p><p>Insert a new<span class="content-builder-menu-inner-label">Tag</span></p></li>',
    //     outputTemplate:  tagsTemplate
    //   },
    // ],
    on: {
      instanceReady: function (e) {
        $(e.editor.element.$).attr('instance_name', e.editor.name);
        // e.editor.focusManager.focus();
        $(e.editor.element.$).parents('.content-box-inner').addClass('active-border');
        //$(e.editor.element.$).parent().parents('.content-box-block').addClass('active-border')
        if (CKEDITOR.instances[e.editor.name].element.getText().replace(/[\n]+/g, '').length > 0) {
          //$(e.editor.element.$).removeClass('cke-editor-placeholder');
        } else {
          //$(e.editor.element.$).addClass('cke-editor-placeholder');
        }
        $(e.editor.element.$).focus()
      },
      blur: function (e) {
        $(e.editor.element.$).parents('.content-box-inner').removeClass('active-border');
        //.parents('.content-box-block').removeClass('active-border');
      },
      focus: function (e) {
        if ($(e.editor.element.$).parent().parents('.content-box-block').outerHeight() < 1122) {
          // $(e.editor.element.$).parent().parents('.content-box-block').addClass('active-border');
          $(e.editor.element.$).parents('.content-box-inner').addClass('active-border');
          $('.form-builder').parent().parents('.content-box-block').removeClass('active-border');
        }
      },
      change: function (event, data) {
        if ($(event.editor.element.$).parent().parents('.content-box-block').outerHeight() > 1122) {
          this.config.toster('If you exceed the content from a page size, then position of fields may disturbed on pdf!');
          $(event.editor.element.$).parents('.content-box-inner').removeClass('active-border');
          $(event.editor.element.$).parents('.content-box-inner').addClass('active-border-red');
          // $(event.editor.element.$).parent().parents('.content-box-block').removeClass('active-border');
          // $(event.editor.element.$).parent().parents('.content-box-block').addClass('active-border-red');
        } else {
          $(event.editor.element.$).parents('.content-box-inner').removeClass('active-border-red');
          $(event.editor.element.$).parents('.content-box-inner').addClass('active-border');
          // $(event.editor.element.$).parent().parents('.content-box-block').removeClass('active-border-red');
          // $(event.editor.element.$).parent().parents('.content-box-block').addClass('active-border');
        }
      }
    }
  }
  // editorConfig = {
  //   // htmlSupport: {
  //   //     allow: [
  //   //         {
  //   //             name: /.*/,
  //   //             attributes: true,
  //   //             classes: false,
  //   //             styles: true
  //   //         }
  //   //     ]
  //   // },
  //   placeholder: 'Type / for quick actions',
  //   allowedContent: true,
  //   enterMode: 2,
  //   toolbar: ['heading', '|', 'FontFamily', 'FontColor', 'FontBackgroundColor', 'FontSize', '|', 'bold', 'Underline', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'Alignment', '|', 'undo', 'redo','timestamp'],
  // }
  public MIN_DISTANCE = 5; // minimum distance to "snap" to a guide
  public guides = []; // no guides available ... 
  public innerOffsetX // we'll use those during drag ... 
  public innerOffsetY // we'll use those during drag ... 
  public signatureObjects = {};
  public fonts = ['Montserrat','Delius','Leckerli One','Rochester','Stalemate','Clicker Script','Caveat','Inspiration','Gloria Hallelujah'];
  initializeDragOnEditorFields(field, element) {
    console.log(field, 'field')
    const that = this
    const elementObj = { parent: String, top: Number, left: Number };
    element.draggable({
      cancel: '.multiChoice-item-container, .droplist-dropdown, .form-builder-table, .guide',
      start(event, ui) {
        $(this).data('preventBehaviour', true);
        that.guides = $.map($(this).parent().children(".draggable").not(this).add($(this).parent()), that.computeGuidesForElementTwo);
        that.innerOffsetX = event.originalEvent.offsetX;
        that.innerOffsetY = event.originalEvent.offsetY;
        $('.fieldsPopover').remove()
        elementObj.parent = $(this).parent();
        elementObj.top = $(this).css('top');
        elementObj.left = $(this).css('left');
        that.InitializeCheckDropElement()
        $('.content-box-inner').addClass('content-box-hovered')
        that.initializeFieldHoverDragStart()
      },
      drag(event, ui) {
        that.guideLines(event, this, ui);
        $(this).css('pointer-events', 'none')
      },
      stop(event, ui) {
        $('div.content-box').unbind('mouseenter')
        $('div.content-box').unbind('mouseleave')
        $('.content-box-inner').removeClass('content-box-hovered')
        $(this).css('pointer-events', '');
        if ($('.addElement').length > 0 && !$('.addElement').hasClass('restricted_box') && !$(this).attr('pdfblock')) {
          let getContentBoxTop = parseInt($('.addElement').offset().top)
          let getContentBoxLeft = parseInt($('.addElement').offset().left)
          getContentBoxTop = ui.offset.top - getContentBoxTop - 1
          getContentBoxLeft = ui.offset.left - getContentBoxLeft - 1
          const field_type = $(this).attr('field')
          const html = $(this).clone()
          html.css('top', `${getContentBoxTop}px`)
          html.css('left', `${getContentBoxLeft}px`)
          $(this).remove();
          $('.addElement').find('.content-box-inner.draging-started').append(html);
          $('div.content-box').removeClass('addElement');
          that.reinitializeDragFunctionOnFields(field_type, $('.drag-fields[uuid="' + $(this).attr('uuid') + '"]'))
        } else if ($(this).attr('pdfblock') == 1 && $('.add-pdf-block').length) {
          let getContentBoxTop = parseInt($('.add-pdf-block').offset().top)
          let getContentBoxLeft = parseInt($('.add-pdf-block').offset().left)
          getContentBoxTop = ui.offset.top - getContentBoxTop - 1
          getContentBoxLeft = ui.offset.left - getContentBoxLeft - 1
          const field_type = $(this).attr('field')
          const html = $(this).clone()
          html.css('top', `${getContentBoxTop}px`)
          html.css('left', `${getContentBoxLeft}px`)
          $(this).remove();
          $('.add-pdf-block').find('.content-box-inner.draging-started').append(html);
          $('div.content-box').removeClass('add-pdf-block');
          that.reinitializeDragFunctionOnFields(field_type, $('.drag-fields[uuid="' + $(this).attr('uuid') + '"]'))
        } else if (!$(this).parent().hasClass('draging-started')) {
          $(this).css('top', elementObj.top)
          $(this).css('left', elementObj.left)
        }

        setTimeout(() => {
          $('.contentBox-popover-container').remove()
        }, 5)
        $("#guide-v, #guide-h, #guide-hb, #guide-vr").hide();
      },
    })

    if (field == 'datepicker-field' || field == 'text-field' || field == 'droplist-field' || field == 'multichoice-field') {
      $('.resize-e').resizable({
        handles: 'e, s',
        // aspectRatio: true,
        // snap: true,
        resize() {
          // $(this).css('height', $(this).height())
        },
      })
    } else if (field == 'signature-field') {
      $('.resize-se').resizable({
        handles: 'se',
        minWidth: 230,
        minHeight: 100,
      })
    } else if (field == 'image-field') {
      $('.resize-se').resizable({
        handles: 'se',
        minWidth: 230,
        minHeight: 160,
      })
    }

  }

  InitializeCheckDropElement() {
    $('.content-box-inner').mousemove(function () {
      $(this).addClass('draging-started')
    })
    $('.content-box-inner').mouseout(function (event) {
      $(this).removeClass('draging-started')
    })
  }
  reinitializeDragFunctionOnFields(field_type, element) {
    console.log(element, 'element');
    this.initializeDragOnEditorFields(field_type, element)
  }
  initializeHoverOnDragStart(params: any = {}) {
    var that = this;
    $('div.content-box').mouseenter(function (e) {
      $('.content-box-enter').removeClass('content-box-enter');
      var indicator = ``;
      // || params.block == 'content-box'
      if ((params.block == 'image-block' || params.block == 'content-box' || params.block == 'content-fields') && (!$(this).hasClass('form-builder-block') && !$(this).hasClass('attachment-block') && !$(this).hasClass('pdf-block') && !$(this).hasClass('page-break-block') && !$(this).hasClass('product-table-block')) ) {
        indicator = `<span position='right' class='dropIndicator-right'></span><span class='dropIndicator-left' position='left'></span>`;
      }
      $(this).append($(`<span class='dropIndicator-bottom' position='bottom'></span>${indicator}`));
      that.initializeZoneIndicator();
      $(this).addClass('content-box-enter');
    })
      .mouseleave(function () {
        $(this).find('.dropIndicator-bottom').remove();
        $(this).find('.dropIndicator-right').remove();
        $(this).find('.dropIndicator-left').remove();
        $('.content-box-enter').removeClass('content-box-enter');
      })
    $('div.template-page').mouseenter(function () {
      $(this).addClass('addContentBox')
    })
      .mouseleave(function () {
        $(this).removeClass('addContentBox')
      })
  }

  initializeFieldHoverDragStart() {
    this.InitializeCheckDropElement()
    $('div.content-box').mouseenter(function () {
      if ($(this).hasClass('content-editor-block')) {
        $(this).addClass('addElement');
      } else if ($(this).hasClass('pdf-block')) {
        $(this).addClass('add-pdf-block');
      }
    })
      .mouseleave(function () {
        $(this).removeClass('addElement');
        $(this).removeClass('add-pdf-block');
      })
  }

  initializeZoneIndicator() {
    var zoneWidth = 40;
    var zone = null, oldZone = null;
    $('.dropIndicator-right, .dropIndicator-left, .dropIndicator-bottom').mousemove(function (e) {
      var position = $(this).attr('position');
      $('.template-page').attr('position', position)
      console.log(position, 'position');
      //   var w = $(this).width();
      //   var h = $(this).height();
      //   var x = e.offsetX;
      //   var y = e.offsetY;
      //   if (x < zoneWidth) zone = "left";
      //   else if (x > w - zoneWidth) zone = "right";
      //   else if (y < zoneWidth) zone = "top";
      //   else if (y > h - zoneWidth) zone = "bottom";
      //   else zone = null;
      //  if (zone != oldZone) console.log(zone,'zone');
      //   oldZone = zone;
    })
  }

  contentBoxSort() {
    var that = this;
    // var fixHelper = function(e, ui) {  
    //   ui.children().each(function() {  
    //   console.log(e);
    //     $(this).width($(this).width());  
    //   });  
    //   return ui;  
    // };
    // $('.content-box-row').sortable({
    //     cursorAt:{top:-2,left:-2},
    //     handle: '.sort-helper',
    //     //helper: fixHelper,
    //     helper: 'clone',
    //     cancel: '.cke_editable',
    //     opacity:1, 
    //     start(){
    //       $(this).hide();
    //       that.initializeHoverOnDragStart({ block: 'content-fields' });
    //     },
    //     sort(){
    //       $('.editorTabScroll.ps').addClass('overflow-visible')
    //       $('.content-box-inner').addClass('content-box-hovered')
    //     },
    //     stop() {
    //       $(this).show();
    //       $('.editorTabScroll.ps').removeClass('overflow-visible')
    //       $('.content-box-inner').removeClass('content-box-hovered')
    //       var position = $('.template-page').attr('position');
    //       console.log(position,'position',$(this));
    //       $('div.content-box').unbind('mouseenter')
    //       $('.dropIndicator-bottom').remove();
    //       $('.dropIndicator-right').remove();
    //       $('.dropIndicator-left').remove();
    //       $('.template-page').removeAttr('position');
    //       $('.top-cornor,.bottom-cornor,.right-cornor,.left-cornor').unbind("mousemove");
    //       $('.content-box-inner').removeClass('content-box-hovered');
    //     }
    // });
    // return;
    $('.template-page').sortable({
      handle: '.sort-helper',
      placeholder: 'ui-state-highlight',
      start() {
        $(this).find('.sort-helper')
        $('.ui-state-highlight').append('<span class="highlightSpanOne"><span class="highlightSpanThree"></span></span>')
        $('.content-box-inner').addClass('content-box-hovered');
        $('.block-action-btn').css('display', 'none');
      },
      sort() {
        $('.ui-sortable-helper').find('.highlightSortContentBox').addClass('highlightSortContentBoxActive')
        $('.ui-sortable-helper').find('.content-box-inner').hide()
      },
      stop() {
        // console.log($(this).html(),'html');
        $('.content-box-inner').removeClass('content-box-hovered')
        $('.highlightSortContentBox').removeClass('highlightSortContentBoxActive')
        $('.content-box-inner').show();
        $('.block-action-btn').css('display', '');
      },
    })
    // .disableSelection();
  }

  guideLines(event, element, ui) {
    // iterate all guides, remember the closest h and v guides
    var leftspace = $(".template-page-container").offset().left - 28;
    var guideV, guideH, distV = this.MIN_DISTANCE + 1, distH = this.MIN_DISTANCE + 1, offsetV, offsetH;
    var chosenGuides = { top: { dist: this.MIN_DISTANCE + 1, guide: { top: 0 }, offset: 0 }, left: { dist: this.MIN_DISTANCE + 1, guide: { left: 0 }, offset: 0 }, right: { dist: this.MIN_DISTANCE + 1, guide: { right: 0 }, offset: 0 }, bottom: { dist: this.MIN_DISTANCE + 1, guide: { bottom: 0 }, offset: 0 } };
    var $t = $(element);
    var scrollTop = 176;
    var setOffset = 0;
    if ($(window).scrollTop() <= 176)
      scrollTop = scrollTop - $(window).scrollTop();
    else
      scrollTop = 0;

    var sectiontop = $t.parent().offset().top;
    if ($(window).scrollTop() > sectiontop)
      setOffset = $(window).scrollTop() - sectiontop;
    else
      scrollTop = (sectiontop - $(window).scrollTop());

    var boxLeft = 0;
    var contentBoxLeft = -25

    if ($($t).parent().hasClass("content-box-inner")) {
      $($t).parents('.content-box').each(function () {
        boxLeft += $(this).position().left + 1;
      });
    }

    var marginlefttemp = $('.template-page').position().left + 52;


    // var getContentInnerBoxTop = parseInt($('.addElement').find('.content-box-inner.draging-started').position().top)
    var getContentInnerBoxLeft = parseInt($($t).parent('.content-box-inner:first').position().left)
    // var getContentInnerMarginTop = parseInt($('.addElement').find('.content-box-inner.draging-started').css('margin-top'))

    var pos = {
      top: (event.originalEvent.pageY - sectiontop) - this.innerOffsetY,
      left: (event.originalEvent.pageX - leftspace - boxLeft - contentBoxLeft - marginlefttemp - getContentInnerBoxLeft) - this.innerOffsetX,
      bottom: event.originalEvent.pageY - this.innerOffsetY,
      right: event.originalEvent.pageX - this.innerOffsetX
    };

    var w = $t.outerWidth() - 1;
    var h = $t.outerHeight() - 1;
    var elemGuides = this.computeGuidesForElementTwo(null, pos, w, h);
    $.each(this.guides, function (i, guide) {
      $.each(elemGuides, function (i, elemGuide) {
        if (guide.type == elemGuide.type) {
          var prop;
          if (guide.type == "h")
            prop = "top";
          else if (guide.type == "v")
            prop = "left";
          else if (guide.type == "hb")
            prop = "bottom";
          else
            prop = "right";
          var d = Math.abs(elemGuide[prop] - guide[prop]);
          if (d < chosenGuides[prop].dist) {
            chosenGuides[prop].dist = d;
            chosenGuides[prop].offset = elemGuide[prop] - pos[prop];
            chosenGuides[prop].guide = guide;
          }
        }
      });
    });

    if (chosenGuides.top.dist <= this.MIN_DISTANCE) {
      $("#guide-h").css("top", (chosenGuides.top.guide.top + scrollTop) - setOffset).show();
      ui.position.top = (chosenGuides.top.guide.top) - chosenGuides.top.offset;
    } else {
      $("#guide-h").hide();
      ui.position.top = pos.top;
    }

    if (chosenGuides.left.dist <= this.MIN_DISTANCE) {
      $("#guide-v").css("left", chosenGuides.left.guide.left + leftspace + boxLeft + contentBoxLeft + marginlefttemp + getContentInnerBoxLeft).show();
      ui.position.left = (chosenGuides.left.guide.left) - chosenGuides.left.offset;
    } else {
      $("#guide-v").hide();
      ui.position.left = pos.left;
    }

    if (chosenGuides.bottom.dist <= this.MIN_DISTANCE) {
      $("#guide-hb").css("top", (chosenGuides.bottom.guide.bottom + scrollTop) - setOffset).show();
    } else {
      $("#guide-hb").hide();
    }

    if (chosenGuides.right.dist <= this.MIN_DISTANCE) {
      $("#guide-vr").css("left", chosenGuides.right.guide.right + leftspace + boxLeft + contentBoxLeft + marginlefttemp + getContentInnerBoxLeft).show();
    } else {
      $("#guide-vr").hide();
    }
  }

  computeGuidesForElementTwo(elem, pos, w, h) {
    if (elem != null) {
      var $t = $(elem);
      pos = $t.position();
      w = $t.outerWidth() - 1;
      h = $t.outerHeight() - 1;
    }

    return [{
      type: "h",
      left: pos.left,
      top: pos.top
    }, {
      type: "h",
      left: pos.left,
      top: pos.top + h
    },

    {
      type: "hb",
      right: pos.left + w,
      bottom: pos.top + h
    }, {
      type: "vr",
      right: pos.left + w,
      bottom: pos.top + h
    },

    {
      type: "v",
      left: pos.left,
      top: pos.top
    }, {
      type: "v",
      left: pos.left + w,
      top: pos.top
    },

    { type: "h", left: pos.left, top: pos.top + (h / 2) },
    { type: "v", left: pos.left + (w / 2), top: pos.top }
    ];
  }

  initEditor(params) {
    if (document.querySelector(`.editor${params.uuid}`)) {
      CKEDITOR.inline(document.querySelector(`.editor${params.uuid}`), this.editorConfig);
    //   InlineEditor.create(document.querySelector(`.editor${params.uuid}`), { htmlSupport: {
    //     allow: [
    //         {
    //             name: /.*/,
    //             attributes: true,
    //             classes: true,
    //             styles: true
    //         }
    //     ]
    // } ,  placeholder: 'Type / for quick actions',
    // allowedContent: true,
    // enterMode: 2,
    // toolbar: ['heading', '|', 'FontFamily', 'FontColor', 'FontBackgroundColor', 'FontSize', '|', 'bold', 'Underline', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'Alignment', '|', 'undo', 'redo','timestamp'],} ).then(editor => {
    //     // Array.from(editor.ui.componentFactory.names())
    //     // editor.ui.getEditableElement().parentElement.insertBefore(
    //     //   editor.ui.view.toolbar.element,
    //     //   editor.ui.getEditableElement(),
    //     // )
    //     editor.setData('')
    //     editor.editing.view.focus()
    //   })
    }
  }

  toastrSuccess(message) {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.success(message, 'Success',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
  }

  toFormData<T>( formValue: T ) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      if(value != null && value != ''){
        formData.append(key, value);
      }
    }
    return formData;
  }

  toastrError(message) {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.error(message, 'Error',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
  }

  toastrWarning(message) {
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.warning(message, 'Warning!',
      {
        toastClass: 'toast ngx-toastr',
        closeButton: true
      });
  }

  initializeFlatpicker(element) {
    if (element.length) {
      flatpickr(element, {
        onChange: function (selectedDates, dateStr, instance) {
          $(instance.input).attr('value', dateStr);
          if ($(instance.input).hasClass('tag-date')) {
            $(instance.input).text(dateStr);
          }
        }
      });
    }
  }

  initializeSignature(element, uuid, reAssigned = [],penColor='#0061ef') {
    var that = this;
    if (element) {
      that.signatureObjects[uuid] = new SignaturePad(element, {
        minDistance: 0,
        minWidth: 0.5,
        maxWidth: 3,
        penColor: penColor,
        backgroundColor: 'rgba(0,0,0,0)',
      });

      that.signatureObjects[uuid].addEventListener("beginStroke", (e) => {
        $(e.detail.target.parentElement).find('span.field-label').css('display', 'none');
      });
      //setTimeout(() => {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      element.width = element.offsetWidth * ratio;
      element.height = element.offsetHeight * ratio;
      element.getContext("2d").scale(ratio, ratio);
      if (that.signatureObjects[uuid].isEmpty()) {
        that.signatureObjects[uuid].clear(); // otherwise isEmpty() might return incorrect value
      }
      if (reAssigned) {
        that.signatureObjects[uuid].fromData(reAssigned, { clear: false });
      }
      //}, 600);
    }
  }

  clearSignature(uuid) {
    this.signatureObjects[uuid].clear();
    $('.signature-field[uuid="' + uuid + '"]').find('span.field-label').css('display', 'block')
  }

  removeSignature(uuid) {
    if (this.signatureObjects.hasOwnProperty(uuid)) {
      delete this.signatureObjects[uuid];
    }
  }

  resizeSignatureBox(element, uuid, data) {
    console.log(element, 'element');
    if (element) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      element.width = element.offsetWidth * ratio;
      element.height = element.offsetHeight * ratio;
      element.getContext("2d").scale(ratio, ratio);
      if (this.signatureObjects[uuid].isEmpty()) {
        this.signatureObjects[uuid].clear(); // otherwise isEmpty() might return incorrect value
      }
      if (data) {
        this.signatureObjects[uuid].fromData(data, { clear: false });
      }
    }
  }

  initializeContentImageResize(element, containment) {
    $(element).resizable({
      // containment:containment,
      minWidth: 60,
      handles: 'ne, se, sw, nw',
    })
  }

  adjustInnerContentBoxWidthOnAdd() {
    var that = this;
    var getInnerContentLength = $('.content-box-enter').find('.content-box-row').children('.rowItem').length;
    var lengthCalc = (100 / getInnerContentLength)
    $('.content-box-enter').find('.content-box-row').children('.rowItem').css('width', lengthCalc + '%');
    this.resizeSignatureContent();
  }
  resizeSignatureContent(element: any = '') {
    var that = this;
    if (element == '') {
      element = $('.content-box-enter');
    }
    console.log(element, 'element');
    element.find('.rowItem').each(function () {
      if ($(this).find('.clear-signature-btn').length) {
        var uuid = $(this).find('.clear-signature-btn').attr('uuid');
        that.resizeSignatureBox(document.querySelector('#signaturepad' + uuid), uuid, that.signatureObjects[uuid].toData());
      }
    })
  }

  initializColumnResizer(element){
    $(element).prev('.JCLRgrips').remove();
    $(element).colResizable({ liveDrag: true,partialRefresh: true});
  }

  generateRandomColor = (opacity = 1) => {
    const red = Math.floor(Math.random() * 255)
    const green = Math.floor(Math.random() * 255)
    const blue = Math.floor(Math.random() * 255)
    return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  }

  getInitials(initial){
    console.log('here');
    const allNames = initial.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }
}

const dataCallback = function (opts, callback) {
  // setTimeout( function() {
  // console.log(this, 'this');
  callback(
    tags().filter(function (item) {
      return item.name.indexOf(opts.query) === 0;
    })
  )
}

const tags = function () {
  return [
    { id: 1, name: 'Text field', fullName: 'Text field', type: 'textfield' },
    { id: 2, name: 'Datepicker', fullName: 'Datepicker', type: 'datepicker' },
    { id: 3, name: 'Droplist', fullName: 'Droplist', type: 'droplist' },
  ];
}

const tagsTemplate = function (item) {
  var shortuuid = new uuid({ length: 6, dictionary: 'number' });
  var tags = '';
  if (item.type == 'textfield') {
    tags = `<span contenteditable="false" data-value="{id}" class="tags has-addons content-draggable-field drag-fields" selected-field="1" field-name="Text Field" field="text-field" uuid="${shortuuid()}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" allow_multi_lines="0" selectedtype="full_name" selectedtypeid="1" selectedforrole="0" tag_view="1" tagname="full_name"><span class="tag is-info"><span class="selected-label" style="color: #0061ef;" type="{type}">[Full name]</span></span><span class="tag is-delete"></span></span>`;
  } else if (item.type == 'datepicker') {
    tags = `<span contenteditable="false" data-value="{id}" class="tags has-addons content-draggable-field drag-fields" selected-field="4" field-name="Datepicker" field="datepicker-field" uuid="${shortuuid()}" field_required="1" field_locked="1" field_fontsize="14" field_style="1" selectedtype="new_field" selectedtypeid="0" selectedforrole="0" tag_view="1" tagname="full_name"><span class="tag is-info"><span class="selected-label" style="color: #0061ef;" type="{type}">[New field]</span></span><span class="tag is-delete"></span></span>`;
  }
  return tags;
}