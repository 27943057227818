import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { ApiService } from 'app/apiService/api.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthLoginV2Component implements OnInit {
  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private apiService:ApiService,
    private toasatr:ToastrService,
    private authenticationService:AuthenticationService,
  ) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get form() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  async onLogin() {
    var redirect = this._route.snapshot.queryParamMap.get('returnUrl');
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    const login = await this.apiService.doLogin(this.loginForm.value);
    this.loading = false;
    if(login.status != 200){
        this.toasatr.error(login.data.message,'Login failed',{ toastClass: 'toast ngx-toastr', closeButton: true });
        return;
    }
    this.authenticationService.saveData('tokenexp',login.data.data.expire_time);
    this.authenticationService.saveData('_token',login.data.data.token);
    this.authenticationService.saveData('user', JSON.stringify(login.data.data));
    setTimeout(() => {
      this._router.navigate([(redirect ? redirect : '/')]);
      this.apiService.initAxios();
    }, 100);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });


    /**
     *  For Development 
     * */ 
    if(localStorage['is_fahad_system'] != undefined && localStorage['is_fahad_system'] != null && localStorage['is_fahad_system'] != ''){
      this.loginForm.patchValue({email: "testingfahad@yopmail.com"});
      this.loginForm.patchValue({password: 'fahadTest1@345'})
    }



    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
