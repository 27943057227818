<div class="table-listing">
    <div class="table-responsive">
        <!-- Hoverable rows -->

        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th></th>
                    <th>Category</th>
                    <th>Start date</th>
                    <th>Company</th>
                    <th>Currency</th>
                    <th>Created on</th>
                    <th>Contract name</th>
                </tr>
            </thead>
            <tbody>
                <tr class="tableDataRow" (click)="contractControlDetailViewClick()">
                    <td>
                        <div class="d-flex align-items-center ng-star-inserted">
                            <div class="avatar bg-light-primary">
                                <span class="avatar-content">SW</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Software
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span class="">2021 Dec 27</span>
                    </td>
                    <td class="">
                        <span>TextPress</span>
                    </td>
                    <td class="">
                        <span>2 Million</span>
                    </td>
                    <td class="">
                        <span>Sep 23 2022 5:17PM</span>
                    </td>
                    <td class="">
                        <span>Demo name</span>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <div class="d-flex align-items-center ng-star-inserted">
                            <div>
                                <img class="rounded-circle mr-1" src="assets/images/portrait/small/avatar-s-10.jpg"
                                    height="35" width="35" alt="datatable-avatar" />
                            </div>

                        </div>
                    </td>
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Software
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span class="">2021 Dec 27</span>
                    </td>
                    <td class="">
                        <span>TextPress</span>
                    </td>
                    <td class="">
                        <span>2 Million</span>
                    </td>
                    <td class="">
                        <span>Sep 23 2022 5:17PM</span>
                    </td>
                    <td class="">
                        <span>Demo name</span>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <div class="d-flex align-items-center ng-star-inserted">
                            <div class="avatar bg-light-secondary">
                                <span class="avatar-content">SW</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Software
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span class="">2021 Dec 27</span>
                    </td>
                    <td class="">
                        <span>TextPress</span>
                    </td>
                    <td class="">
                        <span>2 Million</span>
                    </td>
                    <td class="">
                        <span>Sep 23 2022 5:17PM</span>
                    </td>
                    <td class="">
                        <span>Demo name</span>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <div class="d-flex align-items-center ng-star-inserted">
                            <div>
                                <img class="rounded-circle mr-1" src="assets/images/portrait/small/avatar-s-10.jpg"
                                    height="35" width="35" alt="datatable-avatar" />
                            </div>

                        </div>
                    </td>
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Software
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span class="">2021 Dec 27</span>
                    </td>
                    <td class="">
                        <span>TextPress</span>
                    </td>
                    <td class="">
                        <span>2 Million</span>
                    </td>
                    <td class="">
                        <span>Sep 23 2022 5:17PM</span>
                    </td>
                    <td class="">
                        <span>Demo name</span>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <div class="d-flex align-items-center ng-star-inserted">
                            <div class="avatar bg-light-primary">
                                <span class="avatar-content">SW</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Software
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span class="">2021 Dec 27</span>
                    </td>
                    <td class="">
                        <span>TextPress</span>
                    </td>
                    <td class="">
                        <span>2 Million</span>
                    </td>
                    <td class="">
                        <span>Sep 23 2022 5:17PM</span>
                    </td>
                    <td class="">
                        <span>Demo name</span>
                    </td>
                </tr>
                <tr class="tableDataRow">
                    <td>
                        <div class="d-flex align-items-center ng-star-inserted">
                            <div>
                                <img class="rounded-circle mr-1" src="assets/images/portrait/small/avatar-s-10.jpg"
                                    height="35" width="35" alt="datatable-avatar" />
                            </div>

                        </div>
                    </td>
                    <td>
                        <a href="javascript:void(0);" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                            Software
                        </a>
                        <small placement="top" ngbTooltip="Type"> HR</small>
                    </td>
                    <td>
                        <span class="">2021 Dec 27</span>
                    </td>
                    <td class="">
                        <span>TextPress</span>
                    </td>
                    <td class="">
                        <span>2 Million</span>
                    </td>
                    <td class="">
                        <span>Sep 23 2022 5:17PM</span>
                    </td>
                    <td class="">
                        <span>Demo name</span>
                    </td>
                </tr>

            </tbody>
        </table>
        <ngb-pagination class="d-flex justify-content-end mt-1 mb-1 mr-1" [collectionSize]="30" [(page)]="pageBasic"
            aria-label="Default pagination">
            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [size]="18" [class]="'font-weight-bolder'"></span>
            </ng-template>
            <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'" [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
        </ngb-pagination>
    </div>
</div>