<div class="content-wrapper company-wrapper container-xxl p-0">
    <div class="content-body">
        <form [formGroup]="companyForm" (ngSubmit)="saveCompanySettings()">
            <!-- BCC recipients for signed documents -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">BCC recipients for signed documents</h4>
                                <p class="card-text">When a contract is signed a hidden copy of the verification email will
                                    be sent to below recipients.</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-sm-6 ">
                                        <div class="custom-control custom-switch mb-75">
                                            <label class="mb-0 d-block">Activate</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.bcc_active.value" formControlName="bcc_active" id="BCCactivate" />
                                            <label class="custom-control-label" for="BCCactivate">Activate</label>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-sm-6">
                                        <div class="custom-control custom-switch mb-75">
                                            <label class="mb-0 d-block">PDF verifilabelation</label>
                                            <input type="checkbox" checked class="custom-control-input" [ngModel]="+form.bcc_pdf_varification.value" formControlName="bcc_pdf_varification" id="BCCpdfVerification" />
                                            <label class="custom-control-label" for="BCCpdfVerification">PDF verification</label>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-75">
                                        <div class="form-group mb-0">
                                            <label for="email">Email</label>
                                            <input type="email" class="form-control" id=""  formControlName="bcc_email" placeholder="Enter your Email"  [ngClass]="{ 'is-invalid error': submitted && form.bcc_email.errors }" />
                                            <div *ngIf="submitted && form.bcc_email.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.bcc_email.errors }">
                                                <div *ngIf="form.bcc_email.errors.email">Invalid Email</div>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-75">
                                        <div class="form-group mb-0">
                                            <label for="subject">Subject</label>
                                            <input type="text" class="form-control" id="" formControlName="subject" placeholder="Enter your Subject" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ BCC recipients for signed documents -->
            <!-- Sign with finger/mouse? -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Sign with finger/mouse?</h4>
                                <p class="card-text">You can deactivate to simplify your signing process (when signing box
                                    is not added to your template)</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="custom-control custom-switch">
                                            <label class="mb-0 d-block">Activate</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.finger_sign.value" formControlName="finger_sign" id="SignActivate" />
                                            <label class="custom-control-label" for="SignActivate">Activate</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Sign with finger/mouse? -->
            <!-- Use Full name or First and Last name -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Use Full name or First and Last name</h4>
                                <p class="card-text">You can activate to devide name into two fields instead of one. This
                                    will be shown when entering contacts and recipients when sending documents for
                                    esignature.</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="custom-control custom-switch">
                                            <label class="mb-0 d-block">Full Name</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.name_format.value" formControlName="name_format" id="nameFormat" />
                                            <label class="custom-control-label" for="nameFormat">{{ this.form.name_format.value ? 'First and Last name' : 'Full Name' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Use Full name or First and Last name -->
            <!-- Allow recipient to type their signature -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Allow recipient to type their signature</h4>
                                <p class="card-text">You can deactivate to ensure the recipient actually uses the type
                                    signature</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="custom-control custom-switch">
                                            <label class="mb-0 d-block">Activate</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.signature_type.value" formControlName="signature_type" id="allowRecipient" />
                                            <label class="custom-control-label" for="allowRecipient">Activate</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Allow recipient to type their signature -->
            <!-- Remove PDF(s) from user confirmation email -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Remove PDF(s) from user confirmation emaile</h4>
                                <p class="card-text">You can deactive PDF(s) to user confirmation emails</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="custom-control custom-switch">
                                            <label class="mb-0 d-block">Activate</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.remove_pdf.value" formControlName="remove_pdf" id="removeEmailForPDF" />
                                            <label class="custom-control-label" for="removeEmailForPDF">Activate</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Remove PDF(s) from user confirmation email -->
            <!-- Remove PDF(s) from user confirmation email -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">This feature automatically generates an extra page for
                                    physical signatures. This page is only added when downloading unsigned documents.</h4>
                                <p class="card-text">This feature automatically generates an extra page for physical
                                    signatures. This page is only added when downloading unsigned documents.</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="custom-control custom-switch">
                                            <label class="mb-0 d-block">Activate</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.print_doc.value" formControlName="print_doc" id="addExtrapage" />
                                            <label class="custom-control-label" for="addExtrapage">Activate</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Remove PDF(s) from user confirmation email -->
            <!-- outgoing emails setting -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Do you want to show your name or your company name in all your
                                    outgoing emails?</h4>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Settings</label>
                                            <ng-select [items]="outgoingEmail" bindLabel="name" bindValue="id" formControlName="outgoing_email" [readonly]="readonly"
                                                [searchable]="searchable" placeholder="Please select"> </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ outgoing emails setting -->
            <!-- Set a default email address setting -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Set a default email address which will be visible at every
                                    email footer</h4>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-75">
                                        <div class="custom-control custom-switch">
                                            <label class="mb-0 d-block">Activate</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.active_footer_email.value" formControlName="active_footer_email" id="footerEmailAddress" />
                                            <label class="custom-control-label" for="footerEmailAddress">Activate</label>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-group mb-0">
                                            <label for="WebhookURL">Email</label>
                                            <input type="email" class="form-control" formControlName="footer_email" id="" placeholder="Enter your Email"  [ngClass]="{ 'is-invalid error': submitted && form.footer_email.errors }" />
                                            <div *ngIf="submitted && form.footer_email.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.footer_email.errors }">
                                                <div *ngIf="form.footer_email.errors.email">Invalid Email</div>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Set a default email address setting -->
            <!-- Set a default invitation message -->
            <section class="settingsSections">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Set a default invitation message</h4>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-75">
                                        <div class="custom-control custom-switch">
                                            <label class="mb-0 d-block">Activate</label>
                                            <input type="checkbox" class="custom-control-input" [ngModel]="+form.active_invitation.value" formControlName="active_invitation" id="invitationMessageSwitch" />
                                            <label class="custom-control-label" for="invitationMessageSwitch">Activate</label>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-group mb-0">
                                            <label for="invitationMessage">Invitation message</label>
                                            <fieldset class="form-group mb-0">
                                                <textarea class="form-control" id="invitationMessage" formControlName="invitation_message" rows="2" placeholder="Enter your Invitation message"></textarea>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <!--/ Set a default invitation message -->
            <!-- Set a default invitation message -->
            <section class="settingsSections mb-2">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="card mb-2">
                            <div class="card-body">
                                <h4 class="card-title mb-75">Pricing format setting</h4>
                                <p class="card-text">Select your pricing format for pricing table and document price in
                                    templates</p>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-75">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Amount</label>
                                            <ng-select [items]="chooseprize" bindLabel="label" bindValue="value" (change)="setPricingFormat($event)" formControlName="pricing_format_amount" [readonly]="readonly"
                                                [searchable]="searchable" placeholder="Amount"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-75">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Use currency</label>
                                            <ng-select [items]="chooseCurrency" bindLabel="currency" bindValue="currency_format" formControlName="pricing_format_currency" [readonly]="readonly"
                                                [searchable]="searchable" placeholder="Use currency"> </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12">
                                        <div class="form-group mb-0">
                                            <label for="customSelect">Example</label>
                                            <input type="text" class="form-control" id="" formControlName="pricing_format_example" placeholder="10,000" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mt-2">
                                        <button type="submit" [disabled]="!changesDetect" class="btn btn-success float-right" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--/ Set a default invitation message -->
        </form>
    </div>
</div>