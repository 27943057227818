<div class="card-header">
  <div class="d-flex align-items-center">
    <h4 class="card-title">Latest Activity</h4>
  </div>
</div>
<div class="card-body">
  <ul class="timeline">
    <li class="timeline-item">
      <span class="timeline-point timeline-point-indicator"></span>
      <div class="timeline-event">
        <div
          class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
        >
          <h6>Document Sent 25 days ago by John Doe</h6>
          <div>
            <span class="badge badge-pill badge-light-primary">Signed</span>
          </div>
        </div>
        <p>Signed by Helena Payne 25 days ago</p>
      </div>
    </li>
    <li class="timeline-item">
      <span
        class="timeline-point timeline-point-warning timeline-point-indicator"
      ></span>
      <div class="timeline-event">
        <div
          class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
        >
          <h6>
            Data Processing Agreement Sent about a month ago by Louisa Norton
          </h6>
          <div>
            <span class="badge badge-pill badge-light-warning">Delivered</span>
          </div>
        </div>
        <p>Email delivered to Helena Payne about a month ago</p>
      </div>
    </li>
    <li class="timeline-item">
      <span class="timeline-point timeline-point-indicator"></span>
      <div class="timeline-event">
        <div
          class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
        >
          <h6 class="mb-50">Document Sent 25 days ago by John Doe</h6>
          <div>
            <span class="badge badge-pill badge-light-primary">Signed</span>
          </div>
        </div>
        <p>Signed by Helena Payne 25 days ago</p>
      </div>
    </li>
    <li class="timeline-item">
      <span
        class="timeline-point timeline-point-warning timeline-point-indicator"
      ></span>
      <div class="timeline-event">
        <div
          class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
        >
          <h6>Online Store</h6>
          <div>
            <span class="badge badge-pill badge-light-warning">Delivered</span>
          </div>
        </div>
        <p>Email delivered to Helena Payne about a month ago</p>
      </div>
    </li>
    <li class="timeline-item">
      <span
        class="timeline-point timeline-point-warning timeline-point-indicator"
      ></span>
      <div class="timeline-event">
        <div
          class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
        >
          <h6>
            Data Processing Agreement Sent about a month ago by Louisa Norton
          </h6>
          <div>
            <span class="badge badge-pill badge-light-warning">Delivered</span>
          </div>
        </div>
        <p>Email delivered to Helena Payne about a month ago</p>
      </div>
    </li>
  </ul>
</div>
