import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { OrganizationService } from '../organization.service';
import { FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { GlobalService } from 'app/global/global.service';

@Component({
  selector: 'app-add-organization-sidebar',
  templateUrl: './add-organization-sidebar.component.html',
  styleUrls: ['./add-organization-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  host: { class: 'todo-application' }
})
export class AddOrganizationSidebarComponent implements OnInit {
  
  @Output('fetchOrganization') fetchOrganization : EventEmitter<any> = new EventEmitter();
  public organizationForm: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;
  constructor(
    private _coreSidebarService: CoreSidebarService, 
    private organizationService: OrganizationService,
    public  globalService: GlobalService
    ) { }
  

  ngOnInit(): void {
    this.organizationForm = new FormGroup({
      name : new FormControl('', [Validators.required]),
      register_no: new FormControl('', [Validators.required]),
      phone: new FormControl(''),
      address: new FormControl(''),
      zipcode: new FormControl(''),
      city: new FormControl(''),
      organization_image: new FormControl('')
    });
  }

  
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /** 
  * Convenience getter for easy access to form fields  
  */
  get form() {
    return this.organizationForm.controls;
  }



  /** 
  * Save organization
  */
  async saveOrganization() {
    this.submitted = true;
    if(this.organizationForm.invalid){
      return;
    } 
    this.loading = true;
    const organization = await this.organizationService.addOrganization(this.organizationForm.value);
    if(organization.status != 200){
      this.globalService.toastrError(organization.data.message);
      this.loading = false;
      return;
    }
    this.globalService.toastrSuccess(organization.data.message);
    this.loading = false;
    this.submitted = false;
    this.organizationForm.reset();
    this.toggleSidebar('add-organization-sidebar');
    this.fetchOrganization.emit();
  }


}
