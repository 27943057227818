<div class="signing-sidebarInner">
    <span class="signing-sidebar-closeIcon" (click)="toggleSidebar('signing-right-sidebar')">
        <i data-feather="x" [size]="16" data-dismiss="modal" aria-label="Close"></i>
    </span>
    <div class="rightMenuTopContainer">
        <div class="brandLogoContainer">
            <img src="../../../../assets/images/logo/blueVariantLogoSimplesignSvg.svg">
        </div>

        <div class="contractNameContainer">
            <h4 class="contractNameFirstText">
                Document name
            </h4>
            <p class="contractNameSecondText">
                <span class="countofpages"></span>
            </p>
        </div>
    </div>

    <div class="sidebar-tab-view-wrapper">      
        <ul ngbNav #navJustified="ngbNav" class="nav-tabs nav-justified">
            <li ngbNavItem>
                <a ngbNavLink>
                    <span [data-feather]="'activity'" [size]="15"></span>
                    Activity
                </a>
                <ng-template ngbNavContent>
                    <!-- <div class="sidebar-content-scrollbar" [perfectScrollbar]> -->
                        <div class="recipientChatContainer chat-widget signing-area-activity-tab">
                            <!-- User Chat messages -->
                            <section class="chat-app-window">
                                <div class="sidebar-content-scrollbar activity-tab" [perfectScrollbar]>
                                <div class="user-chats">
                                    <div class="chats">
                                        <div class="chat">
                                            <div class="chatContainer chatContainerStatic">
                                                <h5 class="topHead">
                                                    Asad sent document to Fahad
                                                </h5>
                                                <h2 class="no-span">
                                                    Jun 13 2023 12:15 PM
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="chat">
                                            <div class="chat-avatar">
                                                <span class="avatar box-shadow-1 cursor-pointer">
                                                    <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                        height="36" width="36" />
                                                    <ng-template>
                                                        <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                            alt="avatar" height="36" width="36" />
                                                    </ng-template>
                                                </span>
                                            </div>
                                            <div class="chat-body">
                                                <div class="chat-content">
                                                    <p class="font-small-3">Hey John, I am looking for the best
                                                        admin template.
                                                        Could you please help me to find it out?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat">
                                            <div class="chatContainer chatContainerStatic">
                                                <h5 class="topHead">
                                                    Asad sent document to Fahad
                                                </h5>
                                                <h2 class="no-span">
                                                    Jun 13 2023 12:15 PM
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="chat chat-left">
                                            <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                    <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                    <!---->
                                                    <!----></span></div>
                                            <div class="chat-body">
                                                <div class="chat-content">
                                                    <p class="font-small-3">We need to check if we can provide
                                                        you such information.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat">
                                            <div class="chatContainer chatContainerStatic">
                                                <h5 class="topHead">
                                                    Asad sent document to Fahad
                                                </h5>
                                                <h2 class="no-span">
                                                    Jun 13 2023 12:15 PM
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="chat">
                                            <div class="chat-avatar">
                                                <span class="avatar box-shadow-1 cursor-pointer">
                                                    <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                        height="36" width="36" />
                                                    <ng-template>
                                                        <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                            alt="avatar" height="36" width="36" />
                                                    </ng-template>
                                                </span>
                                            </div>
                                            <div class="chat-body">
                                                <div class="chat-content">
                                                    <p class="font-small-3">Hey John, I am looking for the best
                                                        admin template.
                                                        Could you please help me to find it out?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat">
                                            <div class="chatContainer chatContainerStatic">
                                                <h5 class="topHead">
                                                    Asad sent document to Fahad
                                                </h5>
                                                <h2 class="no-span">
                                                    Jun 13 2023 12:15PM
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="chat chat-left">
                                            <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                    <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                    <!---->
                                                    <!----></span></div>
                                            <div class="chat-body">
                                                <div class="chat-content">
                                                    <p class="font-small-3">We need to check if we can provide
                                                        you such information.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat">
                                            <div class="chat-avatar">
                                                <span class="avatar box-shadow-1 cursor-pointer">
                                                    <img src="assets/images/portrait/small/avatar-s-20.jpg" alt="avatar"
                                                        height="36" width="36" />
                                                    <ng-template>
                                                        <img src="assets/images/portrait/small/avatar-s-20.jpg"
                                                            alt="avatar" height="36" width="36" />
                                                    </ng-template>
                                                </span>
                                            </div>
                                            <div class="chat-body">
                                                <div class="chat-content">
                                                    <p class="font-small-3">Hey John, I am looking for the best
                                                        admin template.
                                                        Could you please help me to find it out?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat chat-left">
                                            <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer">
                                                    <img alt="avatar" height="36" width="36" src="assets/images/portrait/small/avatar-s-20.jpg">
                                                    <!---->
                                                    <!----></span></div>
                                            <div class="chat-body">
                                                <div class="chat-content">
                                                    <p class="font-small-3">We need to check if we can provide
                                                        you such information.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <!-- Submit Chat form -->
                                <form class="chat-app-form justify-content-between">
                                    <div class="input-group input-group-merge mr-50 w-75 form-send-message">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <label for="attach-doc" class="attachment-icon mb-0">
                                                    <i data-feather="image" size="18" class="cursor-pointer lighten-2 text-secondary"></i>
                                                    <input type="file" id="attach-doc" hidden /> 
                                                </label>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control message" placeholder="Type your message" name="chat-message" />
                                    </div>
                                    <button type="button" class="btn btn-primary send" rippleEffect>
                                        <i data-feather="send" class="d-lg-none"></i>
                                        <span class="d-none text-nowrap d-lg-block">Send</span>
                                    </button>
                                </form>
                                <!--/ Submit Chat form -->
                            </section>
                            <!-- User Chat messages -->
                        </div>
                    <!-- </div> -->
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>
                    <span [data-feather]="'users'" [size]="15"></span>
                    Participants
                </a>
                <ng-template ngbNavContent>
                    <div class="sidebar-content-scrollbar participants-tab-content" [perfectScrollbar]>
                        <div class="media-list add-recipients">
                            <div class="media parties-box mb-2 px-1">
                                <a class="media-left" href="javascript:void(0)">
                                    <div class="avatar bg-light-primary">
                                        <div class="avatar-content">KK</div>
                                    </div>
                                </a>
                                <div class="media-body">
                                    <h6 class="font-weight-bold d-block text-nowrap mb-0 sidebar-parties-name"> 
                                        Katherine Kennedy 
                                        <div class="badge badge-light-success">Initiator</div>
                                    </h6>
                                    <small>katherinekennedy@gmail.com</small>
    
                                    <div class="senderContactInfo-btn">
                                        <button type="button" class="btn btn-icon btn-warning">
                                            <span [data-feather]="'phone'" [size]="13"></span>
                                        </button>
                                        <button type="button" class="btn btn-icon btn-warning">
                                            <span [data-feather]="'mail'" [size]="13"></span>
                                        </button>          
                                    </div>
                                </div>
                            </div>
                            <div class="media parties-box mb-2 px-1">
                                <a class="media-left" href="javascript:void(0)">
                                    <div class="avatar bg-light-danger">
                                        <div class="avatar-content">DO</div>
                                    </div>
                                </a>
                                <div class="media-body">
                                    <h6 class="font-weight-bold d-block text-nowrap mb-0 sidebar-parties-name"> 
                                        Dalila Ouldcott
                                        <div class="badge badge-light-secondary">Delivered</div> 
                                    </h6>
                                    <small>dalilaouldcott@gmail.com</small>
                                </div>
                            </div>
                            <div class="media parties-box mb-2 px-1">
                                <a class="media-left" href="javascript:void(0)">
                                    <div class="avatar bg-light-warning">
                                        <div class="avatar-content">MF</div>
                                    </div>
                                </a>
                                <div class="media-body">
                                    <h6 class="font-weight-bold d-block text-nowrap mb-0 sidebar-parties-name"> 
                                        Monica Fuller 
                                        <div class="badge badge-light-secondary">Delivered</div> 
                                    </h6>
                                    <small>monicafuller@gmail.com</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>
                    <span [data-feather]="'file-text'" [size]="15"></span>
                    Documents
                </a>
                <ng-template ngbNavContent>
                    <div class="sidebar-content-scrollbar" [perfectScrollbar]>
                        <div class="media-list document-links">
                            <div class="media links-box mb-2 px-1">
                                <div class="media-body">
                                    <h6 class="font-weight-bold d-block text-nowrap mb-0 sidebar-parties-name"> 
                                        <i [data-feather]="'link'" [size]="13"></i> Document name
                                    </h6>
    
                                    <div class="senderContactInfo-btn">
                                        <button type="button" class="btn btn-icon btn-warning" placement="top"
                                        ngbTooltip="Download PDF" container="body">
                                            <span [data-feather]="'download'" [size]="13"></span>
                                        </button>
                                        <button type="button" class="btn btn-icon btn-warning" placement="top"
                                        ngbTooltip="Print PDF" container="body">
                                            <span [data-feather]="'printer'" [size]="13"></span>
                                        </button>          
                                    </div>
                                </div>
                            </div>
                            <div class="media links-box mb-2 px-1">
                                <div class="media-body">
                                    <div>
                                        <h6 class="font-weight-bold d-block text-nowrap mb-0 sidebar-parties-name"> 
                                            <i [data-feather]="'link'" [size]="13"></i> rbhomeworkoutguide2020-3.pdf
                                        </h6>
                                        <small class="document-link-attachmentText">Uploaded by Asad outlook Shah 333 2023-07-10 09:16</small>
                                    </div>

                                    <div class="senderContactInfo-btn remove-uploadAttachmentBtn">
                                        <button type="button" class="btn btn-icon btn-warning" placement="left"
                                        ngbTooltip="Delete" container="body">
                                            <span [data-feather]="'trash'" [size]="13"></span>
                                        </button>       
                                    </div>
                                </div>
                            </div>
                            <div class="media links-box mb-2 px-1">
                                <div class="media-body">
                                    <div>
                                        <h6 class="font-weight-bold d-block text-nowrap mb-0 sidebar-parties-name"> 
                                            <i [data-feather]="'link'" [size]="13"></i> rbhomeworkoutguide2020-3.pdf
                                        </h6>
                                        <small class="document-link-attachmentText">Uploaded by Asad outlook Shah 333 2023-07-10 09:16</small>
                                    </div>

                                    <div class="senderContactInfo-btn remove-uploadAttachmentBtn">
                                        <button type="button" class="btn btn-icon btn-warning" placement="left"
                                        ngbTooltip="Delete" container="body">
                                            <span [data-feather]="'trash'" [size]="13"></span>
                                        </button>       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn p-0 ml-auto btn-sm d-flex px-1 mt-75 cursor-pointer justify-content-end btn-transparent text-primary signingDocumentlinks-upload-files">Upload files</button>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>
                    <span [data-feather]="'more-horizontal'" [size]="15"></span>
                    More
                </a>
                <ng-template ngbNavContent>
                    <div class="sidebar-content-scrollbar signing-more-tab" [perfectScrollbar]>
                        <div class="media-list add-recipients">
                            <div class="media parties-box mb-2 px-1" (click)="modalOpen(modalInviteToView)">
                                <a class="media-left" href="javascript:void(0)">
                                    <i class="bi bi-envelope-paper"></i>
                                </a>
                                <div class="media-body">
                                    <h6 class="font-weight-bold d-block text-nowrap mb-25 sidebar-parties-name"> 
                                        Invite to view
                                    </h6>
                                    <small>You can invite people to view this document. They will not be able to sign, but their activity will appear on the activity feed.</small>
                                </div>
                            </div>
                            <div class="media parties-box mb-2 px-1" (click)="modalOpenReject(modalRejectContract)">
                                <a class="media-left" href="javascript:void(0)">
                                    <i class="bi bi-exclamation-square"></i>
                                </a>
                                <div class="media-body">
                                    <h6 class="font-weight-bold d-block text-nowrap mb-25 sidebar-parties-name"> 
                                        Reject contract
                                    </h6>
                                    <small>By rejecting the contract you notify the sender that you don't want to participate in this document.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
            <!-- <li ngbNavItem>
                <a ngbNavLink>
                    <span [data-feather]="'bar-chart'" [size]="15"></span>
                    Statistics
                </a>
                <ng-template ngbNavContent>
                    
                </ng-template>
            </li> -->
        </ul>
        <div [ngbNavOutlet]="navJustified"></div>      
    </div>
</div>

<!-- Invite to View Modal -->
<ng-template #modalInviteToView let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Invite to view</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="intiveToView-fields-container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Full Name</label>
                        <input type="text" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Email</label>
                        <input type="email" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Mobile No</label>
                        <input type="number" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Personnr</label>
                        <input type="text" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Address</label>
                        <input type="text" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">City</label>
                        <input type="text" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Zip Code</label>
                        <input type="text" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-75">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Organization</label>
                        <input type="text" class="form-control" id="basicInput" />
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12 mb-0">
                    <div class="form-group intiveToView-fields">
                        <label for="basicInput">Reg No</label>
                        <input type="text" class="form-control" id="basicInput" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>
            Invite to view
        </button>
    </div>
</ng-template>
<!-- / Invite to View Modal -->

<!-- Reject Modal -->
<ng-template #modalRejectContract let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Reject contract</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="reject-contract-container mt-50">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-12">
                    <p class="mb-1 reject-info-text">Are you sure you want to reject this contract?</p>

                    <div class="reject-textarea">
                        <label for="basicTextarea">Comments</label>
                        <fieldset class="form-group">
                            <textarea class="form-control" id="basicTextarea" rows="4"></textarea>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('Accept click')" rippleEffect>
            Reject
        </button>
    </div>
</ng-template>
<!-- / Reject Modal -->