import { Injectable } from '@angular/core';
import feather from 'feather-icons';
import { ToastrService } from 'ngx-toastr';
import uuid from 'short-unique-id';
import Swal from 'sweetalert2'
// import * as InlineEditor from '../../ckCustumBuild/build/ckeditor.js';
import { GlobalService } from 'app/global/global.service';
declare var $: any;
declare var CKEDITOR: any;
@Injectable({
    providedIn: 'root'
})
export class PopOver {
    public uuid = new uuid({ length: 6, dictionary: 'number' });
    constructor(private toastr: ToastrService, private GlobalService: GlobalService) {
        var that = this;
        that.toastr.toastrConfig.preventDuplicates = true;
        $(document).ready(function () {
            $(document).off('click', '.delete-content-box').on('click', '.delete-content-box', function () {
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var instance_name = $('.editor' + uuid).attr('instance_name');//$(this).parents('.contentBox-popover-container').attr('instance_name');
                var boxType = $('.content-box-inner[editor-uuid="' + uuid + '"]').attr('type');
                var parentContainer = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.content-box-row');
                var currenctElement = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.rowItem');
                if (currenctElement.find('.content-box-inner').length > 1) {
                    currenctElement = $('.content-box-inner[editor-uuid="' + uuid + '"]');
                } else if (currenctElement.parents('.content-box').find('.content-box-inner').length == 1) {
                    currenctElement = currenctElement.parents('.content-box');
                }
                that.confirmAlert('Are you sure', `Do you want to delete ${boxType}`, function (result) {
                    if (result.isConfirmed) {
                        var getInnerContentLength = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.content-box .content-box-row').children('.rowItem').length - 1;
                        var lengthCalc = (100 / getInnerContentLength)
                        $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.content-box .content-box-row').children('.rowItem').css('width', lengthCalc + '%');
                        if (CKEDITOR.instances.hasOwnProperty(instance_name)) {
                            CKEDITOR.instances[instance_name].destroy();
                        }
                        currenctElement.remove();
                        let element: HTMLElement = document.querySelector('.closeMenu') as HTMLElement;
                        element?.click();
                        that.GlobalService.resizeSignatureContent(parentContainer);
                    }
                });
                $('.block-action-menu').addClass('hidden');
            });

            $(document).off('click', '.delete-form-builder').on('click', '.delete-form-builder', function () {
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var boxType = $('.content-box-inner[editor-uuid="' + uuid + '"]').attr('type');
                var currenctElement = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.form-builder-block');
                that.confirmAlert('Are you sure', `Do you want to delete ${boxType}`, function (result) {
                    if (result.isConfirmed) {
                        currenctElement.remove();
                        let element: HTMLElement = document.querySelector('.closeMenu') as HTMLElement;
                        element?.click();
                    }
                });
                $('.block-action-menu').addClass('hidden');
            });

            $(document).off('click', '.delete-pdf-block').on('click', '.delete-pdf-block', function () {
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var boxType = $('.content-box-inner[editor-uuid="' + uuid + '"]').attr('type');
                var currenctElement = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.pdf-block');
                that.confirmAlert('Are you sure', `Do you want to delete ${boxType}`, function (result) {
                    if (result.isConfirmed) {
                        currenctElement.remove();
                        let element: HTMLElement = document.querySelector('.closeMenu') as HTMLElement;
                        element?.click();
                    }
                });
                $('.block-action-menu').addClass('hidden');
            });
            $(document).off('click', '.delete_content_box_block').on('click', '.delete_content_box_block', function () {
                var mainContainerBlock = $(this).parents('.content-box-block');
                Swal.fire({
                    title: 'Are you sure?',
                    text: `Do you want to delete content block`,
                    showCancelButton: true,
                    confirmButtonColor: '#0161EF',
                    cancelButtonColor: '#ccc',
                    confirmButtonText: 'Yes, delete'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // if (CKEDITOR.instances.hasOwnProperty(instance_name)) {
                        //     CKEDITOR.instances[instance_name].destroy();
                        // }
                        mainContainerBlock.remove();
                        let element: HTMLElement = document.querySelector('.closeMenu') as HTMLElement;
                        element?.click();
                    }
                })
            })

            $(document).off('click', '.lock-editor').on('click', '.lock-editor', function (e) {
                e.stopPropagation();
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var isLocked = false;
                // var element = $('.content-box-inner[editor-uuid="'+uuid+'"]').parents('.content-box');
                //if(element.find('.content-box-inner').length > 1){
                var element = $('.content-box-inner[editor-uuid="' + uuid + '"]');
                //}
                if (element.find('.editor' + uuid).css('pointer-events') === 'none') {
                    element.removeClass('restricted_box');
                    element.find('.editor' + uuid).css('pointer-events', '');
                    isLocked = false;
                } else {
                    element.find('.editor' + uuid).css('pointer-events', 'none');
                    element.addClass('restricted_box');
                    isLocked = true;
                }
                $(this).find('.lock-editor-text').text(isLocked ? 'Unlock' : 'Lock');
                that.toastr.success((isLocked ? 'Editor is locked' : 'Editor is unlocked'), 'Success!',
                    {
                        positionClass: 'toast-bottom-left',
                        toastClass: 'toast ngx-toastr',
                        closeButton: true
                    });
                $('.block-action-menu').addClass('hidden');
            })
            // duplicate content box
            $(document).off('click', '.duplicate_content_box').on('click', '.duplicate_content_box', function () {
                var customIndex = 0;
                var signaturePoints = [];
                var uuid = that.uuid();
                var currenctUuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var MainContainer = $('.content-box-inner[editor-uuid="' + currenctUuid + '"]').parents('.content-box');
                var innerClone = $('.content-box-inner[editor-uuid="' + currenctUuid + '"]').parents('.rowItem').clone();
                var isConvertToTable = $('.content-box-inner[editor-uuid="' + currenctUuid + '"]').find('.converted-to-table').length;
                //MainContainer.find('.rowItem').length == 1 && MainContainer.find('.rowItem .content-box-inner').length == 1
                console.log(isConvertToTable, 'isConvertToTable');
                if (true) {
                    const standAlone = innerClone;
                    standAlone.find('.cke_editable_inline').parent('.content-box-inner').attr('editor-uuid', uuid)
                    standAlone.find('.cke_editable_inline').attr('class', '');
                    standAlone.find('.block-action-menu ').attr('uuid', uuid);
                    const manipulationMainContainer = MainContainer.clone();
                    manipulationMainContainer.find('.content-box-row').html(standAlone.css('width', '100%'));
                    $(manipulationMainContainer).insertAfter(MainContainer);
                } else {
                    const innerContent = $('.content-box-inner[editor-uuid="' + currenctUuid + '"]');
                    const cloneInnerContent = innerContent.clone();
                    cloneInnerContent.attr('editor-uuid', uuid);
                    cloneInnerContent.find('.cke_editable_inline').attr('class', '');
                    $(innerContent.parent('.rowItem')).append(cloneInnerContent);
                }
                $('.content-box-inner[editor-uuid="' + uuid + '"]').children(':not(.drag-fields)').addClass(`editor${uuid} cke-editor-placeholder cke-editor-box ${isConvertToTable == 1 ? 'converted-to-table' : ''}`).attr('editor-uuid', uuid);
                setTimeout(() => {
                    that.InitializeDuplicateEditor(uuid);
                    that.GlobalService.initializeDragOnEditorFields('drag-fields', $('.content-box-inner[editor-uuid="' + uuid + '"]').find('.drag-fields'));
                    $('.block-action-menu').addClass('hidden');
                    that.GlobalService.initializColumnResizer($("[editor-uuid=" + uuid + "] table"));
                    console.log($("[editor-uuid=" + uuid + "]"), 'cke_editable_inline');
                    if ($(".editor" + uuid).text().length > 0) {
                        $(".editor" + uuid).removeClass('cke-editor-placeholder');
                    }
                }, 100);
                // var mainContainer = $(this).parents('.content-box-block');
                // var clone = mainContainer.clone();
                // setTimeout(() => {
                //         $(clone).insertAfter(mainContainer);
                //         clone.find('.content-box-inner').not('.image-box-inner').each(function () {
                //             var innerUuid = that.uuid();
                //             $(this).attr('editor-uuid', innerUuid);
                //             $(this).find('.cke_editable_inline').attr('class', '');
                //             $(this).children(':not(.drag-fields)').addClass(`editor${innerUuid} cke-editor-placeholder cke-editor-box`).attr('editor-uuid', innerUuid);
                //             that.InitializeDuplicateEditor(innerUuid);
                //             that.GlobalService.initializeDragOnEditorFields('drag-fields', $(this).find('.drag-fields'));
                //         });
                //         clone.find('.image-box-inner').each(function () {
                //             var uuid = that.uuid();
                //             $(this).attr('uuid', uuid);
                //             that.GlobalService.initializeContentImageResize('[uuid="' + uuid + '"] .content-selected-image', '.image-box-inner[uuid="' + uuid + '"]');
                //         });
                // }, 100)
            });

            $(document).off('click', '.duplicate-image-box').on('click', '.duplicate-image-box', function () {
                var uuid = that.uuid();
                var currenctUuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var MainContainer = $('.image-box-inner[uuid="' + currenctUuid + '"]').parents('.content-box');
                var innerClone = $('.image-box-inner[uuid="' + currenctUuid + '"]').parents('.rowItem').clone();
                //MainContainer.find('.rowItem').length == 1 && MainContainer.find('.rowItem .image-box-inner').length == 1
                if (true) {
                    const standAlone = innerClone.clone();
                    $('.content-box-inner').removeClass('active-border');
                    $('.content-selected-image').removeClass('active');
                    standAlone.find('.image-box-inner').attr('uuid', uuid);
                    standAlone.find('.block-action-menu ').attr('uuid', uuid);
                    const manipulationMainContainer = MainContainer.clone();
                    manipulationMainContainer.removeClass('content-editor-block').addClass('image-block').attr('type', 'image');
                    manipulationMainContainer.find('.content-box-row').html(standAlone.css('width', '100%'));
                    $(manipulationMainContainer).insertAfter(MainContainer);
                } else {
                    const innerContent = $('.image-box-inner[uuid="' + currenctUuid + '"]');
                    const cloneInnerContent = innerContent.clone();
                    $('.content-box-inner').removeClass('active-border');
                    $('.content-selected-image').removeClass('active');
                    cloneInnerContent.attr('uuid', uuid);
                    $(cloneInnerContent).insertAfter(innerContent);
                }

                setTimeout(() => {
                    that.GlobalService.initializeContentImageResize('[uuid="' + uuid + '"] .content-selected-image', '.image-box-inner[uuid="' + uuid + '"]');
                    $('.block-action-menu').addClass('hidden');
                }, 100);
            });
            $(document).off('click', '.duplicate_content_boxx').on('click', '.duplicate_content_boxx', function () {
                var customIndex = 0;
                var signaturePoints = [];
                var uuid = that.uuid();
                var currenctUuid = $(this).parents('.contentBox-popover-container, .open-editor-menu').attr('uuid');
                var formBuilder = $('.editor' + $(this).parents('.contentBox-popover-container, .open-editor-menu').attr('uuid')).hasClass('form-builder') ? 'form-builder' : '';
                var MainContainer = $('.content-box-inner[editor-uuid="' + currenctUuid + '"]').parents('.content-box');
                const editorcopy = MainContainer.clone();
                editorcopy.find('.cke_editable_inline' + (formBuilder == '' ? '' : ',.' + formBuilder)).parent('.content-box-inner').attr('editor-uuid', uuid)
                editorcopy.find('.cke_editable_inline' + (formBuilder == '' ? '' : ',.' + formBuilder)).attr('class', '');
                // editorcopy.find('.cke_editable_inline').remove();
                $(editorcopy).insertAfter(MainContainer);
                $('.content-box-inner[editor-uuid="' + uuid + '"]').children(':not(.drag-fields)').addClass(`editor${uuid} cke-editor-placeholder cke-editor-box ${formBuilder}`).attr('editor-uuid', uuid);
                setTimeout(() => {
                    $('.form-builder').parents('.content-box-inner').removeClass('active-border');
                    if (formBuilder == '') {
                        that.InitializeDuplicateEditor(uuid);
                        that.GlobalService.initializeDragOnEditorFields('drag-fields', '');
                        $(editorcopy).find('.image-box-inner').each(function () {
                            var uuid = that.uuid();
                            $(this).attr('uuid', uuid);
                            that.GlobalService.initializeContentImageResize('[uuid="' + uuid + '"] .content-selected-image', '.image-box-inner[uuid="' + uuid + '"]');
                        })
                    } else {
                        for (let index = 0; index < document.querySelectorAll(`.editor${currenctUuid} .form-builder-parent-container .signature-field`).length; index++) {
                            const element = document.querySelectorAll(`.editor${currenctUuid} .form-builder-parent-container .signature-field`)[index];
                            if (that.GlobalService.signatureObjects.hasOwnProperty(element.getAttribute('uuid'))) {
                                signaturePoints.push(that.GlobalService.signatureObjects[element.getAttribute('uuid')].toData());
                                console.log(signaturePoints, 'first');
                            }
                        }
                        $('.content-box-inner[editor-uuid="' + uuid + '"]').addClass('active-border');
                        $('.content-box-inner[editor-uuid="' + uuid + '"]').find('.drag-fields').each(function (i) {
                            $(this).attr('uuid', that.uuid());
                            $(this).find('.clear-signature-btn').attr('uuid', $(this).attr('uuid'));
                            $(this).find('canvas').attr('id', 'signaturepad' + $(this).attr('uuid'));
                            if ($(this).find('.clear-signature-btn').length) {
                                console.log(signaturePoints[customIndex], 'second');
                                that.GlobalService.initializeSignature(document.querySelector('#signaturepad' + $(this).attr('uuid')), $(this).attr('uuid'), signaturePoints[customIndex]);
                                customIndex++;
                            }
                        });
                        that.GlobalService.initializeFlatpicker(document.querySelectorAll('.editor' + uuid + ' .form-builder-parent-container .datepicker-field'));
                    }
                    $('.block-action-menu').addClass('hidden');
                }, 100);
            });

            $(document).off('click', '.page-break-block').on('click', '.page-break-block', function () {
                const element = this
                // $('.pageBreak-popover-container').remove();
                // $(element).append(that.pageBreakPopOver());
                // $('.pageBreak-popover-container').css('top', '0');
            });

            $(document).off('click', '.delete-page-break').on('click', '.delete-page-break', function (event) {
                $(this).parents('.page-break-block').remove();
                // $(event.target.parentElement.parentElement).remove();
            });

            $(document).off('click', '.delete-image-box').on('click', '.delete-image-box', function (event) {
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var parentBox = $('[uuid="' + uuid + '"]').parents('.rowItem ,.attachment-block');
                var parentContainer = $('[uuid="' + uuid + '"]').parents('.content-box-row');
                var getInnerContentLength = $('[uuid="' + uuid + '"]').parents('.content-box-row').children('.rowItem').length - 1;
                var lengthCalc = (100 / getInnerContentLength)
                $('[uuid="' + uuid + '"]').parents('.content-box-row').children('.rowItem').css('width', lengthCalc + '%');
                if (parentBox.find('.content-box-inner').length > 1) {
                    $('[uuid="' + uuid + '"]').remove();
                } else if (parentBox.parents('.content-box').find('.content-box-inner').length == 1) {
                    parentBox.parents('.content-box').remove();
                } else {
                    parentBox.remove();
                }
                $('.contentBox-popover-container').remove();
                that.GlobalService.resizeSignatureContent(parentContainer);
                $('.block-action-menu').addClass('hidden');
            });


            $(document).off('click', '.delete-field-block').on('click', '.delete-field-block', function (event) {
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                that.deleteFieldBlock(uuid);
                $('.contentBox-popover-container').remove();
            });

            $(document).off('click', '.delete-attachment-box').on('click', '.delete-attachment-box', function (event) {
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var parentBox = $('[uuid="' + uuid + '"]').parents('.attachment-block');
                parentBox.remove();
                $('.contentBox-popover-container').remove();
                $('.block-action-menu').addClass('hidden');
            });

            $(document).off('click', '.delete-default-block').on('click', '.delete-default-block', function () {
                var uuid = $(this).parents('.contentBox-popover-container, .block-action-menu').attr('uuid');
                var boxType = $('.content-box-inner[editor-uuid="' + uuid + '"]').attr('type');
                var currenctElement = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.content-box-block');
                that.confirmAlert('Are you sure', `Do you want to delete ${boxType}`, function (result) {
                    if (result.isConfirmed) {
                        currenctElement.remove();
                        let element: HTMLElement = document.querySelector('.closeMenu') as HTMLElement;
                        element?.click();
                    }
                });
                $('.block-action-menu').addClass('hidden');
            });
            $(document).off('click', '.add-content-table-column').on('click', '.add-content-table-column', function () {
                var tableSettigs = $(".selected_column").parents(".general-convert-to-table");
                var borderColor = tableSettigs.attr('border-color');
                var currentColumnsIndex = $(".selected_column").index();
                var last_col_width = $(".selected_column").width();
                var new_width = last_col_width / 2;
                $(".selected_column").parents(".general-convert-to-table").find(".table_headingsRow th:eq(" + currentColumnsIndex + ")").after('<th style="width:' + new_width + 'px;font-weight: normal;border-color:' + borderColor + '"></th>');
                $(".selected_column").parents(".general-convert-to-table").find("tr").not('.table_headingsRow').each(function () {
                    $(this).find("td:eq(" + currentColumnsIndex + ")").after('<td style="font-style: normal;border-color:' + borderColor + '"></td>');
                });
                $('body').trigger('click');
                that.GlobalService.initializColumnResizer(tableSettigs);
            });
            $(document).off('click', '.add-content-table-row').on('click', '.add-content-table-row', function () {
                var tableSettigs = $(".selected_column").parents(".general-convert-to-table");
                var uuid = tableSettigs.attr('uuid');
                var header = tableSettigs.attr('has-header');
                var footer = tableSettigs.attr('has-footer');
                var headerColor = tableSettigs.attr('header-color');
                var footerColor = tableSettigs.attr('secondary-footer-color');
                var evenColor = tableSettigs.attr('even-color');
                var oddColor = tableSettigs.attr('odd-color');
                var clone = $(".selected_column").parents(".general-convert-to-table").find("tr:not(.table_headingsRow):first").clone();
                clone.find('td').text('');
                if ($(".selected_column")[0].localName == 'th') {
                    $(".selected_column").parents(".general-convert-to-table").find('tbody').prepend(clone);
                } else {
                    $(".selected_column").parent("tr").after(clone);
                }
                if (header == "1") {
                    $("[editor-uuid=" + uuid + "] table thead tr").css("background-color", headerColor);
                    $("[editor-uuid=" + uuid + "] table tbody tr:even").css("background-color", evenColor);
                    $("[editor-uuid=" + uuid + "] table tbody tr:odd").css("background-color", oddColor);
                } else {
                    $("[editor-uuid=" + uuid + "] table tr:even").css("background-color", evenColor);
                    $("[editor-uuid=" + uuid + "] table tr:odd").css("background-color", oddColor);
                }
                if (footer == "1") {
                    $("[editor-uuid=" + uuid + "] table tr:last").css("background-color", footerColor);
                }
                $('body').trigger('click');
                that.GlobalService.initializColumnResizer(tableSettigs);
            });
            $(document).off('click', '.delete-content-table-column').on('click', '.delete-content-table-column', function () {
                var tableSettigs = $(".selected_column").parents(".general-convert-to-table");
                var uuid = tableSettigs.attr('uuid');
                var currentColumnsIndex = $(".selected_column").index();
                $(".selected_column").parents(".general-convert-to-table").find("tr").not('.table_headingsRow').each(function () {
                    $(this).find("td:eq(" + currentColumnsIndex + ")").remove();

                    if ($(this).find('td').length == 0) {
                        $(this).remove();
                    }
                });
                $(".selected_column").parents(".general-convert-to-table").find(".table_headingsRow th:eq(" + currentColumnsIndex + ")").remove();
                $('body').trigger('click');
                that.GlobalService.initializColumnResizer($("[editor-uuid=" + uuid + "] table"));
            });
            $(document).off('click', '.delete-content-table-row').on('click', '.delete-content-table-row', function () {
                var tableSettigs = $(".selected_column").parents(".general-convert-to-table");
                var uuid = tableSettigs.attr('uuid');
                var header = tableSettigs.attr('has-header');
                var footer = tableSettigs.attr('has-footer');
                var headerColor = tableSettigs.attr('header-color');
                var footerColor = tableSettigs.attr('secondary-footer-color');
                var evenColor = tableSettigs.attr('even-color');
                var oddColor = tableSettigs.attr('odd-color');
                $(".selected_column").parents('tr').remove();
                $('body').trigger('click');
                if (header == "1") {
                    $("[editor-uuid=" + uuid + "] table thead tr").css("background-color", headerColor);
                    $("[editor-uuid=" + uuid + "] table tbody tr:even").css("background-color", evenColor);
                    $("[editor-uuid=" + uuid + "] table tbody tr:odd").css("background-color", oddColor);
                } else {
                    $("[editor-uuid=" + uuid + "] table tr:even").css("background-color", evenColor);
                    $("[editor-uuid=" + uuid + "] table tr:odd").css("background-color", oddColor);
                }
                if (footer == "1") {
                    $("[editor-uuid=" + uuid + "] table tr:last").css("background-color", footerColor);
                }
                that.GlobalService.initializColumnResizer(tableSettigs);
            });
            $(document).off('click', '.delete-product-table').on('click', '.delete-product-table', function (e) {
                var tableUuid = $(this).parents('.block-action-menu').attr('uuid');
                var boxType = $('table[table-uuid="' + tableUuid + '"]').attr('tablename');
                var currenctElement = $('.content-box-inner[editor-uuid="' + tableUuid + '"]').parents('.product-table-block');
                that.confirmAlert('Are you sure', `Do you want to delete ${boxType}`, function (result) {
                    if (result.isConfirmed) {
                        currenctElement.remove();
                        let element: HTMLElement = document.querySelector('.closeMenu') as HTMLElement;
                        element?.click();
                    }
                });
                $('.block-action-menu').addClass('hidden');
            });
        })
    }
    contentBoxPopover(params: any = {}) {
        var classType = this.classType(params.element, params.target);
        var icon = '';
        if (params.hasOwnProperty('contentType') && params.contentType == 'settings') {
            icon = `<div class="c-tooltip" c-tooltip-title="Settings">${feather.icons.sliders.toSvg({ class: `cursor-pointer ${classType}-settings`, width: 16, height: 16 })}</div>`;
        }
        return `<div class="contentBox-popover-container" uuid="${params.uuid}" instance_name="${params.instance_name}">
            ${icon}
            <div class="c-tooltip" c-tooltip-title="Lock">${feather.icons.slash.toSvg({ class: 'cursor-pointer lock-editor', width: 16, height: 16 })}</div>
            <div class="c-tooltip hidden" c-tooltip-title="Conditional">${feather.icons['check-square'].toSvg({ class: 'cursor-pointer', width: 16, height: 16 })}</div>
            <div class="c-tooltip ${(classType == 'content-box' ? '' : 'hidden')} " c-tooltip-title="Duplicate">${feather.icons['copy'].toSvg({ class: 'cursor-pointer duplicate_content_box', width: 16, height: 16 })}</div>
            <hr class="vertical-line">
            <div class="c-tooltip" c-tooltip-title="Delete">${feather.icons['trash'].toSvg({ class: `cursor-pointer delete-${classType}`, width: 16, height: 16 })}</div>
        </div>`
    }

    imageBoxPopover(params: any = {}) {
        return `<div class="contentBox-popover-container" uuid="${params.uuid}" instance_name="${params.instance_name}">
            <div class="c-tooltip" c-tooltip-title="Upload">${feather.icons.upload.toSvg({ class: `cursor-pointer  upload-${params.type}-modal-setting`, width: 16, height: 16 })}</div>
            <div class="c-tooltip ${(params.type == 'image' ? '' : 'hidden')} " c-tooltip-title="Duplicate">${feather.icons['copy'].toSvg({ class: `cursor-pointer duplicate-${params.type}-box`, width: 16, height: 16 })}</div>
            <hr class="vertical-line">
            <div class="c-tooltip" c-tooltip-title="Delete">${feather.icons['trash'].toSvg({ class: `cursor-pointer delete-${params.type}-box`, width: 16, height: 16 })}</div>
        </div>`;
    }

    InitializeDuplicateEditor(uuid) {
        var that = this;
        that.GlobalService.initEditor({ uuid: uuid });
        this.GlobalService.contentBoxSort();
        $('.content-box-inner[editor-uuid="' + uuid + '"]').find('.drag-fields').each(function () {
            $(this).attr('uuid', that.uuid());
        });
    }

    pageBreakPopOver() {
        return `<div class="pageBreak-popover-container">
            ${feather.icons.trash.toSvg({ class: 'cursor-pointer delete-page-break', width: 16, height: 16 })}
        </div>`
    }

    classType(element, target) {
        var classType = 'default-block';
        if (element.hasClass('form-builder-block')) {
            classType = 'form-builder';
        } else if (element.hasClass('pdf-block')) {
            classType = 'pdf-block';
        } else if ((element.hasClass('content-editor-block') || element.find('.cke_editable_inline').length) && (!target.parents('.content-field-inner').length && !target.hasClass('content-field-inner'))) {
            classType = 'content-box';
        } else if (target.parents('.content-field-inner').length || target.hasClass('content-field-inner')) {
            classType = 'field-block';
        }
        return classType;
    }


    confirmAlert(title: string, message: string, callback: any) {
        var that = this;
        Swal.fire({
            title: title,
            text: message,
            showCancelButton: true,
            confirmButtonColor: '#0161EF',
            cancelButtonColor: '#ccc',
            confirmButtonText: 'Yes, delete'
        }).then((result) => {
            if (callback) {
                callback(result);
            }
        })
    }

    deleteFieldBlock(uuid: any) {
        var that = this;
        var parentBox = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.rowItem');
        var boxType = $('.content-box-inner[editor-uuid="' + uuid + '"]').attr('type');
        this.confirmAlert('Are you sure', `Do you want to delete ${boxType}`, function (result) {
            if (result.isConfirmed) {
                var parentContainer = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.content-box-row');
                var getInnerContentLength = $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.content-box-row').children('.rowItem').length - 1;
                var lengthCalc = (100 / getInnerContentLength)
                $('.content-box-inner[editor-uuid="' + uuid + '"]').parents('.content-box-row').children('.rowItem').css('width', lengthCalc + '%');
                if (parentBox.find('.content-box-inner').length > 1) {
                    $('.content-box-inner[editor-uuid="' + uuid + '"]').remove();
                } else if (parentBox.parents('.content-box').find('.content-box-inner').length == 1) {
                    parentBox.parents('.content-box').remove();
                } else {
                    parentBox.remove();
                }
                let element: HTMLElement = document.querySelector('.closeMenu') as HTMLElement;
                element?.click();
                that.GlobalService.resizeSignatureContent(parentContainer);
            }
        })
    }

    listingPopover(params: any = {}) {
        return `<div class="block-settings">
            <div class="block-action-btn open-editor-menu" uuid="${params.uuid}" classtype="${params.classType}" contenttype="${params.contentType}">
                ${feather.icons['more-vertical'].toSvg({ class: `cursor-pointer`, width: 14, height: 14 })}
            </div>
        </div>`
    }

    appendListingPopover(params: any = {}) {
        var icon = '';
        var contentTableSettings = '';
        var contentBackgroundImage = ``;
        if (typeof params.contentType != 'undefined' && params.hasOwnProperty('contentType') && params.contentType == 'settings') {
            icon = `
                <div class="block-action-menu-list ${params.classType}-settings">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons.sliders.toSvg({ class: `cursor-pointer ${params.classType}-settings`, width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                    <p>Setting</p>
                    </div>
                </div>
                `;
        }
        if (params.classType == 'content-box') {
            contentTableSettings = `
                <div class="block-action-menu-list content-table-settings ${params.element.hasClass('converted-to-table') ? '' : 'hidden'}">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['sliders'].toSvg({ class: 'cursor-pointer content-table-settings', width: 17, height: 17 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Table settings</p>
                    </div>
                </div>`
            contentBackgroundImage = `
                <div class="block-action-menu-list remove-content-background-image ${params.element.parents('.content-editor-block').hasClass('has-background-image') ? '' : 'hidden'}">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['image'].toSvg({ class: 'cursor-pointer remove-content-background-image', width: 17, height: 17 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Remove background image</p>
                    </div>
                </div>`;
        }
        return `<div class="block-action-menu hidden" uuid="${params.uuid}">
                ${icon}
                <div class="block-action-menu-list lock-editor">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons.slash.toSvg({ class: 'cursor-pointer', width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p class="lock-editor-text">Lock</p>
                    </div>
                </div>
                <div class="block-action-menu-list ${(params.classType == 'content-box' ? '' : 'hidden')} duplicate_content_box">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['copy'].toSvg({ class: 'cursor-pointer duplicate_content_box', width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Duplicate</p>
                    </div>
                </div>
                <div class="block-action-menu-list ${(params.classType == 'content-box' ? '' : 'hidden')} convert-to-table" ${params.element.hasClass('converted-to-table') ? 'hidden' : ''}>
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['table'].toSvg({ class: 'cursor-pointer convert-to-table', width: 17, height: 17 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Convert to table</p>
                    </div>
                </div>
                ${contentTableSettings}
                <div class="block-action-menu-list ${(params.classType == 'content-box' ? '' : 'hidden')} content-background-image" ${params.element.parents('.content-editor-block').hasClass('has-background-image') ? 'hidden' : ''}>
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['image'].toSvg({ class: 'cursor-pointer content-background-image', width: 17, height: 17 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Background image</p>
                    </div>
                </div>
                ${contentBackgroundImage}
                <div class="block-action-menu-list delete-${params.classType}">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['trash'].toSvg({ class: `cursor-pointer delete-${params.classType}`, width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Delete</p>
                    </div>
                </div>
            </div>`
    }

    imageBoxListPopover(params: any = {}) {
        return `<div class="block-settings">
            <div class="block-action-btn open-editor-menu other-popover" uuid="${params.uuid}" classtype="${params.type}">
                ${feather.icons['more-vertical'].toSvg({ class: `cursor-pointer`, width: 14, height: 14 })}
            </div>
        </div>`
    }

    appendImageBoxListPopover(params: any = {}){
        return `<div class="block-action-menu hidden" uuid="${params.uuid}">
                <div class="block-action-menu-list upload-${params.type}-modal-setting">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons.upload.toSvg({ class: `cursor-pointer  upload-${params.type}-modal-setting`, width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Upload</p>
                    </div>
                </div>
                <div class="block-action-menu-list ${(params.type == 'image' ? '' : 'hidden')} duplicate-${params.type}-box">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['copy'].toSvg({ class: `cursor-pointer duplicate-${params.type}-box`, width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Duplicate</p>
                    </div>
                </div>
                <div class="block-action-menu-list delete-${params.type}-box">
                    <div class="block-action-menu-list-icon">
                        ${feather.icons['trash'].toSvg({ class: `cursor-pointer delete-${params.type}-box`, width: 16, height: 16 })}
                    </div>
                    <div class="block-action-menu-list-label">
                        <p>Delete</p>
                    </div>
                </div>
            </div>`
    }

    tablePopover(params: any = {}) {
        return `
        <div class="tablePopover" style="position:absolute;top:${params.top}px;left:${params.left}px;">
            <div class="tablePopoverArrow"></div>
            <ul class="twoColUl">
                <li class="cursor-pointer add-content-table-column">${feather.icons['columns'].toSvg({ class: `cursor-pointer`, width: 16, height: 16 })} Add column</li>
                <li class="cursor-pointer add-content-table-row">${feather.icons['minus-square'].toSvg({ class: `cursor-pointer`, width: 16, height: 16 })} Add Row</li>
                <li class="cursor-pointer delete-content-table-column">${feather.icons['columns'].toSvg({ class: `cursor-pointer`, width: 16, height: 16 })} Delete Column</li>
                <li class="cursor-pointer delete-content-table-row">${feather.icons['minus-square'].toSvg({ class: `cursor-pointer`, width: 16, height: 16 })} Delete Row</li>
            </ul>
        </div>
        `
    }
}