<div class="card addonBoxContainer" (click)="modalOpen(autoDelDocSettingModal)">
    <div class="card-body">
        <h5>Automatical deletion of documents</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Small description goes here
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <span [data-feather]="'trash-2'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #autoDelDocSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Automatical deletion of documents</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                This integration collects employee information from timewave.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5">
                            <swiper class="swiper-paginations swiper-container" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets/images/addons-images/no-thumbnail.jpg" alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-4 col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label for="basicInput">Days passed</label>
                                <input type="number" class="form-control" id="Days passed" placeholder="Days passed" />
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12">
                            <div class="form-group mb-1">
                                <label for="customSelect">Document type</label>
                                <ng-select [items]="chooseDocumenttype" bindLabel="name" [readonly]="readonly"
                                    [searchable]="searchable" placeholder="Nothing selected"> </ng-select>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 col-sm-12">
                            <div class="form-group mb-1">
                                <label for="customSelect">Status</label>
                                <ng-select [items]="chooseStatus" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                                    placeholder="Nothing selected"> </ng-select>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="button" class="btn btn-success float-right" rippleEffect>Save</button>
                        </div>

                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->