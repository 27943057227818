<div class="content-wrapper organization-view container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="card mb-2">
                    <div class="p-2">
                        <div class="row">
                            <div class="col-md-5 col-lg-5">
                                <div class="d-flex align-content-center justify-content-between w-100">
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i data-feather="search"></i></span>
                                        </div>
                                        <input (keydown.enter)="fetchOrganization(1,$event.target.value)" type="text" class="form-control" id="email-search" placeholder="Search" aria-label="Search..." aria-describedby="search" />
                                    </div>
                                </div>
                            </div>
                            <div class="m-0 d-flex align-items-center justify-content-end header-section-btn col-sm-12 col-md-6 col-lg-7">
                                <button type="button" class="btn btn-success float-right"
                                    rippleEffect (click)="toggleSidebar('add-organization-sidebar',{},'')">  Add Organization </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-listing">
                        <div class="table-responsive">
                            <app-skeleton *ngIf="loading" [loop]="5" [section]="'default'"></app-skeleton>
                            <!-- Hoverable rows -->
                            <table class="table table-hover table-striped" *ngIf="!loading">
                                <thead>
                                    <tr>
                                        <th>Users</th>
                                        <th>Linked People</th>
                                        <th>Linked Document</th>
                                        <th>Added</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="tableDataRow no-record-found" *ngIf="organizationData.length == 0 && !loading">
                                        <td colspan="4" class="text-center">No records found</td>
                                    </tr>
                                    <tr class="tableDataRow" (click)="toggleSidebar('organization-right-sidebar', organization,i)" *ngFor="let organization of organizationData; let i = index">
                                        <td>
                                            <div class="d-flex align-items-center ng-star-inserted">
                                                <div class="avatar mr-1">
                                                    <span class="avatar-content">{{organization.name | shortName}}</span>
                                                </div>
                                                <div>
                                                    <a href="javascript:void()" class="font-weight-bold d-block text-nowrap text-primary mb-0">
                                                        {{ organization.name }}
                                                    </a>
                                                    <small> {{ organization.register_no }} </small>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{{  organization.recipient.length ?? 0  }}</span>                         
                                        </td>
                                        <td>
                                            <span class="">10</span>
                                        </td>
                                        <td class="">
                                            <span>{{ organization.created_at | dateFormat:'DD-MMM-YYYY hh:mm:ss'}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ngb-pagination class="d-flex justify-content-end mt-1 mr-1" (pageChange)="fetchOrganization($event)" [collectionSize]="pagination.total" [(page)]="page" [maxSize]="3" aria-label="Default pagination">
                                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                        [size]="18" [class]="'font-weight-bolder'"></span>
                                </ng-template>
                                <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'"
                                        [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="add-organization-sidebar" overlayClass="modal-backdrop">
    <app-add-organization-sidebar (fetchOrganization)="fetchOrganization()"></app-add-organization-sidebar>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="organization-right-sidebar" overlayClass="modal-backdrop">
    <app-organization-right-sidebar (fetchOrganization)="fetchOrganization()" (deleteOrganization)="deleteOrganization($event)" (updateListing)="updateListing($event)"></app-organization-right-sidebar>
</core-sidebar>
