import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-groups-and-permissions-addon',
  templateUrl: './groups-and-permissions-addon.component.html',
  styleUrls: ['./groups-and-permissions-addon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class GroupsAndPermissionsAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
