import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'Dashboard',
    type: 'item',
    icon: 'home',
    url: 'dashboard'
  },
  {
    id: 'contacts',
    title: 'Contacts',
    translate: 'Contacts',
    type: 'collapsible',
    icon: 'users',
    children: [
      {
        id: 'Private',
        title: 'Private',
        translate: 'Private',
        type: 'item',
        icon: 'circle',
        url: '/contacts/recipients'
      },
      {
        id: 'Organizations',
        title: 'Organizations',
        translate: 'Organizations',
        type: 'item',
        icon: 'circle',
        url: '/contacts/organizations'
      }
    ]
  },
  {
    id: 'templates',
    title: 'Templates',
    translate: 'Templates',
    type: 'item',
    icon: 'file-text',
    url: 'templates'
  },
  {
    id: 'settings',
    title: 'Settings',
    translate: 'Settings',
    type: 'collapsible',
    icon: 'settings',
    children: [
      {
        id: 'APIsettings',
        title: 'API Settings',
        translate: 'API Settings',
        type: 'item',
        icon: 'circle',
        url: 'settings/apisettings'
      },
      {
        id: 'attachments',
        title: 'Attachments',
        translate: 'Attachments',
        type: 'item',
        icon: 'circle',
        url: 'settings/attachments'
      },
      {
        id: 'branding',
        title: 'Branding',
        translate: 'Branding',
        type: 'item',
        icon: 'circle',
        url: 'settings/branding'
      },
      {
        id: 'bulksend',
        title: 'Bulk Send',
        translate: 'Bulk Send',
        type: 'item',
        icon: 'circle',
        url: 'settings/bulksend'
      },
      {
        id: 'company',
        title: 'Company',
        translate: 'Company',
        type: 'item',
        icon: 'circle',
        url: 'settings/company'
      },
      {
        id: 'contentlibrary',
        title: 'Content Library',
        translate: 'Content Library',
        type: 'item',
        icon: 'circle',
        url: 'settings/contentlibrary'
      },
      {
        id: 'datagroups',
        title: 'Data Groups',
        translate: 'Data Groups',
        type: 'item',
        icon: 'circle',
        url: 'settings/datagroups'
      },
      {
        id: 'documentfields',
        title: 'Document Fields',
        translate: 'Document Fields',
        type: 'item',
        icon: 'circle',
        url: 'settings/documentfields'
      },
      {
        id: 'documenttype',
        title: 'Document Type',
        translate: 'Document Type',
        type: 'item',
        icon: 'circle',
        url: 'settings/documenttype'
      },
      {
        id: 'productlibrary',
        title: 'Product Library',
        translate: 'Product Library',
        type: 'item',
        icon: 'circle',
        url: 'settings/productlibrary'
      },
      {
        id: 'recipientfields',
        title: 'Recipient Fields',
        translate: 'Recipient Fields',
        type: 'item',
        icon: 'circle',
        url: 'settings/recipientfields'
      },
      {
        id: 'microagreement',
        title: 'Micro Agreement',
        translate: 'Micro Agreement',
        type: 'item',
        icon: 'circle',
        url: 'settings/micro-agreement'
      },
      {
        id: 'idcheck',
        title: 'ID Check',
        translate: 'ID Check',
        type: 'item',
        icon: 'circle',
        url: 'settings/idcheck'
      }

    ]
  },
  {
    id: 'Add-Ons',
    title: 'Add-Ons',
    translate: 'Add-Ons',
    type: 'item',
    icon: 'command',
    url: 'addons/addons'
  },
]
