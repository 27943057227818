import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FlatpickrOptions } from 'ng2-flatpickr';


@Component({
  selector: 'app-document-main-sidebar',
  templateUrl: './document-main-sidebar.component.html',
  styleUrls: ['./document-main-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DocumentMainSidebarComponent implements OnInit {
  CaseManagementShowHide: boolean = false;
  filtersShowHide: boolean = false;
  advanceFiltersShowHide: boolean = false;
  public readonly = false;
  public searchable = false;
  public basicDateOptions: FlatpickrOptions = {
    altInput: true
  }

  showDocumentFilter = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  showUsersFilter = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  chooseDocCategories = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys' },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];


  filtersShowHideClick() {
    this.filtersShowHide = !this.filtersShowHide;
  }
  AdvanceFiltersShowHideClick() {
    this.advanceFiltersShowHide = !this.advanceFiltersShowHide;
  }
  CaseManagementShowHideClick() {
    this.CaseManagementShowHide = !this.CaseManagementShowHide;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
