import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recipient-id-attach-field',
  templateUrl: './recipient-id-attach-field.component.html',
  styleUrls: ['./recipient-id-attach-field.component.scss']
})
export class RecipientIdAttachFieldComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
