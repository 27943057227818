import { Injectable } from '@angular/core';
import axios from 'axios';
import { ApiService } from 'app/apiService/api.service';

@Injectable({
  providedIn: 'root'
})
export class RecipientsService {

  constructor(
    private apiService: ApiService,
  ) {
    this.apiService.initAxios();
  }

  /**
   * get all the recipients
   */
  fetchRecipient(page:any,query:any) {
    return axios.get(`recipients?page=${page}&q=${query}`);
  }

  /**
   * add recipient
   * @param formData 
   */
  addRecipient(formData: any) {
    return axios.post('recipientadd', formData);
  }

  /**
   * update recipient
   * @param formData 
   */
  updateRecipient(formData: any) {
    return axios.put('recipientupdate', formData);
  }

  /**
   * delete recipients
   * @param id 
   */
  deleteRecipient(id: any) {
    return axios.delete('recipientdelete?id=' + id);
  }
}