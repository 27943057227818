<div class="content-wrapper documentType-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="card mb-2">
                    <div class="p-2">
                        <div class="row">
                            <div class="col-md-6 col-lg-5">
                                <div class="d-flex align-content-center justify-content-between w-100">
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i data-feather="search"></i></span>
                                        </div>
                                        <input type="text" class="form-control" id="search" placeholder="Search"
                                            aria-label="Search..." aria-describedby="search" />
                                    </div>
                                </div>
                            </div>
                            <div class="m-0 d-flex align-items-center justify-content-end header-section-btn col-sm-12 col-md-6 col-lg-7">
                                <button type="button" class="btn btn-success float-right documentTypeAddlistBtn"
                                    (click)="addDocumentTypeModalOpen(addDocumentTypeModal)" rippleEffect>Add
                                    document type</button>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <!-- Hoverable rows -->
                        <div class="row" id="table-hover-row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="table-responsive">
                                        <!-- Hoverable rows -->
                                        <!-- <app-skeleton *ngIf="!isRecordFetched" [loop]="5" [section]="'document_type'"></app-skeleton> -->
                                        <table class="table table-hover table-striped" *ngIf="!isRecordFetched">
                                            <thead>
                                                <tr>
                                                    <th>Document type</th>
                                                    <th class="align-items-center justify-content-end text-right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="tableDataRow" *ngFor="let data of documentTypes; let i = index">
                                                    <td>
                                                        <div class="custom-control custom-radio">
                                                            <input type="radio" id="customRadio{{ data.id }}" name="customRadio"
                                                                class="custom-control-input" [checked]="data.is_default" />
                                                            <label class="custom-control-label" for="customRadio{{data.id}}">{{ data.document_type }}</label>
                                                        </div>
                                                    </td>
                                                    <td class="overflow-hidden d-flex align-items-center actionTD justify-content-end">
                                                        <div ngbDropdown container="body">
                                                            <button type="button" class="btn btn-sm hide-arrow"
                                                                ngbDropdownToggle data-toggle="dropdown">
                                                                <i data-feather="more-vertical"></i>
                                                            </button>
                                                            <div ngbDropdownMenu>
                                                                <a ngbDropdownItem href="javascript:void(0);" (click)="editDocumentType(data)">
                                                                    <i data-feather="edit-2" class="mr-50"></i>
                                                                    <span>Edit</span>
                                                                </a>
                                                                <a ngbDropdownItem href="javascript:void(0);" (click)="confirmationAlert(data, i)"><i
                                                                        data-feather="trash" class="mr-50"></i>
                                                                    <span>Delete</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ngb-pagination class="d-flex justify-content-end mt-1 mr-1" (pageChange)="getDocumentTypes($event)" [collectionSize]="pagination?.total"
                                            [(page)]="page" [maxSize]="3" aria-label="Default pagination">
                                            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                                    [size]="18" [class]="'font-weight-bolder'"></span>
                                            </ng-template>
                                            <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'"
                                                    [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <ng-template #addDocumentTypeModal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel1">{{ form.id.value ? 'Edit' : 'Add' }} document type</h4>
                <button type="button" class="close" (click)="closeModel()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="documentTypeForm" (ngSubmit)="submitDocumentType()">
                <div class="modal-body" tabindex="0" ngbAutofocus>
                    <label>Document type</label>
                    <div class="form-group">
                        <input type="text" formControlName="document_type" placeholder="Document type" class="form-control" [ngClass]="{ 'is-invalid error': submitted && form.document_type.errors }" />
                        <div *ngIf="submitted && form.document_type.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.document_type.errors }">
                            <div *ngIf="form.document_type.errors.required">Document type is required</div>    
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" (click)="closeModel()"
                        rippleEffect>
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-success" rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{ form.id.value ? 'Update': 'Add' }}
                    </button>
                </div>
            </form>
        </ng-template>
        <!-- / Modal -->
    </div>
</div>