<div class="content-area-wrapper template-view container-xxl p-0">
        <!-- Contract Control Main Sidebar (Filter) -->
        <core-sidebar class="sidebar-left" name="todo-sidebar-main-one" overlayClass="body-content-overlay"
            collapsibleSidebar="bs-gt-lg">
            <!-- Main Sidebar -->
            <div class="main-sidebar sidebar ">
                <div class="sidebar-content todo-sidebar contractControlMainSidebar" [perfectScrollbar]>
                    <div class="todo-app-menu pt-75 pb-75">
                        <div class="add-task py-75">
                            <button type="button" class="btn btn-success btn-block" rippleEffect>
                                Add Contract
                            </button>
                        </div>
                        <div class="sidebar-menu-list">
                            <div class="list-group list-group-filters pr-1" *ngIf="hiddenTableListViewCont">
                                <a class="list-group-item list-group-item-action active">
                                    <i size="18" class="mr-50 feather icon-copy align-middle"></i>
                                    <span class="align-middle">Contract</span>
                                </a>
                                <a class="list-group-item list-group-item-action">
                                    <i size="18" class="mr-50 feather icon-clock align-middle"></i>
                                    <span class="align-middle">Activities</span>
                                </a>
                                <a class="list-group-item list-group-item-action">
                                    <i size="18" class="mr-50 feather icon-check-circle align-middle"></i>
                                    <span class="align-middle">Events</span>
                                </a>
                            </div>
    
                            <div class="list-group list-group-filters pr-1" *ngIf="hiddenChartViewCont">
                                <a class="list-group-item list-group-item-action active">
                                    <i size="18" class="mr-50 feather icon-copy align-middle"></i>
                                    <span class="align-middle">Overview</span>
                                </a>
                                <a class="list-group-item list-group-item-action">
                                    <i size="18" class="mr-50 feather icon-clock align-middle"></i>
                                    <span class="align-middle">Cost</span>
                                </a>
                                <a class="list-group-item list-group-item-action">
                                    <i size="18" class="mr-50 feather icon-check-circle align-middle"></i>
                                    <span class="align-middle">Revenue</span>
                                </a>
                            </div>
    
                            <div class="mt-2 px-2 d-flex justify-content-between">
                                <h6 class="section-label mb-1">Filters</h6>
                                <i class="feather icon-plus cursor-pointer" placement="auto" container="body" ngbTooltip="Show filters"
                                (click)="CC_showHideFiltersClick()"></i>
                            </div>
    
                            <div class="filtersMainContnaier py-0 px-2" *ngIf="CC_showHideFilters">
                                <!-- filter Component  -->
                                <app-contract-control-filters></app-contract-control-filters>
                                <!-- filter Component  -->
                            </div>
    
                            <div class="mt-2 px-2 d-flex justify-content-between">
                                <h6 class="section-label mb-1">Advanced Filter</h6>
                                <i class="feather icon-plus cursor-pointer" placement="auto" container="body" ngbTooltip="Show advanced filters"
                                    (click)="AdvanceFiltersShowHideClick()"></i>
                            </div>
                            <div class="add-more-filters pb-2">
                                <div class="list-group">
                                    <div class="list-group-item d-flex align-items-center" *ngIf="advanceFiltersShowHide">
                                        <div class="list-group">
                                            <div class="list-group-item d-flex align-items-center">
                                                <span id="query-filter-popover" class="list-item-main">
                                                    <span class="mr-75 d-flex align-items-center">
                                                        <i class="" [size]="14" data-feather="user"></i>
                                                    </span>
                                                    <span>
                                                        <span class="filter-text">Full name</span>
                                                    </span>
                                                    <span class="ml-75 m-0 removeFilter cursor-pointer" container="body" placement="auto"
                                                        ngbTooltip="Remove this filter">
                                                        <i class="" [size]="14" data-feather="x"></i>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="list-group-item d-flex align-items-center">
                                                <span id="query-filter-popover" class="list-item-main filterAndCont">
                                                    <span>
                                                        <span class="">and</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="list-group-item d-flex align-items-center">
                                                <span id="query-filter-popover" class="list-item-main">
                                                    <span class="mr-75 d-flex align-items-center">
                                                        <i class="" [size]="14" data-feather="mail"></i>
                                                    </span>
                                                    <span>
                                                        <span class="filter-text">Email</span>
                                                    </span>
                                                    <span class="ml-75 m-0 removeFilter cursor-pointer" container="body" placement="auto"
                                                        ngbTooltip="Remove this filter">
                                                        <i class="" [size]="14" data-feather="x"></i>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="list-group-item d-flex align-items-center">
                                                <span id="query-filter-popover" class="list-item-main filterAndCont">
                                                    <span>
                                                        <span class="">and</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="list-group-item d-flex align-items-center">
                                                <span id="query-filter-popover" class="list-item-main">
                                                    <span class="mr-75 d-flex align-items-center">
                                                        <i class="" [size]="14" data-feather="phone"></i>
                                                    </span>
                                                    <span>
                                                        <span class="filter-text">Phone</span>
                                                    </span>
                                                    <span class="ml-75 m-0 removeFilter cursor-pointer" container="body" placement="auto"
                                                        ngbTooltip="Remove this filter">
                                                        <i class="" [size]="14" data-feather="x"></i>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="list-group-item d-flex align-items-center">
                                                <span id="query-filter-popover" class="list-item-main filterAndCont">
                                                    <span>
                                                        <div ngbDropdown container="body">
                                                            <button type="button" class="btn btn-sm m-0 p-0 hide-arrow"
                                                                container="body" placement="auto" ngbTooltip="Add new filter"
                                                                ngbDropdownToggle data-toggle="dropdown">
                                                                <i class="" [size]="14" data-feather="plus"></i>
                                                            </button>
                                                            <div ngbDropdownMenu class="documentAdvanceFilterDropdown">
                                                                <div class="d-flex align-content-center justify-content-between w-100">
                                                                    <div class="input-group input-group-merge">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i data-feather="search"></i></span>
                                                                        </div>
                                                                        <input type="text" class="form-control" id="search"
                                                                            placeholder="Search people and company data"
                                                                            aria-label="Search..." aria-describedby="search" />
                                                                    </div>
                                                                </div>
                                                                <div class="mt-2 advanceFilterInnerContainer"
                                                                    [perfectScrollbar]>
                                                                    <h5 class="px-25 py-50 m-0 filterTitleText text-muted">PEOPLE
                                                                        DATA</h5>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="user" class="mr-50"></i>
                                                                        <span>Full name</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="user" class="mr-50"></i>
                                                                        <span>Last name</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="mail" class="mr-50"></i>
                                                                        <span>Email</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="phone" class="mr-50"></i>
                                                                        <span>Mobile</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="globe" class="mr-50"></i>
                                                                        <span>City</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="map-pin" class="mr-50"></i>
                                                                        <span>Adress</span>
                                                                    </a>
                                                                    <h5 class="px-25 py-50 m-0 filterTitleText text-muted">Organization
                                                                        data</h5>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="user" class="mr-50"></i>
                                                                        <span>Name</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="globe" class="mr-50"></i>
                                                                        <span>City</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="map-pin" class="mr-50"></i>
                                                                        <span>Adress</span>
                                                                    </a>
                                                                    <h5 class="px-25 py-50 m-0 filterTitleText text-muted">Document
                                                                        data</h5>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="file-text" class="mr-50"></i>
                                                                        <span>Name</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="file-text" class="mr-50"></i>
                                                                        <span>Description</span>
                                                                    </a>
                                                                    <a ngbDropdownItem href="javascript:void(0);">
                                                                        <i data-feather="file-text" class="mr-50"></i>
                                                                        <span>Type</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/ Main Sidebar -->
    
    
        </core-sidebar>
        <!--/ Contract Control Main Sidebar -->
    
    
        <!-- content-right -->
        <div class="content-right">
    
            <!-- Table listing view -->
            <app-table-linsting class="tableListingView" *ngIf="hiddenTableListViewCont">
                <div class="todo-app-list">
                    <div class="app-fixed-search d-flex align-items-center">
                        <div class="sidebar-toggle d-block d-lg-none ml-1">
                            <button class="btn p-0" (click)="toggleSidebar('todo-sidebar-main-one')">
                                <i class="feather icon-menu"></i>
                            </button>
                        </div>
    
                        <div class="d-flex align-content-center justify-content-between w-100">
                            <div class="input-group input-group-merge">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="feather icon-search text-muted"></i></span>
                                </div>
                                <input type="text" class="form-control" id="document-search" placeholder="Search contracts"
                                    aria-label="Search..." aria-describedby="document-search" />
                            </div>
                        </div>
                        <a class="p-0 d-flex align-content-center mr-1 justify-content-center">
                            <i class="feather font-medium-1 icon-pie-chart" (click)="showChartViewCode()"></i>
                        </a>
                        <a class="p-0 d-flex align-content-center mr-75 justify-content-center">
                            <i class="feather font-medium-2 icon-menu" (click)="showTableListViewCode()"></i>
                        </a>
                        <div ngbDropdown>
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow mr-75 d-flex" id="todoActions"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i data-feather="more-vertical" [size]="18" class="text-body"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-3 mr-50 icon-upload-cloud"></i>
                                    Bulk upload</a>
                                <a ngbDropdownItem class="sort-asc">
                                    <i class="feather font-medium-3 mr-50 icon-download"></i>
                                    Export CSV</a>
                            </div>
                        </div>
                    </div>
    
                </div>
    
                <!-- Contract control listing view -->
                <app-contract-control-listing></app-contract-control-listing>
                <!-- Contract control listing view -->
            </app-table-linsting>
            <!-- Table listing view -->
    
            <!-- chart view -->
            <div class="chartViewMainContainer" *ngIf="hiddenChartViewCont">
                <div class="ChartView">
                    <div class="todo-app-list">
                        <div class="app-fixed-search d-flex align-items-center">
                            <div class="sidebar-toggle d-block d-lg-none ml-1">
                                <button class="btn p-0" (click)="toggleSidebar('todo-sidebar-main-one')">
                                    <i class="feather icon-menu"></i>
                                </button>
                            </div>
    
                            <div class="d-flex align-content-center justify-content-between w-100">
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="feather icon-search text-muted"></i></span>
                                    </div>
                                    <input type="text" class="form-control" id="document-search" placeholder="Search contracts"
                                        aria-label="Search..." aria-describedby="document-search" />
                                </div>
                            </div>
                            <a class="p-0 d-flex align-content-center mr-1 justify-content-center">
                                <i class="feather font-medium-1 icon-pie-chart" (click)="showChartViewCode()"></i>
                            </a>
                            <a class="p-0 d-flex align-content-center mr-75 justify-content-center">
                                <i class="feather font-medium-2 icon-menu" (click)="showTableListViewCode()"></i>
                            </a>
                            <div ngbDropdown>
                                <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow mr-75 d-flex" id="todoActions"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i data-feather="more-vertical" [size]="18" class="text-body"></i>
                                </a>
                                <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                                    <a ngbDropdownItem class="sort-asc">
                                        <i class="feather font-medium-3 mr-50 icon-upload-cloud"></i>
                                        Bulk upload</a>
                                    <a ngbDropdownItem class="sort-asc">
                                        <i class="feather font-medium-3 mr-50 icon-download"></i>
                                        Export CSV</a>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <!-- Contract control chart view -->
                    <app-contract-control-chart-view></app-contract-control-chart-view>
                    <!-- Contract control chart view -->
                </div>
            </div>
            <!-- chart view -->
        </div>
        <!--/ content-right -->
    
    </div>