import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import 'jquery-ui-dist/jquery-ui';
import uuid from 'short-unique-id';
import { EditTemplateComponent } from '../../edit-template/edit-template.component';
@Component({
  selector: 'app-content-table-settings',
  templateUrl: './content-table-settings.component.html',
  styleUrls: ['./content-table-settings.component.scss']
})
export class ContentTableSettingsComponent implements OnChanges {
  @Input() data: any[];
  public colorWithHeader = [
    {
      "headerColor": "rgb(189, 189, 189)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(243, 243, 243)",
      "secondaryFooterColor": "rgb(222, 222, 222)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(77, 208, 225)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(224, 247, 250)",
      "secondaryFooterColor": "rgb(162, 232, 241)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(99, 210, 151)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(231, 249, 239)",
      "secondaryFooterColor": "rgb(175, 233, 202)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(247, 203, 77)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(254, 248, 227)",
      "secondaryFooterColor": "rgb(252, 232, 178)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(244, 101, 36)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(255, 230, 221)",
      "secondaryFooterColor": "rgb(255, 204, 188)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(91, 149, 249)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(232, 240, 254)",
      "secondaryFooterColor": "rgb(172, 201, 254)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(38, 166, 154)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(221, 242, 240)",
      "secondaryFooterColor": "rgb(140, 211, 205)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(120, 144, 156)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(235, 239, 241)",
      "secondaryFooterColor": "rgb(187, 200, 206)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(204, 166, 119)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(248, 242, 235)",
      "secondaryFooterColor": "rgb(230, 211, 186)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(139, 195, 74)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(238, 247, 227)",
      "secondaryFooterColor": "rgb(196, 226, 160)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(137, 137, 235)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(232, 231, 252)",
      "secondaryFooterColor": "rgb(196, 195, 247)",
      "footerColor": "rgb(255, 255, 255)"
    },
    {
      "headerColor": "rgb(233, 29, 99)",
      "evenColor": "rgb(255, 255, 255)",
      "oddColor": "rgb(253, 220, 232)",
      "secondaryFooterColor": "rgb(246, 138, 176)",
      "footerColor": "rgb(255, 255, 255)"
    }
  ];
  public colorWithOutHeader = [
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(243, 243, 243)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(243, 243, 243)",
      "secondaryFooterColor": "rgb(222, 222, 222)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(224, 247, 250)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(224, 247, 250)",
      "secondaryFooterColor": "rgb(162, 232, 241)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(231, 249, 239)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(231, 249, 239)",
      "secondaryFooterColor": "rgb(175, 233, 202)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(254, 248, 227)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(254, 248, 227)",
      "secondaryFooterColor": "rgb(252, 232, 178)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(255, 230, 221)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(255, 230, 221)",
      "secondaryFooterColor": "rgb(255, 204, 188)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(232, 240, 254)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(232, 240, 254)",
      "secondaryFooterColor": "rgb(172, 201, 254)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(221, 242, 240)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(221, 242, 240)",
      "secondaryFooterColor": "rgb(140, 211, 205)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(235, 239, 241)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(235, 239, 241)",
      "secondaryFooterColor": "rgb(187, 200, 206)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(248, 242, 235)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(248, 242, 235)",
      "secondaryFooterColor": "rgb(230, 211, 186)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(238, 247, 227)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(238, 247, 227)",
      "secondaryFooterColor": "rgb(196, 226, 160)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(232, 231, 252)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(232, 231, 252)",
      "secondaryFooterColor": "rgb(196, 195, 247)",
    },
    {
      "headerColor": "rgb(255, 255, 255)",
      "evenColor": "rgb(253, 220, 232)",
      "oddColor": "rgb(255, 255, 255)",
      "footerColor": "rgb(253, 220, 232)",
      "secondaryFooterColor": "rgb(246, 138, 176)",
    }
  ];
  public colors = [];
  public header = true;
  public footer = false;
  public headerColor: string = 'rgb(189, 189, 189)';
  public evenColor: string = '#ffffff';
  public oddColor: string = '#f3f3f3';
  public footerColor: string = '#dedede';
  public borderColor: string = '#d3d3d3';
  public textColor: string = '#000000';
  public activatedTheme = 0;
  public borderRadius = false;
  public selectedBorderStyle = 1;
  public borderStyle = [
    { id: 1, name: 'Solid' },
    { id: 2, name: 'Dotted' }
  ];
  tableUUid = 0;
  public currentElement = null;
  constructor(private template: EditTemplateComponent, ) { }

  onInit(): void {
    this.tableUUid = this.data['uuid'];
    this.colors = this.colorWithHeader;
    this.currentElement = $("[editor-uuid=" + this.tableUUid + "] table");
    this.header = this.currentElement.attr('has-header') == "1" ? true : false;
    this.footer = this.currentElement.attr('has-footer') == "1" ? true : false;
    this.activatedTheme = this.currentElement.attr('activated-theme');
    this.selectedBorderStyle = Number(this.currentElement.attr('border-style'));
    this.headerColor = this.currentElement.attr('header-color');
    this.evenColor = this.currentElement.attr('even-color');
    this.oddColor = this.currentElement.attr('odd-color');
    this.footerColor = this.currentElement.attr('footer-color');
    this.borderColor = this.currentElement.attr('border-color');
    this.borderRadius = this.currentElement.attr('border-radius') == "1" ? true : false;
    this.textColor = this.currentElement.attr('text-color');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.data = changes['data'].currentValue;
      this.onInit();
    }
  }

  clsoe() {
    this.template.closeContentTableSettings(false);
  }

  toggleHeaderColor(event) {
    this.header = event.target.checked
    if (event.target.checked) {
      this.colors = this.colorWithHeader;
    } else {
      this.colors = this.colorWithOutHeader;
    }
    $("[editor-uuid=" + this.tableUUid + "] table").attr('has-header', (this.header ? 1 : 0));
    this.changeTableTheme(this.colors[this.activatedTheme]);
  }

  toggleFooterColor(event) {
    this.footer = event.target.checked;
    $("[editor-uuid=" + this.tableUUid + "] table").attr('has-footer', (this.footer ? 1 : 0));
    $("[editor-uuid=" + this.tableUUid + "] table tr:last").toggleClass('table-footer',this.footer);
    this.changeTableTheme(this.colors[this.activatedTheme]);
  }

  selectTheme(options: any, index: any) {
    this.activatedTheme = index;
    this.changeTableTheme(options);
    $("[editor-uuid=" + this.tableUUid + "] table").attr('activated-theme', index);
  }

  changeTableTheme(options) {
    if (this.activatedTheme > -1) {
      if (this.header) {
        $("[editor-uuid=" + this.tableUUid + "] table thead tr").css("background-color", options.headerColor);
        $("[editor-uuid=" + this.tableUUid + "] table tbody tr:even").css("background-color", options.evenColor);
        $("[editor-uuid=" + this.tableUUid + "] table tbody tr:odd").css("background-color", options.oddColor);
        this.headerColor = options.headerColor;
        this.evenColor = options.evenColor;
        this.oddColor = options.oddColor;
        $("[editor-uuid=" + this.tableUUid + "] table").attr({'header-color':options.headerColor,'even-color':options.evenColor,'odd-color':options.oddColor});
      } else {
        $("[editor-uuid=" + this.tableUUid + "] table tr:even").css("background-color", options.oddColor);
        $("[editor-uuid=" + this.tableUUid + "] table tr:odd").css("background-color", options.evenColor);
        this.evenColor = options.oddColor;
        this.oddColor = options.evenColor;
        $("[editor-uuid=" + this.tableUUid + "] table").attr({'even-color':options.oddColor,'odd-color':options.evenColor});
      }

      if (this.footer) {
        $("[editor-uuid=" + this.tableUUid + "] table tr:last").css("background-color", options.secondaryFooterColor);
        this.footerColor = options.secondaryFooterColor;
        $("[editor-uuid=" + this.tableUUid + "] table").attr({'secondary-footer-color':options.secondaryFooterColor});
      }
    } else {
      if (this.header) {
        $("[editor-uuid=" + this.tableUUid + "] table thead tr").css("background-color", this.headerColor);
        $("[editor-uuid=" + this.tableUUid + "] table tbody tr:even").css("background-color", this.evenColor);
        $("[editor-uuid=" + this.tableUUid + "] table tbody tr:odd").css("background-color", this.oddColor);
        $("[editor-uuid=" + this.tableUUid + "] table").attr({'header-color':this.headerColor,'even-color':this.evenColor,'odd-color':this.oddColor});
      } else {
        $("[editor-uuid=" + this.tableUUid + "] table tr:even").css("background-color", this.evenColor);
        $("[editor-uuid=" + this.tableUUid + "] table tr:odd").css("background-color", this.oddColor);
        $("[editor-uuid=" + this.tableUUid + "] table").attr({'even-color':this.evenColor,'odd-color':this.oddColor});
      }
      if (this.footer) {
        $("[editor-uuid=" + this.tableUUid + "] table tr:last").css("background-color", this.footerColor);
        $("[editor-uuid=" + this.tableUUid + "] table").attr({'secondary-footer-color':this.footerColor});
      }
    }
  }

  changeHeaderColor(color: any) {
    this.activatedTheme = -1;
    $("[editor-uuid=" + this.tableUUid + "] table thead tr").css("background-color", color);
    $("[editor-uuid=" + this.tableUUid + "] table").attr({ 'header-color': color, 'activated-theme': '-1' });
  }

  changeEvenColor(color: any) {
    this.activatedTheme = -1;
    $("[editor-uuid=" + this.tableUUid + "] table tbody tr:even").not('.table-footer').css("background-color", color);
    $("[editor-uuid=" + this.tableUUid + "] table").attr({ 'even-color': color, 'activated-theme': '-1' });
  }

  changeOddColor(color: any) {
    this.activatedTheme = -1;
    $("[editor-uuid=" + this.tableUUid + "] table tbody tr:odd").not('.table-footer').css("background-color", color);
    $("[editor-uuid=" + this.tableUUid + "] table").attr({ 'odd-color': color, 'activated-theme': '-1' });
  }

  changeFooterColor(color: any) {
    this.activatedTheme = -1;
    $("[editor-uuid=" + this.tableUUid + "] table tr:last").css("background-color", color);
    $("[editor-uuid=" + this.tableUUid + "] table").attr({ 'footer-color': color, 'activated-theme': '-1' });
    $("[editor-uuid=" + this.tableUUid + "] table").attr({'secondary-footer-color':color});
  }

  changeBorderColor(color: any) {
    $("[editor-uuid=" + this.tableUUid + "] table, [editor-uuid=" + this.tableUUid + "] th, [editor-uuid=" + this.tableUUid + "] td").css('border-color', color);
    $("[editor-uuid=" + this.tableUUid + "] table").attr('border-color', color);
    if (this.borderRadius) {
      $("[editor-uuid=" + this.tableUUid + "] table").css('box-shadow', '0 0 0 1px ' + color);
    }
  }

  changeTextColor(color: any) {
    $("[editor-uuid=" + this.tableUUid + "] table").css('color', color);
    $("[editor-uuid=" + this.tableUUid + "] table").attr('text-color', color);
  }

  changeBorderStyle(style: any) {
    $("[editor-uuid=" + this.tableUUid + "] table").attr('border-style', style.id);
  }

  toggleBorderRadius(event: any) {
    var that = this;
    this.borderRadius = event.target.checked;
    $("[editor-uuid=" + this.tableUUid + "] table").toggleClass('table-border-radius', this.borderRadius);
    $("[editor-uuid=" + this.tableUUid + "] table").attr('border-radius', (this.borderRadius ? '1' : '0'));
    $("[editor-uuid=" + this.tableUUid + "] table").css('box-shadow', (_, attr) => event.target.checked ? '0 0 0 1px ' + that.borderColor : '');
  }

}
