import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-schoolsoft-integration-addon',
  templateUrl: './schoolsoft-integration-addon.component.html',
  styleUrls: ['./schoolsoft-integration-addon.component.scss']
})
export class SchoolsoftIntegrationAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
