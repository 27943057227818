import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { TabsModule } from 'app/main/components/tabs/tabs.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { UsersComponent } from './users.component';
import { UserslistingComponent } from './userslisting/userslisting.component';
import { UsersRightmenuComponent } from './users-rightmenu/users-rightmenu.component';
import { RolesComponent } from './roles/roles.component';
import { GroupsComponent } from './groups/groups.component';


// routing
const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
    data: { animation: 'zoomIn' }
  },
]

@NgModule({
  declarations: [
    UsersComponent,
    UserslistingComponent,
    UsersRightmenuComponent,
    RolesComponent,
    GroupsComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    CoreSidebarModule,
    TabsModule,
    PerfectScrollbarModule,
    NgSelectModule,
    FormsModule,
    ContentHeaderModule
  ],
  exports: [
    GroupsComponent
  ]
})
export class UsersModule { }
