import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})
export class DocumentsComponent implements OnInit {

  hiddenDocumentTableListView: boolean = true;
  hiddenDocumentFolderView: boolean = false;
  hiddenDocumentChartView: boolean = false;

  showFolderViewCode() {
    this.hiddenDocumentTableListView = false;
    this.hiddenDocumentFolderView = true;
    this.hiddenDocumentChartView = false;
    
  }

  showDocumentTableListViewCode() {
    this.hiddenDocumentTableListView = true;
    this.hiddenDocumentFolderView = false;
    this.hiddenDocumentChartView = false;    
  }

  showDocumentChartViewCode() {
    this.hiddenDocumentTableListView = false;
    this.hiddenDocumentFolderView = false;
    this.hiddenDocumentChartView = true;

    
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  constructor(
    private _coreSidebarService: CoreSidebarService,
  ) { 

  }

  ngOnInit(): void {
  }

}
