<div class="content-wrapper microAgreement-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="card mb-2">
                    <div class="p-2">
                        <div class="row">
                            <div class="col-md-6 col-lg-5">
                                <div class="d-flex align-content-center justify-content-between w-100">
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i data-feather="search"></i></span>
                                        </div>
                                        <input type="text" class="form-control" id="email-search" placeholder="Search micro contracts"
                                            aria-label="Search..." aria-describedby="email-search" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="">
                        <!-- Hoverable rows -->
                        <div class="row" id="table-hover-row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="table-responsive">
                                        <!-- Hoverable rows -->
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Recipient</th>
                                                    <th>Email</th>
                                                    <th>Personal no</th>
                                                    <th>Message</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="tableDataRow">
                                                    <td>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <img class="rounded-circle mr-1" src="assets/images/portrait/small/avatar-s-10.jpg"
                                                                height="35" width="35" alt="datatable-avatar" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan8863@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">198101093097</span>
                                                    </td>
                                                    <td>
                                                        <span>XCVSCVVVVVVVVVVVVVVVXXXXXXXXXXXXXXXXXXVVVVVVVVVVVVBBBBBBBBB</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-pill badge-light-danger mr-1">Denied</span>
                                                    </td>
                                                    <td>
                                                        2023 Jan 13 13:15
                                                    </td>

                                                </tr>
                                                <tr class="tableDataRow">
                                                    <td>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <img class="rounded-circle mr-1" src="assets/images/portrait/small/avatar-s-10.jpg"
                                                                height="35" width="35" alt="datatable-avatar" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan8863@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">198101093097</span>
                                                    </td>
                                                    <td>
                                                        <span>XCVSCVVVVVVVVVVVVVVVXXXXXXXXXXXXXXXXXXVVVVVVVVVVVVBBBBBBBBB</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-pill badge-light-danger mr-1">Denied</span>
                                                    </td>
                                                    <td>
                                                        2023 Jan 13 13:15
                                                    </td>

                                                </tr>
                                                <tr class="tableDataRow">
                                                    <td>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <img class="rounded-circle mr-1" src="assets/images/portrait/small/avatar-s-10.jpg"
                                                                height="35" width="35" alt="datatable-avatar" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan8863@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">198101093097</span>
                                                    </td>
                                                    <td>
                                                        <span>XCVSCVVVVVVVVVVVVVVVXXXXXXXXXXXXXXXXXXVVVVVVVVVVVVBBBBBBBBB</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-pill badge-light-danger mr-1">Denied</span>
                                                    </td>
                                                    <td>
                                                        2023 Jan 13 13:15
                                                    </td>

                                                </tr>
                                                <tr class="tableDataRow">
                                                    <td>
                                                        <span class="">Mubashir sami</span>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <img class="rounded-circle mr-1" src="assets/images/portrait/small/avatar-s-10.jpg"
                                                                height="35" width="35" alt="datatable-avatar" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span class="">mubashir.khan8863@gmail.com</span>
                                                    </td>
                                                    <td>
                                                        <span class="">198101093097</span>
                                                    </td>
                                                    <td>
                                                        <span>XCVSCVVVVVVVVVVVVVVVXXXXXXXXXXXXXXXXXXVVVVVVVVVVVVBBBBBBBBB</span>
                                                    </td>
                                                    <td>
                                                        <span class="badge badge-pill badge-light-danger mr-1">Denied</span>
                                                    </td>
                                                    <td>
                                                        2023 Jan 13 13:15
                                                    </td>

                                                </tr>



                                            </tbody>
                                        </table>
                                        <ngb-pagination class="d-flex justify-content-end mt-1 mr-1" [collectionSize]="30"
                                            [(page)]="pageBasic" aria-label="Default pagination">
                                            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                                    [size]="18" [class]="'font-weight-bolder'"></span>
                                            </ng-template>
                                            <ng-template ngbPaginationNext><span [data-feather]="'chevron-right'"
                                                    [size]="18" [class]="'font-weight-bolder'"></span></ng-template>
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>