import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-idcheck',
  templateUrl: './idcheck.component.html',
  styleUrls: ['./idcheck.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IDcheckComponent implements OnInit {
  public pageBasic = 1;
  constructor() { }

  ngOnInit(): void {
  }

}
