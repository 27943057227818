import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bulksend',
  templateUrl: './bulksend.component.html',
  styleUrls: ['./bulksend.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BulksendComponent implements OnInit {

  public pageBasic = 1;

  // modal Open Form
  buksendaddListModalOpen(buksendaddListModal) {
    this.modalService.open(buksendaddListModal);
  }

  buksendaddInnerview(event) {
    if(event.target.nodeName != 'svg'){
    this.router.navigateByUrl('settings/bulksend/listing');
    // event.preventDefault();
    event.stopPropagation();
    }
    return;
  }

  // preventdefultClick() {
  //   this.router.navigateByUrl('settings/bulksend-inner-view');
  //   event.preventDefault();
  //   return;
  // }
  


 
  // @param {NgbModal} modalService;
  constructor(private modalService: NgbModal, private router: Router) { }


  ngOnInit(): void {
    // breadcrumb default
    
  }

}
