<div class="card addonBoxContainer">
    <div class="card-body">
        <h5>Document price</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            This Add-on will allow user to calculate and track sum of Product Table in the Templates or set a custom amount
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <img src="assets/images/addons-images/document_price_addon_icon.png" class="addonThumbnailImg" alt="addonThumbnail" />
        <!-- <span [data-feather]="'save'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span> -->
    </div>
</div>
