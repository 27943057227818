import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-custom-smsinvitation-addon',
  templateUrl: './custom-smsinvitation-addon.component.html',
  styleUrls: ['./custom-smsinvitation-addon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  
})
export class CustomSMSInvitationAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
