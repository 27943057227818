<!-- Main Sidebar -->
<div class="main-sidebar sidebar documentMainSideBar">
  <div class="sidebar-content todo-sidebar">
    <div class="todo-app-menu pt-75 pb-75 documentMainSideBarScroll" [perfectScrollbar]>
      <div class="sidebar-menu-list">
        <div class="list-group list-group-filters">
          <a class="list-group-item list-group-item-action active">
            <i size="18" class="mr-50 feather icon-copy align-middle"></i>
            <span class="align-middle">All</span>
          </a>
          <a class="list-group-item list-group-item-action">
            <i size="18" class="mr-50 feather icon-clock align-middle"></i>
            <span class="align-middle">Pending</span>
          </a>
          <a class="list-group-item list-group-item-action">
            <i size="18" class="mr-50 feather icon-check-circle align-middle"></i>
            <span class="align-middle">Accepted</span>
          </a>
          <a class="list-group-item list-group-item-action">
            <i size="18" class="mr-50 feather icon-x-circle align-middle"></i>
            <span class="align-middle">Lost</span>
          </a>
          <a class="list-group-item list-group-item-action">
            <i size="18" class="mr-50 feather icon-edit-2 align-middle"></i>
            <span class="align-middle">Drafts</span>
          </a>
        </div>

        <!-- Case Management -->
        <div class="mt-2 px-2 d-flex justify-content-between">
          <h6 class="section-label mb-1">Case Management</h6>
          <i class="feather icon-plus cursor-pointer" placement="auto" container="body" ngbTooltip="Show Case management"
            (click)="CaseManagementShowHideClick()"></i>
        </div>
        <div class="list-group list-group-filters" *ngIf="CaseManagementShowHide">
          <a class="list-group-item list-group-item-action">
            <i size="18" class="mr-50 feather icon-clock align-middle"></i>
            <span class="align-middle">Pending approval</span>
            <span class="badge badge-pill badge-light-primary float-right">5</span>
          </a>
          <a class="list-group-item list-group-item-action">
            <i size="18" class="mr-50 feather icon-check align-middle"></i>
            <span class="align-middle">Completed</span>
          </a>
        </div>
        <!-- Case Management -->

        <!-- Filters -->
        <div class="mt-2 px-2 d-flex justify-content-between">
          <h6 class="section-label mb-1">Filters</h6>
          <i class="feather icon-plus cursor-pointer" placement="auto" container="body" ngbTooltip="Show filters"
            (click)="filtersShowHideClick()"></i>
        </div>
        <div class="list-group list-group-filters" *ngIf="filtersShowHide">
          <div class="list-group-item list-group-item-action mb-0">
            <label for="customSelect">From</label>
            <div class="">
              <ng2-flatpickr placeholder="No date select" [ngClass]="'firstsecond'" [config]="basicDateOptions" name="basicDate"></ng2-flatpickr>
            </div>
          </div>
          <div class="list-group-item list-group-item-action mb-0">
            <label for="customSelect">To</label>
            <div class="">
              <ng2-flatpickr placeholder="No date select" [ngClass]="'firstsecond'" [config]="basicDateOptions" name="basicDate"></ng2-flatpickr>
            </div>
          </div>
          <div class="dropdown-divider mx-1"></div>
          <div class="list-group-item list-group-item-action mb-0 pt-0">
            <label>Show document types</label>

            <div class="form-group mb-0">
              <!-- Basic Multi select -->
              <ng-select #SelectDocTypeSR [items]="showDocumentFilter" bindLabel="name" [readonly]="readonly"
                [searchable]="false" placeholder="Please select">
                <ng-template ng-header-tmp>
                  <input class="form-control w-100" type="text" (input)="SelectDocTypeSR.filter($event.target.value)" />
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="list-group-item list-group-item-action mb-0">
            <label>Show users</label>

            <div class="form-group mb-0">
              <ng-select #SelectDocTypeSR [items]="showUsersFilter" bindLabel="name" [readonly]="readonly" [searchable]="false"
                placeholder="Please select">
                <ng-template ng-header-tmp>
                  <input class="form-control w-100" type="text" (input)="SelectDocTypeSR.filter($event.target.value)" />
                </ng-template>
              </ng-select>
            </div>
          </div>

        </div>
        <!-- Filters -->

        <!--Advance Filters -->
        <div class="mt-2 px-2 d-flex justify-content-between">
          <h6 class="section-label mb-1">Advanced Filter</h6>
          <i class="feather icon-plus cursor-pointer" placement="auto" container="body" ngbTooltip="Show advanced filters"
            (click)="AdvanceFiltersShowHideClick()"></i>
        </div>
        <div class="add-more-filters">
          <div class="list-group">
            <div class="list-group-item d-flex align-items-center" *ngIf="advanceFiltersShowHide">
              <div class="list-group">
                <div class="list-group-item d-flex align-items-center">
                  <span id="query-filter-popover" class="list-item-main">
                    <span class="mr-75 d-flex align-items-center">
                      <i class="" [size]="14" data-feather="user"></i>
                    </span>
                    <span>
                      <span class="filter-text">Full name</span>
                    </span>
                    <span class="ml-75 m-0 removeFilter cursor-pointer" container="body" placement="auto" ngbTooltip="Remove this filter">
                      <i class="" [size]="14" data-feather="x"></i>
                    </span>
                  </span>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <span id="query-filter-popover" class="list-item-main filterAndCont">
                    <span>
                      <span class="">and</span>
                    </span>
                  </span>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <span id="query-filter-popover" class="list-item-main">
                    <span class="mr-75 d-flex align-items-center">
                      <i class="" [size]="14" data-feather="mail"></i>
                    </span>
                    <span>
                      <span class="filter-text">Email</span>
                    </span>
                    <span class="ml-75 m-0 removeFilter cursor-pointer" container="body" placement="auto" ngbTooltip="Remove this filter">
                      <i class="" [size]="14" data-feather="x"></i>
                    </span>
                  </span>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <span id="query-filter-popover" class="list-item-main filterAndCont">
                    <span>
                      <span class="">and</span>
                    </span>
                  </span>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <span id="query-filter-popover" class="list-item-main">
                    <span class="mr-75 d-flex align-items-center">
                      <i class="" [size]="14" data-feather="phone"></i>
                    </span>
                    <span>
                      <span class="filter-text">Phone</span>
                    </span>
                    <span class="ml-75 m-0 removeFilter cursor-pointer" container="body" placement="auto" ngbTooltip="Remove this filter">
                      <i class="" [size]="14" data-feather="x"></i>
                    </span>
                  </span>
                </div>
                <div class="list-group-item d-flex align-items-center">
                  <span id="query-filter-popover" class="list-item-main filterAndCont">
                    <span>
                      <div ngbDropdown container="body">
                        <button type="button" class="btn btn-sm m-0 p-0 hide-arrow" container="body" placement="auto"
                          ngbTooltip="Add new filter" ngbDropdownToggle data-toggle="dropdown">
                          <i class="" [size]="14" data-feather="plus"></i>
                        </button>
                        <div ngbDropdownMenu class="documentAdvanceFilterDropdown">
                          <div class="d-flex align-content-center justify-content-between w-100">
                            <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                                <span class="input-group-text"><i data-feather="search"></i></span>
                              </div>
                              <input type="text" class="form-control" id="search" placeholder="Search people and company data"
                                aria-label="Search..." aria-describedby="search" />
                            </div>
                          </div>
                          <div class="mt-2 advanceFilterInnerContainer" [perfectScrollbar]>
                            <h5 class="px-25 py-50 m-0 filterTitleText text-muted">PEOPLE DATA</h5>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="user" class="mr-50"></i>
                              <span>Full name</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="user" class="mr-50"></i>
                              <span>Last name</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="mail" class="mr-50"></i>
                              <span>Email</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="phone" class="mr-50"></i>
                              <span>Mobile</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="globe" class="mr-50"></i>
                              <span>City</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="map-pin" class="mr-50"></i>
                              <span>Adress</span>
                            </a>
                            <h5 class="px-25 py-50 m-0 filterTitleText text-muted">Organization data</h5>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="user" class="mr-50"></i>
                              <span>Name</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="globe" class="mr-50"></i>
                              <span>City</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="map-pin" class="mr-50"></i>
                              <span>Adress</span>
                            </a>
                            <h5 class="px-25 py-50 m-0 filterTitleText text-muted">Document data</h5>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="file-text" class="mr-50"></i>
                              <span>Name</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="file-text" class="mr-50"></i>
                              <span>Description</span>
                            </a>
                            <a ngbDropdownItem href="javascript:void(0);">
                              <i data-feather="file-text" class="mr-50"></i>
                              <span>Type</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
              </div>

            </div>
          </div>

        </div>


        <!-- Advance Filters -->

      </div>
    </div>
  </div>
</div>
<!--/ Main Sidebar -->