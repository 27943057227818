import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-finnish-bank-idaddon',
  templateUrl: './finnish-bank-idaddon.component.html',
  styleUrls: ['./finnish-bank-idaddon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FinnishBankIDAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
