<div class="col-md-12 p-0">
    <div class="card text-center mb-1">
        <div class="card-body">
            <ul ngbNav #navTab="ngbNav" class="nav-tabs border-bottom">
                <li ngbNavItem>
                    <a ngbNavLink>All</a>
                    <ng-template ngbNavContent>
                        <div class="d-flex align-content-center my-1 border-bottom py-2 justify-content-end w-100 user-chats">
                            <div class="d-flex justify-content-end">
                                <div class="chatContainer">
                                    <div class="chat-body">
                                        <div class="chat-content">
                                            <p class="mb-0">Can I get details of my last transaction I made last month?</p>
                                            <div class="mb-25 dropdown-divider"></div>
                                            <div class="d-flex align-content-center mt-50 justify-content-between w-100">
                                                <small class="font-small-1 mt-25">Mubashir sami 02 01 2023 4:23</small>
                                                <div class="">
                                                    <div class="custom-control p-0 DocchatContainerCheckbox custom-control-success custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="CC_doctab_Checkboxone"
                                                            checked />
                                                        <label class="custom-control-label" for="CC_doctab_Checkboxone"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                            height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg"
                                            class="ng-star-inserted">
                                        <!---->
                                        <!----></span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-content-center my-1 border-bottom py-2 justify-content-end w-100 user-chats">
                            <div class="d-flex justify-content-end">
                                <div class="chatContainer">
                                    <div class="chat-body">
                                        <div class="chat-content">
                                            <p class="mb-0">Can I get details of my last transaction I made last month?</p>
                                            <div class="mb-25 dropdown-divider"></div>
                                            <div class="d-flex align-content-center mt-50 justify-content-between w-100">
                                                <small class="font-small-1 mt-25">Mubashir sami 02 01 2023 4:23</small>
                                                <div class="">
                                                    <div class="custom-control p-0 DocchatContainerCheckbox custom-control-success custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="CC_doctab_Checkboxtwo"
                                                            checked />
                                                        <label class="custom-control-label" for="CC_doctab_Checkboxtwo"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                            height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg"
                                            class="ng-star-inserted">
                                        <!---->
                                        <!----></span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-content-center my-1 border-bottom py-2 justify-content-end w-100 user-chats">
                            <div class="d-flex justify-content-end">
                                <div class="DocchatContainer">
                                    <div class="chat-body">
                                        <div class="chat-content">
                                            <div class="media-list">
                                                <div class="media pl-0">
                                                    <a class="media-left" (click)="toggleSidebar('Link-document-right-sidebar')">
                                                        <img src="assets/images/portrait/small/thumb.jpg" alt="Generic placeholder image"
                                                            height="150" width="110" />
                                                    </a>
                                                    <div class="media-body">
                                                        <p class="media-heading">Cookie candy</p>
                                                        <a href="#">Home</a> / <a href="#">Sent</a>
                                                    </div>
                                                </div>
                                                <div class="mb-25 dropdown-divider"></div>
                                                <div class="d-flex align-content-center mt-50 justify-content-between w-100">
                                                    <small class="font-small-1 mt-25">Mubashir sami 02 01 2023 4:23</small>
                                                    <div class="">
                                                        <div class="custom-control p-0 DocchatContainerCheckbox custom-control-success custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="CC_doctab_Checkboxthree"
                                                                checked />
                                                            <label class="custom-control-label" for="CC_doctab_Checkboxthree"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                            height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg"
                                            class="ng-star-inserted">
                                        <!---->
                                        <!----></span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a ngbNavLink>Notes</a>
                    <ng-template ngbNavContent>
                        <div class="d-flex align-content-center my-1 justify-content-end w-100 user-chats">
                            <div class="chatContainer">
                                <div class="chat-body">
                                    <div class="chat-content">
                                        <p class="mb-0">Can I get details of my last transaction I made last month?</p>
                                        <div class="mb-25 dropdown-divider"></div>
                                        <small class="font-small-1">Mubashir sami 02 01 2023 4:23</small>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                        height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg" class="ng-star-inserted">
                                    <!---->
                                    <!----></span>
                            </div>
                        </div>
                        <div class="d-flex align-content-center my-1 justify-content-end w-100 user-chats">
                            <div class="chatContainer">
                                <div class="chat-body">
                                    <div class="chat-content">
                                        <p class="mb-0">Can I get details of my last transaction I made last month?</p>
                                        <div class="mb-25 dropdown-divider"></div>
                                        <small class="font-small-1">Mubashir sami 02 01 2023 4:23</small>
                                    </div>
                                </div>
                            </div>
                            <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                        height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg" class="ng-star-inserted">
                                    <!---->
                                    <!----></span>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a href="javascript:void(0)" ngbNavLink>Activities</a>
                    <ng-template ngbNavContent>
                        <div class="d-flex align-content-center my-1 justify-content-end w-100 user-chats">
                            <div class="d-flex justify-content-end">
                                <div class="chatContainer">
                                    <div class="chat-body">
                                        <div class="chat-content">
                                            <p class="mb-0">Can I get details of my last transaction I made last month?</p>
                                            <h5 class="lineThroughtext">I get details</h5>
                                            <div class="mb-25 dropdown-divider"></div>
                                            <div class="d-flex align-content-center mt-50 justify-content-between w-100">
                                                <small class="font-small-1 mt-25">Mubashir sami 02 01 2023 4:23</small>
                                                <div class="">
                                                    <div class="custom-control p-0 DocchatContainerCheckbox custom-control-success custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="CC_doctab_CheckboxActOne"
                                                            checked />
                                                        <label class="custom-control-label" for="CC_doctab_CheckboxActOne"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                            height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg"
                                            class="ng-star-inserted">
                                        <!---->
                                        <!----></span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-content-center my-1 justify-content-end w-100 user-chats">
                            <div class="d-flex justify-content-end">
                                <div class="chatContainer">
                                    <div class="chat-body">
                                        <div class="chat-content">
                                            <p class="mb-0">Can I get details of my last transaction I made last month?</p>
                                            <h5 class="lineThroughtext">I get details</h5>
                                            <div class="mb-25 dropdown-divider"></div>
                                            <div class="d-flex align-content-center mt-50 justify-content-between w-100">
                                                <small class="font-small-1 mt-25">Mubashir sami 02 01 2023 4:23</small>
                                                <div class="">
                                                    <div class="custom-control p-0 DocchatContainerCheckbox custom-control-success custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="CC_doctab_CheckboxActtwo"
                                                            checked />
                                                        <label class="custom-control-label" for="CC_doctab_CheckboxActtwo"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                            height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg"
                                            class="ng-star-inserted">
                                        <!---->
                                        <!----></span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem>
                    <a href="javascript:void(0)" ngbNavLink>Documents</a>
                    <ng-template ngbNavContent>
                        <div class="d-flex align-content-center my-1 justify-content-end w-100 user-chats">


                            <div class="d-flex justify-content-end">
                                <div class="DocchatContainer">
                                    <div class="chat-body">
                                        <div class="chat-content">
                                            <div class="media-list">
                                                <div class="media pl-0">
                                                    <a class="media-left" (click)="toggleSidebar('Link-document-right-sidebar')">
                                                        <img src="assets/images/portrait/small/thumb.jpg" alt="Generic placeholder image"
                                                            height="150" width="110" />
                                                    </a>
                                                    <div class="media-body">
                                                        <p class="media-heading">Cookie candy</p>
                                                        <a href="#">Home</a> / <a href="#">Sent</a>
                                                    </div>
                                                </div>
                                                <div class="mb-25 dropdown-divider"></div>

                                                <div class="d-flex align-content-center mt-50 justify-content-between w-100">
                                                    <small class="font-small-1 mt-25">Mubashir sami 02 01 2023 4:23</small>
                                                    <div class="">
                                                        <div class="custom-control p-0 DocchatContainerCheckbox custom-control-success custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="CC_doctab_Checkbox"
                                                                checked />
                                                            <label class="custom-control-label" for="CC_doctab_Checkbox"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-avatar"><span class="avatar box-shadow-1 cursor-pointer"><img alt="avatar"
                                            height="36" width="36" src="assets/images/portrait/small/avatar-s-11.jpg"
                                            class="ng-star-inserted">
                                        <!---->
                                        <!----></span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="navTab" class="mt-0 pt-75"></div>
        </div>
    </div>
</div>