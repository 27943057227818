 import { Component, EventEmitter, ViewChild, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganizationService } from '../organization.service';
import { GlobalService } from 'app/global/global.service';

@Component({
  selector: 'app-organization-right-sidebar',
  templateUrl: './organization-right-sidebar.component.html',
  styleUrls: ['./organization-right-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'todo-application' }
})
export class OrganizationRightSidebarComponent implements OnInit {

  @Output('fetchOrganization') fetchOrganization : EventEmitter<any> = new EventEmitter();
  @Output('deleteOrganization') deleteOrganization : EventEmitter<any> = new EventEmitter();
  @Output('updateListing') updateListing : EventEmitter<void> = new EventEmitter();
  @ViewChild('auto') auto;
  
  public hiddenFields: boolean = false;
  public changeText = "Show more";
  public organizationForm: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;
  public changesDetect: boolean = false;
  public index = -1;
  public isDeleted: boolean = false;
  public organizationData: any = {};
  public keyword = 'firstname';
  public isLoading: boolean = false;
  public data:any = [];
  public connectedRecipients: any = [];

  constructor(
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService,
    private organizationService: OrganizationService,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    console.log('check333', this.auto)
    this.initOrganizationForm({});
  }


/**
 * Modal open form 
 * @param ModalId 
 */
  linkPeopleModalOpen(linkedPeopleModal) {
    this.modalService.open(linkedPeopleModal);
  }


/**
 * Modal open form 
 * @param ModalName 
 */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  
  showHiddenFields() {
    this.hiddenFields = !this.hiddenFields;
    this.hiddenFields ? this.changeText = "Hide" : this.changeText = "Show more"; 
  }

 
  /** 
   * Initializing Organization for edit and passing object to this Function
   * @param Object 
   */
  initOrganizationForm(data:any = {}){
    this.index = data?.index;
    this.organizationData = data;
    this.connectedRecipients = (data.recipient ?? []);
    this.organizationForm = new FormGroup({
      id: new FormControl(data?.id ?? ''),
      name : new FormControl(data?.name ?? '', [Validators.required]),
      register_no: new FormControl(data?.register_no ?? '', [Validators.required]),
      phone: new FormControl(data?.phone ?? ''),
      address: new FormControl(data?.address ?? ''),
      zipcode: new FormControl(data?.zipcode ?? ''),
      city: new FormControl(data?.city ?? ''),
      organization_image: new FormControl(data?.organization_image ?? '')
    });

    const initialValue = this.organizationForm.value;
      this.organizationForm.valueChanges.subscribe((value:any) => {
        this.changesDetect = Object.keys(initialValue).some(
          (key) => this.organizationForm.controls[key].value != 
          initialValue[key]
        );
    });
  }


 /** 
  * Convenience getter for easy access to form fields 
  */
  get form() {
    return this.organizationForm.controls;
  }


  /** 
   * Updating Organization Data
   */
  async updateOrganization(){
    this.submitted = true;
    this.loading = true;
    var form = this.organizationForm.value;
    if(this.organizationForm.invalid){
      return;
    }
    if(this.organizationData.name == form.name){
      delete form.name;
    }
    const updateOrganization = await this.organizationService.updateOrganization(form);
    if(updateOrganization.status != 200){
      this.globalService.toastrError(updateOrganization.data.message);
      this.loading = false;
      return;
    } 
    this.globalService.toastrSuccess(updateOrganization.data.message);
    this.loading = false;
    this.submitted = false;
    this.organizationForm.reset();
    this.toggleSidebar('organization-right-sidebar');
    this.changesDetect = false;
    this.fetchOrganization.emit();
  }

  /**
   *  Delete organization 
   */
  async removeOrganization() {
    this.isDeleted = true;
    const organization = await this.organizationService.deleteOrganization(this.organizationForm.value?.id);
    if(organization.status != 200){
      this.globalService.toastrError(organization.data.message);
      this.isDeleted = false;
      return;
    }
    this.deleteOrganization.emit({id: this.index});
    this.globalService.toastrSuccess(organization.data.message);
    this.isDeleted = false;
    this.toggleSidebar('organization-right-sidebar');
  }


  /**
   * Getting item on autocomplete select
   * @param item 
   * @returns 
   */
  async selectEvent(item) {
    var formData = {id:item.id, organization_id: this.organizationForm.value?.id};
    const responseData = await this.organizationService.link(formData);
    if(responseData?.status != 200){
      this.globalService.toastrWarning(responseData.data.message);
      return;
    }
    this.globalService.toastrSuccess(responseData.data.message);
    this.connectedRecipients[this.connectedRecipients.length] = item;
    this.data = [];
    // this.updateListing.emit({data: item, index: this.index});
  }


  /**
   * Fetching recipients on keyup
   * @param val 
   * @returns recipients data
   */
  async onChangeSearch(val: string) {
    if(val == ''){
      this.data = [];
      return;
    }
    this.isLoading = true;
    const recipients = await this.organizationService.searchRecipient(val); 
    this.data = recipients.data.data || [];
    this.isLoading = false;
  }

  onFocused(e:any) {
   
  }


 async unlinkRecipient($event:any , recipientData:any, index:any){
    var formData = {id:recipientData.id, organization_id: 0};
    const responseData = await this.organizationService.link(formData);
    if(responseData?.status != 200){
      this.globalService.toastrWarning(responseData.data.message);
      return;
    }
    this.globalService.toastrSuccess('Recipient unlinked!');
    this.connectedRecipients.splice(index, 1);
    console.log(this.organizationData, 'this.organizationData')
    // this.updateListing.emit({data: this.connectedRecipients, index: this.index});
  }

}
