<div class="card addonBoxContainer">
    <div class="card-body">
        <h5>Attest Participant</h5>
        <p class="card-text font-small-3 addonboxDiscription">
            Allows you do add a viewer participant as an alternative to signing.
        </p>
        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'check-circle'" [class]="'mr-25'"></span>Added</button>
        <img src="assets/images/addons-images/Attest.png" class="addonThumbnailImg" alt="addonThumbnail" />
    </div>
</div>  