<div class="slideout-content">
    <!-- Modal to add attachments starts-->
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('attachmentsidebar')">
                    ×
                </button>
                <div class="modal-header mb-1">
                    <h5 class="modal-title" id="exampleModalLabel">Create attachment</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <div class="form-group">
                        <label class="form-label" for="Attachment-Name">Attachment Name</label>
                        <input type="text" class="form-control dt-full-name" id="Attachment-Name" placeholder="Attachment name"
                            name="" aria-describedby="Attachment-Name" />
                    </div>
                    <div class="form-group">
                        <label for="customSelect">Include or exclude?</label>
                        <ng-select [items]="includeOrExclude" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                            placeholder="Include or exclude?"> </ng-select>
                    </div>
                    <div class="form-group">
                        <label for="customSelect">Uploading is</label>
                        <ng-select [items]="uploadingIs" bindLabel="name" [readonly]="readonly" [searchable]="searchable"
                            placeholder="Uploading is"> </ng-select>
                    </div>
                    <div class="custom-control custom-checkbox form-group">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" checked />
                        <label class="custom-control-label" for="customCheck1">Allow multiple</label>
                    </div>
                    <div class="custom-control custom-switch form-group">
                        <p class="mb-50">Save to contacts</p>
                        <div class="d-flex justify-content-between">
                            <small>settings</small>
                            <input type="checkbox" class="custom-control-input" id="saveToContacts" />
                            <label class="custom-control-label" for="saveToContacts"></label>
                        </div>
                    </div>
                    <div class="custom-control custom-switch form-group">
                        <p class="mb-50">Automatic deletion</p>
                        <div class="d-flex justify-content-between">
                            <small>settings</small>
                            <input type="checkbox" class="custom-control-input" id="automaticDeletion" />
                            <label class="custom-control-label" for="automaticDeletion"></label>
                        </div>
                    </div>
                    <label class="form-label" for="days">Days</label>
                    <div class=" input-group mb-2">
                        
                        <input type="text" class="form-control" id="days" placeholder="Days" aria-label="Days" />
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">Days</span>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mr-1" rippleEffect>Save</button>
                    <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('attachmentsidebar')" rippleEffect>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add attachments Ends-->
</div>