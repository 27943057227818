import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-forgottendocumentwidget',
  templateUrl: './forgottendocumentwidget.component.html',
  styleUrls: ['./forgottendocumentwidget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgottendocumentwidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
