<div class="documentFolderviewInnerCont p-2" [perfectScrollbar]>
    <div class="row match-height">
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="card file-manager-item border">
                <div class="card-img-top d-flex file-logo-wrapper">
                    <div class="documentFolderDropdown" ngbDropdown>
                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow mr-75 d-flex" id="todoActions"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i data-feather="more-vertical" [size]="14" class="text-body"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                            <a ngbDropdownItem class="sort-asc" (click)="addNewFolderModalOpenClick(addNewFolderModal)">
                                <i class="feather font-medium-3 mr-50 icon-folder-plus"></i>
                                Add folder</a>
                            <a ngbDropdownItem class="sort-asc" (click)="manageFolderModalOpenClick(manageFolderModal)">
                                <i class="feather font-medium-3 mr-50 icon-settings"></i>
                                Manage folder</a>
                            <a ngbDropdownItem class="sort-asc">
                                <i class="feather font-medium-3 mr-50 icon-trash"></i>
                                Delete Folder</a>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <i class="feather font-large-2 DocFolderIcon icon-folder"></i>
                        <!-- <img class="" src="assets/images/slider/04.jpg" alt="Card image cap" /> -->
                    </div>
                </div>
                <div class="card-body p-1">
                    <div class="docfileNamecontent-wrapper">
                        <p class="card-text file-name mb-0">
                            Signed
                        </p>
                    </div>
                    <small class="file-accessed text-muted">Last accessed: 18 hours ago</small>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="card file-manager-item border">
                <div class="card-img-top d-flex file-logo-wrapper">
                    <div class="documentFolderDropdown" ngbDropdown>
                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow mr-75 d-flex" id="todoActions"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i data-feather="more-vertical" [size]="14" class="text-body"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                            <a ngbDropdownItem class="sort-asc" (click)="addNewFolderModalOpenClick(addNewFolderModal)">
                                <i class="feather font-medium-3 mr-50 icon-folder-plus"></i>
                                Add folder</a>
                            <a ngbDropdownItem class="sort-asc" (click)="manageFolderModalOpenClick(manageFolderModal)">
                                <i class="feather font-medium-3 mr-50 icon-settings"></i>
                                Manage folder</a>
                            <a ngbDropdownItem class="sort-asc">
                                <i class="feather font-medium-3 mr-50 icon-trash"></i>
                                Delete Folder</a>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <i class="feather font-large-2 DocFolderIcon icon-folder"></i>
                        <!-- <img class="" src="assets/images/slider/04.jpg" alt="Card image cap" /> -->
                    </div>
                </div>
                <div class="card-body p-1">
                    <div class="docfileNamecontent-wrapper">
                        <p class="card-text file-name mb-0">
                            Draft
                        </p>
                    </div>
                    <small class="file-accessed text-muted">Last accessed: 18 hours ago</small>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="card file-manager-item border">
                <div class="card-img-top d-flex file-logo-wrapper">
                    <div class="documentFolderDropdown" ngbDropdown>
                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow mr-75 d-flex" id="todoActions"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i data-feather="more-vertical" [size]="14" class="text-body"></i>
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="todoActions">
                            <a ngbDropdownItem class="sort-asc" (click)="addNewFolderModalOpenClick(addNewFolderModal)">
                                <i class="feather font-medium-3 mr-50 icon-folder-plus"></i>
                                Add folder</a>
                            <a ngbDropdownItem class="sort-asc" (click)="manageFolderModalOpenClick(manageFolderModal)">
                                <i class="feather font-medium-3 mr-50 icon-settings"></i>
                                Manage folder</a>
                            <a ngbDropdownItem class="sort-asc">
                                <i class="feather font-medium-3 mr-50 icon-trash"></i>
                                Delete Folder</a>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <i class="feather font-large-2 DocFolderIcon icon-folder"></i>
                        <!-- <img class="" src="assets/images/slider/04.jpg" alt="Card image cap" /> -->
                    </div>
                </div>
                <div class="card-body p-1">
                    <div class="docfileNamecontent-wrapper">
                        <p class="card-text file-name mb-0">
                            Sent
                        </p>
                    </div>
                    <small class="file-accessed text-muted">Last accessed: 18 hours ago</small>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="card file-manager-item border">
                <div class="card-img-top d-flex file-logo-wrapper p-0">
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <!-- <i class="feather font-large-2 DocFolderIcon icon-folder"></i> -->
                        <img class="documentFolderThumbnail" src="assets/images/pages/documentThumbOne.jpg" alt="Card image cap" />
                    </div>
                </div>
                <div class="card-body p-1">
                    <div class="docfileNamecontent-wrapper">
                        <p class="card-text file-name mb-0">
                            Termination Letter Template
                        </p>
                    </div>
                    <small class="file-accessed text-muted">Last accessed: 18 hours ago</small>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="card file-manager-item border">
                <div class="card-img-top d-flex file-logo-wrapper p-0">
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <!-- <i class="feather font-large-2 DocFolderIcon icon-folder"></i> -->
                        <img class="documentFolderThumbnail" src="assets/images/pages/documentThumbOne.jpg" alt="Card image cap" />
                    </div>
                </div>
                <div class="card-body p-1">
                    <div class="docfileNamecontent-wrapper">
                        <p class="card-text file-name mb-0">
                            Termination Letter Template
                        </p>
                    </div>
                    <small class="file-accessed text-muted">Last accessed: 18 hours ago</small>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3 col-12">
            <div class="card file-manager-item border">
                <div class="card-img-top d-flex file-logo-wrapper p-0">
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <!-- <i class="feather font-large-2 DocFolderIcon icon-folder"></i> -->
                        <img class="documentFolderThumbnail" src="assets/images/pages/documentThumbOne.jpg" alt="Card image cap" />
                    </div>
                </div>
                <div class="card-body p-1">
                    <div class="docfileNamecontent-wrapper">
                        <p class="card-text file-name mb-0">
                            Termination Letter Template
                        </p>
                    </div>
                    <small class="file-accessed text-muted">Last accessed: 18 hours ago</small>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- add folder Modal -->
<ng-template #addNewFolderModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Add New Folder</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <label>Add folder </label>
            <div class="form-group">
                <input type="text" placeholder="Add folder" class="form-control" />
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-success" rippleEffect>
            Create
        </button>
    </div>
</ng-template>
<!-- / add folder Modal -->

<!-- manage Folder Modal -->
<ng-template #manageFolderModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Manage your folder</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form action="#">
            <div class="col-12 p-0">
                <label>Folder name</label>
                <div class="form-group">
                    <input type="text" placeholder="Folder name" class="form-control" />
                </div>
            </div>
            <div class="col-12 p-0">
                <label>Use this subject to email documents to this folder</label>
                <div class="form-group">
                    <input type="text" placeholder="" class="form-control" />
                </div>
            </div>
            <p class="mt-1">Folder owner is <a href="#">Jenny Looper</a></p>
            <small>You've shared this folder with the following users:</small>
            <div class="avatar-group mt-50">
                <div placement="top" class="avatar pull-up" ngbTooltip="Vinnie Mostowy" placement="top" container="body">
                    <img class="media-object" src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="32"
                        width="32" />
                </div>
                <div placement="top" class="avatar pull-up" ngbTooltip="Elicia Rieske" placement="top" container="body">
                    <img class="media-object" src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="32"
                        width="32" />
                </div>
                <div placement="top" class="avatar pull-up" ngbTooltip="Julee Rossignol" placement="top" container="body">
                    <img class="media-object" src="assets/images/portrait/small/avatar-s-10.jpg" alt="Avatar" height="32"
                        width="32" />
                </div>
                <div placement="top" class="avatar pull-up" ngbTooltip="Darcey Nooner" placement="top" container="body">
                    <img class="media-object" src="assets/images/portrait/small/avatar-s-8.jpg" alt="Avatar" height="32"
                        width="32" />
                </div>
                <div placement="top" class="avatar pull-up" ngbTooltip="Jenny Looper" placement="top" container="body">
                    <img class="media-object" src="assets/images/portrait/small/avatar-s-20.jpg" alt="Avatar" height="32"
                        width="32" />
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('Cross click')" rippleEffect>
            Cancel
        </button>
        <button type="button" class="btn btn-primary" rippleEffect>
            Save
        </button>
    </div>
</ng-template>
<!-- / manage Folder Modal -->