import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import 'hammerjs';
import { AuthGuard } from './auth/helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { PagesModule } from 'app/main/pages/pages.module';

import { DashboardComponent } from './main/pages/dashboard/dashboard.component';
import { DashboardModule } from './main/pages/dashboard/dashboard.module';
import { TemplatesComponent } from './main/pages/templates/templates.component';
import { TemplatesModule } from './main/pages/templates/tamplates.module';
import { UsersModule } from './main/pages/users/users.module';
import { EditTemplateComponent } from './main/pages/edit-template/edit-template.component';
import { DocumentsComponent } from './main/pages/documents/documents.component';
import { DocumentsModule } from './main/pages/documents/documents.module';
import { EditTemplateModule } from './main/pages/edit-template/edit-template.module';
import { ContractControlModule } from './main/pages/contract-control/contract-control.module';
import { ProfileSettingsModule } from './main/pages/profile-settings/profile-settings.module';

import { ColorPickerModule } from 'ngx-color-picker';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { NgApexchartsModule } from "ng-apexcharts";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CommonModule } from '@angular/common';
import { from } from 'rxjs';

import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { SigningPagesModule } from './main/signing-pages/signingpages.module';


const appRoutes: Routes = [
  // {
  //   path: 'pages',
  //   loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  // },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { animation: 'zoomIn' },
    canActivate:[AuthGuard]
  },
  {
    path: 'templates',
    component: TemplatesComponent,
    data: { animation: 'zoomIn' },
    canActivate:[AuthGuard]
  },
  {
    path: 'documents',
    component: DocumentsComponent,
    data: { animation: 'zoomIn' },
    canActivate:[AuthGuard]
  },
  {
    path: 'edit-template',
    component: EditTemplateComponent,
    data: { animation: 'zoomIn' },
    canActivate:[AuthGuard]
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/miscellaneous/error' //Error 404 - Page not found
  },
  
];

@NgModule({
  declarations: [AppComponent,DashboardComponent,TemplatesComponent,EditTemplateComponent,DocumentsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,

    //Pages
    PagesModule,
    
    //dashboard
    DashboardModule,

    // Templates
    TemplatesModule,

    // Documents
    DocumentsModule,

    //contractcontrol
    ContractControlModule,

    //profileSettings
    ProfileSettingsModule,
    UsersModule,

    


    EditTemplateModule,

    NgApexchartsModule,

    PerfectScrollbarModule,

    CKEditorModule,

    ColorPickerModule,

    CoreTouchspinModule,
    SigningPagesModule
  ],

  bootstrap: [AppComponent],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},AuthGuard]
})
export class AppModule {}
