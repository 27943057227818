import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
  @Input('section') section: string;
  @Input('loop') loop: any;
  constructor() { }

  ngOnInit(): void {
    this.loop = Array.from(Array(this.loop).keys());
  }

}
