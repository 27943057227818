<div class="slideout-content">
    <!-- Modal to add attachments starts-->
    <div class="modalsd modal-slide-in sdfade add-recipient-right-sidebar" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <div class="modal-header mb-1 d-flex justify-content-between align-items-center px-2 py-1">
                    <h5 class="modal-title" id="exampleModalLabel">Add New Contact</h5>
                    <div class="d-flex justify-content-between align-items-center text-dark cursor-pionter">
                        <span placement="auto" container="body" ngbTooltip="Close" (click)="toggleSidebar('add-recipient-right-sidebar')">
                            <i data-feather="x" [size]="16" data-dismiss="modal" aria-label="Close"></i>
                        </span>
                    </div>
                </div>
                <div class="modal-body flex-grow-1 p-0">
                    <div class="addRecipient-scroll-content-container px-2" [perfectScrollbar]>
                        <form [formGroup]="recipientForm" (ngSubmit)="saveRecipient()">
                            <div class="form-group">
                                <label class="form-label" for="first-Name">First Name</label>
                                <input type="text" formControlName="firstname" class="form-control" aria-describedby="first-Name" [ngClass]="{ 'is-invalid error': submitted && form.firstname.errors }" />
                                <div *ngIf="submitted && form.firstname.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && form.firstname.errors }">
                                    <div *ngIf="form.firstname.errors.required">First name is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="last-Name">Last Name</label>
                                <input type="text" formControlName="lastname" class="form-control" aria-describedby="last-Name" />
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="email">Email</label>
                                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.email.errors }" aria-describedby="email" />
                                <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
                                    <div *ngIf="form.email.errors.required">Email is required</div>
                                    <div *ngIf="form.email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="phone">Phone</label>
                                <input id="phone" type="tel" class="form-control" name="phone" aria-describedby="phone" [ngClass]="{ 'is-invalid': submitted && !phone_no_valid }" />
                                <div [ngClass]="{ 'is-invalid': submitted && !phone_no_valid }"></div>
                                <div *ngIf="submitted && !phone_no_valid" class="invalid-feedback">
                                    <div *ngIf="!phone_no_valid">Phone number is not valid</div>
                                </div>
                            </div>
                            <div *ngIf="hiddenFields">
                                <div class="form-group">
                                    <label class="form-label" for="persnol-id-number">Persnol ID Number</label>
                                    <input type="text" formControlName="persnol_no" class="form-control" aria-describedby="persnol-id-number" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="address">Address</label>
                                    <input type="text" formControlName="address" class="form-control" aria-describedby="address" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="zip-code">Zip Code</label>
                                    <input type="text" formControlName="zipcode" class="form-control" aria-describedby="zip-code" />
                                </div>
                                <div class="form-group">
                                    <label class="form-label" for="city">City</label>
                                    <input type="text" formControlName="city" class="form-control" aria-describedby="city" />
                                </div>
                            </div>
                            <button type="button" class="btn p-0 ml-auto btn-sm d-flex text-primary btn-transparent" (click)="showHiddenFields()">{{changeText}}</button>
                            <div class="d-flex mt-2">
                                <button [disabled]="loading" type="submit" class="btn btn-primary mr-1" rippleEffect><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Save</button>
                                <button [disabled]="loading" type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('add-recipient-right-sidebar')" rippleEffect>
                                Cancel
                            </button>
                            </div>
                        </form>

                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Modal to add attachments Ends-->
</div>