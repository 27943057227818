import { Component, OnInit, ViewEncapsulation, Output,EventEmitter, Input, OnChanges, SimpleChanges, Injectable } from '@angular/core';
@Component({
  selector: 'app-recipients-tab',
  templateUrl: './recipients-tab.component.html',
  styleUrls: ['./recipients-tab.component.scss'],
  // encapsulation: ViewEncapsulation.None

})
@Injectable({
  providedIn: 'root',
})
export class RecipientsTabComponent implements OnInit {
  @Output() detectChange:any = new EventEmitter();
  // @Input() hideBackButton:any;
  constructor() { }
  recipientViewType = 0;
  ngOnInit(): void {
    // console.log(this.hideBackButton,'changes');
  }

  goToRecipientDetail(){
    this.detectChange.emit({viewType:'detail'});
    this.recipientViewType = 1;
  }

  // ngOnChanges(changes:SimpleChanges){
  //   console.log(changes,'changes',);
  //   if(changes.hideBackButton && changes.hideBackButton.currentValue == 'hide'){
  //     this.recipientViewType = 0;
  //   }
  // }
}
