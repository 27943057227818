import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viewer-party-addon',
  templateUrl: './viewer-party-addon.component.html',
  styleUrls: ['./viewer-party-addon.component.scss']
})
export class ViewerPartyAddonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
