<div class="card addonBoxContainer" (click)="modalOpen(simpleStorageSettingModal)">
    <div class="card-body">
        <h5>Simple Storage</h5>
        <p class="card-text font-small-3 addonboxDiscription">Upload any document and have a smart, secure and
            award-winning archive for your business.</p>

        <button type="button" class="btn p-0 mr-auto btn-sm mt-0 addAddonBtn btn-transparent text-primary"> <span
                [data-feather]="'plus'" [class]="'mr-25'"></span>Add</button>
        <!-- <img src="assets/images/addons-images/contract.png" class="addonThumbnailImg" alt="addonThumbnail" /> -->
        <span [data-feather]="'hard-drive'" [size]="'70'" [class]="'mr-25 addonThumbnailImg'"></span>
    </div>
</div>

<!-- Modal -->
<ng-template #simpleStorageSettingModal let-modal>
    <div class="modal-header">
        <div class="addonPopupHeader">
            <h4 class="modal-title" id="myModalLabel1">Simple Storage</h4>
            <small>Powered by Simple Sign</small>
        </div>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
        <button type="button" class="btn-sm btn btn-flat-success waves-effect m-0 addonActivateBtn" rippleEffect>
            Activate Add-on</button>
        <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom">
            <li ngbNavItem>
                <a ngbNavLink>Overview</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-7 col-lg-7">
                            <p class="font-small-3">
                                Create and share folders with Simple Storage.<br><br>
                                Archive documents signed with SimpleSign. Or upload documents or complete folders into
                                your archive. There is also an option to email files directly to a specific folder.<br><br>
                                Structure the documents in folders and share with SimpleSign or external users. Access
                                is given with separate login. Set reminders, add comments etc.<br><br>
                                Explore these and many more smart features in Simple Storage.
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-5 addon-swiper-Cont">
                            <swiper class="swiper-paginations swiper-container" [config]="swiperPaginations">
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\SimpleStorage\Archive1.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\SimpleStorage\Archive2.png"
                                    alt="banner" />
                                <img class="img-fluid" src="assets\images\addons-images\addon-thumbnails\SimpleStorage\Archive3.png"
                                    alt="banner" />
                            </swiper>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Settings</a>
                <ng-template ngbNavContent>
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <div class="input-group input-group-merge mb-2">
                                <input type="text" class="form-control" placeholder="User email" aria-label="User email"
                                    aria-describedby="basic-addon6" />
                                <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon6">@simplesign.io</span>
                                </div>
                            </div>
                            <p class="font-small-3">
                                With archive you get an award-winning archive solution for your business. Simply upload
                                any document into your archive, create folders and share the folders to internal
                                co-workers or external clients.
                                <br>
                                <br>
                                You can upload documents by dropping files or complete folders into your archive. There
                                is also an option to email files directly to a specific folder. Set reminders on
                                documents and add comments if needed.
                                <br>
                                <br>
                                Automatically archive signed contracts into the folder you want. You can also
                                automatically create folders based on templates or recipients. Why not auto-generate a
                                folder for each of your clients?
                                <br>
                                <br>
                                Explore many more smart archive features in this award-winning, secure and intuitive
                                digital archive.
                            </p>
                            <div class="form-group">
                                <input type="text" class="form-control" id="basicInput" placeholder="Add multiple email here" />
                            </div>
                            <div class="accessTokenCont">
                                <label for="basicInput">Import documents via API (access TOKEN)</label>
                                <div class="accessTokeninnerDiv">
                                    <ul class="list-group">
                                        <li class="list-group-item font-small-3 list-group-item-primary">https://esign.simplesign.io/api/v3/request/uploadfileforarchive</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</ng-template>
<!-- / Modal -->